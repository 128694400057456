import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Tooltip
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Book as BookIcon,
  Headphones as HeadphonesIcon,
  Download as DownloadIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  listReadListenContent,
  createReadListenContent,
  updateReadListenContent,
  deleteReadListenContent,
  listUserProgress,
  getUserProgressStats
} from '../api';

dayjs.extend(relativeTime);

const EditorContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .quill': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100vh - 280px)',
  },
  '& .ql-toolbar': {
    border: '1px solid #404040',
    borderRadius: '4px 4px 0 0',
    background: '#2d2d2d',
    '& .ql-stroke': {
      stroke: '#ffffff',
    },
    '& .ql-fill': {
      fill: '#ffffff',
    },
    '& .ql-picker': {
      color: '#ffffff',
    },
    '& .ql-picker-options': {
      backgroundColor: '#2d2d2d',
      border: '1px solid #404040',
    },
  },
  '& .ql-container': {
    flex: 1,
    overflow: 'auto',
    border: '1px solid #404040',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    backgroundColor: '#2d2d2d',
    '&.ql-snow': {
      border: '1px solid #404040',
      borderTop: 'none',
    },
  },
  '& .ql-editor': {
    minHeight: '400px',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    color: '#ffffff',
    '&.ql-blank::before': {
      color: '#666666',
    },
  },
});

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    ['link', 'blockquote'],
    [{ 'align': [] }],
    ['clean']
  ]
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const ReadListenPage = () => {
  const [tab, setTab] = useState(0);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingContent, setEditingContent] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content_type: 'TEXT',
    sequence_number: 1,
    is_active: true,
    text_content: '',
    audio_file: null
  });
  const [userProgress, setUserProgress] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData();
  }, [tab]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const contentResponse = await listReadListenContent();
      // The response already contains the array of content items
      setContents(contentResponse.data || []);
  
      if (tab === 1) {
        const progressResponse = await listUserProgress();
        setUserProgress(progressResponse.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleDialogOpen = (content = null) => {
    if (content) {
      setEditingContent(content);
      setFormData({
        title: content.title,
        description: content.description === 'NA' ? '' : content.description,
        content_type: content.content_type,
        sequence_number: content.sequence_number,
        is_active: content.is_active,
        text_content: content.text_content || '',
        audio_file: null // We'll keep the existing file unless changed
      });
    } else {
      setEditingContent(null);
      setFormData({
        title: '',
        description: '',
        content_type: 'TEXT',
        sequence_number: contents.length + 1,
        is_active: true,
        text_content: '',
        audio_file: null
      });
    }
    setDialogOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!formData.title.trim()) {
        throw new Error('Title is required');
      }

      if (formData.content_type === 'TEXT' && !formData.text_content.trim()) {
        throw new Error('Content is required for text type');
      }

      if (formData.content_type === 'AUDIO' && !formData.audio_file && !editingContent?.audio_file) {
        throw new Error('Audio file is required for audio type');
      }

      if (editingContent) {
        await updateReadListenContent(editingContent.id, formData);
        enqueueSnackbar('Content updated successfully', { variant: 'success' });
      } else {
        await createReadListenContent(formData);
        enqueueSnackbar('Content created successfully', { variant: 'success' });
      }
      setDialogOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error saving content:', error);
      enqueueSnackbar(error.message || 'Failed to save content', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (contentId) => {
    if (!window.confirm('Are you sure you want to delete this content?')) return;

    try {
      await deleteReadListenContent(contentId);
      enqueueSnackbar('Content deleted successfully', { variant: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error deleting content:', error);
      enqueueSnackbar('Failed to delete content', { variant: 'error' });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      );
    }

    if (tab === 0) {
        return (
          <Grid container spacing={3}>
            {contents.map((content) => (
              <Grid item xs={12} md={6} lg={4} key={content.id}>
                <StyledCard>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {content.content_type === 'TEXT' ? (
                        <BookIcon sx={{ mr: 1 }} />
                      ) : (
                        <HeadphonesIcon sx={{ mr: 1 }} />
                      )}
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {content.title}
                      </Typography>
                    </Box>
      
                    <Typography color="textSecondary" gutterBottom>
                      {content.description !== 'NA' ? content.description : ''}
                    </Typography>
      
                    {/* Content Display Section */}
                    {content.content_type === 'TEXT' && content.text_content && (
                      <Box sx={{ 
                        mt: 2, 
                        maxHeight: '300px', 
                        overflow: 'auto',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: 1,
                        p: 2
                      }}>
                        <div 
                          dangerouslySetInnerHTML={{ __html: content.text_content }}
                          className="prose prose-invert max-w-none"
                        />
                      </Box>
                    )}
      
                    {content.content_type === 'AUDIO' && content.audio_file && (
                      <Box sx={{ mt: 2 }}>
                        <audio 
                          controls 
                          className="w-full"
                          src={content.audio_file}
                        >
                          <source src={content.audio_file} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                      </Box>
                    )}
      
                    <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                      <Chip
                        label={`Sequence: ${content.sequence_number}`}
                        size="small"
                      />
                      <Chip
                        label={content.is_active ? 'Active' : 'Inactive'}
                        color={content.is_active ? 'success' : 'default'}
                        size="small"
                      />
                      <Chip
                        label={`Completions: ${content.completion_count}`}
                        size="small"
                        color="primary"
                      />
                      <Chip
                        label={`Created by: ${content.created_by_name}`}
                        size="small"
                        variant="outlined"
                      />
                    </Box>
      
                    <Box sx={{ 
                      mt: 2, 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      gap: 1 
                    }}>
                      <Tooltip title="Edit">
                        <IconButton 
                          onClick={() => handleDialogOpen(content)}
                          sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton 
                          onClick={() => handleDelete(content.id)}
                          color="error"
                          sx={{ '&:hover': { bgcolor: 'error.light' } }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        );
      }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Completed At</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userProgress
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((progress) => (
                <TableRow key={progress.id} hover>
                  <TableCell>{progress.user_full_name}</TableCell>
                  <TableCell>{progress.content_title}</TableCell>
                  <TableCell>
                    <Chip
                      label={progress.content_type}
                      size="small"
                      icon={progress.content_type === 'TEXT' ? <BookIcon /> : <HeadphonesIcon />}
                    />
                  </TableCell>
                  <TableCell>
                    {dayjs(progress.completed_at).fromNow()}
                  </TableCell>
                  <TableCell>{progress.notes}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userProgress.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Read & Listen Content
        </Typography>
        {tab === 0 && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleDialogOpen()}
          >
            Add New Content
          </Button>
        )}
      </Box>

      <Paper sx={{ mb: 4 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Content Management" />
          <Tab label="User Progress" />
        </Tabs>
      </Paper>

      {renderContent()}

      <Dialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            {editingContent ? 'Edit Content' : 'Add New Content'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Content Type</InputLabel>
                  <Select
                    value={formData.content_type}
                    onChange={(e) => setFormData({ ...formData, content_type: e.target.value })}
                    label="Content Type"
                  >
                    <MenuItem value="TEXT">Text</MenuItem>
                    <MenuItem value="AUDIO">Audio</MenuItem>
                  </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Sequence Number"
                  value={formData.sequence_number}
                  onChange={(e) => setFormData({ ...formData, sequence_number: parseInt(e.target.value) })}
                  required
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={formData.is_active}
                    onChange={(e) => setFormData({ ...formData, is_active: e.target.value })}
                    label="Status"
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {formData.content_type === 'TEXT' ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Content
                  </Typography>
                  <EditorContainer>
                    <ReactQuill
                      theme="snow"
                      value={formData.text_content}
                      onChange={(value) => setFormData({ ...formData, text_content: value })}
                      modules={quillModules}
                      placeholder="Start writing your content..."
                    />
                  </EditorContainer>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <input
                      accept="audio/mp3"
                      type="file"
                      id="audio-file-input"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && !file.type.includes('audio/')) {
                          enqueueSnackbar('Please select an audio file', { variant: 'error' });
                          return;
                        }
                        setFormData({ ...formData, audio_file: file });
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="audio-file-input">
                      <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        startIcon={<DownloadIcon />}
                      >
                        {formData.audio_file ? formData.audio_file.name : 'Upload Audio File (MP3)'}
                      </Button>
                    </label>
                  </Box>
                  {editingContent && editingContent.audio_file && !formData.audio_file && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Current Audio:
                      </Typography>
                      <audio controls style={{ width: '100%' }}>
                        <source src={editingContent.audio_file} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                      <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                        Current file will be kept if no new file is uploaded
                      </Typography>
                    </Box>
                  )}
                  {formData.audio_file && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Selected Audio Preview:
                      </Typography>
                      <audio controls style={{ width: '100%' }}>
                        <source src={URL.createObjectURL(formData.audio_file)} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setDialogOpen(false)}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Saving...' : (editingContent ? 'Update' : 'Create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default ReadListenPage;