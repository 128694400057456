import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  CircularProgress,
  Paper,
  LinearProgress,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
  CheckCircle as CheckCircleIcon,
  DoneAll as DoneAllIcon,
  Book as BookIcon,
  Headphones as HeadphonesIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  getNextAvailableContent,
  getMyProgress1,
  markContentComplete1,
  logContentAction
} from '../api';

dayjs.extend(relativeTime);

// Helper function to format time
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const ReadListenStudentPage = () => {
  const [currentContent, setCurrentContent] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioProgress, setAudioProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [completionNotes, setCompletionNotes] = useState('');
  const [progressHistory, setProgressHistory] = useState([]);
  const audioRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Reset audio states when content changes
    if (currentContent && currentContent.content_type === 'AUDIO') {
      setAudioPlaying(false);
      setAudioProgress(0);
      setCurrentTime(0);
      setDuration(0);
    }
  }, [currentContent]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Get progress data first
      const progressResponse = await getMyProgress1();
      setProgress(progressResponse.data);
      setProgressHistory(progressResponse.data.completed_content || []);
  
      // Only fetch next content if we haven't completed everything
      if (progressResponse.data.completion_percentage < 100) {
        try {
          const contentResponse = await getNextAvailableContent();
          setCurrentContent(contentResponse.data);
        } catch (error) {
          // Handle 404 for no content available
          if (error.response && error.response.status === 404) {
            setCurrentContent(null);
          } else {
            throw error; // Re-throw other errors to be caught by outer catch
          }
        }
      } else {
        // If completion is 100%, we know there's no more content
        setCurrentContent(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar(
        error.response?.data?.message || 'Failed to fetch content', 
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  const secondsToIsoDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const milliseconds = Math.round((seconds % 1) * 1000000);
    return `PT${hours}H${minutes}M${secs}.${milliseconds}S`;
  };

  const handleAudioPlay = () => {
    if (audioRef.current) {
      if (audioPlaying) {
        audioRef.current.pause();
        logContentAction({
          content_id: currentContent.id,
          action: 'paused',
          position: secondsToIsoDuration(audioRef.current.currentTime)
        });
      } else {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          enqueueSnackbar('Error playing audio file', { variant: 'error' });
        });
        logContentAction({
          content_id: currentContent.id,
          action: 'started',
          position: secondsToIsoDuration(audioRef.current.currentTime)
        });
      }
      setAudioPlaying(!audioPlaying);
    }
  };

  const handleAudioTimeUpdate = () => {
    if (audioRef.current) {
      const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setAudioProgress(progress);
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleAudioEnded = () => {
    setAudioPlaying(false);
    setAudioProgress(100);
    logContentAction({
      content_id: currentContent.id,
      action: 'completed',
      position: secondsToIsoDuration(audioRef.current.duration)
    });
  };

  const handleAudioLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleMarkComplete = async () => {
    try {
      const data = {
        notes: completionNotes
      };
  
      if (currentContent.content_type === 'AUDIO' && audioRef.current) {
        // Convert seconds to ISO duration string that Django can parse
        const hours = Math.floor(audioRef.current.currentTime / 3600);
        const minutes = Math.floor((audioRef.current.currentTime % 3600) / 60);
        const seconds = Math.floor(audioRef.current.currentTime % 60);
        const milliseconds = Math.round((audioRef.current.currentTime % 1) * 1000000);
        
        // Format as ISO 8601 duration string
        data.listened_duration = `PT${hours}H${minutes}M${seconds}.${milliseconds}S`;
      }
  
      await markContentComplete1(currentContent.id, data);
      enqueueSnackbar('Content marked as complete!', { variant: 'success' });
      setCompletionDialogOpen(false);
      fetchData();
    } catch (error) {
      console.error('Error marking content complete:', error);
      enqueueSnackbar('Failed to mark content as complete', { variant: 'error' });
    }
  };

  const CompletedMessage = ({ stats }) => {
    // Add null check
    if (!stats) return null;
  
    return (
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <DoneAllIcon sx={{ fontSize: 48, color: 'success.main', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          All Content Completed! 🎉
        </Typography>
        <Typography color="textSecondary" sx={{ mb: 2 }}>
          You've completed all available content ({stats.total_completed} items):
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mb: 2 }}>
          <Box sx={{ textAlign: 'center' }}>
            <HeadphonesIcon color="primary" />
            <Typography variant="body2" color="textSecondary">
              {stats.audio_completed} Audio
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <BookIcon color="primary" />
            <Typography variant="body2" color="textSecondary">
              {stats.text_completed} Text
            </Typography>
          </Box>
        </Box>
        <Typography color="textSecondary">
          Check back later for new content updates!
        </Typography>
      </Paper>
    );
  };

  const renderAudioPlayer = () => {
    const audioUrl = currentContent?.audio_file;
    if (!audioUrl) return null;

    return (
      <Box sx={{ mt: 3 }}>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={handleAudioTimeUpdate}
          onEnded={handleAudioEnded}
          onLoadedMetadata={handleAudioLoadedMetadata}
          style={{ width: '100%' }}
          controls={false}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton 
            onClick={handleAudioPlay}
            size="large"
            color="primary"
          >
            {audioPlaying ? <PauseIcon /> : <PlayIcon />}
          </IconButton>
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <LinearProgress variant="determinate" value={audioProgress} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Typography variant="caption">{formatTime(currentTime)}</Typography>
              <Typography variant="caption">{formatTime(duration)}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={4}>
        {/* Progress Overview */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Your Progress
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="textSecondary">
                Overall Completion
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress?.completion_percentage || 0}
                sx={{ mt: 1, mb: 1 }}
              />
              <Typography variant="body2">
                {progress?.total_completed || 0} of {progress?.total_available || 0} completed
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" gutterBottom>
              Recent Completions
            </Typography>
            <List>
              {progressHistory.slice(0, 5).map((item) => (
                <ListItem key={item.id}>
                  <ListItemIcon>
                    {item.content_type === 'TEXT' ? <BookIcon /> : <HeadphonesIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.content_title}
                    secondary={dayjs(item.completed_at).fromNow()}
                  />
                  <DoneAllIcon color="success" />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Current Content */}
        <Grid item xs={12} md={8}>
  {loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <CircularProgress />
    </Box>
  ) : progress ? (  // Add check for progress
    currentContent ? (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {currentContent.title}
          </Typography>
          <Typography color="textSecondary" paragraph>
            {currentContent.description}
          </Typography>
          
          {currentContent.content_type === 'TEXT' ? (
            <Box sx={{ mt: 3 }}>
              <div dangerouslySetInnerHTML={{ __html: currentContent.text_content }} />
            </Box>
          ) : (
            renderAudioPlayer()
          )}

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<CheckCircleIcon />}
              onClick={() => setCompletionDialogOpen(true)}
            >
              Mark as Complete
            </Button>
          </Box>
        </CardContent>
      </Card>
    ) : (
      <CompletedMessage stats={progress} />
    )
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <Typography color="textSecondary">Loading progress data...</Typography>
    </Box>
  )}
</Grid>
      </Grid>

      {/* Completion Dialog */}
      <Dialog open={completionDialogOpen} onClose={() => setCompletionDialogOpen(false)}>
        <DialogTitle>Mark Content as Complete</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Add notes (optional)"
            fullWidth
            multiline
            rows={4}
            value={completionNotes}
            onChange={(e) => setCompletionNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompletionDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleMarkComplete} variant="contained">
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReadListenStudentPage;