import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Button,
  TextField,
  Pagination,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Chip,
  Tab,
  Tabs,
  Avatar,
  useMediaQuery
} from '@mui/material';
import TabPanel from '../components/TabPanel';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import EnhancedAttendanceReviewTable from '../components/EnhancedAttendanceReviewTable';
import StudentDetailsModal from '../components/StudentDetailsModal';
import { 
  getDashboardStats, 
  listTrainers,
  listUsers,
  listAttendanceReviews,
  updateAttendance,
  updateAttendanceReview,
  listStudentFeedbacks,
  respondToStudentFeedback,
  getTrainerOccupation,
  listCourseHolds,
  approveCourseHold,
  rejectCourseHold,
  listNotices,
  listLeaveRequests,
  approveLeaveRequest,
  rejectLeaveRequest,
  getLeaveRequestHistory,
  createNotice,
  processAttendanceReview,
  getUser,
  getCourse,
  getStudentCourse,
  deleteNotice,
  getCurrentUser
} from '../api';
import MessagingComponent from '../components/MessagingComponent';
import { formatDate } from '../utils/dateUtils';
import NoticeCard from './NoticeCard';
import {
  getAttendanceReviewHistory,
  getStudentFeedbackHistory,
  getCourseHoldHistory
} from '../api';
import TrainersTable from './TrainersTable';
import AdminDashboardLoader from '../components/AdminDashboardLoader';
import { useTheme } from '@mui/material/styles';

// Styled components (unchanged from original)
const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(15, 23, 42, 0.9)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
  },
}));

const GradientChip = styled(Chip)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const ManagersDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dashboardData, setDashboardData] = useState({
    total_trainers: 0,
    total_attendance_reviews: 0,
    total_student_feedbacks: 0,
    total_course_holds: 0,
    total_leave_requests: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const [courseHolds, setCourseHolds] = useState([]);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [notices, setNotices] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveRequestModalOpen, setLeaveRequestModalOpen] = useState(false);
  const [leaveRemarks, setLeaveRemarks] = useState('');
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [studentFeedbacks, setStudentFeedbacks] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [trainerNames, setTrainerNames] = useState({});
  const [leaveRequestHistory, setLeaveRequestHistory] = useState([]);
  const [reviewHistoryModalOpen, setReviewHistoryModalOpen] = useState(false);
  const [feedbackHistoryModalOpen, setFeedbackHistoryModalOpen] = useState(false);
  const [courseHoldHistoryModalOpen, setCourseHoldHistoryModalOpen] = useState(false);
  const [reviewHistory, setReviewHistory] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [courseHoldHistory, setCourseHoldHistory] = useState([]);
  const [activeLeaveTab, setActiveLeaveTab] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [trainerDetails, setTrainerDetails] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [newNotice, setNewNotice] = useState({
    title: '',
    content: '',
    audience: 'ALL',
    attachments: [],
    links: []
  });

  // Cache to store API responses and avoid duplicate requests
  const [userCache, setUserCache] = useState({});
  const [courseCache, setCourseCache] = useState({});
  const [studentCourseCache, setStudentCourseCache] = useState({});
  
  // Data fetching status tracking
  const isDataFetching = useRef(false);
  const dataRefreshCounter = useRef(0);
  const fileInputRef = useRef(null);

  // Helper function to get user data from cache or API
  const getUserData = useCallback(async (userId) => {
    if (!userId) return null;
    
    // Return from cache if available
    if (userCache[userId]) {
      return userCache[userId];
    }
    
    try {
      const response = await getUser(userId);
      const userData = response.data;
      
      // Update cache
      setUserCache(prev => ({
        ...prev,
        [userId]: userData
      }));
      
      return userData;
    } catch (error) {
      console.error(`Error fetching user data for ID ${userId}:`, error);
      return null;
    }
  }, [userCache]);
  
  // Helper function to get course data from cache or API
  const getCourseData = useCallback(async (courseId) => {
    if (!courseId) return null;
    
    // Return from cache if available
    if (courseCache[courseId]) {
      return courseCache[courseId];
    }
    
    try {
      const response = await getCourse(courseId);
      const courseData = response.data;
      
      // Update cache
      setCourseCache(prev => ({
        ...prev,
        [courseId]: courseData
      }));
      
      return courseData;
    } catch (error) {
      console.error(`Error fetching course data for ID ${courseId}:`, error);
      return null;
    }
  }, [courseCache]);
  
  // Helper function to get student course data from cache or API
  const getStudentCourseData = useCallback(async (studentCourseId) => {
    if (!studentCourseId) return null;
    
    // Return from cache if available
    if (studentCourseCache[studentCourseId]) {
      return studentCourseCache[studentCourseId];
    }
    
    try {
      const response = await getStudentCourse(studentCourseId);
      const data = response.data;
      
      // Update cache
      setStudentCourseCache(prev => ({
        ...prev,
        [studentCourseId]: data
      }));
      
      return data;
    } catch (error) {
      console.error(`Error fetching student course data for ID ${studentCourseId}:`, error);
      return null;
    }
  }, [studentCourseCache]);

  // Fetch user role once at component mount
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await getCurrentUser();
        console.log("User data received:", response);
        
        const userData = response.data;
        
        if (userData && userData.role) {
          setUserRole(userData.role);
          console.log("User role set to:", userData.role);
        } else {
          console.error("User role not found in the response");
          setUserRole('');
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setUserRole('');
      }
    };
  
    fetchUserRole();
  }, []);

  // Main data fetching function - optimized to fetch all data in parallel
  const fetchData = useCallback(async () => {
    // Prevent concurrent fetches
    if (isDataFetching.current) {
      return;
    }
    
    isDataFetching.current = true;
    setLoading(true);
    setError(null);
    const currentFetchId = ++dataRefreshCounter.current;
    
    try {
      const today = new Date().toISOString().split('T')[0];
      
      // Make all primary API requests in parallel
      const [
        dashboardStatsResponse,
        attendanceReviewsResponse,
        studentFeedbacksResponse,
        courseHoldsResponse,
        noticesResponse,
        leaveRequestsResponse,
        leaveRequestHistoryResponse
      ] = await Promise.all([
        getDashboardStats(today),
        listAttendanceReviews(),
        listStudentFeedbacks(),
        listCourseHolds(),
        listNotices(),
        listLeaveRequests(),
        getLeaveRequestHistory()
      ]);
      
      // If another fetch was started after this one, discard these results
      if (currentFetchId !== dataRefreshCounter.current) {
        return;
      }

      // Update dashboard stats
      setDashboardData({
        total_trainers: dashboardStatsResponse.data.total_trainers,
        total_attendance_reviews: attendanceReviewsResponse.data.length,
        total_student_feedbacks: studentFeedbacksResponse.data.length,
        total_course_holds: courseHoldsResponse.data.length,
        total_leave_requests: leaveRequestsResponse.data.length,
        total_students: dashboardStatsResponse.data.total_students,
        active_courses: dashboardStatsResponse.data.active_courses,
      });
      
      // Set initial data
      const rawAttendanceReviews = attendanceReviewsResponse.data || [];
      const rawStudentFeedbacks = studentFeedbacksResponse.data || [];
      const rawCourseHolds = courseHoldsResponse.data || [];
      const rawLeaveRequests = leaveRequestsResponse.data || [];
      
      // Set the basic data immediately to improve perceived performance
      setNotices(noticesResponse.data || []);
      setLeaveRequestHistory(leaveRequestHistoryResponse.data || []);
      setLeaveRequests(rawLeaveRequests);
      
      // Set initial raw data for reviews, feedbacks, and holds
      setAttendanceReviews(rawAttendanceReviews);
      setStudentFeedbacks(rawStudentFeedbacks);
      setCourseHolds(rawCourseHolds);
      
      // Now enhance the data with additional details in batches
      
      // 1. Enhance attendance reviews with trainer names
      const enhanceAttendanceReviews = async () => {
        try {
          // Process in small batches to avoid overwhelming the API
          const batchSize = 5;
          const enhancedReviews = [...rawAttendanceReviews];
          
          for (let i = 0; i < enhancedReviews.length; i += batchSize) {
            const batch = enhancedReviews.slice(i, i + batchSize);
            const batchPromises = batch.map(async (review, batchIndex) => {
              const index = i + batchIndex;
              if (!review.trainer) return review;
              
              const trainerData = await getUserData(review.trainer);
              if (trainerData) {
                enhancedReviews[index] = {
                  ...review,
                  trainerName: trainerData.full_name || trainerData.username,
                };
              }
              return enhancedReviews[index];
            });
            
            await Promise.all(batchPromises);
            
            // Update state for each batch to improve perceived performance
            if (currentFetchId === dataRefreshCounter.current) {
              setAttendanceReviews([...enhancedReviews]);
            } else {
              return; // Another fetch was started, abort this one
            }
          }
        } catch (error) {
          console.error('Error enhancing attendance reviews:', error);
        }
      };
      
      // 2. Enhance student feedbacks with student/course names
      const enhanceStudentFeedbacks = async () => {
        try {
          const batchSize = 5;
          const enhancedFeedbacks = [...rawStudentFeedbacks];
          
          for (let i = 0; i < enhancedFeedbacks.length; i += batchSize) {
            const batch = enhancedFeedbacks.slice(i, i + batchSize);
            const batchPromises = batch.map(async (feedback, batchIndex) => {
              const index = i + batchIndex;
              
              const [student, course] = await Promise.all([
                getUserData(feedback.student),
                getCourseData(feedback.course)
              ]);
              
              if (student || course) {
                enhancedFeedbacks[index] = {
                  ...feedback,
                  studentName: student ? (student.full_name || student.username) : 'Unknown',
                  courseName: course ? course.name : 'Unknown Course'
                };
              }
              return enhancedFeedbacks[index];
            });
            
            await Promise.all(batchPromises);
            
            // Update state for each batch
            if (currentFetchId === dataRefreshCounter.current) {
              setStudentFeedbacks([...enhancedFeedbacks]);
            } else {
              return; // Another fetch was started, abort this one
            }
          }
        } catch (error) {
          console.error('Error enhancing student feedbacks:', error);
        }
      };
      
      // 3. Enhance course holds with student/course names
      const enhanceCourseHolds = async () => {
        try {
          const batchSize = 5;
          const enhancedHolds = [...rawCourseHolds];
          
          for (let i = 0; i < enhancedHolds.length; i += batchSize) {
            const batch = enhancedHolds.slice(i, i + batchSize);
            const batchPromises = batch.map(async (hold, batchIndex) => {
              const index = i + batchIndex;
              
              const [studentCourseData, requestedByUser] = await Promise.all([
                getStudentCourseData(hold.student_course),
                getUserData(hold.requested_by)
              ]);
              
              if (studentCourseData || requestedByUser) {
                enhancedHolds[index] = {
                  ...hold,
                  studentName: studentCourseData ? 
                    (studentCourseData.student.full_name || studentCourseData.student.username || 'Unknown Student') : 'Unknown Student',
                  courseName: studentCourseData ? 
                    (studentCourseData.course.name || 'Unknown Course') : 'Unknown Course',
                  requestedByName: requestedByUser ? 
                    (requestedByUser.full_name || requestedByUser.username || 'Unknown User') : 'Unknown User'
                };
              }
              return enhancedHolds[index];
            });
            
            await Promise.all(batchPromises);
            
            // Update state for each batch
            if (currentFetchId === dataRefreshCounter.current) {
              setCourseHolds([...enhancedHolds]);
            } else {
              return; // Another fetch was started, abort this one
            }
          }
        } catch (error) {
          console.error('Error enhancing course holds:', error);
        }
      };
      
      // 4. Process trainer details for leave requests
      const processTrainerDetails = async () => {
        try {
          // Get unique trainer IDs from leave requests
          const trainerIds = new Set(rawLeaveRequests
            .map(request => request.user)
            .filter(Boolean));
          
          // Only process trainers that aren't in cache yet
          const trainersToProcess = [...trainerIds].filter(id => !trainerDetails[id]);
          
          if (trainersToProcess.length === 0) return;
          
          const newDetails = { ...trainerDetails };
          
          // Process in small batches
          const batchSize = 5;
          for (let i = 0; i < trainersToProcess.length; i += batchSize) {
            const batch = trainersToProcess.slice(i, i + batchSize);
            const batchPromises = batch.map(async (trainerId) => {
              const userData = await getUserData(trainerId);
              if (userData) {
                newDetails[trainerId] = userData;
              }
            });
            
            await Promise.all(batchPromises);
          }
          
          // Update state once at the end
          if (currentFetchId === dataRefreshCounter.current) {
            setTrainerDetails(newDetails);
          }
        } catch (error) {
          console.error('Error processing trainer details:', error);
        }
      };
      
      // Run all enhancement processes in parallel
      await Promise.all([
        enhanceAttendanceReviews(),
        enhanceStudentFeedbacks(),
        enhanceCourseHolds(),
        processTrainerDetails()
      ]);
      
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setLoading(false);
      isDataFetching.current = false;
    }
  }, [getUserData, getCourseData, getStudentCourseData, trainerDetails]);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  // Clean up loading state when component unmounts
  useEffect(() => {
    return () => {
      isDataFetching.current = false;
    };
  }, []);
  
  // Effect to handle the loader timing
  useEffect(() => {
    if (!loading) {
      // Add a small delay before hiding the loader to ensure data is processed
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [loading]);

  // Fetch trainer details when selected leave request changes
  useEffect(() => {
    const fetchSelectedTrainerDetails = async () => {
      if (!selectedLeaveRequest || !selectedLeaveRequest.user) return;
      
      // Skip if we already have this trainer's details
      if (trainerDetails[selectedLeaveRequest.user]) return;
      
      try {
        const userData = await getUserData(selectedLeaveRequest.user);
        
        if (userData) {
          setTrainerDetails(prev => ({
            ...prev,
            [selectedLeaveRequest.user]: userData
          }));
        }
      } catch (error) {
        console.error(`Error fetching trainer details for selected request:`, error);
      }
    };
  
    fetchSelectedTrainerDetails();
  }, [selectedLeaveRequest, trainerDetails, getUserData]);

  // Optimized review history fetch
  const fetchReviewHistory = useCallback(async () => {
    try {
      const response = await getAttendanceReviewHistory();
      
      if (!response.data || response.data.length === 0) {
        setReviewHistory([]);
        return;
      }
      
      // Start with basic history data
      setReviewHistory(response.data);
      
      // Then enhance the data in batches
      const enhancedHistory = [...response.data];
      const batchSize = 5;
      
      for (let i = 0; i < enhancedHistory.length; i += batchSize) {
        const batch = enhancedHistory.slice(i, i + batchSize);
        const batchPromises = batch.map(async (review, batchIndex) => {
          const index = i + batchIndex;
          
          const [student, trainer] = await Promise.all([
            getUserData(review.student),
            getUserData(review.trainer)
          ]);
          
          enhancedHistory[index] = {
            ...review,
            studentName: student ? (student.full_name || student.username) : 'Unknown',
            trainerName: trainer ? (trainer.full_name || trainer.username) : 'Unknown'
          };
        });
        
        await Promise.all(batchPromises);
        
        // Update state for each batch for responsiveness
        setReviewHistory([...enhancedHistory]);
      }
    } catch (error) {
      console.error('Error fetching review history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch review history', severity: 'error' });
    }
  }, [getUserData]);

  // Optimized feedback history fetch
  const fetchFeedbackHistory = useCallback(async () => {
    try {
      const response = await getStudentFeedbackHistory();
      
      if (!response.data || response.data.length === 0) {
        setFeedbackHistory([]);
        return;
      }
      
      // Start with basic history data
      setFeedbackHistory(response.data);
      
      // Then enhance the data in batches
      const enhancedHistory = [...response.data];
      const batchSize = 5;
      
      for (let i = 0; i < enhancedHistory.length; i += batchSize) {
        const batch = enhancedHistory.slice(i, i + batchSize);
        const batchPromises = batch.map(async (feedback, batchIndex) => {
          const index = i + batchIndex;
          
          const [student, course, respondedBy] = await Promise.all([
            getUserData(feedback.student),
            getCourseData(feedback.course),
            getUserData(feedback.responded_by)
          ]);
          
          enhancedHistory[index] = {
            ...feedback,
            studentName: student ? (student.full_name || student.username) : 'Unknown',
            courseName: course ? course.name : 'Unknown Course',
            responderName: respondedBy ? (respondedBy.full_name || respondedBy.username) : 'Unknown'
          };
        });
        
        await Promise.all(batchPromises);
        
        // Update state for each batch for responsiveness
        setFeedbackHistory([...enhancedHistory]);
      }
    } catch (error) {
      console.error('Error fetching feedback history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch feedback history', severity: 'error' });
    }
  }, [getUserData, getCourseData]);

  // Optimized course hold history fetch
  const fetchCourseHoldHistory = useCallback(async () => {
    try {
      const response = await getCourseHoldHistory();
      setCourseHoldHistory(response.data || []);
    } catch (error) {
      console.error('Error fetching course hold history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch course hold history', severity: 'error' });
    }
  }, []);

  // Optimized delete notice handler
  const handleDeleteNotice = async (noticeId) => {
    try {
      await deleteNotice(noticeId);
      // Optimistically update the UI
      setNotices(notices.filter(notice => notice.id !== noticeId));
      setSnackbar({ open: true, message: 'Notice deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting notice:', error);
      setSnackbar({ open: true, message: 'Failed to delete notice', severity: 'error' });
      // Refresh notices to ensure UI is in sync with backend
      const noticesResponse = await listNotices();
      setNotices(noticesResponse.data || []);
    }
  };

  // Handler for leave request actions (approve/reject)
  const handleLeaveRequestAction = async (action) => {
    if (!selectedLeaveRequest) return;
  
    try {
      if (action === 'approve') {
        await approveLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      } else {
        await rejectLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      }
      
      // Optimistically update UI
      const updatedLeaveRequests = leaveRequests.map(request => 
        request.id === selectedLeaveRequest.id 
          ? { ...request, status: action === 'approve' ? 'APPROVED' : 'REJECTED' }
          : request
      );
      
      setLeaveRequests(updatedLeaveRequests);
      setSnackbar({ open: true, message: `Leave request ${action}ed successfully`, severity: 'success' });
      setSelectedLeaveRequest(null);
      setLeaveRemarks('');
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing leave request:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} leave request`, severity: 'error' });
    }
  };

  // Handler for course hold actions
  const handleCourseHoldAction = async (holdId, action) => {
    try {
      if (action === 'approve') {
        await approveCourseHold(holdId);
      } else {
        await rejectCourseHold(holdId);
      }
      
      // Optimistically update UI
      const updatedHolds = courseHolds.map(hold => 
        hold.id === holdId 
          ? { ...hold, status: action === 'approve' ? 'APPROVED' : 'REJECTED' }
          : hold
      );
      
      setCourseHolds(updatedHolds);
      setSnackbar({ open: true, message: `Course hold ${action === 'approve' ? 'approved' : 'rejected'} successfully`, severity: 'success' });
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing course hold:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} course hold`, severity: 'error' });
    }
  };

  // Handler for updating attendance
  const handleUpdateAttendance = async (attendanceId, studentId, reviewId, newStatus, reviewDecision) => {
    try {
      const studentIdNumber = parseInt(studentId, 10);
  
      // Execute both API calls in parallel
      await Promise.all([
        updateAttendance(attendanceId, { 
          status: newStatus,
          student: studentIdNumber
        }),
        processAttendanceReview(reviewId, { status: reviewDecision })
      ]);
  
      // Optimistically update UI
      const updatedReviews = attendanceReviews.map(review => 
        review.id === reviewId 
          ? { ...review, status: reviewDecision }
          : review
      );
      
      setAttendanceReviews(updatedReviews);
      setSnackbar({ open: true, message: 'Attendance and review updated successfully', severity: 'success' });
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error('Error updating attendance and review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance and review', severity: 'error' });
    }
  };

  // Handler for processing review
  const handleProcessReview = async (reviewId, newStatus) => {
    try {
      await processAttendanceReview(reviewId, newStatus);
      
      // Optimistically update UI
      const updatedReviews = attendanceReviews.map(review => 
        review.id === reviewId 
          ? { ...review, status: newStatus.status || newStatus }
          : review
      );
      
      setAttendanceReviews(updatedReviews);
      setSnackbar({ open: true, message: 'Review processed successfully', severity: 'success' });
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error('Error processing review:', error);
      setSnackbar({ open: true, message: 'Failed to process review', severity: 'error' });
    }
  };

  // Handler for updating attendance review
  const handleUpdateAttendanceReview = async (reviewId, newStatus) => {
    try {
      await updateAttendanceReview(reviewId, { status: newStatus });
      
      // Optimistically update UI
      const updatedReviews = attendanceReviews.map(review => 
        review.id === reviewId 
          ? { ...review, status: newStatus }
          : review
      );
      
      setAttendanceReviews(updatedReviews);
      setSnackbar({ open: true, message: 'Attendance review updated successfully', severity: 'success' });
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error('Error updating attendance review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance review', severity: 'error' });
    }
  };

  // Handler for responding to student feedback
  const handleRespondToFeedback = async () => {
    try {
      await respondToStudentFeedback(selectedFeedback.id, { 
        remarks: feedbackResponse,
        status: feedbackStatus
      });
      
      // Optimistically update UI
      const updatedFeedbacks = studentFeedbacks.map(feedback => 
        feedback.id === selectedFeedback.id 
          ? { 
              ...feedback, 
              status: feedbackStatus,
              admin_remarks: feedbackResponse 
            }
          : feedback
      );
      
      setStudentFeedbacks(updatedFeedbacks);
      setSnackbar({ open: true, message: 'Response to student feedback sent successfully', severity: 'success' });
      handleCloseFeedbackModal();
      
      // Refresh data in the background
      fetchData();
    } catch (error) {
      console.error('Error responding to student feedback:', error);
      setSnackbar({ open: true, message: 'Failed to send response to student feedback', severity: 'error' });
    }
  };

  // Handler for creating notice
  const handleCreateNotice = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newNotice.title);
      formData.append('content', newNotice.content);
      formData.append('audience', newNotice.audience);
      
      // Properly append each file with the correct field name
      newNotice.attachments.forEach((file) => {
        formData.append('attachments', file);
      });
      
      newNotice.links.forEach((link, index) => {
        formData.append(`links[${index}][url]`, link.url);
        formData.append(`links[${index}][title]`, link.title);
      });
  
      const response = await createNotice(formData);
      
      // Optimistically update UI with the new notice
      const newNoticeData = response.data || {
        id: Date.now(), // Temporary ID
        title: newNotice.title,
        content: newNotice.content,
        audience: newNotice.audience,
        created_at: new Date().toISOString()
      };
      
      setNotices([newNoticeData, ...notices]);
      setSnackbar({ open: true, message: 'Notice created successfully', severity: 'success' });
      setNoticeModalOpen(false);
      setNewNotice({ title: '', content: '', audience: 'ALL', attachments: [], links: [] });
      
      // Refresh notices in the background
      const noticesResponse = await listNotices();
      setNotices(noticesResponse.data || []);
    } catch (error) {
      console.error('Error creating notice:', error);
      setSnackbar({ open: true, message: 'Failed to create notice', severity: 'error' });
    }
  };

  // File input handlers
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setNewNotice(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...files]
    }));
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (index) => {
    setNewNotice(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  // Modal handlers
  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
    setSelectedReview(null);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
    setSelectedFeedback(null);
    setFeedbackResponse('');
    setFeedbackStatus('');
  };

  // Render loading state
  if (showLoader) {
    return (
      <AdminDashboardLoader 
        actualLoading={loading} 
        onLoadComplete={() => setShowLoader(false)} 
      />
    );
  }

  // Render error state
  if (error) {
    return (
      <GlassContainer maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </GlassContainer>
    );
  }

  // Main render
  return (
    <>
      <GlassContainer sx={{ paddingBottom: '400px' }}>
        <Typography variant="h4" gutterBottom color="white" align="left">Management Dashboard</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Total Trainers
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_trainers}
                </Typography>
                <Typography variant="subtitle2" color="white" mt={1}>
                  Total Students: {dashboardData.total_students}
                </Typography>
                <Typography variant="subtitle2" color="white">
                  Active Courses: {dashboardData.active_courses}
                </Typography>
                <Typography variant="subtitle1" color="white" mt={2}>
                  Leave Requests: {dashboardData.total_leave_requests}
                </Typography>
                <GradientButton onClick={() => setLeaveRequestModalOpen(true)}>
                  View Leave Requests
                </GradientButton>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Attendance Reviews
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_attendance_reviews}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={handleOpenReviewModal} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setReviewHistoryModalOpen(true);
                    fetchReviewHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Student Feedbacks
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_student_feedbacks}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={handleOpenFeedbackModal} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setFeedbackHistoryModalOpen(true);
                    fetchFeedbackHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Course Hold Requests
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_course_holds}
                </Typography>
                <Box mt={2}>
                  <GradientButton onClick={() => setCourseHoldModalOpen(true)} sx={{ mr: 1 }}>
                    View Details
                  </GradientButton>
                  <GradientButton onClick={() => {
                    setCourseHoldHistoryModalOpen(true);
                    fetchCourseHoldHistory();
                  }}>
                    View History
                  </GradientButton>
                </Box>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" gutterBottom color="white">
                  Create Notice
                </Typography>
                <Typography variant="h3" color="white">
                  +
                </Typography>
                <GradientButton onClick={() => setNoticeModalOpen(true)}>
                  Post New Notice
                </GradientButton>
              </Box>
            </GlassCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlassCard>
              <NoticeCard 
                notices={notices} 
                userRole={userRole} 
                onDelete={handleDeleteNotice} 
              />
            </GlassCard>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom color="white">Trainers</Typography>
          {isMobile ? (
            <Box sx={{ overflowX: 'auto' }}>
              <TrainersTable userRole={userRole} />
            </Box>
          ) : (
            <TrainersTable userRole={userRole} />
          )}
        </Box>

        {/* Course Hold Modal */}
<GlassModal 
  open={courseHoldModalOpen} 
  onClose={() => setCourseHoldModalOpen(false)} 
  fullWidth 
  maxWidth="md"
>
  <DialogTitle>
    <Typography variant="h5" color="white">Course Hold Requests</Typography>
  </DialogTitle>
  <DialogContent>
    <List>
      {courseHolds.map((hold) => (
        <ListItem key={hold.id}>
          <GlassCard sx={{ width: '100%' }}>
            <Typography variant="h6" color="white">
              {hold.student?.name || hold.studentName || 'Unknown Student'} - {hold.courseName || 'Unknown Course'}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="white">
                  <strong>Student Course ID:</strong> {hold.student_course}
                </Typography>
                <Typography variant="body2" color="white">
                  <strong>Start Date:</strong> {new Date(hold.start_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/\s/g, '-')}
                </Typography>
                <Typography variant="body2" color="white">
                <strong>End Date:</strong> {new Date(hold.end_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/\s/g, '-')}
                </Typography>
                {hold.coordinator && (
                  <Typography variant="body2" color="white">
                    <strong>Coordinator:</strong> {hold.coordinator.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="white">
                  <strong>Requested by:</strong> {hold.requestedByName || hold.requested_by}
                </Typography>
                <Typography variant="body2" color="white">
                  <strong>Created at:</strong> {new Date(hold.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/\s/g, '-')}
                </Typography>
                <Typography variant="body2" color="white">
                  <strong>Updated at:</strong> {new Date(hold.updated_at || hold.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).replace(/\s/g, '-')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="white">
                  <strong>Reason:</strong> {hold.reason}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
              <GradientChip 
                label={hold.status} 
                color={hold.status === 'PENDING' ? 'warning' : 'success'} 
                variant="outlined"
              />
              {hold.status === 'PENDING' && (
                <Box>
                  <GradientButton 
                    onClick={() => handleCourseHoldAction(hold.id, 'approve')} 
                    size="small" 
                    style={{ marginRight: '8px' }}
                  >
                    Approve
                  </GradientButton>
                  <GradientButton 
                    onClick={() => handleCourseHoldAction(hold.id, 'reject')} 
                    size="small"
                  >
                    Reject
                  </GradientButton>
                </Box>
              )}
            </Box>
          </GlassCard>
        </ListItem>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setCourseHoldModalOpen(false)}>
      Close
    </GradientButton>
  </DialogActions>
</GlassModal>
        {/* Attendance Review Modal */}
        <GlassModal 
          open={openReviewModal} 
          onClose={handleCloseReviewModal} 
          fullWidth 
          maxWidth="md"
        >
          <DialogTitle>
            <Typography variant="h5" color="white">Attendance Reviews</Typography>
          </DialogTitle>
          <DialogContent>
            <StyledTableContainer>
              <EnhancedAttendanceReviewTable 
                attendanceReviews={attendanceReviews}
                handleProcessReview={handleProcessReview}
              />
            </StyledTableContainer>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={handleCloseReviewModal}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Student Feedback Modal */}
        <GlassModal open={openFeedbackModal} onClose={handleCloseFeedbackModal} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Student Feedbacks</Typography>
          </DialogTitle>
          <DialogContent>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Student</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Topic</TableCell>
                    <TableCell>Feedback</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentFeedbacks.map((feedback) => (
                    <TableRow key={feedback.id}>
                      <TableCell>{feedback.studentName}</TableCell>
                      <TableCell>{feedback.courseName}</TableCell>
                      <TableCell>{feedback.feedback_type}</TableCell>
                      <TableCell>{feedback.topic}</TableCell>
                      <TableCell>{feedback.content.substring(0, 50)}...</TableCell>
                      <TableCell>{feedback.status}</TableCell>
                      <TableCell>
                        <GradientButton
                          onClick={() => {
                            setSelectedFeedback(feedback);
                            setFeedbackResponse(feedback.admin_remarks || '');
                            setFeedbackStatus(feedback.status);
                          }}
                          size="small"
                        >
                          Respond
                        </GradientButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={handleCloseFeedbackModal}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Create Notice Modal */}
        <GlassModal open={noticeModalOpen} onClose={() => setNoticeModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Create New Notice</Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Title"
              value={newNotice.title}
              onChange={(e) => setNewNotice({ ...newNotice, title: e.target.value })}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <TextField
              fullWidth
              label="Content"
              value={newNotice.content}
              onChange={(e) => setNewNotice({ ...newNotice, content: e.target.value })}
              margin="normal"
              multiline
              rows={4}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel style={{ color: 'white' }}>Audience</InputLabel>
              <Select
                value={newNotice.audience}
                onChange={(e) => setNewNotice({ ...newNotice, audience: e.target.value })}
                style={{ color: 'white' }}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="STUDENTS">Students</MenuItem>
                <MenuItem value="STUDENTS_TRAINERS">Students and Trainers</MenuItem>
                <MenuItem value="ADMINS_MANAGERS">Admins and Managers</MenuItem>
              </Select>
            </FormControl>
            
            <>
              <input
                ref={fileInputRef}
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                type="file"
                multiple
                onChange={handleFileSelect}
              />
              <GradientButton 
                onClick={handleUploadClick}
                sx={{ mt: 2, mb: 2 }}
              >
                Upload Attachments
              </GradientButton>
            </>
            
            {newNotice.attachments.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1" color="white">Attachments:</Typography>
                {newNotice.attachments.map((file, index) => (
                  <GradientChip
                    key={index}
                    label={file.name}
                    onDelete={() => handleRemoveFile(index)}
                    color="primary"
                    variant="outlined"
                    style={{ margin: '4px' }}
                  />
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setNoticeModalOpen(false)}>Cancel</GradientButton>
            <GradientButton 
              onClick={handleCreateNotice} 
              disabled={!newNotice.title || !newNotice.content}
            >
              Create Notice
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Leave Requests Modal */}
        <GlassModal open={leaveRequestModalOpen} onClose={() => setLeaveRequestModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Leave Requests</Typography>
          </DialogTitle>
          <DialogContent>
            <Tabs 
              value={activeLeaveTab} 
              onChange={(event, newValue) => setActiveLeaveTab(newValue)} 
              textColor="inherit" 
              indicatorColor="secondary"
            >
              <Tab label="Pending Requests" />
              <Tab label="Request History" />
            </Tabs>
            
            <TabPanel value={activeLeaveTab} index={0}>
              <StyledTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trainer</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveRequests.map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>
                          {trainerDetails[request.user]?.full_name || 
                          trainerDetails[request.user]?.username || 
                          'Loading...'}
                        </TableCell>
                        <TableCell>{new Date(request.start_date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }).replace(/\s/g, '-')}</TableCell>
                        <TableCell>{new Date(request.end_date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }).replace(/\s/g, '-')}</TableCell>
                        <TableCell>{request.reason}</TableCell>
                        <TableCell>{request.status}</TableCell>
                        <TableCell>
                          {request.status === 'PENDING' && (
                            <GradientButton onClick={() => setSelectedLeaveRequest(request)} size="small">
                              Take Action
                            </GradientButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </TabPanel>
            
            <TabPanel value={activeLeaveTab} index={1}>
              <StyledTableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trainer</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Admin Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveRequestHistory.map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>
                          {request.trainer_name || request.user?.username || 'Unknown'}
                        </TableCell>
                        <TableCell>{new Date(request.start_date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }).replace(/\s/g, '-')}</TableCell>
                        <TableCell>{new Date(request.end_date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }).replace(/\s/g, '-')}</TableCell>
                        <TableCell>{request.reason}</TableCell>
                        <TableCell>{request.status}</TableCell>
                        <TableCell>{request.admin_remarks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setLeaveRequestModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Leave Action Dialog */}
        <GlassModal open={!!selectedLeaveRequest} onClose={() => setSelectedLeaveRequest(null)} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography variant="h5" color="white">Leave Request Action</Typography>
          </DialogTitle>
          <DialogContent>
            {selectedLeaveRequest && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1" gutterBottom color="white">
                    Trainer: {trainerDetails[selectedLeaveRequest.user]?.full_name || 
                            trainerDetails[selectedLeaveRequest.user]?.username || 
                            'Loading...'}
                  </Typography>
                  <Typography variant="body1" gutterBottom color="white">
                    From: {new Date(selectedLeaveRequest.start_date).toLocaleDateString()} 
                    To: {new Date(selectedLeaveRequest.end_date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body1" gutterBottom color="white">
                    Reason: {selectedLeaveRequest.reason}
                  </Typography>
                </Box>

                {/* Attachments section */}
                {selectedLeaveRequest.attachments?.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" color="white" gutterBottom>
                      Attachments:
                    </Typography>
                    {selectedLeaveRequest.attachments.map((attachment, index) => (
                      <Paper 
                        key={attachment.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          p: 2,
                          mb: 1,
                          background: 'rgba(255, 255, 255, 0.05)',
                          borderRadius: '8px',
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="body2" color="white">
                            Attachment {index + 1}
                          </Typography>
                        </Box>
                        <GradientButton
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = attachment.file;
                            link.download = `attachment-${index + 1}`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                          size="small"
                        >
                          Download
                        </GradientButton>
                      </Paper>
                    ))}
                  </Box>
                )}

                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Remarks"
                  value={leaveRemarks}
                  onChange={(e) => setLeaveRemarks(e.target.value)}
                  margin="normal"
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setSelectedLeaveRequest(null)}>
              Cancel
            </GradientButton>
            <GradientButton onClick={() => handleLeaveRequestAction('approve')}>
              Approve
            </GradientButton>
            <GradientButton onClick={() => handleLeaveRequestAction('reject')}>
              Reject
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Feedback Response Modal */}
        <GlassModal open={!!selectedFeedback} onClose={() => setSelectedFeedback(null)} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography variant="h5" color="white">Respond to Feedback</Typography>
          </DialogTitle>
          <DialogContent>
            {selectedFeedback && (
              <>
                <Typography variant="body1" gutterBottom color="white">
                  Student: {selectedFeedback.studentName}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Course: {selectedFeedback.courseName}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Type: {selectedFeedback.feedback_type}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Topic: {selectedFeedback.topic}
                </Typography>
                <Typography variant="body1" gutterBottom color="white">
                  Feedback: {selectedFeedback.content}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel style={{ color: 'white' }}>Status</InputLabel>
                  <Select
                    value={feedbackStatus}
                    onChange={(e) => setFeedbackStatus(e.target.value)}
                    style={{ color: 'white' }}
                  >
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                    <MenuItem value="RESOLVED">Resolved</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Response"
                  value={feedbackResponse}
                  onChange={(e) => setFeedbackResponse(e.target.value)}
                  margin="normal"
                  InputLabelProps={{ style: { color: 'white' } }}
                  InputProps={{ style: { color: 'white' } }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setSelectedFeedback(null)}>
              Cancel
            </GradientButton>
            <GradientButton onClick={handleRespondToFeedback} disabled={!feedbackResponse.trim()}>
              Send Response
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Attendance Review History Modal */}
        <GlassModal open={reviewHistoryModalOpen} onClose={() => setReviewHistoryModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Attendance Review History</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {reviewHistory.map((review) => (
                <ListItem key={review.id}>
                  <GlassCard sx={{ width: '100%' }}>
                    <Typography variant="h6" color="white">
                      Review for Student: {review.studentName} (ID: {review.student})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Trainer: {review.trainerName} (ID: {review.trainer})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Status: {review.status}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Remark: {review.remark}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Processed at: {new Date(review.processed_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Processed by ID: {review.processed_by}
                    </Typography>
                  </GlassCard>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setReviewHistoryModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Student Feedback History Modal */}
        <GlassModal open={feedbackHistoryModalOpen} onClose={() => setFeedbackHistoryModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>
            <Typography variant="h5" color="white">Student Feedback History</Typography>
          </DialogTitle>
          <DialogContent>
            <List>
              {feedbackHistory.map((feedback) => (
                <ListItem key={feedback.id}>
                  <GlassCard sx={{ width: '100%' }}>
                    <Typography variant="h6" color="white">
                      Feedback from {feedback.studentName} (ID: {feedback.student})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Course: {feedback.courseName} (ID: {feedback.course})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Type: {feedback.feedback_type}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Topic: {feedback.topic}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Content: {feedback.content}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Admin Remarks: {feedback.admin_remarks}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Responded by: {feedback.responderName} (ID: {feedback.responded_by})
                    </Typography>
                    <Typography variant="body2" color="white">
                      Created at: {new Date(feedback.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      Resolved at: {new Date(feedback.resolved_at).toLocaleString()}
                    </Typography>
                  </GlassCard>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setFeedbackHistoryModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </GlassModal>

        {/* Course Hold History Modal */}
<GlassModal open={courseHoldHistoryModalOpen} onClose={() => setCourseHoldHistoryModalOpen(false)} fullWidth maxWidth="md">
  <DialogTitle>
    <Typography variant="h5" color="white">Course Hold History</Typography>
  </DialogTitle>
  <DialogContent>
    <List>
      {courseHoldHistory.map((hold) => (
        <ListItem key={hold.id}>
          <GlassCard sx={{ width: '100%' }}>
            <Typography variant="h6" color="white">
              Hold for {hold.student?.name || hold.student?.full_name || 'Unknown Student'} 
              (ID: {hold.student?.id || 'N/A'})
            </Typography>
            <Typography variant="body2" color="white">
              <strong>Start Date:</strong> {formatDate(hold.start_date) || 'Not specified'}
            </Typography>
            <Typography variant="body2" color="white">
              <strong>End Date:</strong> {formatDate(hold.end_date) || 'Not specified'}
            </Typography>
            <Typography variant="body2" color="white">
              <strong>Reason:</strong> {hold.reason || 'No reason provided'}
            </Typography>
            <Typography variant="body2" color="white">
              <strong>Status:</strong> {hold.status || 'Unknown'}
            </Typography>
            {hold.coordinator && (
              <Typography variant="body2" color="white">
                <strong>Coordinator:</strong> {hold.coordinator.name}
              </Typography>
            )}
            <Typography variant="body2" color="white">
              <strong>Created at:</strong> {hold.created_at ? formatDate(hold.created_at) : 'Not available'}
            </Typography>
          </GlassCard>
        </ListItem>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <GradientButton onClick={() => setCourseHoldHistoryModalOpen(false)}>
      Close
    </GradientButton>
  </DialogActions>
</GlassModal>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </GlassContainer>
    </>
  );
};

export default ManagersDashboard;