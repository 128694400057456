import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  useMediaQuery,
  Autocomplete,
  TextField,
  Menu,
  MenuItem,
  ListItemIcon,
  Popover,
  Paper,
  Tabs,
  Tab,
  Fade,
} from '@mui/material';
import LoadingScreen from '../components/LoadingScreen';
import { styled, useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LeaveHistoryCard from '../components/LeaveHistoryCard';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RightTrainerNav from '../components/RightTrainerNav';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { 
  getTrainer, 
  listStudentCourses, 
  getUser, 
  getTrainerOccupation, 
  addTrainerRemarks, 
  getTrainerAudit, 
  getAttendance, 
  updateAttendance, 
  getCurrentUser, 
  getTrainerGroupCourses, 
  listTrainerAudits, 
  createTrainerAudit,
  changeAttendanceStatus,
  createAttendance,
  listTrainers,
  markTrainer,
  unmarkTrainer,
  clearAllMarks,
} from '../api';
import AttendanceOverviewModal from './AttendanceOverviewModal';
import UpdateSalaryModal from './UpdateSalaryModal';
import AddEditTrainerModal from '../components/AddEditTrainerModal';
import SalaryHistoryModal from './SalaryHistoryModal';
import AddAuditModal from '../components/AddAuditModal';
import AuditHistoryModal from '../components/AuditHistoryModal';
import AuditDetailsModal from '../components/AuditDetailsModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WorkingWindowModal from '../components/WorkingWindowModal';
import { getTrainerWorkingWindows, createWorkingWindow, deleteWorkingWindow } from '../api';



// Styled Components
const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));



const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 40px rgba(0, 0, 0, 0.2)',
  },
}));


const BottomBar = styled(Box)(({ theme }) => ({
  position: 'sticky',  // Change from 'fixed' to 'sticky'
  bottom: theme.spacing(3), // Match GlassContainer padding
  left: theme.spacing(3),
  right: theme.spacing(3),
  zIndex: 1000,
  background: 'rgba(18, 18, 18, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius, // Add border radius
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  margin: `${theme.spacing(2)} 0`, // Add margin top and bottom
  '& .MuiAutocomplete-popper': {
    zIndex: 1400,
  }
}));
const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '20px',
  overflow: 'hidden',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: 'white',
    '&::before, &::after': {
      borderColor: 'transparent',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 2),
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: 'white',
    },
    padding: '6px 16px',
    minHeight: '48px',
    textTransform: 'none',
    fontSize: '14px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
  minHeight: '48px',
}));

const TrainerTab = styled(Tab)(({ theme, colormark }) => ({
  backgroundColor: colormark ? `${colorMapping[colormark]}22!important` : 'transparent',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: colormark ? `${colorMapping[colormark]}44!important` : 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      fontSize: '0.875rem',
    },
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transition: 'background-color 0.3s ease',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 6,
  padding: theme.spacing(1, 2.5),
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    padding: theme.spacing(0.75, 2),
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',

  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

// Calendar Modal styled components
const CalendarModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '900px',
  background: 'rgba(18, 18, 18, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 0 24px rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  color: 'white',
}));

const DetailView = styled(Box)(({ theme }) => ({
  width: '250px',
  borderRight: `1px solid rgba(255, 255, 255, 0.1)`,
  paddingRight: '20px',
}));

const CalendarContainer = styled(Box)({
  flex: 1,
  marginLeft: '20px',
});

const QRImageContainer = styled(Box)({
  position: 'relative',
  width: '100px',
  height: '100px',
  position: 'absolute',
  top: '20px',
  right: '20px',
});

const QRImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const WarningOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '1.2',
});

// Calendar components
const DayCell = styled(Box)(({ theme, isSelected, isToday, status }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 
    isSelected ? 'rgba(255, 255, 255, 0.2)' :
    isToday ? 'rgba(255, 255, 255, 0.1)' :
    'transparent',
  color: 'white',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
}));

const WeekDayHeader = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
}));

const StatusText = styled(Typography)(({ status }) => ({
  fontSize: '10px',
  color: 
    status === 'PRESENT' ? '#4CAF50' :
    status === 'TRAINER_ABSENT' ? '#FFC107' :
    status === 'OFF' ? '#2196F3' :
    status === 'COMP' ? '#9C27B0' :
    status === 'ABSENT' ? '#F44336' : 
    'inherit',
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '900px',
  bgcolor: 'rgba(18, 18, 18, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
  color: 'white',
};

const colorMapping = {
  red: '#FF0000',
  yellow: '#FFD700',
  green: '#00FF00'
};

// AttendanceCalendarModal Component
function AttendanceCalendarModal({ open, onClose, studentId, studentName, courseInfo, trainerId }) {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [attendanceData, setAttendanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingDate, setEditingDate] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  // Near the top of the TrainersPage component with other state declarations


  const fetchAttendanceData = useCallback(async () => {
    if (!studentId) {
      console.error('fetchAttendanceData called with no studentId');
      return;
    }
    setLoading(true);
    try {
      const startDate = selectedMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = selectedMonth.endOf('month').format('YYYY-MM-DD');
      const response = await getAttendance({
        student: studentId,
        start_date: startDate,
        end_date: endDate
      });
      const attendanceMap = {};
      (response?.data || []).forEach(attendance => {
        if (attendance && attendance.timestamp) {
          const date = dayjs(attendance.timestamp).format('YYYY-MM-DD');
          attendanceMap[date] = attendance;
        }
      });
      setAttendanceData(attendanceMap);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
    }
  }, [studentId, selectedMonth]);

  useEffect(() => {
    if (open && studentId) {
      fetchAttendanceData();
    }
  }, [open, fetchAttendanceData, studentId]);

  const handlePreviousMonth = () => {
    setSelectedMonth(prev => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(prev => prev.add(1, 'month'));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleStatusClick = (event, date) => {
    setAnchorEl(event.currentTarget);
    setEditingDate(date);
  };

  const handleStatusChange = async (newStatus) => {
    setAnchorEl(null);
    if (editingDate && studentId) {
      try {
        const date = editingDate.format('YYYY-MM-DD');
        
        const attendanceResponse = await getAttendance({
          student: studentId,
          date: date,
          course: courseInfo.id
        });

        const attendancePayload = {
          student: studentId,
          course: courseInfo.id,
          student_course: courseInfo.studentCourseId,
          status: newStatus,
          trainer: trainerId,
          date: date,
        };

        let response;
        if (attendanceResponse.data && attendanceResponse.data.length > 0) {
          const attendanceId = attendanceResponse.data[0].id;
          response = await updateAttendance(attendanceId, attendancePayload);
        } else {
          response = await createAttendance(attendancePayload);
        }

        setAttendanceData(prev => ({
          ...prev,
          [date]: {
            ...response.data,
            status: newStatus,
          }
        }));

        setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success'});
      } catch (error) {
        console.error('Error updating attendance:', error);
        setSnackbar({ open: true, message: 'Failed to update attendance', severity: 'error' });
      }
    }
    setEditingDate(null);
  };

  const renderCalendar = () => {
    const startOfMonth = selectedMonth.startOf('month');
    const endOfMonth = selectedMonth.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const calendarDays = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    calendarDays.push(
      <Grid container key="weekdays">
        {weekDays.map(day => (
          <Grid item xs key={day}>
            <WeekDayHeader>{day}</WeekDayHeader>
          </Grid>
        ))}
      </Grid>
    );

    let week = [];
    for (let day = startDate; day.isBefore(endDate) || day.isSame(endDate); day = day.add(1, 'day')) {
      const dateString = day.format('YYYY-MM-DD');
      const attendance = attendanceData[dateString] || {};
      const isSelected = selectedDate.isSame(day, 'day');
      const isToday = day.isSame(dayjs(), 'day');

      week.push(
        <Grid item xs key={dateString}>
          <DayCell 
            isSelected={isSelected}
            isToday={isToday}
            onClick={() => handleDateClick(day)}
          >
            <Typography variant="body2">{day.date()}</Typography>
            <StatusText 
              status={attendance.status}
              onClick={(e) => handleStatusClick(e, day)}
            >
              {attendance.status || 'N/A'}
            </StatusText>
          </DayCell>
        </Grid>
      );

      if (week.length === 7) {
        calendarDays.push(<Grid container key={day.format('YYYY-MM-DD')}>{week}</Grid>);
        week = [];
      }
    }

    if (week.length > 0) {
      calendarDays.push(<Grid container key={endDate.format('YYYY-MM-DD')}>{week}</Grid>);
    }

    return calendarDays;
  };

  const renderDetailView = () => {
    const dateString = selectedDate.format('YYYY-MM-DD');
    const attendance = attendanceData[dateString] || {};
    
    return (
      
      <DetailView>
        <Typography variant="h2" gutterBottom>{selectedDate.date()}</Typography>
        <Typography variant="h6" gutterBottom>{attendance.status || 'N/A'}</Typography>
        <Typography variant="body2" gutterBottom>{selectedDate.format('MMMM YYYY')}</Typography>
        <List>
          <ListItem>
            <ListItemText primary="Time" secondary={attendance.timestamp || 'N/A'} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Course" secondary={(courseInfo && courseInfo.name) || 'N/A'} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Trainer" secondary={(courseInfo && courseInfo.trainer && courseInfo.trainer.name) || 'N/A'} />
          </ListItem>
        </List>
        <GradientButton
          startIcon={<AddCircleOutlineIcon />}
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Add Notification
        </GradientButton>
      </DetailView>
    );
  };

  if (!open) {
    return null;
  }

  return (
    
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="attendance-calendar-modal"
      aria-describedby="attendance-calendar-for-student"
    >
      <Box sx={modalStyle}>
        <CalendarModalContent>
          {renderDetailView()}
          <CalendarContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <IconButton onClick={handlePreviousMonth} disabled={selectedMonth.isBefore(dayjs().subtract(3, 'month'))}>
                <ArrowBackIosNewIcon style={{ color: 'white' }} />
              </IconButton>
              <Typography variant="h6" style={{ color: 'white' }}>{selectedMonth.format('MMMM YYYY')}</Typography>
              <IconButton onClick={handleNextMonth} disabled={selectedMonth.isAfter(dayjs())}>
                <ArrowForwardIosIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
            {loading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              renderCalendar()
            )}
          </CalendarContainer>
        </CalendarModalContent>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              backgroundColor: 'rgba(18, 18, 18, 0.8)',
              color: 'white',
            },
          }}
        >
          <MenuItem onClick={() => handleStatusChange('PRESENT')}>Present</MenuItem>
          <MenuItem onClick={() => handleStatusChange('ABSENT')}>Absent</MenuItem>
          <MenuItem onClick={() => handleStatusChange('TRAINER_ABSENT')}>Trainer Absent</MenuItem>
          <MenuItem onClick={() => handleStatusChange('OFF')}>Off</MenuItem>
          <MenuItem onClick={() => handleStatusChange('COMP')}>Comp</MenuItem>
        </Menu>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ backgroundColor: 'rgba(18, 18, 18, 0.8)', color: 'white' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}

// Main TrainersPage Component
function TrainersPage() {
  const [trainer, setTrainer] = useState(null);
  const [students, setStudents] = useState([]);
  const [groupCourses, setGroupCourses] = useState([]);
  const [occupation, setOccupation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceOverviewOpen, setAttendanceOverviewOpen] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { id } = useParams();
  const [updateSalaryModalOpen, setUpdateSalaryModalOpen] = useState(false);
  const navigate = useNavigate();
  const [colorMenuAnchor, setColorMenuAnchor] = useState(null);
  const [selectedTrainerId, setSelectedTrainerId] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [salaryHistoryModalOpen, setSalaryHistoryModalOpen] = useState(false);
  const [addAuditModalOpen, setAddAuditModalOpen] = useState(false);
  const [auditDetailsModalOpen, setAuditDetailsModalOpen] = useState(false);
  const [auditHistoryModalOpen, setAuditHistoryModalOpen] = useState(false);
  const [audits, setAudits] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [trainers, setTrainers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [markedTrainers, setMarkedTrainers] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [workingWindows, setWorkingWindows] = useState([]);
const [workingWindowModalOpen, setWorkingWindowModalOpen] = useState(false);
const [loadingStates, setLoadingStates] = useState({
  trainerProfile: false,
  studentData: false,
  courseInfo: false,
  attendance: false,
  setup: false
});

  // Load marked trainers from localStorage
  useEffect(() => {
    const savedMarkedTrainers = localStorage.getItem('markedTrainers');
    if (savedMarkedTrainers) {
      setMarkedTrainers(JSON.parse(savedMarkedTrainers));
    }
  }, []);

  // Save marked trainers to localStorage
  useEffect(() => {
    localStorage.setItem('markedTrainers', JSON.stringify(markedTrainers));
  }, [markedTrainers]);

  const fetchTrainers = useCallback(async (search = '', loadMore = false) => {
    try {
      const response = await listTrainers({ 
        page: loadMore ? page + 1 : 1, 
        page_size: 10,
        search 
      });
      
      if (loadMore) {
        setTrainers(prev => [...prev, ...response.results]);
        setPage(prev => prev + 1);
      } else {
        setTrainers(response.results);
        setPage(1);
      }
      
      setHasMore(response.results.length === 10);
      
      if (!id && response.results.length > 0 && !loadMore) {
        navigate(`/trainers/${response.results[0].id}`);
      }
  
      // Update selectedTab when loading more
      if (loadMore && response.results.length > 0) {
        setSelectedTab(prev => prev + response.results.length);
      }
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setSnackbar({ open: true, message: 'Failed to fetch trainers', severity: 'error' });
    }
  }, [navigate, id, page]);
  
  // Add handler for loading more trainers
  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchTrainers(searchTerm, true);
    }
  };

  const handleAddWorkingWindow = async (windowData) => {
    try {
      if (!trainer?.user?.id) {
        setSnackbar({
          open: true,
          message: 'Trainer not found',
          severity: 'error'
        });
        return;
      }
      
      // If admin/manager, send trainer_id, otherwise just send window data
      const currentUser = await getCurrentUser();
      const isAdminOrManager = ['ADMIN', 'MANAGER'].includes(currentUser.data.role);
      
      if (isAdminOrManager) {
        await createWorkingWindow(trainer.user.id, windowData);
      } else {
        await createWorkingWindow(null, windowData);
      }
      
      await fetchWorkingWindows(trainer.user.id);
      
      setSnackbar({
        open: true,
        message: 'Working window added successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error adding working window:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to add working window',
        severity: 'error'
      });
    }
  };
  
  const fetchWorkingWindows = async (userId) => {
    try {
      if (!userId) {
        setWorkingWindows([]);
        return;
      }
  
      console.log('Fetching working windows for user:', userId);
      const response = await getTrainerWorkingWindows(userId);
      setWorkingWindows(response.data || []);
    } catch (error) {
      console.error('Error fetching working windows:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to fetch working windows',
        severity: 'error'
      });
    }
  };
  
  useEffect(() => {
    if (trainer?.user?.id) {  // Use user.id
      fetchWorkingWindows(trainer.user.id);
    }
  }, [trainer]);
  
  const handleDeleteWorkingWindow = async (windowId) => {
    try {
      await deleteWorkingWindow(windowId);
      await fetchWorkingWindows(trainer.user.id); // or trainerData.profile.id for TrainerDashboard
      setSnackbar({
        open: true,
        message: 'Working window deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting working window:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete working window',
        severity: 'error'
      });
    }
  };

 

  const fetchData = useCallback(async (trainerId = id) => {
    if (!trainerId) {
      console.error('No trainer ID provided');
      return;
    }
  
    setLoading(true);
    setError(null);
    
    try {
      // Loading trainer profile
      setLoadingStates(prev => ({ ...prev, trainerProfile: true }));
      const trainerResponse = await getTrainer(trainerId);
      const trainer = trainerResponse.data ?? null;
      setTrainer(trainer);
      setLoadingStates(prev => ({ ...prev, trainerProfile: false, studentData: true }));
  
      if (!trainer || !trainer.user || !trainer.user.id) {
        throw new Error('Trainer user data is missing or incomplete');
      }
  
      // Loading other data
      setLoadingStates(prev => ({ ...prev, courseInfo: true }));
      const [currentUserResponse, groupCoursesResponse, studentsResponse, occupationResponse] = await Promise.all([
        getCurrentUser(),
        getTrainerGroupCourses(trainer.user.id),
        listStudentCourses({ trainer: trainer.user.id }),
        getTrainerOccupation(trainer.user.id, new Date().toISOString().split('T')[0])
      ]);
  
      setCurrentUserRole(currentUserResponse.data.role);
      setGroupCourses(groupCoursesResponse.data);
      setOccupation(occupationResponse.data ?? null);
      setLoadingStates(prev => ({ ...prev, courseInfo: false, attendance: true }));
  
      // Loading student data
      const studentsWithUserData = await Promise.all(
        (studentsResponse.data ?? []).map(async (studentCourse) => {
          try {
            const userResponse = await getUser(studentCourse.student);
            return {
              ...studentCourse,
              studentData: userResponse.data ?? {}
            };
          } catch (error) {
            console.error(`Failed to fetch data for student ${studentCourse.student}:`, error);
            return studentCourse;
          }
        })
      );
  
      setStudents(studentsWithUserData);
      setLoadingStates(prev => ({ ...prev, attendance: false, setup: true }));
  
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
      setLoadingStates(prev => ({
        trainerProfile: false,
        studentData: false,
        courseInfo: false,
        attendance: false,
        setup: false
      }));
    }
  }, [id]);
  useEffect(() => {
    if (!page || page === 1) {
      fetchTrainers(searchTerm);
    }
  }, [fetchTrainers, searchTerm, page]);
  
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [fetchData, id]);

  const fetchAudits = useCallback(async () => {
    if (trainer && trainer.user && trainer.user.id) {
      try {
        const response = await listTrainerAudits(trainer.user.id);
        setAudits(response.data);
      } catch (error) {
        console.error('Error fetching audits:', error);
      }
    }
  }, [trainer]);

  useEffect(() => {
    if (trainer) {
      fetchAudits();
    }
  }, [trainer, fetchAudits]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchTrainers(searchTerm);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (trainers[newValue]) {
      navigate(`/trainers/${trainers[newValue].id}`);
    }
  };

  const handleMarkTrainer = (trainer, event) => {
    event.preventDefault();
    setSelectedTrainerId(trainer.user.id);
    setColorMenuAnchor(event.currentTarget);
  };

  const handleColorSelect = async (colorChoice) => {
    if (selectedTrainerId) {
      try {
        if (markedTrainers[selectedTrainerId] === colorChoice.value) {
          await unmarkTrainer(selectedTrainerId);
          setMarkedTrainers(prev => {
            const newMarked = { ...prev };
            delete newMarked[selectedTrainerId];
            return newMarked;
          });
        } else {
          await markTrainer(selectedTrainerId, colorChoice.value);
          setMarkedTrainers(prev => ({
            ...prev,
            [selectedTrainerId]: colorChoice.value
          }));
        }
        // Refresh trainers list to update colors
        await fetchTrainers(searchTerm);
      } catch (error) {
        console.error('Error marking/unmarking trainer:', error);
        setSnackbar({
          open: true,
          message: 'Failed to update trainer color marking',
          severity: 'error'
        });
      }
    }
    setColorMenuAnchor(null);
  };

  const handleClearMarks = async () => {
    try {
      await clearAllMarks();
      setMarkedTrainers({});
      // Refresh trainers list
      await fetchTrainers(searchTerm);
      setSnackbar({
        open: true,
        message: 'All marks cleared successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error clearing marks:', error);
      setSnackbar({
        open: true,
        message: 'Failed to clear marks',
        severity: 'error'
      });
    }
  };

  const handleAddAudit = async (auditData) => {
    try {
      await createTrainerAudit({ ...auditData, trainer: trainer.user.id });
      setAddAuditModalOpen(false);
      fetchAudits();
      setSnackbar({ open: true, message: 'Audit added successfully', severity: 'success' });
    } catch (error) {
      console.error('Error adding audit:', error);
      setSnackbar({ open: true, message: 'Failed to add audit', severity: 'error' });
    }
  };

  const handleAttendanceClick = (student) => {
    if (student && student.studentData && student.studentData.id) {
      setSelectedStudent(student);
      setAttendanceModalOpen(true);
    } else {
      console.error('Invalid student data:', student);
      setSnackbar({ open: true, message: 'Invalid student data', severity: 'error' });
    }
  };

  const handleEditTrainer = () => {
    setEditModalOpen(true);
  };

  const handleSaveTrainer = (updatedTrainerData) => {
    setTrainer(prevTrainer => ({
      ...prevTrainer,
      ...updatedTrainerData,
      user: {
        ...prevTrainer.user,
        ...updatedTrainerData.user
      }
    }));
    fetchData();
    setSnackbar({ open: true, message: 'Trainer details updated successfully', severity: 'success' });
  };

  const handleViewAuditDetails = (audit) => {
    setSelectedAudit(audit);
    setAuditDetailsModalOpen(true);
  };

  const handleAddRemarks = async (auditId, remarks) => {
    try {
      await addTrainerRemarks(auditId, remarks);
      const updatedAudit = await getTrainerAudit(auditId);
      setSelectedAudit(updatedAudit.data);
      fetchAudits();
      setSnackbar({ open: true, message: 'Remarks added successfully', severity: 'success' });
    } catch (error) {
      console.error('Error adding remarks:', error);
      setSnackbar({ open: true, message: 'Failed to add remarks', severity: 'error' });
    }
  };

  const handleStudentClick = (studentCourse) => {
    navigate(`/student-details-big/${studentCourse.studentData.id}`);
  };

  const handleCloseAttendanceModal = () => {
    setAttendanceModalOpen(false);
    setSelectedStudent(null);
  };

  const colorOptions = [
    { name: 'Red', value: 'red' },
    { name: 'Yellow', value: 'yellow' },
    { name: 'Green', value: 'green' }
  ];

  const studentMap = students.reduce((acc, student) => {
    if (student.studentData && student.studentData.id) {
      acc[student.studentData.id] = {
        name: student.studentData.full_name || student.studentData.username || 'N/A',
        classTime: student.class_time || 'N/A'
      };
    }
    return acc;
  }, {});

  const dummyQRImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png";

 

  
  
  return (
    <>
      <LoadingScreen 
        loading={loading}
        loadingSteps={[
          'Loading trainer profile',
          'Fetching student data',
          'Loading course information',
          'Retrieving attendance records',
          'Setting up trainer details'
        ]}
      /> 
  
      {/* Error State */}
      {!loading && error && (
        <GlassContainer>
          <Alert severity="error">{error}</Alert>
        </GlassContainer>
      )}
      
      {/* No Trainer State */}
      {!loading && !trainer && (
        <GlassContainer>
          <Alert severity="warning">No trainer data available.</Alert>
        </GlassContainer>
      )}
      
      {/* Main Content */}
      {!loading && trainer && (
        <>
          {/* Main Container */}
          <GlassContainer sx={{ paddingBottom: theme => theme.spacing(50) }}>
            <Box style={{ minHeight: '100vh', position: 'relative' }}>
              {/* Header */}
              <Typography variant="h4" gutterBottom color="white">Trainer Details</Typography>
              
              {/* Action Buttons */}
              <Box display="flex" flexWrap="wrap" gap={2} mb={2}>
                <GradientButton onClick={() => setAttendanceOverviewOpen(true)}>
                  Attendance Overview
                </GradientButton>
                {(currentUserRole === 'ADMIN' || currentUserRole === 'MANAGER') && (
                  <>
                    <GradientButton onClick={() => setUpdateSalaryModalOpen(true)}>
                      Update Salary
                    </GradientButton>
                    <GradientButton onClick={handleEditTrainer}>
                      Edit Trainer Details
                    </GradientButton>
                    <GradientButton onClick={() => setSalaryHistoryModalOpen(true)}>
                      Salary History
                    </GradientButton>
                    <GradientButton onClick={() => setAddAuditModalOpen(true)}>
                      Add Audit
                    </GradientButton>
                    <GradientButton onClick={() => setAuditHistoryModalOpen(true)}>
                      Audit History
                    </GradientButton>
                    <GradientButton 
                      onClick={() => setWorkingWindowModalOpen(true)}
                      startIcon={<AccessTimeIcon />}
                    >
                      Working Windows
                    </GradientButton>
                  </>
                )}
              </Box>
              
              {/* Information Cards */}
              <Grid container spacing={3}>
                {/* Profile Card */}
                <Grid item xs={12} md={4}>
                  <GlassCard>
                    <Typography variant="h6" gutterBottom>Profile</Typography>
                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography><strong>Full Name:</strong> {trainer.user?.full_name ?? 'N/A'}</Typography>
                      <Typography><strong>Email:</strong> {trainer.user?.email ?? 'N/A'}</Typography>
                      <Box>
                        <strong>Contract Type: </strong>
                        <Chip 
                          label={trainer.contract_type ?? 'N/A'}
                          color={trainer.contract_type === 'SALARIED' ? 'success' : 'error'}
                          size="small"
                          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' }}
                        />
                      </Box>
                      <Typography><strong>Phone:</strong> {trainer.user?.phone ?? 'N/A'}</Typography>
                      <Typography><strong>Approved Hours:</strong> {trainer.approved_hours ?? 'N/A'}</Typography>
                      <Typography><strong>Occupied Hours:</strong> {occupation?.occupied_hours ?? 'N/A'}</Typography>
                    </Box>
                  </GlassCard>
                </Grid>
  
                {/* Financial Details Card */}
                <Grid item xs={12} md={4}>
                  <GlassCard>
                    <Typography variant="h6" gutterBottom>Financial Details</Typography>
                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography>
                        <strong>Salary:</strong> {currentUserRole === 'MANAGER' ? 'Private' : (trainer.salary ?? 'N/A')}
                      </Typography>
                      <Typography><strong>Bank Name:</strong> {trainer.bank_name ?? 'N/A'}</Typography>
                      <Typography><strong>Account Number:</strong> {trainer.account_number ?? 'N/A'}</Typography>
                      <Typography><strong>IFSC Code:</strong> {trainer.ifsc_code ?? 'N/A'}</Typography>
                    </Box>
                    <QRImageContainer>
                      <QRImage 
                        src={trainer.upi_qr_image || dummyQRImage} 
                        alt="UPI QR Code" 
                      />
                      {!trainer.upi_qr_image && (
                        <WarningOverlay>
                          <Typography variant="body2">
                            NOT REAL
                            <br />
                            DO NOT SCAN
                          </Typography>
                        </WarningOverlay>
                      )}
                    </QRImageContainer>
                  </GlassCard>
                </Grid>
  
                {/* Meeting Links Card */}
                <Grid item xs={12} md={4}>
                  <GlassCard>
                    <Typography variant="h6" gutterBottom>Meeting Links</Typography>
                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', my: 2 }} />
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography><strong>Google Meet:</strong> {trainer.google_meet_link ?? 'N/A'}</Typography>
                      <Typography><strong>Zoom:</strong> {trainer.zoom_meeting_link ?? 'N/A'}</Typography>
                    </Box>
                  </GlassCard>
                </Grid>
              </Grid>
  
              {/* Individual Students Table */}
              <Box mt={4}>
                <Typography variant="h5" gutterBottom color="white">
                  Assigned Students (Individual Courses)
                </Typography>
                <GlassTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        {!isMobile && <TableCell>Phone</TableCell>}
                        {!isMobile && <TableCell>Coordinator</TableCell>}
                        <TableCell>Course</TableCell>
                        {!isMobile && <TableCell>Start Date</TableCell>}
                        {!isMobile && <TableCell>End Date</TableCell>}
                        <TableCell>Class Time</TableCell>
                        <TableCell>Attendance</TableCell>
                      </TableRow>
                    </TableHead>
                   
<TableBody>
  {students.length > 0 ? (
    students.map((studentCourse) => (
      <TableRow key={studentCourse.id}>
        <TableCell>
          <GradientText
            variant="body1"
            onClick={() => handleStudentClick(studentCourse)}
          >
            {studentCourse.studentData?.full_name || `Student ID: ${studentCourse.student}`}
          </GradientText>
        </TableCell>
        {!isMobile && <TableCell>{studentCourse.studentData?.phone ?? 'N/A'}</TableCell>}
        {!isMobile && <TableCell>{studentCourse.studentData?.coordinator?.name ?? 'N/A'}</TableCell>}
        <TableCell>{studentCourse.course?.name ?? 'N/A'}</TableCell>
        {!isMobile && (
          <TableCell>
            {studentCourse.start_date 
              ? dayjs(studentCourse.start_date).format('DD-MMM-YYYY')
              : 'N/A'}
          </TableCell>
        )}
        {!isMobile && (
          <TableCell>
            {studentCourse.end_date 
              ? dayjs(studentCourse.end_date).format('DD-MMM-YYYY')
              : 'N/A'}
          </TableCell>
        )}
        <TableCell>
          {studentCourse.class_time 
            ? dayjs(`2024-01-01 ${studentCourse.class_time}`).format('hh:mm A')
            : 'N/A'}
        </TableCell>
        <TableCell>
          <GradientButton onClick={() => handleAttendanceClick(studentCourse)}>
            Attendance
          </GradientButton>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={isMobile ? 5 : 8} align="center">
        No individual students assigned to this trainer
      </TableCell>
    </TableRow>
  )}
</TableBody>
                  </Table>
                </GlassTableContainer>
              </Box>
  
              {/* Group Courses Table */}
              <Box mt={4}>
                <Typography variant="h5" gutterBottom color="white">
                  Group Course Assignments
                </Typography>
                <GlassTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Course Name</TableCell>
                        <TableCell>Class Time</TableCell>
                        {!isMobile && <TableCell>Duration</TableCell>}
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  {groupCourses.length > 0 ? (
    groupCourses.map((course) => (
      <TableRow key={course.id}>
        <TableCell>{course.name}</TableCell>
        <TableCell>
          {course.class_time 
            ? dayjs(`2024-01-01 ${course.class_time}`).format('hh:mm A')
            : 'N/A'}
        </TableCell>
        {!isMobile && <TableCell>{course.class_duration}</TableCell>}
        <TableCell>
          <GradientButton
            onClick={() => window.open(course.bbb_join_url, '_blank')}
            disabled={!course.bbb_join_url}
          >
            Join Room
          </GradientButton>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={isMobile ? 3 : 4} align="center">
        No group courses assigned to this trainer
      </TableCell>
    </TableRow>
  )}
</TableBody>
                  </Table>
                </GlassTableContainer>
              </Box>
            </Box>
            <LeaveHistoryCard trainerId={trainer?.user?.id} />
          </GlassContainer>
  
         
  
          {/* Modals */}
          <AttendanceCalendarModal
            open={attendanceModalOpen}
            onClose={handleCloseAttendanceModal}
            studentId={selectedStudent?.studentData?.id}
            studentName={selectedStudent?.studentData?.full_name || selectedStudent?.studentData?.username || 'Student'}
            courseInfo={{
              id: selectedStudent?.course?.id,
              studentCourseId: selectedStudent?.id,
              name: selectedStudent?.course?.name
            }}
            trainerId={trainer?.user?.id}
          />
          
          <AttendanceOverviewModal
            open={attendanceOverviewOpen}
            onClose={() => setAttendanceOverviewOpen(false)}
            trainerId={trainer?.user?.id}
            studentMap={studentMap}
          />
          
          <UpdateSalaryModal
            open={updateSalaryModalOpen}
            onClose={() => setUpdateSalaryModalOpen(false)}
            trainerId={trainer?.user?.id}
            trainerName={trainer?.user?.full_name}
          />
          
          <AddEditTrainerModal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            trainer={trainer}
            onSave={handleSaveTrainer}
          />
          
          <SalaryHistoryModal
            open={salaryHistoryModalOpen}
            onClose={() => setSalaryHistoryModalOpen(false)}
            trainerId={trainer?.user?.id}
            trainerName={trainer?.user?.full_name}
          />
  
  <WorkingWindowModal
          open={workingWindowModalOpen}
          onClose={() => setWorkingWindowModalOpen(false)}
          windows={workingWindows}
          onSave={handleAddWorkingWindow}
          onDelete={handleDeleteWorkingWindow}
        />

        <AddAuditModal
          open={addAuditModalOpen}
          onClose={() => setAddAuditModalOpen(false)}
          onSubmit={handleAddAudit}
        />
        
        <AuditHistoryModal
          open={auditHistoryModalOpen}
          onClose={() => setAuditHistoryModalOpen(false)}
          audits={audits}
          onViewDetails={handleViewAuditDetails}
        />
        
        {selectedAudit && (
          <AuditDetailsModal
            open={auditDetailsModalOpen}
            onClose={() => {
              setAuditDetailsModalOpen(false);
              setSelectedAudit(null);
            }}
            audit={selectedAudit}
            onAddRemarks={handleAddRemarks}
          />
        )}

        {/* Color Marking Menu */}
        <Menu
          anchorEl={colorMenuAnchor}
          open={Boolean(colorMenuAnchor)}
          onClose={() => setColorMenuAnchor(null)}
          PaperProps={{
            sx: {
              backgroundColor: 'rgba(18, 18, 18, 0.8)',
              color: 'white',
            }
          }}
        >
          {colorOptions.map((color) => (
            <MenuItem 
              key={color.value} 
              onClick={() => handleColorSelect(color)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  backgroundColor: `${colorMapping[color.value]}22`
                }
              }}
            >
              <FiberManualRecordIcon sx={{ color: colorMapping[color.value] }} />
              {color.name}
            </MenuItem>
          ))}
          {Object.keys(markedTrainers).length > 0 && (
            <MenuItem onClick={handleClearMarks}>
              Clear All Marks
            </MenuItem>
          )}
        </Menu>

        {/* Global Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    )}
  </>
);
}

export default TrainersPage;