import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Paper, 
  Tabs, 
  Tab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  CardActions,
  Divider,
  Chip,
  Avatar,
  Tooltip,
  CircularProgress,
  Badge,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Snackbar,
  Switch,
  FormControlLabel,
  Stack
} from '@mui/material';
import { 
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Article as ArticleIcon,
  VideoLibrary as VideoIcon,
  Image as ImageIcon,
  Link as LinkIcon,
  Topic as TopicIcon,
  Close as CloseIcon
} from '@mui/icons-material';
// Using ReactQuill instead of CKEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { 
  listNewsPosts, 
  createNewsPost, 
  updateNewsPost, 
  deleteNewsPost,
  listNewsTopics,
  createNewsTopic,
  updateNewsTopic,
  deleteNewsTopic
} from '../api';

// Define the Quill modules and formats
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const quillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

// Topic Management Component
const TopicManagement = () => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [formData, setFormData] = useState({ name: '', description: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  
  useEffect(() => {
    fetchTopics();
  }, []);
  
  const fetchTopics = async () => {
    setLoading(true);
    try {
      const response = await listNewsTopics();
      setTopics(response.data.results || response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
      showSnackbar('Error fetching topics', 'error');
    }
    setLoading(false);
  };
  
  const handleOpenDialog = (topic = null) => {
    if (topic) {
      setSelectedTopic(topic);
      setFormData({ name: topic.name, description: topic.description });
    } else {
      setSelectedTopic(null);
      setFormData({ name: '', description: '' });
    }
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedTopic(null);
    setFormData({ name: '', description: '' });
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleSubmit = async () => {
    try {
      if (selectedTopic) {
        await updateNewsTopic(selectedTopic.id, formData);
        showSnackbar('Topic updated successfully', 'success');
      } else {
        await createNewsTopic(formData);
        showSnackbar('Topic created successfully', 'success');
      }
      fetchTopics();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving topic:', error);
      showSnackbar('Error saving topic', 'error');
    }
  };
  
  const handleDeleteTopic = async (topicId) => {
    if (!window.confirm('Are you sure you want to delete this topic? This will affect all associated posts.')) {
      return;
    }
    
    try {
      await deleteNewsTopic(topicId);
      showSnackbar('Topic deleted successfully', 'success');
      fetchTopics();
    } catch (error) {
      console.error('Error deleting topic:', error);
      showSnackbar('Error deleting topic', 'error');
    }
  };
  
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };
  
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Manage Topics</Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Topic
        </Button>
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : topics.length === 0 ? (
        <Alert severity="info">No topics found. Create your first topic to get started.</Alert>
      ) : (
        <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(30, 41, 59, 0.7)' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Posts</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topics.map(topic => (
                <TableRow key={topic.id}>
                  <TableCell>{topic.name}</TableCell>
                  <TableCell>{topic.description}</TableCell>
                  <TableCell>{topic.posts_count}</TableCell>
                  <TableCell>{format(new Date(topic.created_at), 'MMM d, yyyy')}</TableCell>
                  <TableCell>
                    <IconButton 
                      size="small" 
                      onClick={() => handleOpenDialog(topic)}
                      color="primary"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      size="small" 
                      onClick={() => handleDeleteTopic(topic.id)}
                      color="error"
                      disabled={topic.posts_count > 0}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Topic Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedTopic ? 'Edit Topic' : 'Add New Topic'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Topic Name"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleInputChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={!formData.name}
          >
            {selectedTopic ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

// Post Form Component
const PostForm = ({ post = null, topics, onSave, onCancel }) => {
  const initialFormState = {
    title: '',
    topic_id: '',
    post_type: 'ARTICLE',
    is_published: true,
    article_data: { content: '', uploaded_images: [] },
    video_data: { video_url: '', video_file: null, thumbnail: null, duration: '' },
    image_data: { image: null, caption: '' },
    link_data: { url: '', description: '', preview_image: null }
  };
  
  const [formData, setFormData] = useState(post ? {
    title: post.title,
    topic_id: post.topic.id,
    post_type: post.post_type,
    is_published: post.is_published,
    article_data: post.article_data || { content: '', uploaded_images: [] },
    video_data: post.video_data || { video_url: '', video_file: null, thumbnail: null, duration: '' },
    image_data: post.image_data || { image: null, caption: '' },
    link_data: post.link_data || { url: '', description: '', preview_image: null }
  } : initialFormState);
  
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };
  
  const handleContentTypeData = (type, field, value) => {
    setFormData(prev => ({
      ...prev,
      [`${type}_data`]: {
        ...prev[`${type}_data`],
        [field]: value
      }
    }));
  };
  
  const handleFileChange = (type, field, e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    handleContentTypeData(type, field, file);
  };
  
  const renderFormByType = () => {
    switch(formData.post_type) {
      case 'ARTICLE':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Article Content</Typography>
            <Box sx={{ mb: 3 }}>
              <ReactQuill
                theme="snow"
                value={formData.article_data.content}
                onChange={(content) => handleContentTypeData('article', 'content', content)}
                modules={quillModules}
                formats={quillFormats}
                style={{ height: '300px', marginBottom: '50px' }}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>Upload Images (Optional)</Typography>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => {
                  const files = Array.from(e.target.files);
                  setFormData(prev => ({
                    ...prev,
                    article_data: {
                      ...prev.article_data,
                      uploaded_images: [...prev.article_data.uploaded_images, ...files]
                    }
                  }));
                }}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button 
                variant="outlined" 
                onClick={() => fileInputRef.current.click()}
                sx={{ mb: 2 }}
              >
                Select Images
              </Button>
              
              {formData.article_data.uploaded_images.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {formData.article_data.uploaded_images.map((file, index) => (
                    <Box 
                      key={index} 
                      sx={{ position: 'relative', width: 100, height: 100 }}
                    >
                      <img 
                        src={URL.createObjectURL(file)} 
                        alt={`Upload ${index}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          bgcolor: 'background.paper',
                          '&:hover': { bgcolor: 'background.paper' },
                        }}
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            article_data: {
                              ...prev.article_data,
                              uploaded_images: prev.article_data.uploaded_images.filter((_, i) => i !== index)
                            }
                          }));
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        );
        
      case 'VIDEO':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Video Details</Typography>
            
            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(formData.video_data.video_url)}
                    onChange={() => {
                      setFormData(prev => ({
                        ...prev,
                        video_data: {
                          ...prev.video_data,
                          video_url: prev.video_data.video_url ? '' : 'https://',
                          video_file: null
                        }
                      }));
                    }}
                  />
                }
                label="Use external video URL (YouTube, Vimeo, etc.)"
              />
            </Box>
            
            {formData.video_data.video_url ? (
              <TextField
                fullWidth
                label="Video URL"
                name="video_url"
                value={formData.video_data.video_url}
                onChange={(e) => handleContentTypeData('video', 'video_url', e.target.value)}
                margin="normal"
                helperText="Enter URL to a YouTube, Vimeo, or other video platform"
              />
            ) : (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>Upload Video File</Typography>
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleFileChange('video', 'video_file', e)}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <Button 
                  variant="outlined" 
                  onClick={() => fileInputRef.current.click()}
                  sx={{ mb: 1 }}
                >
                  Select Video
                </Button>
                
                {formData.video_data.video_file && (
                  <Box sx={{ mt: 1 }}>
                    <Chip 
                      label={formData.video_data.video_file.name} 
                      onDelete={() => handleContentTypeData('video', 'video_file', null)}
                    />
                  </Box>
                )}
              </Box>
            )}
            
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>Upload Thumbnail (Optional)</Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange('video', 'thumbnail', e)}
                style={{ display: 'none' }}
              />
              <Button 
                variant="outlined" 
                onClick={() => fileInputRef.current.click()}
                sx={{ mb: 1 }}
              >
                Select Thumbnail
              </Button>
              
              {formData.video_data.thumbnail && (
                <Box sx={{ mt: 1 }}>
                  <Box sx={{ position: 'relative', width: 150, height: 100 }}>
                    <img 
                      src={typeof formData.video_data.thumbnail === 'string' 
                        ? formData.video_data.thumbnail 
                        : URL.createObjectURL(formData.video_data.thumbnail)} 
                      alt="Thumbnail"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        '&:hover': { bgcolor: 'background.paper' },
                      }}
                      onClick={() => handleContentTypeData('video', 'thumbnail', null)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
            
            <TextField
              fullWidth
              label="Duration (e.g., 10:30)"
              name="duration"
              value={formData.video_data.duration}
              onChange={(e) => handleContentTypeData('video', 'duration', e.target.value)}
              margin="normal"
              helperText="Enter the video duration in MM:SS format"
            />
          </Box>
        );
        
      case 'IMAGE':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Image Details</Typography>
            
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>Upload Image</Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange('image', 'image', e)}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button 
                variant="outlined" 
                onClick={() => fileInputRef.current.click()}
                sx={{ mb: 1 }}
              >
                Select Image
              </Button>
              
              {formData.image_data.image && (
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Box sx={{ position: 'relative', width: 200, height: 150 }}>
                    <img 
                      src={typeof formData.image_data.image === 'string' 
                        ? formData.image_data.image 
                        : URL.createObjectURL(formData.image_data.image)} 
                      alt="Image"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        '&:hover': { bgcolor: 'background.paper' },
                      }}
                      onClick={() => handleContentTypeData('image', 'image', null)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
            
            <TextField
              fullWidth
              label="Caption (Optional)"
              name="caption"
              value={formData.image_data.caption}
              onChange={(e) => handleContentTypeData('image', 'caption', e.target.value)}
              margin="normal"
              multiline
              rows={3}
            />
          </Box>
        );
        
      case 'LINK':
        return (
          <Box>
            <Typography variant="subtitle1" gutterBottom>Link Details</Typography>
            
            <TextField
              fullWidth
              label="URL"
              name="url"
              value={formData.link_data.url}
              onChange={(e) => handleContentTypeData('link', 'url', e.target.value)}
              margin="normal"
              required
              placeholder="https://example.com"
            />
            
            <TextField
              fullWidth
              label="Description (Optional)"
              name="description"
              value={formData.link_data.description}
              onChange={(e) => handleContentTypeData('link', 'description', e.target.value)}
              margin="normal"
              multiline
              rows={3}
            />
            
            <Box sx={{ mb: 3, mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>Preview Image (Optional)</Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange('link', 'preview_image', e)}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button 
                variant="outlined" 
                onClick={() => fileInputRef.current.click()}
                sx={{ mb: 1 }}
              >
                Select Preview Image
              </Button>
              
              {formData.link_data.preview_image && (
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ position: 'relative', width: 200, height: 150 }}>
                    <img 
                      src={typeof formData.link_data.preview_image === 'string' 
                        ? formData.link_data.preview_image 
                        : URL.createObjectURL(formData.link_data.preview_image)} 
                      alt="Preview"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px' }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        '&:hover': { bgcolor: 'background.paper' },
                      }}
                      onClick={() => handleContentTypeData('link', 'preview_image', null)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
        
      default:
        return null;
    }
  };
  
  const validateForm = () => {
    if (!formData.title.trim()) return false;
    if (!formData.topic_id) return false;
    
    switch(formData.post_type) {
      case 'ARTICLE':
        return formData.article_data.content.trim() !== '';
      case 'VIDEO':
        return (formData.video_data.video_url || formData.video_data.video_file);
      case 'IMAGE':
        return !!formData.image_data.image;
      case 'LINK':
        return formData.link_data.url.trim() !== '';
      default:
        return false;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // Make sure the submitted data matches what the backend expects
      const submissionData = {
        title: formData.title,
        topic_id: formData.topic_id,
        post_type: formData.post_type,
        is_published: formData.is_published
      };
      
      // Add the specific content type data with the correct structure
      if (formData.post_type === 'ARTICLE') {
        submissionData.article = {
          content: formData.article_data.content,
          uploaded_images: formData.article_data.uploaded_images
        };
      } else if (formData.post_type === 'VIDEO') {
        submissionData.video = formData.video_data;
      } else if (formData.post_type === 'IMAGE') {
        submissionData.image = formData.image_data;
      } else if (formData.post_type === 'LINK') {
        submissionData.link = formData.link_data;
      }
      
      console.log('Submitting data:', submissionData); // Debug log
      
      if (post) {
        // For updating an existing post
        await onSave(post.id, submissionData);
      } else {
        // For creating a new post
        await onSave(submissionData);
      }
    } catch (error) {
      console.error('Error saving post:', error);
      // Show more detailed error information
      if (error.response && error.response.data) {
        console.error('Server response:', error.response.data);
      }
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            margin="normal"
            required
          />
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Topic</InputLabel>
            <Select
              name="topic_id"
              value={formData.topic_id}
              onChange={handleInputChange}
              label="Topic"
            >
              {topics && topics.length > 0 ? (
                topics.map(topic => (
                  <MenuItem key={topic.id} value={topic.id}>
                    {topic.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  No topics available. Please create a topic first.
                </MenuItem>
              )}
            </Select>
            {(!topics || topics.length === 0) && (
              <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                No topics available. Please create a topic first in the Topics tab.
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 3, mb: 3 }}>
        <FormControl component="fieldset">
          <Typography variant="subtitle1" gutterBottom>Content Type</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {[
              { value: 'ARTICLE', label: 'Article', icon: <ArticleIcon /> },
              { value: 'VIDEO', label: 'Video', icon: <VideoIcon /> },
              { value: 'IMAGE', label: 'Image', icon: <ImageIcon /> },
              { value: 'LINK', label: 'Link', icon: <LinkIcon /> }
            ].map(option => (
              <Button
                key={option.value}
                variant={formData.post_type === option.value ? 'contained' : 'outlined'}
                onClick={() => setFormData(prev => ({ ...prev, post_type: option.value }))}
                startIcon={option.icon}
                sx={{ px: 2 }}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        </FormControl>
      </Box>
      
      <Box sx={{ mt: 3, mb: 4 }}>
        {renderFormByType()}
      </Box>
      
      <FormControlLabel
        control={
          <Switch
            checked={formData.is_published}
            onChange={handleInputChange}
            name="is_published"
          />
        }
        label="Publish immediately"
      />
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          disabled={!validateForm() || loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {post ? 'Update' : 'Create'} Post
        </Button>
      </Box>
    </Box>
  );
};

// Post List Component
const PostList = ({ posts, onEdit, onDelete, onTogglePublish }) => {
  return (
    <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(30, 41, 59, 0.7)' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Topic</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {posts.map(post => (
            <TableRow key={post.id}>
              <TableCell>{post.title}</TableCell>
              <TableCell>
                <Chip 
                  icon={
                    post.post_type === 'ARTICLE' ? <ArticleIcon /> :
                    post.post_type === 'VIDEO' ? <VideoIcon /> :
                    post.post_type === 'IMAGE' ? <ImageIcon /> : <LinkIcon />
                  }
                  label={post.post_type.charAt(0) + post.post_type.slice(1).toLowerCase()}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              </TableCell>
              <TableCell>{post.topic.name}</TableCell>
              <TableCell>
                <Chip 
                  icon={post.is_published ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  label={post.is_published ? 'Published' : 'Draft'}
                  size="small"
                  color={post.is_published ? 'success' : 'default'}
                  variant="outlined"
                />
              </TableCell>
              <TableCell>{format(new Date(post.created_at), 'MMM d, yyyy')}</TableCell>
              <TableCell>
                <IconButton 
                  size="small" 
                  onClick={() => onEdit(post)}
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={() => onTogglePublish(post)}
                  color="info"
                >
                  {post.is_published ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={() => onDelete(post.id)}
                  color="error"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Main News Management Component
const NewsManagementPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [posts, setPosts] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPosts, setTotalPosts] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  
  useEffect(() => {
    fetchTopics();
  }, []);
  
  useEffect(() => {
    if (activeTab === 0) {
      fetchPosts();
    }
  }, [activeTab, page, rowsPerPage]);
  
  const fetchTopics = async () => {
    setLoading(true);
    try {
      const response = await listNewsTopics();
      setTopics(response.data.results || response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
      showSnackbar('Error fetching topics', 'error');
    }
    setLoading(false);
  };
  
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await listNewsPosts({
        page: page + 1,
        page_size: rowsPerPage
      });
      
      setPosts(response.data.results || response.data);
      setTotalPosts(response.data.count || (response.data).length);
    } catch (error) {
      console.error('Error fetching posts:', error);
      showSnackbar('Error fetching posts', 'error');
    }
    setLoading(false);
  };
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };
  
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  
  const handleOpenForm = (post = null) => {
    setSelectedPost(post);
    setShowForm(true);
  };
  
  const handleCloseForm = () => {
    setSelectedPost(null);
    setShowForm(false);
  };
  
  const handleSavePost = async (postIdOrData, formData = null) => {
    try {
      if (formData) {
        // Update existing post
        await updateNewsPost(postIdOrData, formData);
        showSnackbar('Post updated successfully', 'success');
      } else {
        // Create new post
        await createNewsPost(postIdOrData);
        showSnackbar('Post created successfully', 'success');
      }
      
      fetchPosts();
      handleCloseForm();
    } catch (error) {
      console.error('Error saving post:', error);
      showSnackbar('Error saving post', 'error');
    }
  };
  
  const handleDeletePost = async (postId) => {
    if (!window.confirm('Are you sure you want to delete this post?')) {
      return;
    }
    
    try {
      await deleteNewsPost(postId);
      showSnackbar('Post deleted successfully', 'success');
      fetchPosts();
    } catch (error) {
      console.error('Error deleting post:', error);
      showSnackbar('Error deleting post', 'error');
    }
  };
  
  const handleTogglePublish = async (post) => {
    try {
      await updateNewsPost(post.id, { is_published: !post.is_published });
      showSnackbar(`Post ${post.is_published ? 'unpublished' : 'published'} successfully`, 'success');
      fetchPosts();
    } catch (error) {
      console.error('Error toggling publish status:', error);
      showSnackbar('Error updating post status', 'error');
    }
  };
  
  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', px: { xs: 1, sm: 2, md: 3 } }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'white', fontWeight: 600 }}>
        News Management
      </Typography>
      
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Posts" />
        <Tab label="Topics" />
      </Tabs>
      
      {activeTab === 0 ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5">Manage Posts</Typography>
            <Button 
              variant="contained" 
              startIcon={<AddIcon />}
              onClick={() => handleOpenForm()}
            >
              Create Post
            </Button>
          </Box>
          
          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : showForm ? (
            <Paper 
              sx={{ 
                p: 3, 
                borderRadius: 2, 
                mb: 4,
                backgroundColor: 'rgba(30, 41, 59, 0.7)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)' 
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6">
                  {selectedPost ? 'Edit Post' : 'Create New Post'}
                </Typography>
                <IconButton onClick={handleCloseForm}>
                  <CloseIcon />
                </IconButton>
              </Box>
              
              <PostForm 
                post={selectedPost}
                topics={topics}
                onSave={handleSavePost}
                onCancel={handleCloseForm}
              />
            </Paper>
          ) : posts.length === 0 ? (
            <Alert severity="info">
              No posts found. Create your first post to get started.
            </Alert>
          ) : (
            <>
              <PostList 
                posts={posts}
                onEdit={handleOpenForm}
                onDelete={handleDeletePost}
                onTogglePublish={handleTogglePublish}
              />
              
              <TablePagination
                component="div"
                count={totalPosts}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </>
          )}
        </>
      ) : (
        <TopicManagement />
      )}
      
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewsManagementPage;