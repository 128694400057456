import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Chip,
  CircularProgress,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Menu,
  useTheme,
  useMediaQuery,
  Zoom
} from '@mui/material';
import {
  ThumbUp as ThumbUpIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon,
  Comment as CommentIcon,
  MoreVert as MoreVertIcon,
  ArrowBack as ArrowBackIcon,
  Share as ShareIcon,
  Schedule as ScheduleIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { Newspaper } from 'lucide-react';
import { format, formatDistance } from 'date-fns';
import DOMPurify from 'dompurify';
import {
  listNewsPosts,
  listNewsTopics,
  likeNewsPost,
  saveNewsPost,
  getNewsPostComments,
  commentOnNewsPost, 
  getSavedNewsPosts
} from '../api';

// Styled Components
const NewsFeedButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF0000, #D32F2F)',
  color: 'white',
  borderRadius: '24px',
  textTransform: 'none',
  padding: '8px 16px',
  fontWeight: 'bold',
  boxShadow: '0 2px 10px rgba(211, 47, 47, 0.4)',
  '&:hover': {
    background: 'linear-gradient(45deg, #D32F2F, #B71C1C)',
  },
}));

const NewsCard = styled(Card)(({ theme }) => ({
  marginBottom: '16px',
  borderRadius: '12px',
  overflow: 'visible',
  background: 'rgba(30, 41, 59, 0.8)',
  backdropFilter: 'blur(8px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  color: 'white'
}));

const MobileBackdrop = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  overflow: 'hidden',
  backgroundColor: '#0A0F24',
}));

// Video Player Component
const VideoPlayer = ({ videoUrl, thumbnailUrl, title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  
  // Get video source type (youtube, vimeo, direct)
  const getVideoSourceType = (url) => {
    if (!url) return null;
    
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'youtube';
    }
    
    if (url.includes('vimeo.com')) {
      return 'vimeo';
    }
    
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi'];
    if (videoExtensions.some(ext => url.toLowerCase().endsWith(ext))) {
      return 'direct';
    }
    
    return 'unknown';
  };

  // Convert to embed URL
  const getEmbedUrl = (url, type) => {
    if (!url) return '';
    
    if (type === 'youtube') {
      const videoId = url.includes('youtu.be/')
        ? url.split('youtu.be/')[1].split('?')[0]
        : url.includes('v=')
          ? url.split('v=')[1].split('&')[0]
          : '';
      
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }
    
    if (type === 'vimeo') {
      const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;
      const match = url.match(vimeoRegex);
      
      if (match && match[1]) {
        return `https://player.vimeo.com/video/${match[1]}`;
      }
    }
    
    return url;
  };
  
  const videoType = getVideoSourceType(videoUrl);
  const embedUrl = getEmbedUrl(videoUrl, videoType);
  
  const handleVideoLoad = () => {
    setIsLoading(false);
  };
  
  const handleVideoError = () => {
    setIsLoading(false);
    setError(true);
  };
  
  if (videoType === 'youtube' || videoType === 'vimeo') {
    return (
      <Box 
        sx={{
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'white' }} />
          </Box>
        )}
        
        <Box
          component="iframe"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title || "Video player"}
          onLoad={handleVideoLoad}
          onError={handleVideoError}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            borderRadius: 1,
          }}
        />
      </Box>
    );
  }
  
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      )}
      
      <Box
        component="video"
        poster={thumbnailUrl}
        controls
        preload="metadata"
        onLoadedData={handleVideoLoad}
        onError={handleVideoError}
        sx={{
          width: '100%',
          display: 'block',
          borderRadius: 1,
          backgroundColor: '#000',
          aspectRatio: '16/9',
        }}
      >
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </Box>
      
      {error && (
        <Box
          sx={{
            p: 2,
            textAlign: 'center',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <Typography variant="body2">
            Unable to load video. <a href={videoUrl} target="_blank" rel="noopener noreferrer" style={{color: '#90caf9'}}>Open in new tab</a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// Image Viewer Component
const ImageViewer = ({ imageSrc, altText, caption }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  
  const handleImageLoad = () => {
    setLoading(false);
  };
  
  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };
  
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  
  return (
    <Box 
      sx={{ 
        position: 'relative',
        cursor: expanded ? 'zoom-out' : 'zoom-in',
        width: '100%',
        transition: 'all 0.3s ease',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }}
      onClick={toggleExpand}
    >
      {loading && (
        <Box 
          sx={{
            height: '300px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }}
        >
          <CircularProgress size={40} sx={{ color: 'white' }} />
        </Box>
      )}
      
      {error ? (
        <Box 
          sx={{
            height: '200px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: 'rgba(255, 255, 255, 0.7)',
            p: 2
          }}
        >
          <Typography variant="body1" gutterBottom>
            Unable to load image
          </Typography>
          <Typography variant="caption">
            {altText || 'Image'}
          </Typography>
        </Box>
      ) : (
        <img
          src={imageSrc}
          alt={altText || 'Image'}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ 
            width: '100%',
            display: loading ? 'none' : 'block',
            objectFit: expanded ? 'contain' : 'contain',
            maxHeight: expanded ? '80vh' : '500px',
            transition: 'all 0.3s ease',
            backgroundColor: expanded ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
            zIndex: expanded ? 5 : 'auto',
            borderRadius: '4px'
          }}
        />
      )}
      
      {!loading && !error && expanded && (
        <Box 
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            p: 0.5,
            borderRadius: 1,
            fontSize: '0.75rem',
          }}
        >
          Click to zoom out
        </Box>
      )}
      
      {caption && !expanded && (
        <Box 
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            fontSize: '0.875rem',
            maxHeight: '60px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: loading ? 'none' : 'block',
          }}
        >
          {caption}
        </Box>
      )}
    </Box>
  );
};

// Article Content Component
const ArticleContent = ({ article, title }) => {
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  // Extract images from article content
  const extractImagesFromContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const images = Array.from(doc.querySelectorAll('img'));
    return images.map(img => ({
      src: img.getAttribute('src'),
      alt: img.getAttribute('alt') || title
    }));
  };
  
  // Prepare article preview (first 300 characters or so)
  const createPreview = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    
    // Remove images from preview text calculation
    const images = Array.from(doc.querySelectorAll('img'));
    images.forEach(img => img.remove());
    
    // Get text content
    let textContent = doc.body.textContent || '';
    const preview = textContent.substring(0, 300);
    
    return preview.length < textContent.length ? `${preview}...` : preview;
  };
  
  const extractedImages = article?.content ? extractImagesFromContent(article.content) : [];
  const hasMoreContent = article?.content && article.content.length > 300;
  
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (!article) return null;
  
  return (
    <>
      <CardContent sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
        {/* Preview text */}
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          {createPreview(article.content)}
        </Typography>
        
        {/* Display first image if any */}
        {(extractedImages.length > 0 || (article.images && article.images.length > 0)) && (
          <Box 
            sx={{ 
              mb: 2, 
              borderRadius: 1, 
              overflow: 'hidden',
              maxHeight: '300px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img 
              src={extractedImages[0]?.src || article.images[0]?.image} 
              alt={title}
              style={{ 
                maxWidth: '100%', 
                maxHeight: '300px', 
                objectFit: 'contain',
                borderRadius: '4px'
              }}
            />
          </Box>
        )}
        
        {/* Read more button */}
        {hasMoreContent && (
          <Button 
            variant="outlined"
            onClick={handleOpenDialog}
            sx={{ 
              mt: 1, 
              color: '#90caf9', 
              borderColor: 'rgba(144, 202, 249, 0.5)',
              '&:hover': {
                borderColor: '#90caf9',
                backgroundColor: 'rgba(144, 202, 249, 0.08)'
              }
            }}
          >
            Read Full Article
          </Button>
        )}
      </CardContent>
      
      {/* Full article dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#1e293b',
            color: 'white',
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            maxHeight: '90vh'
          }
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: '#0D47A1', boxShadow: 'none' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        
        <Box sx={{ 
          p: 3, 
          color: 'rgba(255, 255, 255, 0.9)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(0, 0, 0, 0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          }
        }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(article.content, {
                ADD_ATTR: ['target'],
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
              }),
            }}
            sx={{
              img: {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '4px',
                my: 2
              },
              a: {
                color: '#90caf9',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              },
              p: {
                my: 1.5
              },
              blockquote: {
                borderLeft: '4px solid rgba(144, 202, 249, 0.5)',
                pl: 2,
                py: 1,
                my: 2,
                fontStyle: 'italic',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '0 4px 4px 0'
              },
              'h1, h2, h3, h4, h5, h6': {
                color: 'white',
                mt: 3,
                mb: 2
              },
              ul: {
                pl: 3
              },
              li: {
                mb: 1
              }
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

// Comments Section Component
const CommentsSection = ({ postId, onNewComment }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState('');

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const response = await getNewsPostComments(postId);
        const results = response.data.results || response.data;
        setComments(Array.isArray(results) ? results : []);
      } catch (error) {
        console.error('Error fetching comments:', error);
        setComments([]);
      }
      setLoading(false);
    };

    fetchComments();
  }, [postId]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;

    try {
      const response = await commentOnNewsPost(postId, { content: commentText });
      setComments(prevComments => [response.data, ...(Array.isArray(prevComments) ? prevComments : [])]);
      setCommentText('');
      if (onNewComment) onNewComment();
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
        Comments
      </Typography>

      <Box component="form" onSubmit={handleSubmitComment} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          placeholder="Add a comment..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          variant="outlined"
          size="small"
          InputProps={{
             sx: {
               backgroundColor: 'rgba(255, 255, 255, 0.05)',
               color: 'white',
               '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: 'rgba(255, 255, 255, 0.2)',
               },
               '&:hover .MuiOutlinedInput-notchedOutline': {
                 borderColor: 'rgba(255, 255, 255, 0.4)',
               },
             },
            endAdornment: (
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={!commentText.trim()}
                sx={{ ml: 1 }}
              >
                Post
              </Button>
            ),
          }}
          InputLabelProps={{
             style: { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={3}>
          <CircularProgress size={28} sx={{ color: 'white' }}/>
        </Box>
      ) : !Array.isArray(comments) || comments.length === 0 ? (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 3, color: 'rgba(255, 255, 255, 0.7)' }}>
          No comments yet. Be the first to comment!
        </Typography>
      ) : (
        <Box>
          {comments.map((comment) => (
            <Box key={comment.id} sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.9)' }}>
              <Box display="flex">
                <Avatar
                  src={comment.user?.profile_image}
                  alt={comment.user?.full_name}
                  sx={{ width: 32, height: 32, mr: 1.5 }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle2" component="span">
                      {comment.user?.full_name || 'User'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistance(new Date(comment.created_at), new Date(), { addSuffix: true })}
                    </Typography>
                  </Box>
                  <Typography variant="body2">{comment.content}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

// Post Card Component
const PostCard = ({ post, onLike, onSave }) => {
  const [showComments, setShowComments] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const toggleComments = () => setShowComments(!showComments);

  // Function to get post content based on post_type
  const getPostContent = () => {
    switch(post.post_type) {
      case 'ARTICLE':
        return (
          <>
            {post.article && (
              <ArticleContent 
                article={post.article} 
                title={post.title}
              />
            )}
          </>
        );

      case 'VIDEO':
        return (
          <>
            {post.video && (
              <>
                <CardContent>
                  {post.video.duration && (
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                      <ScheduleIcon fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                        {post.video.duration}
                      </Typography>
                    </Box>
                  )}
                  
                  {post.video.video_url && (
                    <Box sx={{ mb: 2 }}>
                      <VideoPlayer 
                        videoUrl={post.video.video_url}
                        thumbnailUrl={post.video.thumbnail}
                        title={post.title}
                      />
                    </Box>
                  )}
                  
                  {!post.video.video_url && post.video.thumbnail && (
                    <Box sx={{ mb: 2 }}>
                      <img
                        src={post.video.thumbnail}
                        alt={post.title}
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }}
                      />
                    </Box>
                  )}
                </CardContent>
              </>
            )}
          </>
        );

      case 'IMAGE':
        return (
          <>
            {post.image?.image && (
              <Box sx={{ width: '100%', mb: post.image?.caption ? 0 : 2 }}>
                <ImageViewer 
                  imageSrc={post.image.image}
                  altText={post.title}
                  caption={post.image?.caption}
                />
              </Box>
            )}
              
            {post.image?.caption && (
              <CardContent>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.7)', 
                    fontStyle: 'italic',
                    mt: -1,
                    mb: 1
                  }}
                >
                  {post.image.caption}
                </Typography>
              </CardContent>
            )}
          </>
        );

      case 'LINK':
        return (
          <>
            {post.link && (
              <CardContent>
                {post.link.url && (
                  <Typography 
                    variant="body2" 
                    color="primary"
                    component="a"
                    href={post.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mb: 1, wordBreak: 'break-all', display: 'block' }}
                  >
                    {post.link.url}
                  </Typography>
                )}
                
                {post.link.description && (
                  <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)', mt: 1 }}>
                    {post.link.description}
                  </Typography>
                )}
              </CardContent>
            )}
            
            {post.link?.preview_image && (
              <Box sx={{ p: 2 }}>
                <img
                  src={post.link.preview_image}
                  alt={post.title}
                  style={{ 
                    width: '100%', 
                    maxHeight: '200px', 
                    objectFit: 'cover',
                    borderRadius: '4px'
                  }}
                />
              </Box>
            )}
          </>
        );

      default:
        return (
          <CardContent>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              No content available for this post type.
            </Typography>
          </CardContent>
        );
    }
  };

  return (
    <NewsCard>
      <CardContent sx={{ pb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={post.created_by?.profile_image}
              alt={post.created_by?.full_name}
              sx={{ mr: 1.5 }}
            />
            <Box>
              <Typography variant="subtitle1" component="div">
                {post.created_by?.full_name || 'Unknown User'}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatDistance(new Date(post.created_at), new Date(), { addSuffix: true })}
              </Typography>
            </Box>
          </Box>
          <Box>
            {post.topic && (
              <Chip
                label={post.topic.name}
                size="small"
                sx={{ mr: 1, backgroundColor: 'rgba(59, 130, 246, 0.2)', color: '#90caf9' }}
              />
            )}
            <IconButton
              size="small"
              onClick={handleMenuOpen}
              sx={{ color: 'white' }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              PaperProps={{
                sx: {
                  backgroundColor: '#2d3748',
                  color: 'white'
                }
              }}
            >
              <MenuItem onClick={handleMenuClose}>Copy link</MenuItem>
              <MenuItem onClick={handleMenuClose}>Report post</MenuItem>
            </Menu>
          </Box>
        </Box>
        <Typography variant="h6" component="h2" gutterBottom sx={{ color: 'white' }}>
          {post.title}
        </Typography>
      </CardContent>

      {getPostContent()}

      <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <IconButton
            onClick={() => onLike(post.id)}
            sx={{ color: post.is_liked ? '#f48fb1' : 'white' }}
          >
            {post.is_liked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          </IconButton>
          <Typography variant="body2" component="span" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {post.likes_count > 0 && post.likes_count}
          </Typography>
          <IconButton onClick={toggleComments} sx={{ ml: 1, color: 'white' }}>
            <CommentIcon />
          </IconButton>
          <Typography variant="body2" component="span" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {post.comments_count > 0 && post.comments_count}
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => onSave(post.id)}
            sx={{ color: post.is_saved ? '#f48fb1' : 'white' }}
          >
            {post.is_saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
          </IconButton>
          <IconButton sx={{ color: 'white' }}>
            <ShareIcon />
          </IconButton>
        </Box>
      </Box>

      {showComments && (
        <Box sx={{ px: 2, pb: 2 }}>
          <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.2)' }}/>
          <CommentsSection
            postId={post.id}
            onNewComment={() => {
              if (onLike) onLike(post.id, true);
            }}
          />
        </Box>
      )}
    </NewsCard>
  );
};

// Mobile News Feed Component
const MobileNewsFeed = ({ onClose }) => {
  const [posts, setPosts] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');
  const [selectedTopic, setSelectedTopic] = useState('all');
  const [refreshKey, setRefreshKey] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  // Fetch topics on component mount
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await listNewsTopics();
        setTopics(response.data.results || response.data || []);
      } catch (error) {
        console.error('Error fetching topics:', error);
        setTopics([]);
      }
    };

    fetchTopics();
  }, []);

  // Fetch posts based on selectedTab and selectedTopic
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        let response;
        if (selectedTab === 'saved') {
          response = await getSavedNewsPosts();
        } else {
          const params = {};
          if (selectedTopic !== 'all') {
            params.topic = selectedTopic;
          }
          response = await listNewsPosts(params);
        }
        const results = response.data.results || response.data;
        setPosts(Array.isArray(results) ? results : []);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setPosts([]);
        setSnackbar({ open: true, message: 'Failed to load posts.', severity: 'error' });
      }
      setLoading(false);
    };

    fetchPosts();
  }, [selectedTab, selectedTopic, refreshKey]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedTopic('all');
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    if (selectedTab === 'saved') {
      setSelectedTab('all');
    }
  };

  const handleLikePost = async (postId, refreshOnly = false) => {
    if (!refreshOnly) {
      try {
        await likeNewsPost(postId);
        setPosts(currentPosts => currentPosts.map(post =>
          post.id === postId
            ? { ...post, is_liked: !post.is_liked, likes_count: post.is_liked ? post.likes_count - 1 : post.likes_count + 1 }
            : post
        ));
      } catch (error) {
        console.error('Error liking post:', error);
        setSnackbar({ open: true, message: 'Error liking post.', severity: 'error' });
        return;
      }
    }
    if(refreshOnly) {
      setRefreshKey(prev => prev + 1);
    }
  };

  const handleSavePost = async (postId) => {
    const originalPosts = [...posts];
    const postToUpdate = posts.find(p => p.id === postId);
    if (!postToUpdate) return;

    const isCurrentlySaved = postToUpdate.is_saved;

    setPosts(currentPosts => currentPosts.map(post =>
      post.id === postId ? { ...post, is_saved: !isCurrentlySaved } : post
    ));

    if (selectedTab === 'saved' && isCurrentlySaved) {
      setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
    }

    try {
      await saveNewsPost(postId);
      setSnackbar({
        open: true,
        message: isCurrentlySaved ? 'Post removed from saved' : 'Post saved',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving post:', error);
      setPosts(originalPosts);
      setSnackbar({
        open: true,
        message: 'Error updating saved status. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#0A0F24' }}>
      <MobileBackdrop />
      
      {/* App Bar */}
      <AppBar position="sticky" sx={{ backgroundColor: '#0D47A1', boxShadow: '0 2px 10px rgba(0,0,0,0.2)' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            News Feed
          </Typography>
        </Toolbar>
      </AppBar>
      
      {/* Tabs for selection */}
      <Box sx={{ borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.12)', bgcolor: 'rgba(13, 71, 161, 0.7)' }}>
        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="inherit"
          sx={{
            '& .MuiTab-root': {
              color: 'rgba(255, 255, 255, 0.7)',
              '&.Mui-selected': {
                color: 'white',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#f48fb1'
            }
          }}
        >
          <Tab label="News Feed" value="all" />
          <Tab label="Saved Posts" value="saved" />
        </Tabs>
      </Box>
      
      {/* Topic Filter (only shown on News Feed tab) */}
      {selectedTab === 'all' && (
        <Box sx={{ px: 2, py: 2, bgcolor: 'rgba(0, 0, 0, 0.2)' }}>
          <FormControl fullWidth size="small" sx={{
            '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
              '&.Mui-focused fieldset': { borderColor: '#4A90E2' },
              '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
            }
          }}>
            <InputLabel id="mobile-topic-select">Filter by Topic</InputLabel>
            <Select
              labelId="mobile-topic-select"
              value={selectedTopic}
              onChange={handleTopicChange}
              label="Filter by Topic"
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#1e293b',
                    color: 'white',
                  },
                },
              }}
            >
              <MenuItem value="all">All Topics</MenuItem>
              {topics.map(topic => (
                <MenuItem key={topic.id} value={topic.id}>{topic.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {/* Topic Chips */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, pb: 1, overflowX: 'auto' }}>
            {topics.slice(0, 5).map(topic => (
              <Chip
                key={topic.id}
                label={topic.name}
                clickable
                onClick={() => setSelectedTopic(topic.id)}
                sx={{
                  backgroundColor: selectedTopic === topic.id ? '#4A90E2' : 'rgba(59, 130, 246, 0.2)',
                  color: selectedTopic === topic.id ? 'white' : '#90caf9',
                  marginBottom: 1
                }}
              />
            ))}
          </Box>
        </Box>
      )}
      
      {/* Main Content */}
      <Box sx={{ p: 2, pb: 8 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress sx={{ color: 'white' }} />
          </Box>
        ) : posts.length === 0 ? (
          <Box sx={{ 
            backgroundColor: 'rgba(30, 41, 59, 0.8)',
            backdropFilter: 'blur(8px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            p: 3,
            textAlign: 'center'
          }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              {selectedTab === 'saved' ? 'No Saved Posts' : 'No Posts Found'}
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {selectedTab === 'saved'
                ? "You haven't saved any posts yet."
                : "There are no posts to display matching your current filters."}
            </Typography>
          </Box>
        ) : (
          posts.map(post => (
            <PostCard
              key={post.id}
              post={post}
              onLike={handleLikePost}
              onSave={handleSavePost}
            />
          ))
        )}
      </Box>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { NewsFeedButton, MobileNewsFeed };