import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  Button,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tooltip,
  CircularProgress,
  Alert,
  Paper,
  Collapse,
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExcelQuestionUploader from './ExcelQuestionUploader';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CloudUpload as CloudUploadIcon,
  EmojiEvents as TrophyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Visibility as VisibilityIcon,
  Save as SaveIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  DragHandle as DragHandleIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';

import {
  listCompetitions,
  createCompetition,
  updateCompetition,
  deleteCompetition,
  createCompetitionWithQuestions,
  bulkCreateCompetitionQuestions,
  getCompetitionLeaderboard,
  getHistoricalLeaderboards,
  uploadFile3,
  getCompetition,
  getCompetitionQuestion,
  updateCompetitionQuestion,
  deleteCompetitionQuestion,
  updateCompetitionQuestionOption,
  deleteCompetitionQuestionOption,
  addCompetitionQuestionOption,
  reorderCompetitionQuestions,
  reorderQuestionOptions,
} from '../api';
import CompetitionLeaderboard from '../components/CompetitionLeaderboard';

// Styled Components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  border: '1px solid rgba(255, 255, 255, 0.2)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  padding: '8px 24px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  },
  '&.Mui-disabled': {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
  },
}));

const DropzoneArea = styled(Box)(({ theme }) => ({
  border: '2px dashed rgba(255, 255, 255, 0.3)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  transition: 'border-color 0.2s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(31, 41, 55, 0.95)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    minWidth: '600px',
  },
}));

const DraggableListItem = styled(ListItem)(({ theme, isDragging }) => ({
  border: '1px solid rgba(255, 255, 255, 0.12)',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  background: isDragging ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.05)',
  },
}));

const QuestionCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: theme.palette.common.white,
    fontSize: 12,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
}));

const CompetitionsPage = () => {
    // State Management
    const [competitions, setCompetitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [competitionToDelete, setCompetitionToDelete] = useState(null);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    const [openQuizDialog, setOpenQuizDialog] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [openDeleteQuestionDialog, setOpenDeleteQuestionDialog] = useState(false);
    const [openDeleteOptionDialog, setOpenDeleteOptionDialog] = useState(false);
    const [expandedCompetition, setExpandedCompetition] = useState(null);
    const [viewMode, setViewMode] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [leaderboards, setLeaderboards] = useState([]);
    const [questionOrder, setQuestionOrder] = useState([]);
    const [optionOrder, setOptionOrder] = useState([]);
    const [isReordering, setIsReordering] = useState(false);
    const [savingOrder, setSavingOrder] = useState(false);
    const [selectedOptionId, setSelectedOptionId] = useState(null);
   
    const [currentQuestion, setCurrentQuestion] = useState({
      question_text: '',
      video_url: '',
      image: null,
      options: Array(2).fill().map(() => ({ 
        option_text: '', 
        is_correct: false,
        image: null
      }))
    });
  
    // Form Data
    const [formData, setFormData] = useState({
      title: '',
      description: '',
      time_limit: 30,
      start_date: dayjs(),
      expiry_date: dayjs().add(7, 'day'),
      questions: []
    });

    const handleLeaderboardPageChange = async (competitionId, newPage) => {
        try {
          const response = await getCompetitionLeaderboard(competitionId, {
            page: newPage,
            page_size: 20
          });
      
          setLeaderboard(prev => {
            const filtered = prev.filter(lb => lb.competition_id !== competitionId);
            return [...filtered, {
              competition_id: competitionId,
              entries: response.data,
              currentPage: newPage,
              totalPages: Math.ceil(response.data.count / 20)
            }];
          });
        } catch (error) {
          console.error('Error fetching leaderboard page:', error);
        }
      };
  
    const [addedQuestions, setAddedQuestions] = useState([]);
  
    // Dropzone Configuration
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: async (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          try {
            const file = acceptedFiles[0];
            if (editingQuestion) {
              setEditingQuestion(prev => ({
                ...prev,
                image: file
              }));
            } else {
              setCurrentQuestion(prev => ({
                ...prev,
                image: file
              }));
            }
          } catch (error) {
            setError('Error handling uploaded file');
            console.error('Upload error:', error);
          }
        }
      },
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/gif': []
      },
      maxFiles: 1,
      multiple: false,
      maxSize: 5242880 // 5MB
    });
  
    // Effects
    useEffect(() => {
      return () => {
        if (editingQuestion?.image instanceof File) {
          URL.revokeObjectURL(URL.createObjectURL(editingQuestion.image));
        }
        if (currentQuestion?.image instanceof File) {
          URL.revokeObjectURL(URL.createObjectURL(currentQuestion.image));
        }
      };
    }, [editingQuestion?.image, currentQuestion?.image]);
  
    useEffect(() => {
      fetchCompetitions();
    }, []);
  
    // Handlers
    const fetchCompetitions = async () => {
        setLoading(true);
        try {
          const response = await listCompetitions();
          const competitionsData = response.data.results || response.data;
          setCompetitions(competitionsData);
      
          // Fetch leaderboards for active competitions
          const activeCompetitions = competitionsData.filter(comp => comp.is_active);
          const leaderboardData = await Promise.all(
            activeCompetitions.map(async (comp) => {
              try {
                // Add pagination parameters to initial fetch
                const lbResponse = await getCompetitionLeaderboard(comp.id, {
                  page: 1,
                  page_size: 20
                });
                
                return { 
                  competition_id: comp.id, 
                  entries: lbResponse.data,
                  currentPage: 1,
                  // Add total pages calculation
                  totalPages: Math.ceil(lbResponse.data.count / 20)
                };
              } catch (error) {
                console.error(`Error fetching leaderboard for competition ${comp.id}:`, error);
                return { 
                  competition_id: comp.id, 
                  entries: [],
                  currentPage: 1,
                  totalPages: 0
                };
              }
            })
          );
          setLeaderboard(leaderboardData);
        } catch (error) {
          setError('Failed to fetch competitions');
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };

    
  
    const handleImagePreview = (image) => {
      if (!image) return null;
      if (image instanceof File) {
        return URL.createObjectURL(image);
      }
      return image;
    };
  
    const handleAddQuestion = () => {
      if (!currentQuestion.question_text.trim()) {
        setError('Question text is required');
        return;
      }
    
      if (!currentQuestion.options.some(opt => opt.is_correct)) {
        setError('At least one option must be marked as correct');
        return;
      }
    
      if (currentQuestion.options.some(opt => !opt.option_text.trim())) {
        setError('All options must have text');
        return;
      }
    
      const newQuestion = {
        question_text: currentQuestion.question_text.trim(),
        video_url: currentQuestion.video_url?.trim() || '',
        image: currentQuestion.image,
        options: currentQuestion.options.map((opt, index) => ({
          option_text: opt.option_text.trim(),
          is_correct: Boolean(opt.is_correct),
          image: opt.image,
          order: index + 1
        }))
      };
    
      setAddedQuestions(prev => [...prev, newQuestion]);
      
      setCurrentQuestion({
        question_text: '',
        video_url: '',
        image: null,
        options: Array(2).fill().map(() => ({ 
          option_text: '', 
          is_correct: false,
          image: null 
        }))
      });
    
      setSuccess('Question added successfully');
    };
  
    const validateDates = (startDate, expiryDate) => {
      const now = dayjs();
      const start = dayjs(startDate);
      const expiry = dayjs(expiryDate);
    
      if (start.isBefore(now)) {
        throw new Error('Start date must be in the future');
      }
      
      if (expiry.isBefore(start)) {
        throw new Error('Expiry date must be after start date');
      }
    };

    const handleCreateCompetition = async () => {
        if (!formData.title.trim()) {
          setError('Title is required');
          return;
        }
      
        if (addedQuestions.length === 0) {
          setError('At least one question is required');
          return;
        }
      
        try {
          setLoading(true);
          const formDataObj = new FormData();
          
          // Add basic competition data
          formDataObj.append('title', formData.title);
          formDataObj.append('description', formData.description || '');
          formDataObj.append('time_limit', formData.time_limit.toString());
          formDataObj.append('start_date', formData.start_date.toISOString());
          formDataObj.append('expiry_date', formData.expiry_date.toISOString());
      
          // Prepare questions data including image information
          const questionsData = addedQuestions.map((question, index) => ({
            question_text: question.question_text,
            video_url: question.video_url || '',
            order: index + 1,
            has_image: question.image instanceof File,
            options: question.options.map((opt, optIndex) => ({
              option_text: opt.option_text,
              is_correct: Boolean(opt.is_correct),
              order: optIndex + 1,
              has_image: opt.image instanceof File
            }))
          }));
      
          formDataObj.append('questions', JSON.stringify(questionsData));
      
          // Add question images with correct naming convention
          addedQuestions.forEach((question, index) => {
            if (question.image instanceof File) {
              formDataObj.append(
                `question_${index}_image`,
                question.image,
                question.image.name
              );
            }
          });
      
          // Add option images with correct naming convention
          addedQuestions.forEach((question, qIndex) => {
            question.options.forEach((option, oIndex) => {
              if (option.image instanceof File) {
                formDataObj.append(
                  `question_${qIndex}_option_${oIndex}_image`,
                  option.image,
                  option.image.name
                );
              }
            });
          });
      
          const response = await createCompetition(formDataObj);
          setSuccess('Competition created successfully');
          setOpenDialog(false);
          resetForm();
          await fetchCompetitions();
      
        } catch (error) {
          console.error('Competition creation error:', error);
          let errorMessage = 'Failed to create competition';
          if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
          }
          setError(errorMessage);
        } finally {
          setLoading(false);
        }
      };
    
      const handleUpdateQuestion = async (questionData) => {
        if (!currentQuestionId) {
          setError('Question ID is missing');
          return;
        }
      
        try {
          setLoading(true);
          const formData = new FormData();
          
          formData.append('question_text', questionData.question_text);
          
          if (questionData.video_url) {
            formData.append('video_url', questionData.video_url);
          }
          
          if (questionData.image instanceof File) {
            formData.append('image', questionData.image);
          }
          
          const optionsData = questionData.options.map((option, index) => ({
            id: option.id,
            option_text: option.option_text,
            is_correct: Boolean(option.is_correct),
            order: index + 1
          }));
          
          formData.append('options', JSON.stringify(optionsData));
      
          await updateCompetitionQuestion(
            selectedCompetition.id,
            currentQuestionId,
            formData
          );
          
          const updatedCompetition = await getCompetition(selectedCompetition.id);
          setSelectedCompetition(updatedCompetition.data);
          setEditingQuestion(null);
          setCurrentQuestionId(null);
          setSuccess('Question updated successfully');
          
        } catch (error) {
          console.error('Update error:', error);
          setError(error.response?.data?.error || 'Failed to update question');
        } finally {
          setLoading(false);
        }
      };

      
    
      const handleOptionReorder = async (questionId, result) => {
        if (!result.destination || !questionId) return;
      
        const newOptions = Array.from(editingQuestion.options);
        const [reorderedItem] = newOptions.splice(result.source.index, 1);
        newOptions.splice(result.destination.index, 0, reorderedItem);
      
        setEditingQuestion(prev => ({
          ...prev,
          options: newOptions.map((opt, index) => ({
            ...opt,
            order: index + 1
          }))
        }));
      
        try {
          await reorderQuestionOptions(
            selectedCompetition.id,
            questionId,
            newOptions.map((option, index) => ({
              option_id: option.id,
              order: index + 1
            }))
          );
          
          const response = await getCompetition(selectedCompetition.id);
          const updatedQuestion = response.data.questions.find(q => q.id === questionId);
          if (updatedQuestion) {
            setEditingQuestion(updatedQuestion);
          }
          setSuccess('Options reordered successfully');
        } catch (error) {
          setError('Failed to reorder options');
          console.error('Error:', error);
          
          const response = await getCompetition(selectedCompetition.id);
          const originalQuestion = response.data.questions.find(q => q.id === questionId);
          if (originalQuestion) {
            setEditingQuestion(originalQuestion);
          }
        }
      };
    
      const handleQuestionReorder = async (result) => {
        if (!result.destination) return;
      
        const newQuestionOrder = Array.from(questionOrder);
        const [reorderedItem] = newQuestionOrder.splice(result.source.index, 1);
        newQuestionOrder.splice(result.destination.index, 0, reorderedItem);
      
        setQuestionOrder(newQuestionOrder);
        setSavingOrder(true);
      
        try {
          await reorderCompetitionQuestions(selectedCompetition.id, 
            newQuestionOrder.map((questionId, index) => ({
              question_id: questionId,
              order: index + 1
            }))
          );
          
          const updatedCompetition = await getCompetition(selectedCompetition.id);
          setSelectedCompetition(updatedCompetition.data);
          setSuccess('Questions reordered successfully');
        } catch (error) {
          setError('Failed to reorder questions');
          console.error('Error:', error);
        } finally {
          setSavingOrder(false);
        }
      };
    
      const handleAddOption = async (questionId) => {
        if (!questionId) {
          setError('Question ID is missing');
          return;
        }
      
        try {
          setLoading(true);
          await addCompetitionQuestionOption(
            selectedCompetition.id,
            questionId,
            {
              option_text: '',
              is_correct: false,
              order: editingQuestion.options.length + 1
            }
          );
          
          const response = await getCompetition(selectedCompetition.id);
          const updatedQuestion = response.data.questions.find(q => q.id === questionId);
          if (updatedQuestion) {
            setEditingQuestion(updatedQuestion);
          }
          setSuccess('Option added successfully');
        } catch (error) {
          setError('Failed to add option');
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
    
      const handleViewQuiz = async (competition) => {
        try {
          setLoading(true);
          const response = await getCompetition(competition.id);
          setSelectedCompetition(response.data);
          setQuestionOrder(response.data.questions.map(q => q.id));
          setOpenQuizDialog(true);
        } catch (error) {
          setError('Failed to load quiz details');
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
    
      const handleEditQuestion = async (questionId) => {
        try {
          setLoading(true);
          const response = await getCompetition(selectedCompetition.id);
          const question = response.data.questions.find(q => q.id === questionId);
          if (!question) {
            throw new Error('Question not found');
          }
          
          setEditingQuestion(question);
          setCurrentQuestionId(questionId);
          setViewMode(false);
        } catch (error) {
          setError('Failed to load question details');
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
    
      const resetForm = () => {
        setFormData({
          title: '',
          description: '',
          time_limit: 30,
          start_date: dayjs(),
          expiry_date: dayjs().add(7, 'day'),
          questions: []
        });
        setCurrentQuestion({
          question_text: '',
          video_url: '',
          image: null,
          options: Array(2).fill().map(() => ({ option_text: '', is_correct: false }))
        });
        setAddedQuestions([]);
      };

      // Render Methods
  const renderDropzone = (image, isEditing = false) => (
    <DropzoneArea 
      {...getRootProps()} 
      sx={{ 
        borderColor: isDragActive ? 'primary.main' : 'rgba(255, 255, 255, 0.3)',
        bgcolor: isDragActive ? 'rgba(255, 255, 255, 0.05)' : 'transparent',
        mb: 2,
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
        }
      }}
    >
      <input {...getInputProps()} />
      <CloudUploadIcon sx={{ fontSize: 40, mb: 1, color: 'white' }} />
      <Typography color="white">
        {isDragActive
          ? 'Drop the image here'
          : image
          ? 'Drop new image to replace or click'
          : 'Drop image here or click to select'}
      </Typography>
      {image && (
        <Box sx={{ mt: 2 }}>
          <img 
            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
            alt="Preview"
            style={{ 
              maxWidth: '200px', 
              maxHeight: '200px',
              borderRadius: '4px',
              objectFit: 'contain'
            }}
            onLoad={() => {
              if (image instanceof File) {
                URL.revokeObjectURL(image);
              }
            }}
          />
        </Box>
      )}
    </DropzoneArea>
  );

  const renderQuestionEditForm = () => (
    <Box sx={{ p: 3 }}>
      <TextField
        fullWidth
        label="Question Text"
        value={editingQuestion.question_text}
        onChange={(e) => setEditingQuestion({
          ...editingQuestion,
          question_text: e.target.value
        })}
        required
        sx={{ mb: 2 }}
        InputLabelProps={{ sx: { color: 'white' } }}
        InputProps={{ sx: { color: 'white' } }}
      />
  
      <TextField
        fullWidth
        label="Video URL (optional)"
        value={editingQuestion.video_url || ''}
        onChange={(e) => setEditingQuestion({
          ...editingQuestion,
          video_url: e.target.value
        })}
        sx={{ mb: 2 }}
        InputLabelProps={{ sx: { color: 'white' } }}
        InputProps={{ sx: { color: 'white' } }}
      />
  
      {renderDropzone(editingQuestion.image, true)}
  
      <Typography variant="h6" color="white" sx={{ mt: 3, mb: 2 }}>
        Options
      </Typography>
  
      <DragDropContext
        onDragEnd={(result) => handleOptionReorder(editingQuestion.id, result)}
      >
        <Droppable droppableId={`options-${editingQuestion.id}`}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {editingQuestion.options.map((option, index) => (
                <Draggable
                  key={option.id}
                  draggableId={`option-${option.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        p: 2,
                        mb: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <DragHandleIcon sx={{ color: 'white' }} />
                      
                      <TextField
                        fullWidth
                        label={`Option ${index + 1}`}
                        value={option.option_text}
                        onChange={(e) => {
                          const newOptions = [...editingQuestion.options];
                          newOptions[index] = {
                            ...newOptions[index],
                            option_text: e.target.value
                          };
                          setEditingQuestion({
                            ...editingQuestion,
                            options: newOptions
                          });
                        }}
                        InputLabelProps={{ sx: { color: 'white' } }}
                        InputProps={{ sx: { color: 'white' } }}
                        required
                      />
  
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel sx={{ color: 'white' }}>Correct?</InputLabel>
                        <Select
                          value={option.is_correct}
                          label="Correct?"
                          onChange={(e) => {
                            const newOptions = [...editingQuestion.options];
                            newOptions[index] = {
                              ...newOptions[index],
                              is_correct: e.target.value
                            };
                            setEditingQuestion({
                              ...editingQuestion,
                              options: newOptions
                            });
                          }}
                          sx={{ color: 'white' }}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
  
                      <IconButton
                        onClick={() => {
                          setSelectedOptionId(option.id);
                          setOpenDeleteOptionDialog(true);
                        }}
                        sx={{ color: 'error.main' }}
                        disabled={editingQuestion.options.length <= 2}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
  
      <Button
        startIcon={<AddIcon />}
        onClick={() => handleAddOption(editingQuestion.id)}
        disabled={editingQuestion.options.length >= 6}
        sx={{ mt: 2, mb: 3, color: 'white' }}
      >
        Add Option
      </Button>
  
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
        <Button
          onClick={() => {
            setEditingQuestion(null);
            setCurrentQuestionId(null);
          }}
          sx={{ color: 'white' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          onClick={() => handleUpdateQuestion(editingQuestion)}
          disabled={loading}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );

  const renderQuizViewDialog = () => (
    <DragDropContext onDragEnd={handleQuestionReorder}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          startIcon={isReordering ? <SaveIcon /> : <DragHandleIcon />}
          onClick={() => setIsReordering(!isReordering)}
          sx={{ mr: 1, color: 'white' }}
        >
          {isReordering ? 'Save Order' : 'Reorder Questions'}
        </Button>
        <Button
          startIcon={viewMode ? <VisibilityIcon /> : <EditIcon />}
          onClick={() => setViewMode(!viewMode)}
          sx={{ color: 'white' }}
        >
          {viewMode ? 'Hide Answers' : 'Show Answers'}
        </Button>
      </Box>
      
      <Droppable droppableId="questions">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            {selectedCompetition?.questions?.map((question, index) => (
              <Draggable
                key={question.id}
                draggableId={`question-${question.id}`}
                index={index}
                isDragDisabled={!isReordering}
              >
                {(provided, snapshot) => (
                  <QuestionCard
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{ p: 2 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      {isReordering && (
                        <Box {...provided.dragHandleProps} sx={{ mr: 1, cursor: 'grab' }}>
                          <DragHandleIcon sx={{ color: 'white' }} />
                        </Box>
                      )}
                      
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="h6" color="white">
                            Question {index + 1}
                          </Typography>
                          {!isReordering && (
                            <Box>
                              <IconButton
                                onClick={() => handleEditQuestion(question.id)}
                                sx={{ color: 'primary.main', mr: 1 }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setEditingQuestion(question);
                                  setOpenDeleteQuestionDialog(true);
                                }}
                                sx={{ color: 'error.main' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
  
                        <Typography color="white" sx={{ mb: 2 }}>
                          {question.question_text}
                        </Typography>
  
                        {question.video_url && (
                          <Typography color="white" variant="body2" sx={{ mb: 2 }}>
                            Video URL: {question.video_url}
                          </Typography>
                        )}
  
                        {question.image && (
                          <Box sx={{ mb: 2 }}>
                            <img
                              src={handleImagePreview(question.image)}
                              alt="Question"
                              style={{ maxWidth: '100%', borderRadius: '4px' }}
                            />
                          </Box>
                        )}
  
                        <List sx={{ mt: 2 }}>
                          {question.options.map((option, optIndex) => (
                            <ListItem
                              key={option.id}
                              sx={{
                                bgcolor: viewMode && option.is_correct ? 
                                  'rgba(76, 175, 80, 0.1)' : 
                                  'transparent',
                                borderRadius: 1,
                                mb: 1
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography color="white">
                                    {optIndex + 1}. {option.option_text}
                                    {viewMode && option.is_correct && (
                                      <CheckIcon
                                        sx={{
                                          ml: 1,
                                          verticalAlign: 'middle',
                                          color: 'success.main',
                                          fontSize: 20
                                        }}
                                      />
                                    )}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </QuestionCard>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );

  const renderCreateCompetitionForm = () => (
    <Box sx={{ bgcolor: 'rgba(255, 255, 255, 0.05)', p: 2, borderRadius: 1 }}>
      <TextField
        fullWidth
        label="Question Text"
        value={currentQuestion.question_text}
        onChange={(e) => setCurrentQuestion({
          ...currentQuestion,
          question_text: e.target.value
        })}
        sx={{ mb: 2 }}
        InputLabelProps={{ sx: { color: 'white' } }}
        InputProps={{ sx: { color: 'white' } }}
        required
      />

      <TextField
        fullWidth
        label="Video URL (optional)"
        value={currentQuestion.video_url}
        onChange={(e) => setCurrentQuestion({
          ...currentQuestion,
          video_url: e.target.value
        })}
        sx={{ mb: 2 }}
        InputLabelProps={{ sx: { color: 'white' } }}
        InputProps={{ sx: { color: 'white' } }}
      />

      {renderDropzone(currentQuestion.image)}

      <Typography variant="subtitle2" color="white" sx={{ mb: 1 }}>
        Options (minimum 2, maximum 6):
      </Typography>

      <DragDropContext onDragEnd={(result) => {
        if (!result.destination) return;
        
        const newOptions = [...currentQuestion.options];
        const [reorderedItem] = newOptions.splice(result.source.index, 1);
        newOptions.splice(result.destination.index, 0, reorderedItem);
        
        setCurrentQuestion({
          ...currentQuestion,
          options: newOptions
        });
      }}>
        <Droppable droppableId="create-options">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {currentQuestion.options.map((option, index) => (
                <Draggable
                  key={index}
                  draggableId={`create-option-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        display: 'flex',
                        gap: 1,
                        mb: 1,
                        p: 2,
                        bgcolor: snapshot.isDragging ? 
                          'rgba(255, 255, 255, 0.1)' : 
                          'rgba(255, 255, 255, 0.05)',
                        borderRadius: 1
                      }}
                    >
                      <DragHandleIcon sx={{ color: 'white', my: 'auto' }} />
                      <TextField
                        fullWidth
                        label={`Option ${index + 1}`}
                        value={option.option_text}
                        onChange={(e) => {
                          const newOptions = [...currentQuestion.options];
                          newOptions[index].option_text = e.target.value;
                          setCurrentQuestion({
                            ...currentQuestion,
                            options: newOptions
                          });
                        }}
                        InputLabelProps={{ sx: { color: 'white' } }}
                        InputProps={{ sx: { color: 'white' } }}
                        required
                      />
                      
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel sx={{ color: 'white' }}>Correct?</InputLabel>
                        <Select
                          value={option.is_correct}
                          label="Correct?"
                          onChange={(e) => {
                            const newOptions = [...currentQuestion.options];
                            newOptions[index].is_correct = e.target.value;
                            setCurrentQuestion({
                              ...currentQuestion,
                              options: newOptions
                            });
                          }}
                          sx={{ color: 'white' }}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>

                      {currentQuestion.options.length > 2 && (
                        <IconButton
                          onClick={() => {
                            const newOptions = currentQuestion.options.filter((_, i) => i !== index);
                            setCurrentQuestion({
                              ...currentQuestion,
                              options: newOptions
                            });
                          }}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {currentQuestion.options.length < 6 && (
        <Button
          startIcon={<AddIcon />}
          onClick={() => setCurrentQuestion({
            ...currentQuestion,
            options: [
              ...currentQuestion.options,
              { option_text: '', is_correct: false }
            ]
          })}
          sx={{ mt: 1, color: 'white' }}
        >
          Add Option
        </Button>
      )}

      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleAddQuestion}
          disabled={
            !currentQuestion.question_text.trim() ||
            currentQuestion.options.length < 2 ||
            !currentQuestion.options.some(opt => opt.is_correct) ||
            currentQuestion.options.some(opt => !opt.option_text.trim())
          }
        >
          Add Question
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" color="white" gutterBottom>
          Added Questions ({addedQuestions.length})
        </Typography>
        {addedQuestions.map((question, index) => (
          <Box 
            key={index}
            sx={{ 
              mb: 2,
              p: 2,
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              borderRadius: 1
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography color="white">
                Question {index + 1}: {question.question_text}
              </Typography>
              <IconButton
                onClick={() => {
                  setAddedQuestions(prev => prev.filter((_, i) => i !== index));
                  setFormData(prev => ({
                    ...prev,
                    questions: prev.questions.filter((_, i) => i !== index)
                  }));
                }}
                sx={{ color: 'error.main' }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <List dense>
              {question.options.map((option, optIndex) => (
                <ListItem key={optIndex}>
                  <ListItemText
                    primary={
                      <Typography color="white">
                        {option.option_text}
                        {option.is_correct && (
                          <CheckIcon sx={{ ml: 1, color: 'success.main', fontSize: 16 }} />
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="white">
          Competitions
        </Typography>
        <GradientButton
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create Competition
        </GradientButton>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      {loading && !selectedCompetition ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {competitions.map((competition) => (
            <Grid item xs={12} key={competition.id}>
              <Box>
                <GlassCard>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom color="white">
                        {competition.title}
                      </Typography>
                      <Box>
                        <IconButton
                          onClick={() => setExpandedCompetition(
                            expandedCompetition === competition.id ? null : competition.id
                          )}
                          sx={{ color: 'white' }}
                        >
                          {expandedCompetition === competition.id ? 
                            <ExpandLessIcon /> : <ExpandMoreIcon />
                          }
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setCompetitionToDelete(competition);
                            setOpenDeleteDialog(true);
                          }}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>

                    <Collapse in={expandedCompetition === competition.id}>
                      <Typography variant="body2" sx={{ mb: 2 }} color="white">
                        {competition.description}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" color="white">
                          Time Limit: {competition.time_limit} minutes
                        </Typography>
                        <Typography 
                          variant="body2" 
                          color={competition.is_active ? 'success.main' : 'error.main'}
                        >
                          {competition.is_active ? 'Active' : 'Expired'}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="white">
                        Starts: {dayjs(competition.start_date).format('MMM D, YYYY HH:mm')}
                      </Typography>
                      <Typography variant="body2" color="white">
                        Ends: {dayjs(competition.expiry_date).format('MMM D, YYYY HH:mm')}
                      </Typography>

                      <Button
                        variant="outlined"
                        startIcon={<VisibilityIcon />}
                        onClick={() => handleViewQuiz(competition)}
                        sx={{ mt: 2, color: 'white', borderColor: 'white' }}
                      >
                        View/Edit Quiz
                      </Button>
                    </Collapse>
                  </Box>
                </GlassCard>
                
                {/* Leaderboard component outside the card */}
                {leaderboard.find(l => l.competition_id === competition.id)?.entries && (
  <CompetitionLeaderboard 
    leaderboardData={leaderboard.find(l => l.competition_id === competition.id).entries}
    competition={competition}
   
    onPageChange={(newPage) => handleLeaderboardPageChange(competition.id, newPage)}
    currentPage={leaderboard.find(l => l.competition_id === competition.id).currentPage || 1}
    totalPages={leaderboard.find(l => l.competition_id === competition.id).totalPages || 1}
    loading={loading}
  />
)}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Create Competition Dialog */}
      <StyledDialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ color: 'white' }}>
          Create Competition
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{ sx: { color: 'white' } }}
              InputProps={{ sx: { color: 'white' } }}
              required
            />

            <TextField
              fullWidth
              multiline
              rows={3}
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              sx={{ mb: 2 }}
              InputLabelProps={{ sx: { color: 'white' } }}
              InputProps={{ sx: { color: 'white' } }}
            />

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Time Limit (minutes)"
                  value={formData.time_limit}
                  onChange={(e) => setFormData({ 
                    ...formData, 
                    time_limit: Math.max(1, Math.min(180, parseInt(e.target.value) || 30)) 
                  })}
                  InputLabelProps={{ sx: { color: 'white' } }}
                  InputProps={{ sx: { color: 'white' } }}
                  helperText="Between 1 and 180 minutes"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateTimePicker
                  label="Start Date"
                  value={formData.start_date}
                  onChange={(date) => setFormData({ ...formData, start_date: date })}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      InputLabelProps: { sx: { color: 'white' } },
                      InputProps: { sx: { color: 'white' } },
                      required: true
                    }
                  }}
                  minDateTime={dayjs()}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateTimePicker
                  label="Expiry Date"
                  value={formData.expiry_date}
                  onChange={(date) => setFormData({ ...formData, expiry_date: date })}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      InputProps: { sx: { color: 'white' } },
                      required: true
                    }
                  }}
                  minDateTime={formData.start_date}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 3, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" color="white" gutterBottom>
              Add Questions
            </Typography>
            <Box sx={{ mb: 3 }}>
  <Divider sx={{ my: 3, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
  <ExcelQuestionUploader 
    setAddedQuestions={setAddedQuestions}
    onQuestionsProcessed={(questions) => {
      setSuccess(`Successfully processed ${questions.length} questions from Excel`);
    }} 
  />
  <Divider sx={{ my: 3, bgcolor: 'rgba(255, 255, 255, 0.12)' }} />
</Box>
            {renderCreateCompetitionForm()}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() => {
              setOpenDialog(false);
              resetForm();
            }}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateCompetition}
            disabled={
              loading || 
              !formData.title.trim() || 
              !formData.start_date ||
              !formData.expiry_date ||
              addedQuestions.length === 0
            }
            startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            Create Competition
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Delete Competition Dialog */}
      <StyledDialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setCompetitionToDelete(null);
        }}
      >
        <DialogTitle color="white">Delete Competition</DialogTitle>
        <DialogContent>
          <Typography color="white">
            Are you sure you want to delete "{competitionToDelete?.title}"? 
            This will also delete all questions and participant data. This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setCompetitionToDelete(null);
            }}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                setLoading(true);
                await deleteCompetition(competitionToDelete.id);
                setSuccess('Competition deleted successfully');
                setOpenDeleteDialog(false);
                setCompetitionToDelete(null);
                await fetchCompetitions();
              } catch (error) {
                setError('Failed to delete competition');
                console.error('Error:', error);
              } finally {
                setLoading(false);
              }
            }}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* View/Edit Quiz Dialog */}
      <StyledDialog 
        open={openQuizDialog} 
        onClose={() => {
          setOpenQuizDialog(false);
          setEditingQuestion(null);
          setCurrentQuestionId(null);
          setViewMode(false);
          setIsReordering(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" color="white">
              {editingQuestion ? 'Edit Question' : selectedCompetition?.title}
            </Typography>
            <IconButton 
              onClick={() => setOpenQuizDialog(false)}
              sx={{ color: 'error.main' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {editingQuestion ? renderQuestionEditForm() : renderQuizViewDialog()}
        </DialogContent>
      </StyledDialog>
    </Box>
  );
};

export default CompetitionsPage;