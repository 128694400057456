// ArticleContent Component to be added to your NewsFeed.js file
import React, { useState } from 'react';
import {
  Box,
  Typography,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import DOMPurify from 'dompurify';

const ArticleContent = ({ article, title }) => {
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  // Extract images from article content
  const extractImagesFromContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const images = Array.from(doc.querySelectorAll('img'));
    return images.map(img => ({
      src: img.getAttribute('src'),
      alt: img.getAttribute('alt') || title
    }));
  };
  
  // Prepare article preview (first 300 characters or so)
  const createPreview = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    
    // Remove images from preview text calculation
    const images = Array.from(doc.querySelectorAll('img'));
    images.forEach(img => img.remove());
    
    // Get text content
    let textContent = doc.body.textContent || '';
    const preview = textContent.substring(0, 300);
    
    return preview.length < textContent.length ? `${preview}...` : preview;
  };
  
  const extractedImages = article?.content ? extractImagesFromContent(article.content) : [];
  const hasMoreContent = article?.content && article.content.length > 300;
  
  // Handle opening the full article dialog
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  
  // Handle closing the full article dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (!article) return null;
  
  return (
    <>
      <CardContent sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
        {/* Preview text */}
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          {createPreview(article.content)}
        </Typography>
        
        {/* Display first image if any (with fallback to article images array) */}
        {(extractedImages.length > 0 || (article.images && article.images.length > 0)) && (
          <Box 
            sx={{ 
              mb: 2, 
              borderRadius: 1, 
              overflow: 'hidden',
              maxHeight: '300px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img 
              src={extractedImages[0]?.src || article.images[0]?.image} 
              alt={title}
              style={{ 
                maxWidth: '100%', 
                maxHeight: '300px', 
                objectFit: 'contain',
                borderRadius: '4px'
              }}
            />
          </Box>
        )}
        
        {/* Read more button */}
        {hasMoreContent && (
          <Button 
            variant="outlined"
            onClick={handleOpenDialog}
            sx={{ 
              mt: 1, 
              color: '#90caf9', 
              borderColor: 'rgba(144, 202, 249, 0.5)',
              '&:hover': {
                borderColor: '#90caf9',
                backgroundColor: 'rgba(144, 202, 249, 0.08)'
              }
            }}
          >
            Read Full Article
          </Button>
        )}
      </CardContent>
      
      {/* Full article dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#1e293b', // Dark background
            color: 'white',
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            maxHeight: '90vh'
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          <IconButton 
            onClick={handleCloseDialog}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent dividers sx={{ 
          p: 3, 
          color: 'rgba(255, 255, 255, 0.9)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(0, 0, 0, 0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.3)',
          }
        }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(article.content, {
                ADD_ATTR: ['target'], // Allow target attribute for links
                // Configure image styling to ensure they're contained in the dialog
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
              }),
            }}
            sx={{
              // Styling for article content
              img: {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '4px',
                my: 2
              },
              a: {
                color: '#90caf9',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              },
              p: {
                my: 1.5
              },
              blockquote: {
                borderLeft: '4px solid rgba(144, 202, 249, 0.5)',
                pl: 2,
                py: 1,
                my: 2,
                fontStyle: 'italic',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '0 4px 4px 0'
              },
              'h1, h2, h3, h4, h5, h6': {
                color: 'white',
                mt: 3,
                mb: 2
              },
              ul: {
                pl: 3
              },
              li: {
                mb: 1
              }
            }}
          />
        </DialogContent>
        
        <DialogActions sx={{ p: 2, bgcolor: 'rgba(0, 0, 0, 0.2)' }}>
          <Button 
            onClick={handleCloseDialog} 
            variant="contained"
            sx={{ 
              bgcolor: 'rgba(144, 202, 249, 0.2)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(144, 202, 249, 0.3)'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArticleContent;