import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  LinearProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Divider,
  Container
} from '@mui/material';

import {
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
  CheckCircle as CheckCircleIcon,
  DoneAll as DoneAllIcon,
  Book as BookIcon,
  Headphones as HeadphonesIcon,
  ArrowBack as ArrowBackIcon,
  MenuBook as MenuBookIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@mui/icons-material';
import { History } from 'lucide-react';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  getNextAvailableContent,
  getMyProgress1,
  markContentComplete1,
  logContentAction,
  listUserProgress,
  getReadListenContent
} from '../api';

dayjs.extend(relativeTime);

// Styled Components
const ContentCard = styled(Card)(({ theme }) => ({
  background: '#0D47A1',
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  border: '1px solid rgba(255,255,255,0.1)',
  color: 'white',
  marginBottom: theme.spacing(2)
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #4A90E2, #2196F3)',
  color: '#fff',
  borderRadius: 24,
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(45deg, #2196F3, #4A90E2)',
  },
  '&:disabled': {
    background: 'rgba(255,255,255,0.12)',
    color: 'rgba(255,255,255,0.3)'
  }
}));

const AudioProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#4A90E2'
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  '& img': {
    maxWidth: '100%',
    height: 'auto'
  },
  '& *': {
    maxWidth: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word'
  }
}));

const WrappedTypography = styled(Typography)({
  maxWidth: '100%',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  hyphens: 'auto'
});

// Helper functions
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const secondsToIsoDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const milliseconds = Math.round((seconds % 1) * 1000000);
  return `PT${hours}H${minutes}M${secs}.${milliseconds}S`;
};

const ReadListenContent = ({ onClose }) => {
  const [currentContent, setCurrentContent] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioProgress, setAudioProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [completionNotes, setCompletionNotes] = useState('');
  const [progressHistory, setProgressHistory] = useState([]);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [selectedHistoryContent, setSelectedHistoryContent] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const audioRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    setLoading(true);
    try {
      const progressResponse = await getMyProgress1();
      setProgress(progressResponse.data);

      if (progressResponse.data.completion_percentage < 100) {
        try {
          const contentResponse = await getNextAvailableContent();
          setCurrentContent(contentResponse.data);
        } catch (error) {
          if (error.response?.status === 404) {
            setCurrentContent(null);
          } else {
            throw error;
          }
        }
      } else {
        setCurrentContent(null);
      }
      
      await fetchHistoryData();
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch content', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchHistoryData = async () => {
    try {
      const response = await listUserProgress();
      const sortedHistory = response.data.sort((a, b) => 
        new Date(b.completed_at) - new Date(a.completed_at)
      );
      setProgressHistory(sortedHistory);
    } catch (err) {
      console.error('Error fetching history:', err);
      enqueueSnackbar('Failed to load history data', { variant: 'error' });
    }
  };

  const handleContentClick = async (item) => {
    setHistoryLoading(true);
    try {
      const response = await getReadListenContent(item.content);
      setSelectedHistoryContent(response.data);
      setCompletionDialogOpen(false);
      
      if (response.data.content_type === 'AUDIO') {
        setAudioPlaying(false);
        setAudioProgress(0);
        setCurrentTime(0);
        setDuration(0);
      }
    } catch (err) {
      console.error('Error fetching content:', err);
      enqueueSnackbar('Failed to load content details', { variant: 'error' });
    } finally {
      setHistoryLoading(false);
    }
  };

  const handleAudioPlay = () => {
    if (audioRef.current) {
      if (audioPlaying) {
        audioRef.current.pause();
        logContentAction({
          content_id: currentContent.id,
          action: 'paused',
          position: secondsToIsoDuration(audioRef.current.currentTime)
        });
      } else {
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          enqueueSnackbar('Error playing audio file', { variant: 'error' });
        });
        logContentAction({
          content_id: currentContent.id,
          action: 'started',
          position: secondsToIsoDuration(audioRef.current.currentTime)
        });
      }
      setAudioPlaying(!audioPlaying);
    }
  };

  const handleAudioTimeUpdate = () => {
    if (audioRef.current) {
      const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setAudioProgress(progress);
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleAudioEnded = () => {
    setAudioPlaying(false);
    setAudioProgress(100);
    logContentAction({
      content_id: currentContent.id,
      action: 'completed',
      position: secondsToIsoDuration(audioRef.current.duration)
    });
  };

  const handleMarkComplete = async () => {
    try {
      const data = {
        notes: completionNotes
      };

      if (currentContent.content_type === 'AUDIO' && audioRef.current) {
        data.listened_duration = secondsToIsoDuration(audioRef.current.currentTime);
      }

      await markContentComplete1(currentContent.id, data);
      enqueueSnackbar('Content marked as complete!', { variant: 'success' });
      setCompletionDialogOpen(false);
      setCompletionNotes('');
      setShowContinueDialog(true);
    } catch (error) {
      console.error('Error marking content complete:', error);
      enqueueSnackbar('Failed to mark content as complete', { variant: 'error' });
    }
  };

  const handleContinueReading = () => {
    setShowContinueDialog(false);
    fetchData();
  };

  const handleGoHome = () => {
    setShowContinueDialog(false);
    onClose();
  };

  const handleHistoryContentClose = () => {
    setSelectedHistoryContent(null);
    setAudioPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (historyDialogOpen) {
      fetchHistoryData();
    }
  }, [historyDialogOpen]);

  useEffect(() => {
    if (currentContent && currentContent.content_type === 'AUDIO') {
      setAudioPlaying(false);
      setAudioProgress(0);
      setCurrentTime(0);
      setDuration(0);
    }
  }, [currentContent]);

  const renderAudioPlayer = () => {
    const audioUrl = currentContent?.audio_file || selectedHistoryContent?.audio_file;
    if (!audioUrl) return null;

    return (
      <Box sx={{ mt: 3 }}>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={handleAudioTimeUpdate}
          onEnded={handleAudioEnded}
          onLoadedMetadata={() => {
            if (audioRef.current) {
              setDuration(audioRef.current.duration);
            }
          }}
          style={{ display: 'none' }}
        />
        <Box sx={{ 
          backgroundColor: 'rgba(255,255,255,0.05)',
          borderRadius: 2,
          p: 2
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton 
              onClick={handleAudioPlay}
              size="large"
              sx={{ 
                color: '#4A90E2',
                '&:hover': { backgroundColor: 'rgba(74,144,226,0.1)' }
              }}
            >
              {audioPlaying ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
            <Box sx={{ flexGrow: 1, mx: 2 }}>
              <AudioProgressBar variant="determinate" value={audioProgress} />
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mt: 1,
                color: 'rgba(255,255,255,0.7)'
              }}>
                <Typography variant="caption">{formatTime(currentTime)}</Typography>
                <Typography variant="caption">{formatTime(duration)}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderHistoryContent = () => {
    if (!selectedHistoryContent) return null;

    return (
      <Dialog
        fullScreen
        open={Boolean(selectedHistoryContent)}
        onClose={handleHistoryContentClose}
        PaperProps={{
          sx: {
            bgcolor: '#0A0F24',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: '#0B1537',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: 'white'
        }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleHistoryContentClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{selectedHistoryContent.title}</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
            {selectedHistoryContent.description}
          </Typography>
          
          {selectedHistoryContent.content_type === 'TEXT' ? (
            <Paper sx={{ 
              p: 2, 
              bgcolor: 'rgba(255,255,255,0.05)',
              color: 'white' 
            }}>
              <div dangerouslySetInnerHTML={{ __html: selectedHistoryContent.text_content }} />
            </Paper>
          ) : (
            <Box sx={{ 
              p: 2, 
              bgcolor: 'rgba(255,255,255,0.05)',
              borderRadius: 2 
            }}>
              {renderAudioPlayer()}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  const CompletedMessage = ({ stats }) => {
    if (!stats) return null;

    return (
      <Paper sx={{ 
        p: 4, 
        textAlign: 'center',
        backgroundColor: 'rgba(255,255,255,0.05)',
        color: 'white'
      }}>
        <DoneAllIcon sx={{ fontSize: 48, color: '#4CAF50', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          All Content Completed! 🎉
        </Typography>
        <Typography sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
          You've completed all available content ({stats.total_completed} items):
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mb: 2 }}>
          <Box sx={{ textAlign: 'center' }}>
            <HeadphonesIcon sx={{ color: '#4A90E2' }} />
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              {stats.audio_completed} Audio
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <BookIcon sx={{ color: '#4A90E2' }} />
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              {stats.text_completed} Text
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ color: 'rgba(255,255,255,0.7)' }}>
          Check back later for new content updates!
        </Typography>
      </Paper>
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress sx={{ color: '#4A90E2' }} />
      </Box>
    );
  }

  return (
    <ContentWrapper sx={{ p: 2 }}>
      <ContentCard>
        <CardContent>
          <WrappedTypography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
            Your Progress
          </WrappedTypography>
          <Box sx={{ mb: 3 }}>
            <WrappedTypography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Overall Completion
            </WrappedTypography>
            <LinearProgress
              variant="determinate"
              value={progress?.completion_percentage || 0}
              sx={{ 
                mt: 1, 
                mb: 1,
                height: 8,
                borderRadius: 4,
                backgroundColor: 'rgba(255,255,255,0.1)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4A90E2'
                }
              }}
            />
            <WrappedTypography variant="body2">
              {progress?.total_completed || 0} of {progress?.total_available || 0} completed
            </WrappedTypography>
          </Box>

          <Divider sx={{ my: 2, borderColor: 'rgba(255,255,255,0.1)' }} />

          <WrappedTypography variant="subtitle1" gutterBottom>
            Recent Completions
          </WrappedTypography>
          <List sx={{ width: '100%' }}>
            {progressHistory.slice(0, 5).map((item) => (
              <ListItem 
                key={item.id}
                sx={{
                  flexWrap: 'wrap',
                  '& .MuiListItemText-root': {
                    flex: '1 1 auto',
                    minWidth: 0
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  {item.content_type === 'TEXT' ? (
                    <BookIcon sx={{ color: '#4A90E2' }} />
                  ) : (
                    <HeadphonesIcon sx={{ color: '#4A90E2' }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <WrappedTypography variant="body2" sx={{ color: 'white' }}>
                      {item.content_title}
                    </WrappedTypography>
                  }
                  secondary={
                    <WrappedTypography variant="caption" sx={{ color: 'rgba(255,255,255,0.5)' }}>
                      {dayjs(item.completed_at).fromNow()}
                    </WrappedTypography>
                  }
                />
                <CheckCircleOutlineIcon sx={{ color: '#4CAF50', ml: 1 }} />
              </ListItem>
            ))}
          </List>

          <Box mt={3}>
            <GradientButton
              onClick={() => setHistoryDialogOpen(true)}
              startIcon={<History />}
              fullWidth
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                py: 1.5
              }}
            >
              View Full History
            </GradientButton>
          </Box>
        </CardContent>
      </ContentCard>

      {currentContent ? (
        <ContentCard>
          <CardContent>
            <WrappedTypography variant="h5" gutterBottom>
              {currentContent.title}
            </WrappedTypography>
            <WrappedTypography sx={{ color: 'rgba(255,255,255,0.7)' }} paragraph>
              {currentContent.description}
            </WrappedTypography>
            
            {currentContent.content_type === 'TEXT' ? (
              <ContentWrapper sx={{ 
                mt: 3,
                '& img': { maxWidth: '100%', height: 'auto' },
                '& a': { color: '#4A90E2' },
                '& p, & div, & span': {
                  maxWidth: '100%',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word'
                }
              }}>
                <div 
                  dangerouslySetInnerHTML={{ __html: currentContent.text_content }}
                  style={{
                    maxWidth: '100%',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                  }}
                />
              </ContentWrapper>
            ) : (
              renderAudioPlayer()
            )}

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <GradientButton
                size="large"
                startIcon={<CheckCircleIcon />}
                onClick={() => setCompletionDialogOpen(true)}
                fullWidth
              >
                Mark as Complete
              </GradientButton>
            </Box>
          </CardContent>
        </ContentCard>
      ) : (
        <CompletedMessage stats={progress} />
      )}

      {/* History Dialog */}
      <Dialog
        fullScreen
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: '#0A0F24',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: '#0B1537',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: 'white'
        }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setHistoryDialogOpen(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Reading & Listening History</Typography>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          {historyLoading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {progressHistory.map((item) => (
                <Paper 
                  key={item.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: 'rgba(255,255,255,0.05)',
                    borderRadius: 2,
                    cursor: 'pointer',
                    '&:hover': { 
                      bgcolor: 'rgba(255,255,255,0.08)',
                      transform: 'translateX(8px)',
                      transition: 'all 0.2s ease'
                    }
                  }}
                  onClick={() => handleContentClick(item)}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    {item.content_type === 'TEXT' ? (
                      <BookIcon sx={{ color: '#4A90E2' }} />
                    ) : (
                      <HeadphonesIcon sx={{ color: '#4A90E2' }} />
                    )}
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" sx={{ color: 'white', mb: 0.5 }}>
                        {item.content_title}
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>
                        Completed {dayjs(item.completed_at).fromNow()}
                      </Typography>
                    </Box>
                    <KeyboardArrowRightIcon sx={{ color: '#4A90E2' }} />
                  </Box>
                  {item.notes && (
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        mt: 1,
                        color: 'rgba(255,255,255,0.7)',
                        fontStyle: 'italic'
                      }}
                    >
                      Notes: {item.notes}
                    </Typography>
                  )}
                </Paper>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      {/* Selected History Content Viewer */}
      {renderHistoryContent()}

      {/* Completion Dialog */}
      <Dialog 
        open={completionDialogOpen} 
        onClose={() => setCompletionDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#0B1537',
            color: 'white',
            width: '90%',
            maxWidth: '600px'
          }
        }}
      >
        <DialogTitle>
          <WrappedTypography>Mark Content as Complete</WrappedTypography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Add notes (optional)"
            fullWidth
            multiline
            rows={4}
            value={completionNotes}
            onChange={(e) => setCompletionNotes(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'rgba(255,255,255,0.3)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255,255,255,0.5)',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255,255,255,0.7)',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setCompletionDialogOpen(false)}
            sx={{ color: 'rgba(255,255,255,0.7)' }}
          >
            Cancel
          </Button>
          <GradientButton onClick={handleMarkComplete}>
            Complete
          </GradientButton>
        </DialogActions>
      </Dialog>

      {/* Continue Dialog */}
      <Dialog
        open={showContinueDialog}
        onClose={() => setShowContinueDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#0D47A1',
            color: 'white',
            maxWidth: '400px',
            width: '90%',
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,
            color: '#4CAF50' 
          }}>
            <DoneAllIcon />
            <Typography>Great Progress!</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: 'white', mb: 2 }}>
            Would you like to continue reading, or would you prefer to take a break and continue tomorrow?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ 
          padding: 2, 
          flexDirection: 'column', 
          gap: 2
        }}>
          <GradientButton 
            onClick={handleContinueReading} 
            fullWidth
          >
            Continue Reading
          </GradientButton>
          <Button 
            onClick={handleGoHome}
            fullWidth
            sx={{ 
              color: 'white',
              borderColor: 'rgba(255,255,255,0.3)',
              '&:hover': {
                borderColor: 'white',
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}
            variant="outlined"
          >
            Go Back to Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    </ContentWrapper>
  );
};

// Main ReadListenDialog component
export const ReadListenDialog = ({ open, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          bgcolor: '#0B1537'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          bgcolor: '#0B1537', 
          color: 'white',
          borderBottom: '1px solid rgba(255,255,255,0.1)'
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Read & Listen</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#0A0F24', p: 0 }}>
        <ReadListenContent onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

// Styled button to be used in the mobile dashboard
export const StyledReadListenButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #4A90E2, #2196F3)',
  color: '#fff',
  borderRadius: 24,
  padding: '10px 20px',
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    background: 'linear-gradient(45deg, #2196F3, #4A90E2)',
  },
  '&:disabled': {
    background: 'rgba(255,255,255,0.12)',
    color: 'rgba(255,255,255,0.3)'
  }
}));

// Export a pre-configured button component
export const ReadListenButton = ({ onClick }) => {
    return (
      <StyledReadListenButton
        onClick={onClick}
        startIcon={<MenuBookIcon />}
      >
        Read & Listen
      </StyledReadListenButton>
    );
  };
  
  export default ReadListenDialog;