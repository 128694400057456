import React, { useState, useEffect } from 'react';
import { Book, BookOpen, History, Clock, FileText, Volume2 } from 'lucide-react';
import { listUserProgress, getReadListenContent } from '../api';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  LinearProgress,
  Alert,
  Box,
  Button,
  Tabs,
  Tab,
  Paper
} from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(45deg, #673ab7 30%, #3f51b5 90%)',
  color: theme.palette.common.white,
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  overflow: 'hidden',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const ReadingHistoryModal = ({ student }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [activeTab, setActiveTab] = useState(0); // Use 0 for history, 1 for content
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);

  useEffect(() => {
    if (student) {
      fetchHistoryData();
    }
  }, [student]);

  const fetchHistoryData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listUserProgress({ user: student });
      const historyResponse = response.data || [];
      setHistoryData(historyResponse.sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at)));
    } catch (err) {
      console.error('Error fetching history:', err);
      setError('Failed to load history data');
    } finally {
      setLoading(false);
    }
  };

  const handleContentClick = async (contentId) => {
    try {
      const response = await getReadListenContent(contentId);
      setSelectedContent(response.data);
      setActiveTab(1);
    } catch (err) {
      console.error('Error fetching content:', err);
      setError('Failed to load content details');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <History sx={{ mr: 1, verticalAlign: 'middle' }} /> Reading & Listening History
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <StyledTab label="History" />
          <StyledTab label="Content Details" disabled={!selectedContent} />
        </Tabs>

        {loading && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress color="secondary" />
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {!loading && !error && activeTab === 0 && (
          <Box mt={2}>
            {historyData.length > 0 ? (
              historyData.map((item) => (
                <StyledPaper
                  key={item.id}
                  onClick={() => handleContentClick(item.content)}
                  sx={{ mb: 1, cursor: 'pointer', '&:hover': { boxShadow: '0 6px 18px rgba(0, 0, 0, 0.3)' } }}
                >
                  <Box display="flex" alignItems="center">
                    {item.content_type === 'AUDIO' ? (
                      <Volume2 sx={{ mr: 1, color: '#f44336' }} />
                    ) : (
                      <FileText sx={{ mr: 1, color: '#2196f3' }} />
                    )}
                    <Typography variant="subtitle1">{item.content_title}</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Completed on: {new Date(item.completed_at).toLocaleDateString()}
                  </Typography>
                  {item.notes && (
                    <Typography variant="caption" sx={{ fontStyle: 'italic', mt: 1 }}>
                      Notes: {item.notes}
                    </Typography>
                  )}
                </StyledPaper>
              ))
            ) : (
              <Typography variant="body1">No history found for this student.</Typography>
            )}
          </Box>
        )}

        {!loading && !error && activeTab === 1 && selectedContent && (
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              {selectedContent.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {selectedContent.description}
            </Typography>

            {selectedContent.content_type === 'TEXT' ? (
              <StyledPaper>
                <div dangerouslySetInnerHTML={{ __html: selectedContent.text_content }} />
              </StyledPaper>
            ) : (
              <StyledPaper>
                <audio
                  ref={audioRef}
                  src={selectedContent.audio_file}
                  style={{ width: '100%' }}
                  onEnded={() => setIsPlaying(false)}
                />
                <IconButton onClick={handlePlayPause} aria-label={isPlaying ? 'Pause' : 'Play'}>
                  {isPlaying ? <Pause color="secondary" /> : <PlayArrow color="secondary" />}
                </IconButton>
              </StyledPaper>
            )}
          </Box>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default ReadingHistoryModal;