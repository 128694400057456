import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Paper,
  Tooltip,
  DialogActions,
  Snackbar,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { 
  listBBBRecordings, 
  deleteBBBRecording, 
  syncBBBRecordings, 
  getCurrentUser,
  listUsers,
  listTrainers
} from '../api';
import { format, parseISO, isValid } from 'date-fns';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

// Styled Components
const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  marginTop: theme.spacing(3),
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing(1.5),
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&.Mui-disabled': {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      color: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputAdornment-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const ViewAllButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'none',
  padding: '2px 8px',
  fontSize: '0.75rem',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(30, 30, 30, 0.95)',
    backdropFilter: 'blur(10px)',
    color: theme.palette.common.white,
    borderRadius: '16px',
    minWidth: '300px',
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiListItem-root': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

// Custom Paper component for dropdown items
const CustomPaper = props => (
  <Paper
    {...props}
    sx={{
      backgroundColor: 'rgba(30, 30, 30, 0.95)',
      backdropFilter: 'blur(10px)',
      color: 'white',
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      maxHeight: 300,
      overflowY: 'auto',
      '& .MuiAutocomplete-option': {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '&[aria-selected="true"]': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
        },
      }
    }}
  />
);

// Helper Components
const LoadingRow = () => (
  <TableRow>
    {Array(7).fill(0).map((_, index) => (
      <TableCell key={index}>
        <Skeleton 
          variant="text" 
          sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            height: '20px'
          }} 
        />
      </TableCell>
    ))}
  </TableRow>
);

const EmptyState = () => (
  <Box 
    display="flex" 
    flexDirection="column" 
    alignItems="center" 
    p={4}
    sx={{
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
      margin: '20px 0'
    }}
  >
    <Typography variant="h6" color="white" gutterBottom>
      No recordings found
    </Typography>
    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
      Try adjusting your filters or sync to get the latest recordings
    </Typography>
  </Box>
);

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, loading }) => (
  <StyledDialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete this recording? This action cannot be undone.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <GradientButton 
        onClick={onConfirm} 
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        Delete
      </GradientButton>
    </DialogActions>
  </StyledDialog>
);

// Main Component
const ClassRecordingsPage = () => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({
    student: '',
    trainer: '',
    startDate: null,
    endDate: null,
    room: '',
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [syncMessage, setSyncMessage] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [recordingToDelete, setRecordingToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const loadingRef = useRef(false);
  const [showFilters, setShowFilters] = useState(false);
  
  // State for student dropdown
  const [studentOptions, setStudentOptions] = useState([]);
  const [studentLoading, setStudentLoading] = useState(false);
  const [studentInputValue, setStudentInputValue] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  
  // State for trainer dropdown
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [trainerLoading, setTrainerLoading] = useState(false);
  const [trainerInputValue, setTrainerInputValue] = useState('');
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  
  // Form values state
  const [roomValue, setRoomValue] = useState('');
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasAdminPermissions = currentUser?.role === 'ADMIN' || currentUser?.role === 'MANAGER';

  // We'll ensure filter state is synchronized and up-to-date before API calls
  const fetchRecordings = useCallback(async (pageNum = 1, append = false) => {
    if (loadingRef.current) return;
    
    loadingRef.current = true;
    setLoading(true);
    setError(null);

    try {
      // Use the current filter state directly to ensure it's always up-to-date
      const formattedFilters = {
        student: selectedStudent?.username || '',
        trainer: selectedTrainer?.username || '',
        room: roomValue,
        startDate: startDateValue && isValid(startDateValue) 
          ? format(startDateValue, 'yyyy-MM-dd') 
          : null,
        endDate: endDateValue && isValid(endDateValue)
          ? format(endDateValue, 'yyyy-MM-dd')
          : null,
        page: pageNum,
        page_size: 20,
      };

      // Update the main filters state to match the current values
      setFilters(formattedFilters);

      console.log('Fetching recordings with filters:', formattedFilters);
      const response = await listBBBRecordings(formattedFilters);
      const newRecordings = response.data.results || [];
      
      setRecordings(prev => append ? [...prev, ...newRecordings] : newRecordings);
      setHasMore(!!response.data.next);
      setPage(pageNum);
    } catch (err) {
      console.error('Error fetching recordings:', err);
      setError('Failed to fetch recordings. Please try again.');
      setSnackbar({
        open: true,
        message: 'Failed to fetch recordings',
        severity: 'error'
      });
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [selectedStudent, selectedTrainer, roomValue, startDateValue, endDateValue]);

  // Fetch students with debounce
  const fetchStudents = useCallback(
    debounce(async (searchTerm) => {
      if (!hasAdminPermissions) return;
      
      try {
        setStudentLoading(true);
        const response = await listUsers({
          page: 1,
          page_size: 50,
          roles: ['STUDENT'],
          search: searchTerm
        });
        
        // Map to format required for Autocomplete
        const students = response.results?.map(user => ({
          id: user.id,
          username: user.username,
          fullName: user.full_name,
          label: `${user.username}${user.full_name ? ` (${user.full_name})` : ''}`
        })) || [];
        
        setStudentOptions(students);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setStudentLoading(false);
      }
    }, 500),
    [hasAdminPermissions]
  );

  // Fetch trainers with debounce
  const fetchTrainers = useCallback(
    debounce(async (searchTerm) => {
      if (!hasAdminPermissions) return;
      
      try {
        setTrainerLoading(true);
        const response = await listTrainers({
          page: 1,
          page_size: 50,
          search: searchTerm
        });
        
        // Map to format required for Autocomplete
        const trainers = response.results?.map(trainer => ({
          id: trainer.id,
          username: trainer.user.username,
          fullName: trainer.user.full_name,
          label: `${trainer.user.username}${trainer.user.full_name ? ` (${trainer.user.full_name})` : ''}`
        })) || [];
        
        setTrainerOptions(trainers);
      } catch (error) {
        console.error('Error fetching trainers:', error);
      } finally {
        setTrainerLoading(false);
      }
    }, 500),
    [hasAdminPermissions]
  );

  // Initial fetch of dropdowns
  useEffect(() => {
    if (hasAdminPermissions) {
      fetchStudents('');
      fetchTrainers('');
    }
  }, [hasAdminPermissions, fetchStudents, fetchTrainers]);

  // Update search terms when they change
  useEffect(() => {
    if (hasAdminPermissions) {
      fetchStudents(studentInputValue);
    }
  }, [studentInputValue, fetchStudents, hasAdminPermissions]);

  useEffect(() => {
    if (hasAdminPermissions) {
      fetchTrainers(trainerInputValue);
    }
  }, [trainerInputValue, fetchTrainers, hasAdminPermissions]);

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchRecordings(page + 1, true);
    }
  };

  const handleRoomChange = (event) => {
    setRoomValue(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDateValue(date);
  };

  const handleEndDateChange = (date) => {
    setEndDateValue(date);
  };

  const handleStudentChange = (event, newValue) => {
    setSelectedStudent(newValue);
  };

  const handleTrainerChange = (event, newValue) => {
    setSelectedTrainer(newValue);
  };

  const handleApplyFilters = () => {
    setPage(1);
    fetchRecordings(1, false);
    if (isMobile) {
      setShowFilters(false);
    }
  };

  const handleClearFilters = () => {
    // Clear all filter values
    setSelectedStudent(null);
    setSelectedTrainer(null);
    setRoomValue('');
    setStartDateValue(null);
    setEndDateValue(null);
    
    // Reset the state and fetch updated data
    setPage(1);
    setTimeout(() => fetchRecordings(1, false), 0);
  };

  const handleDeleteClick = (recording) => {
    setRecordingToDelete(recording);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    try {
      await deleteBBBRecording(recordingToDelete.id);
      setRecordings(prev => prev.filter(r => r.id !== recordingToDelete.id));
      setSnackbar({
        open: true,
        message: 'Recording deleted successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error deleting recording:', err);
      setSnackbar({
        open: true,
        message: 'Failed to delete recording',
        severity: 'error'
      });
    } finally {
      setDeleteLoading(false);
      setDeleteDialogOpen(false);
      setRecordingToDelete(null);
    }
  };

  const handleSync = async () => {
    setSyncing(true);
    setSyncMessage(null);
    try {
      await syncBBBRecordings();
      setSyncMessage('Sync started successfully. New recordings will appear soon.');
      setSnackbar({
        open: true,
        message: 'Sync started successfully',
        severity: 'success'
      });
      setTimeout(() => fetchRecordings(1, false), 5000);
    } catch (err) {
      setSyncMessage('Failed to sync recordings. Please try again.');
      setSnackbar({
        open: true,
        message: 'Failed to sync recordings',
        severity: 'error'
      });
    } finally {
      setSyncing(false);
    }
  };

  const handlePlayback = (playbackUrl) => {
    if (playbackUrl) {
      window.open(playbackUrl, '_blank');
    } else {
      setSnackbar({
        open: true,
        message: 'Playback URL not available',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
      } catch (err) {
        console.error('Error fetching current user:', err);
        setError('Failed to fetch user data. Please try again.');
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchRecordings(1, false);
    }
  }, [currentUser, fetchRecordings]);

  const renderFilters = () => (
    <Box sx={{ display: isMobile && !showFilters ? 'none' : 'block' }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {hasAdminPermissions && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
                Student
              </Typography>
              <StyledAutocomplete
                fullWidth
                options={studentOptions}
                loading={studentLoading}
                value={selectedStudent}
                onChange={handleStudentChange}
                onInputChange={(event, newInputValue) => setStudentInputValue(newInputValue)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Search by username"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {studentLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                PaperComponent={CustomPaper}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
                Trainer
              </Typography>
              <StyledAutocomplete
                fullWidth
                options={trainerOptions}
                loading={trainerLoading}
                value={selectedTrainer}
                onChange={handleTrainerChange}
                onInputChange={(event, newInputValue) => setTrainerInputValue(newInputValue)}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Search by username"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {trainerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                PaperComponent={CustomPaper}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
                Room
              </Typography>
              <StyledTextField
                fullWidth
                value={roomValue}
                onChange={handleRoomChange}
                InputProps={{
                  endAdornment: roomValue && (
                    <IconButton
                      size="small"
                      onClick={() => setRoomValue('')}
                    >
                      <ClearIcon sx={{ color: 'white' }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
            Start Date
          </Typography>
          <DatePicker
            value={startDateValue}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {startDateValue && (
                        <IconButton
                          size="small"
                          onClick={() => setStartDateValue(null)}
                        >
                          <ClearIcon sx={{ color: 'white' }} />
                        </IconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.7)' }}>
            End Date
          </Typography>
          <DatePicker
            value={endDateValue}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {endDateValue && (
                        <IconButton
                          size="small"
                          onClick={() => setEndDateValue(null)}
                        >
                          <ClearIcon sx={{ color: 'white' }} />
                        </IconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent="flex-start">
          <Grid item>
            <GradientButton
              onClick={handleApplyFilters}
              startIcon={<FilterListIcon />}
            >
              Apply Filters
            </GradientButton>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleClearFilters}
              sx={{
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.23)',
                '&:hover': {
                  borderColor: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const renderTable = () => (
    <div id="scrollableDiv" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
      <InfiniteScroll
        dataLength={recordings.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<LoadingRow />}
        scrollableTarget="scrollableDiv"
      >
        <GlassTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {!isMobile && <TableCell>Room</TableCell>}
                <TableCell>Student</TableCell>
                {!isMobile && <TableCell>Trainer</TableCell>}
                <TableCell>Duration</TableCell>
                {!isMobile && <TableCell>Participants</TableCell>}
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordings.length === 0 && !loading ? (
                <TableRow>
                  <TableCell colSpan={isMobile ? 4 : 7}>
                    <EmptyState />
                  </TableCell>
                </TableRow>
              ) : (
                recordings.map((recording) => (
                  <TableRow key={recording.id}>
                    <TableCell>
                      {format(parseISO(recording.creation_date), 'dd MMM yyyy, HH:mm')}
                    </TableCell>
                    {!isMobile && <TableCell>{recording.meta_data.name}</TableCell>}
                    <TableCell>
                      {Array.isArray(recording.student) ? (
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography variant="body2">
                            {`${recording.student.length} Students`}
                          </Typography>
                          <ViewAllButton
                            onClick={() => {
                              setSelectedStudents(recording.student);
                              setIsModalOpen(true);
                            }}
                          >
                            View All
                          </ViewAllButton>
                        </Box>
                      ) : (
                        recording.student
                      )}
                    </TableCell>
                    {!isMobile && <TableCell>{recording.trainer}</TableCell>}
                    <TableCell>
                      {Math.round((parseInt(recording.meta_data.endTime) - 
                        parseInt(recording.meta_data.startTime)) / 60000)} min
                    </TableCell>
                    {!isMobile && <TableCell>{recording.meta_data.participants}</TableCell>}
                    <TableCell align="center">
                      <Tooltip title="Play Recording">
                        <IconButton 
                          onClick={() => handlePlayback(recording.playback_url)}
                          sx={{ color: 'white' }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </Tooltip>
                      {(currentUser?.role === 'ADMIN' || currentUser?.role === 'MANAGER') && (
                        <Tooltip title="Delete Recording">
                          <IconButton
                            onClick={() => handleDeleteClick(recording)}
                            sx={{ color: 'white' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </GlassTableContainer>
      </InfiniteScroll>
    </div>
  );

  return (
    <GlassContainer>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="white">Class Recordings</Typography>
        {isMobile && (
          <Button
            onClick={() => setShowFilters(!showFilters)}
            startIcon={<FilterListIcon />}
            sx={{ color: 'white' }}
          >
            Filters
          </Button>
        )}
      </Box>

      {renderFilters()}

      {(currentUser?.role === 'ADMIN' || currentUser?.role === 'MANAGER') && (
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <GradientButton
            startIcon={<SyncIcon />}
            onClick={handleSync}
            disabled={syncing}
          >
            {syncing ? 'Syncing...' : 'Sync Recordings'}
          </GradientButton>
        </Box>
      )}

      {error && (
        <Alert 
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => fetchRecordings(1, false)}
            >
              <RefreshIcon />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      {syncMessage && (
        <Alert 
          severity={syncMessage.includes('Failed') ? 'error' : 'info'}
          sx={{ mb: 2 }}
        >
          {syncMessage}
        </Alert>
      )}

      {renderTable()}

      <StyledDialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>Students</DialogTitle>
        <DialogContent>
          <List>
            {selectedStudents.map((student, index) => (
              <ListItem key={index}>
                <ListItemText primary={student} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} sx={{ color: 'white' }}>
            Close
          </Button>
        </DialogActions>
      </StyledDialog>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        loading={deleteLoading}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

export default ClassRecordingsPage;