import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { getLeaveRequestHistory } from '../api';

const GlassCard = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  marginTop: theme.spacing(4),
  maxHeight: '600px', // Fixed height for 4 rows plus header
  overflow: 'hidden', // Hide overflow
  display: 'flex',
  flexDirection: 'column'
}));

const ScrollableContainer = styled(Box)({
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
});

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'transparent',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing(2), // Increased padding for better readability
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableHead-root': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& .MuiTableCell-root': {
      background: 'rgba(18, 18, 18, 0.8)', // Dark background for header
      fontWeight: 'bold',
    },
  },
}));

const StatusChip = styled(Chip)(({ status, theme }) => ({
  backgroundColor: 
    status === 'APPROVED' ? 'rgba(46, 125, 50, 0.2)' :
    status === 'REJECTED' ? 'rgba(211, 47, 47, 0.2)' :
    'rgba(245, 124, 0, 0.2)',
  color: 
    status === 'APPROVED' ? '#4caf50' :
    status === 'REJECTED' ? '#f44336' :
    '#ff9800',
  borderRadius: '4px',
  fontSize: '0.75rem',
}));

const LeaveHistoryCard = ({ trainerId }) => {
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaveHistory = async () => {
      try {
        const response = await getLeaveRequestHistory();
        // Filter leave history for the specific trainer and sort by date
        const trainerLeaveHistory = response.data
          .filter(leave => leave.user === trainerId)
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); // Sort by date, newest first
        setLeaveHistory(trainerLeaveHistory);
      } catch (error) {
        console.error('Error fetching leave history:', error);
      } finally {
        setLoading(false);
      }
    };

    if (trainerId) {
      fetchLeaveHistory();
    }
  }, [trainerId]);

  if (loading) {
    return <GlassCard><Typography>Loading leave history...</Typography></GlassCard>;
  }

  return (
    <GlassCard>
      <Typography variant="h5" gutterBottom sx={{ px: 2, py: 1 }}>
        Leave History
      </Typography>
      <ScrollableContainer>
        <GlassTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Admin Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveHistory.length > 0 ? (
                leaveHistory.map((leave) => (
                  <TableRow key={leave.id}>
                    <TableCell>
                      {dayjs(leave.start_date).format('DD-MMM-YYYY')}
                    </TableCell>
                    <TableCell>
                      {dayjs(leave.end_date).format('DD-MMM-YYYY')}
                    </TableCell>
                    <TableCell>
                      {dayjs(leave.end_date).diff(dayjs(leave.start_date), 'days') + 1} days
                    </TableCell>
                    <TableCell>{leave.reason}</TableCell>
                    <TableCell>
                      <StatusChip
                        label={leave.status}
                        status={leave.status}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{leave.admin_remarks || '-'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No leave history found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </GlassTableContainer>
      </ScrollableContainer>
    </GlassCard>
  );
};

export default LeaveHistoryCard;