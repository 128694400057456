import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  CircularProgress,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CloudUpload as CloudUploadIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

// Styled Components to match your existing style
const DropzoneArea = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  border: '2px dashed rgba(255, 255, 255, 0.3)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    background: 'rgba(255, 255, 255, 0.05)',
  }
}));

const PreviewBox = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  maxHeight: '300px',
  overflowY: 'auto',
}));

const ExcelQuestionUploader = ({ onQuestionsProcessed, setAddedQuestions }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);

  // Function to generate template Excel file
  const generateTemplate = () => {
    const template = XLSX.utils.book_new();
    const templateData = [
      ['Question Text', 'Video URL (Optional)', 'Option 1', 'Option 2', 'Option 3 (Optional)', 'Option 4 (Optional)', 'Option 5 (Optional)', 'Option 6 (Optional)', 'Correct Option Number (1-6)'],
      ['What is 2+2?', '', '3', '4', '5', '6', '', '', '2'],
      ['Who painted the Mona Lisa?', 'https://example.com/video', 'Vincent van Gogh', 'Leonardo da Vinci', 'Pablo Picasso', '', '', '', '2']
    ];

    const ws = XLSX.utils.aoa_to_sheet(templateData);
    XLSX.utils.book_append_sheet(template, ws, 'Questions Template');

    // Save the file
    XLSX.writeFile(template, 'competition_questions_template.xlsx');
  };

  // Validate and process Excel data
  const processExcelData = (data) => {
    if (!Array.isArray(data) || data.length < 2) {
      throw new Error('Invalid file format. Please use the template provided.');
    }

    const questions = data.slice(1).filter(row => row.some(cell => cell));

    return questions.map((row, index) => {
      const correctOptionNum = parseInt(row[8]);
      if (isNaN(correctOptionNum) || correctOptionNum < 1 || correctOptionNum > 6) {
        throw new Error(`Invalid correct option number in row ${index + 2}`);
      }

      // Get all non-empty options
      const options = row.slice(2, 8)
        .filter(opt => opt?.toString().trim())
        .map((optionText, idx) => ({
          option_text: optionText.trim(),
          is_correct: (idx + 1) === correctOptionNum,
          order: idx + 1
        }));

      if (options.length < 2) {
        throw new Error(`Row ${index + 2} must have at least 2 options`);
      }

      return {
        question_text: row[0]?.trim() || '',
        video_url: row[1]?.trim() || '',
        options
      };
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    setLoading(true);
    setError(null);
    setPreview(null);

    try {
      const file = acceptedFiles[0];
      const arrayBuffer = await file.arrayBuffer();
      
      // Read with full options for better compatibility
      const workbook = XLSX.read(arrayBuffer, {
        cellStyles: true,
        cellDates: true,
        cellNF: true,
        cellFormula: true
      });
      
      // Get first sheet
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      const processedQuestions = processExcelData(data);
      setPreview(processedQuestions);
      
      // Update the parent component's state
      if (setAddedQuestions) {
        setAddedQuestions(prev => [...prev, ...processedQuestions]);
      }
      if (onQuestionsProcessed) {
        onQuestionsProcessed(processedQuestions);
      }
    } catch (err) {
      setError(err.message || 'Error processing file');
      console.error('Excel processing error:', err);
    } finally {
      setLoading(false);
    }
  }, [onQuestionsProcessed, setAddedQuestions]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    maxFiles: 1
  });

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="white">
          Upload Questions from Excel
        </Typography>
        <Button
          startIcon={<DownloadIcon />}
          onClick={generateTemplate}
          sx={{ color: 'white' }}
        >
          Download Template
        </Button>
      </Box>

      <DropzoneArea
        {...getRootProps()}
        elevation={0}
        sx={{
          borderColor: isDragActive ? 'primary.main' : undefined,
          bgcolor: isDragActive ? 'rgba(255, 255, 255, 0.05)' : undefined
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 40, mb: 1, color: 'white' }} />
        <Typography color="white">
          {isDragActive
            ? 'Drop the Excel file here'
            : 'Drag and drop an Excel file here, or click to select'}
        </Typography>
        <Typography variant="body2" color="white" sx={{ mt: 1, opacity: 0.7 }}>
          Only .xlsx and .xls files are accepted
        </Typography>
      </DropzoneArea>

      {loading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {preview && (
        <Box sx={{ mt: 3 }}>
          <Typography color="white" gutterBottom>
            Preview: {preview.length} questions processed
          </Typography>
          <PreviewBox>
            {preview.map((question, idx) => (
              <Box key={idx} sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
                <Typography color="white" fontWeight="bold">
                  Q{idx + 1}: {question.question_text}
                </Typography>
                {question.video_url && (
                  <Typography color="white" variant="body2" sx={{ opacity: 0.7, ml: 2 }}>
                    Video URL: {question.video_url}
                  </Typography>
                )}
                {question.options.map((opt, optIdx) => (
                  <Typography
                    key={optIdx}
                    color="white"
                    sx={{
                      ml: 2,
                      color: opt.is_correct ? 'success.main' : 'white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    {optIdx + 1}. {opt.option_text} {opt.is_correct && '✓'}
                  </Typography>
                ))}
              </Box>
            ))}
          </PreviewBox>
        </Box>
      )}
    </Box>
  );
};

export default ExcelQuestionUploader;