import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MessagePopupHandler from '../components/MessagePopupHandler';
import MobileSocialPage from './MobileSocialPage';
import webSocketService from '../services/apiws';
import { People as PeopleIcon } from '@mui/icons-material';
import MobileAssignmentManagement from '../components/MobileAssignmentManagement';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import { ReadListenDialog, ReadListenButton } from './ReadListenIntegration';
import EnhancedSocialNavigation from '../components/EnhancedSocialNavigation';
import { MenuBook as MenuBookIcon } from '@mui/icons-material';
import { NewsFeedButton, MobileNewsFeed } from './MobileNewsIntegration';





import dayjs from 'dayjs';
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Card,
  Button,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Snackbar,
  Paper,
  Grid,
  DialogContentText,
  Input,
  Badge,
  Stack,
  AlertTitle,
  Container,
  Tooltip
} from '@mui/material';
import TrainerNotification from '../components/TrainerNotification';


import { keyframes } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Skeleton,
    
    TablePagination,
    InputAdornment,
  } from '@mui/material';

  
  import {
    Close as CloseIcon,
 
    
    Article as ArticleIcon,
   
    Quiz as QuizIcon,
  } from '@mui/icons-material';
  import { 
    KeyboardArrowDown as KeyboardArrowDownIcon,
    TouchApp as TouchAppIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon
  } from '@mui/icons-material';

  import {
   
    Accordion,
    AccordionSummary,
    AccordionDetails,
 
    LinearProgress,
  } from '@mui/material';
  import {
    CheckCircle,
   
    ExpandMore as ExpandMoreIcon
  } from '@mui/icons-material';
  import { FileText, Newspaper } from 'lucide-react';
  import {
    PlayArrow as PlayArrowIcon,
    Delete as DeleteIcon,
    Sync as SyncIcon,
    Refresh as RefreshIcon,
    FilterList as FilterListIcon,
    Clear as ClearIcon,
  } from '@mui/icons-material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  School as SchoolIcon,
  VideoLibrary as VideoIcon,
  Person as PersonIcon,
  EmojiEvents as CompetitionsIcon,
  Edit as EditIcon,
  Feedback as FeedbackIcon,
  Logout as LogoutIcon,
  Description as DescriptionIcon,
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  CalendarMonth as CalendarIcon,
  ArrowBack as ArrowBackIcon,
  KeyboardArrowRight as ArrowRightIcon,
  AccessTime as TimeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Notifications as NotificationsIcon
} from '@mui/icons-material';

import ChatbotNotification from '../components/ChatbotNotification';

import { listBBBRecordings, deleteBBBRecording, syncBBBRecordings } from '../api';
import { format, parseISO, isValid } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import EnhancedProfileBanner from '../components/EnhancedProfileBanner';
import {
    TimerOutlined,
    EmojiEventsOutlined,
    HistoryOutlined
  } from '@mui/icons-material';
  
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  getCurrentUser,
  listStudentCourses,
  changeAttendanceStatus,
  createAttendance,
  getAttendance,
  updateUserMe,
  createStudentFeedback,
  listStudentFeedbacks,
  createCourseHold,
  getStudyMaterialsForStudent,
  getStudentFeedbackHistory,
  getImageUrl,
  getCoordinator,
  getStudentAbsences,
  listNotices,
  listAttendances,
  markNotificationAsRead,
  listNotifications, getCompetitionLeaderboard,
  getCompetitionAttempts,
  listCompetitions
} from '../api';

import {
    listStudentCourseFlows,
    getStudentProgressReport
  } from '../api';

  import { 
   
    markContentComplete
  } from '../api';


import NoticeCard from './NoticeCard';
import AttendanceCalendar from '../components/AttendanceCalendar';
import ClassContentSection from '../components/ClassContentSection';
import Messaging from '../components/Messaging';
import NotificationItem from '../components/NotificationItem';
import { formatDate } from '../utils/dateUtils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types'
import CompetitionLeaderboard from '../components/CompetitionLeaderboard';
import ContentViewer from '../components/ContentViewer';

dayjs.extend(utc);
dayjs.extend(timezone);


// Styled Components
const AppContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: '#0A0F24',
  position: 'relative',
  color: 'white',
  overflowX: 'hidden'
}));

const TabPanel = styled(Box)({
  padding: '16px',
  color: 'white'
});


const SocialTabPanel = styled(TabPanel)({

  backgroundColor: '#0A0F24',
  width: '110%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden', // Changed to prevent double scrolling
  marginLeft: '-5%',  // Adjust as needed
  marginRight: '10px',
});
const Banner = styled(Box)(({ theme }) => ({
  background: '#0D47A1',
  borderBottomLeftRadius: 24,
  borderBottomRightRadius: 24,
  padding: '20px 16px',
  position: 'relative',
  marginBottom: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
}));

const ProfileSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginTop: 12
});

const StyledAvatar = styled(Avatar)({
  width: 80,
  height: 80,
  border: '3px solid #4A90E2',
  boxShadow: '0 0 15px rgba(74,144,226,0.3)'
});

const ScrollableCards = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  padding: '8px 16px',
  gap: 16,
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  scrollSnapType: 'x mandatory',
  '-webkit-overflow-scrolling': 'touch'
});

const ClassCard = styled(Card)(({ theme }) => ({
  minWidth: 280,
  background: '#0D47A1',
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  padding: 16,
  scrollSnapAlign: 'start',
  border: '1px solid rgba(255,255,255,0.1)',
  color: 'white'
}));

const BannerContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      display: 'block',
      maxHeight: '150px',
    }
  }));

  const ContentCard = styled(Card)(({ theme }) => ({
    background: '#0D47A1',
    backdropFilter: 'blur(10px)',
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    border: '1px solid rgba(255,255,255,0.1)',
    color: 'white'
  }));
  
  const CompetitionCard = styled(ContentCard)(({ theme }) => ({
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
    }
  }));



const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #4A90E2, #2196F3)',
  color: '#fff',
  borderRadius: 24,
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(45deg, #2196F3, #4A90E2)',
  },
  '&:disabled': {
    background: 'rgba(255,255,255,0.12)',
    color: 'rgba(255,255,255,0.3)'
  }
}));

const ExpandIcon = styled(KeyboardArrowDownIcon)(({ isExpanded }) => ({
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
  }));
  
  const ExpandText = styled(Typography)(({ isExpanded }) => ({
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
  }));

  const RotatingIcon = styled(KeyboardArrowDownIcon)(({ expanded }) => ({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
  }));
  

const StatusChip = styled(Chip)(({ theme, status }) => ({
  borderRadius: 12,
  backgroundColor: 
    status === 'PRESENT' ? 'rgba(76, 175, 80, 0.2)' :
    status === 'ABSENT' ? 'rgba(244, 67, 54, 0.2)' :
    'rgba(255, 152, 0, 0.2)',
  color: 
    status === 'PRESENT' ? '#4CAF50' :
    status === 'ABSENT' ? '#f44336' :
    '#ff9800',
  border: `1px solid ${
    status === 'PRESENT' ? '#4CAF50' :
    status === 'ABSENT' ? '#f44336' :
    '#ff9800'
  }`
}));

const BottomNav = styled(BottomNavigation)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#0B1537',
  borderTop: '1px solid rgba(255,255,255,0.1)',
  zIndex: 9999,
  width: '100%',
  maxWidth: '100%'
});
const pulseAnimation1 = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px #4A90E2;
  }
  50% {
    box-shadow: 0 0 20px #4A90E2;
  }
  100% {
    box-shadow: 0 0 5px #4A90E2;
  }
`;

const SocialBottomNavAction = styled(BottomNavigationAction)(({ theme, isselected }) => ({
  color: isselected === 'true' ? 'white' : 'rgba(255,255,255,0.5)',
  background: 'linear-gradient(45deg, #FF0000, #D32F2F)',
  margin: '4px 8px',
  borderRadius: '12px',
  animation: isselected === 'true' ? 'none' : `${pulseAnimation1} 2s infinite, ${glowAnimation} 2s infinite`,
  '&.Mui-selected': {
    color: 'white'
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: '0.75rem',
    '&.Mui-selected': { 
      fontSize: '0.75rem' 
    }
  }
}));



const ScrollableList = styled(List)({
  maxHeight: '300px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
});

const NotificationPaper = styled(Paper)(({ theme }) => ({
  background: 'rgba(25, 25, 25, 0.95)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const NotificationList = styled(List)(({ theme }) => ({
  maxHeight: '50vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedBottomNavAction = styled(BottomNavigationAction)(({ theme, isanimating }) => ({
    ...(isanimating === 'true' && {
      animation: `${pulseAnimation} 1.5s ease-in-out infinite`,
    }),
  }));
  

  const COMPETITION_MESSAGES = [
    "A new challenge for you!",
    "Have you taken the new challenge?",
    "Do you want to compete?",
    "A new competition is waiting",
  ];

  const MobileStudentDashboard = () => { // Removed onLogout prop
    const navigate = useNavigate();
  
  // State declarations
  const [activeTab, setActiveTab] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
  const [coordinatorData, setCoordinatorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackDetailsModalOpen, setFeedbackDetailsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [readListenModalOpen, setReadListenModalOpen] = useState(false);
  const [newsFeedOpen, setNewsFeedOpen] = useState(false);


  const [courseHoldData, setCourseHoldData] = useState({
    start_date: null,
    end_date: null,
    reason: ''
  });

  const [hasNewCompetition, setHasNewCompetition] = useState(false);
const [showCompetitionTooltip, setShowCompetitionTooltip] = useState(false);
  const [feedbackType, setFeedbackType] = useState('GENERAL');
  const [feedbackTopic, setFeedbackTopic] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [showAbsenceWarning, setShowAbsenceWarning] = useState(false);
  const [absenceWarningMessage, setAbsenceWarningMessage] = useState('');
  const [notices, setNotices] = useState([]);
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [notifications, setNotifications] = useState({ unread: [], read: [] });
  const [notificationPopperOpen, setNotificationPopperOpen] = useState(false);
  const [activeNotificationTab, setActiveNotificationTab] = useState(0);
  const [notificationIconRef, setNotificationIconRef] = useState(null);
  const [competitions, setCompetitions] = useState([]);
const [competitionAttempts, setCompetitionAttempts] = useState({});
const [leaderboards, setLeaderboards] = useState([]);
const [collapsedLeaderboards, setCollapsedLeaderboards] = useState(new Set());
const [recordings, setRecordings] = useState([]);
const [recordingsPage, setRecordingsPage] = useState(1);
const [hasMoreRecordings, setHasMoreRecordings] = useState(true);
const [recordingsLoading, setRecordingsLoading] = useState(false);
const [recordingsError, setRecordingsError] = useState(null);
const [showProgressModal, setShowProgressModal] = useState(false);
const [selectedContent, setSelectedContent] = useState(null);
const [currentTooltipMessage, setCurrentTooltipMessage] = useState("");

const [recordingsFilters, setRecordingsFilters] = useState({
  startDate: null,
  endDate: null,
});
const [expandedFlow, setExpandedFlow] = useState(null);
const [expandedItems, setExpandedItems] = useState({});
const [selectedRecording, setSelectedRecording] = useState(null);
const [deleteRecordingDialogOpen, setDeleteRecordingDialogOpen] = useState(false);
const loadingRef = useRef(false);
const [studentFlows, setStudentFlows] = useState([]);
const handleToggleNewsFeed = () => {
  setNewsFeedOpen(!newsFeedOpen);
};
const handleLogout = () => {
    try {
      // Clear any auth data from localStorage
      localStorage.clear();
      sessionStorage.clear();
      
      // Navigate to login page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };


  const defaultCourseIndex = useMemo(() => {
    if (!coursesData.length) return 0;
    
    // Find the first personal training course (non-group course)
    const personalCourseIndex = coursesData.findIndex(
      course => !course.course.is_group_class
    );
    
    // Return the personal course index if found, otherwise return 0
    return personalCourseIndex >= 0 ? personalCourseIndex : 0;
  }, [coursesData]);
  
  // Update the selected course index when courses data changes
  useEffect(() => {
    setSelectedCourseIndex(defaultCourseIndex);
  }, [defaultCourseIndex]);
  // Utility functions
  const getCurrentIST = useCallback(() => {
    return dayjs().tz('Asia/Kolkata');
  }, []);

  const getISTDate = useCallback((localDate) => {
    return dayjs(localDate).tz('Asia/Kolkata').format('YYYY-MM-DD');
  }, []);

  // Memoized values
  const hasIndividualCourses = useMemo(() => {
    return coursesData.some(course => !course.course.is_group_class);
  }, [coursesData]);
  

  const isAnyIndividualCourseAttendanceMarked = useMemo(() => {
    return coursesData.some(course => 
      !course.course.is_group_class && attendanceData[course.id]?.marked
    );
  }, [coursesData, attendanceData]);

  const shouldShowAttendanceButtons = useCallback((course) => {
    // For individual courses, always show attendance
    if (!course.course.is_group_class) {
      return true;
    }
    
    // For group courses, only show if there are no individual courses
    const hasIndividualCourse = coursesData.some(c => !c.course.is_group_class);
    return !hasIndividualCourse;
  }, [coursesData]);

  const canJoinCourse = useCallback((course) => {
    const istNow = getCurrentIST();
    const attendanceForCourse = attendanceData[course.id];
    const hasIndividualCourse = coursesData.some(c => !c.course.is_group_class);
  
    // Get today's date in IST
    const currentISTDate = istNow.format('YYYY-MM-DD');
  
    // For individual courses
    if (!course.course.is_group_class) {
      if (!attendanceForCourse?.marked) return false;
      const attendanceDate = dayjs(attendanceForCourse.time).tz('Asia/Kolkata').format('YYYY-MM-DD');
      return attendanceDate === currentISTDate;
    }
  
    // For group courses
    if (course.course.is_group_class) {
      if (hasIndividualCourse) {
        // If student has individual courses, check if any individual course has attendance
        const individualCourseAttendance = coursesData.some(c => {
          if (!c.course.is_group_class) {
            const attendance = attendanceData[c.id];
            if (!attendance?.marked) return false;
            const attDate = dayjs(attendance.time).tz('Asia/Kolkata').format('YYYY-MM-DD');
            return attDate === currentISTDate;
          }
          return false;
        });
        return individualCourseAttendance;
      } else {
        // If no individual courses, check this group course's attendance
        if (!attendanceForCourse?.marked) return false;
        const attendanceDate = dayjs(attendanceForCourse.time).tz('Asia/Kolkata').format('YYYY-MM-DD');
        return attendanceDate === currentISTDate;
      }
    }
  
    return false;
  }, [attendanceData, coursesData, getCurrentIST]);

  // Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const checkCompetitions = async () => {
      try {
        if (activeTab !== 2) { // Only check if not already on competitions tab
          const competitionsResponse = await listCompetitions({ is_active: true });
          const activeCompetitions = competitionsResponse.data;
          
          if (activeCompetitions.length > 0) {
            // Check if user has attempted any competitions
            const attemptsPromises = activeCompetitions.map(comp => 
              getCompetitionAttempts(comp.id)
            );
            
            const attemptsResponses = await Promise.all(attemptsPromises);
            const hasUnattemptedCompetition = activeCompetitions.some((comp, index) => 
              attemptsResponses[index].data.length === 0
            );
            
            setHasNewCompetition(hasUnattemptedCompetition);
            setShowCompetitionTooltip(hasUnattemptedCompetition);
          } else {
            setHasNewCompetition(false);
            setShowCompetitionTooltip(false);
          }
        }
      } catch (error) {
        console.error('Error checking competitions:', error);
      }
    };
  
    checkCompetitions();
  }, [activeTab]);

  useEffect(() => {
    let messageInterval;
    if (hasNewCompetition && showCompetitionTooltip) {
      // Set initial message
      setCurrentTooltipMessage(COMPETITION_MESSAGES[Math.floor(Math.random() * COMPETITION_MESSAGES.length)]);
      
      // Rotate messages every 3 seconds
      messageInterval = setInterval(() => {
        setCurrentTooltipMessage(prevMessage => {
          const availableMessages = COMPETITION_MESSAGES.filter(msg => msg !== prevMessage);
          return availableMessages[Math.floor(Math.random() * availableMessages.length)];
        });
      }, 3000);
    }
  
    return () => {
      if (messageInterval) clearInterval(messageInterval);
    };
  }, [hasNewCompetition, showCompetitionTooltip]);

  useEffect(() => {
    const fetchStudentFlows = async () => {
      if (!studentData?.id) return;
      
      try {
        const flowsResponse = await listStudentCourseFlows({ 
          student: studentData.id,
          is_active: true 
        });
  
        if (!flowsResponse.data || !Array.isArray(flowsResponse.data)) {
          throw new Error('Invalid response format from student flows API');
        }
  
        const flowsWithProgress = await Promise.all(
          flowsResponse.data.map(async (flow) => {
            try {
              const progressResponse = await getStudentProgressReport(flow.id);
              return {
                ...flow,
                progress: progressResponse.data
              };
            } catch (progressError) {
              console.error(`Error fetching progress for flow ${flow.id}:`, progressError);
              return {
                ...flow,
                progress: {
                  overall_progress: { completion_percentage: 0 },
                  items_progress: []
                }
              };
            }
          })
        );
  
        setStudentFlows(flowsWithProgress);
      } catch (err) {
        console.error('Error fetching student flows:', err);
        setError('Failed to load student progress');
      }
    };
  
    fetchStudentFlows();
  }, [studentData?.id]);

  useEffect(() => {
    if (studentData) {
      checkRecentAbsences();
    }
  }, [studentData]);

  useEffect(() => {
    const checkISTMidnight = () => {
      const istNow = getCurrentIST();
      if (istNow.hour() === 0 && istNow.minute() === 0) {
        setAttendanceData({});
      }
    };

    

    

    const timer = setInterval(checkISTMidnight, 60000);
    return () => clearInterval(timer);
  }, [getCurrentIST]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await listNotifications();
        const allNotifications = Array.isArray(response.data) ? response.data : [];
        setNotifications({
          unread: allNotifications.filter(notification => !notification.is_read),
          read: allNotifications.filter(notification => notification.is_read)
        });
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Initialize WebSocket connection when component mounts
    webSocketService.connect();
    
    return () => {
      // Clean up WebSocket connection when component unmounts
      webSocketService.disconnect();
    };
  }, []);

  

  // Data fetching
  const fetchData = async () => {
    setLoading(true);
    
    try {
      // First get the user data
      const userResponse = await getCurrentUser();
      const userId = userResponse.data.id;
  
      // Then fetch everything else with the user ID
      const [
        coursesResponse,
        feedbacksResponse,
        feedbackHistoryResponse,
        materialsResponse,
        noticesResponse
      ] = await Promise.all([
        listStudentCourses({ student: userId }),
        listStudentFeedbacks(),
        getStudentFeedbackHistory(),
        getStudyMaterialsForStudent(userId),
        listNotices()
      ]);
  
      setStudentData(userResponse.data);
      setCoursesData(coursesResponse.data);
      
      // Find the first personal course index
      const personalCourseIndex = coursesResponse.data.findIndex(
        course => !course.course.is_group_class
      );
      // Set the selected course index to personal course if found, otherwise 0
      setSelectedCourseIndex(personalCourseIndex >= 0 ? personalCourseIndex : 0);
  
      // Continue with the rest of the data setting
      setFeedbacks(feedbacksResponse.data);
      setFeedbackHistory(feedbackHistoryResponse.data);
      setStudyMaterials(materialsResponse.data);
      setNotices(noticesResponse.data);
  
      if (userResponse.data.coordinator) {
        const coordinatorResponse = await getCoordinator(userResponse.data.coordinator);
        setCoordinatorData(coordinatorResponse.data);
      }
  
      // Fetch attendance data
      const attendancePromises = coursesResponse.data.map(async (course) => {
        const istDate = getISTDate(new Date());
        const attendanceResponse = await getAttendance({
          student: userId,
          date: istDate,
          student_course: course.id
        });
  
        return {
          courseId: course.id,
          data: attendanceResponse.data[0] || null
        };
      });
  
      const attendanceResults = await Promise.all(attendancePromises);
      const newAttendanceData = {};
      attendanceResults.forEach(result => {
        newAttendanceData[result.courseId] = result.data ? {
          marked: true,
          time: dayjs(result.data.timestamp).tz('Asia/Kolkata'),
          status: result.data.status,
          id: result.data.id
        } : {
          marked: false,
          time: null,
          status: null,
          id: null
        };
      });
  
      setAttendanceData(newAttendanceData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const fetchRecordings = async (pageNum = 1, append = false) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setRecordingsLoading(true);
    setRecordingsError(null);
  
    try {
      const formattedFilters = {
        ...recordingsFilters,
        startDate: recordingsFilters.startDate && isValid(recordingsFilters.startDate) 
          ? format(recordingsFilters.startDate, 'yyyy-MM-dd') 
          : null,
        endDate: recordingsFilters.endDate && isValid(recordingsFilters.endDate)
          ? format(recordingsFilters.endDate, 'yyyy-MM-dd')
          : null,
        page: pageNum,
        page_size: 10,
      };
  
      const response = await listBBBRecordings(formattedFilters);
      const newRecordings = response.data.results || [];
      
      setRecordings(prev => append ? [...prev, ...newRecordings] : newRecordings);
      setHasMoreRecordings(!!response.data.next);
      setRecordingsPage(pageNum);
    } catch (err) {
      console.error('Error fetching recordings:', err);
      setRecordingsError('Failed to fetch recordings');
      setSnackbar({
        open: true,
        message: 'Failed to fetch recordings',
        severity: 'error'
      });
    } finally {
      setRecordingsLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if (activeTab === 2) {
      fetchCompetitionsData();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 3) { // Note: Tab 3 is recordings (zero-indexed)
      fetchRecordings(1, false);
    }
  }, [activeTab]);
  
  

  const checkRecentAbsences = async () => {
    if (!studentData) return;

    const endDate = dayjs().format('YYYY-MM-DD');
    const startDate = dayjs().subtract(30, 'days').format('YYYY-MM-DD');

    try {
      const response = await listAttendances({
        student: studentData.id,
        start_date: startDate,
        end_date: endDate,
      });

      const absences = response.data.filter(
        (attendance) => attendance.status === 'ABSENT'
      );
      const absenceCount = absences.length;

      if (absenceCount === 1) {
        setAbsenceWarningMessage(
          "You have already been marked absent once in the last 30 days. " +
            "Please make sure not to take any more leaves as it will impact your studies, " +
            "and more than 2 leaves will not be compensated."
        );
        setShowAbsenceWarning(true);
      } else if (absenceCount > 1) {
        setAbsenceWarningMessage(
          "You have been absent for more than 2 days now, and you will not be entitled to any more compensations. " +
            "Make sure to not take any more leaves to be able to complete your course on time.\n\n" +
            "आप अब 2 दिनों से ज़्यादा समय से अनुपस्थित हैं और आपको अब कोई अतिरिक्त क्लास नहीं मिलेगी। " +
            "सुनिश्चित करें कि आप समय पर अपना कोर्स पूरा करने के लिए अब और छुट्टी न लें।"
        );
        setShowAbsenceWarning(true);
      }
    } catch (error) {
      console.error('Error fetching recent attendance:', error);
    }
  };

  // Event Handlers
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePlayRecording = (playbackUrl) => {
    if (playbackUrl) {
      window.open(playbackUrl, '_blank');
    } else {
      setSnackbar({
        open: true,
        message: 'Playback URL not available',
        severity: 'error'
      });
    }
  };
  
  const loadMoreRecordings = () => {
    if (!recordingsLoading && hasMoreRecordings) {
      fetchRecordings(recordingsPage + 1, true);
    }
  };
  
  const handleRecordingFilterChange = (name, value) => {
    setRecordingsFilters(prev => ({ ...prev, [name]: value }));
  };
  
  const clearRecordingFilters = () => {
    setRecordingsFilters({
      startDate: null,
      endDate: null,
    });
    fetchRecordings(1, false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleProfileUpdate = async () => {
    try {
      const formData = new FormData();
      Object.keys(updatedProfile).forEach(key => {
        formData.append(key, updatedProfile[key]);
      });
      if (newProfileImage) {
        formData.append('profile_image', newProfileImage);
      }
      const response = await updateUserMe(formData);
      setStudentData({ ...studentData, ...response.data });
      setEditMode(false);
      setImagePreview(null);
      setNewProfileImage(null);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to update profile', severity: 'error' });
    }
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setNewProfileImage(file);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup preview URL when component unmounts
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const handleAttendanceMarking = async (status, courseId) => {
    try {
      const course = coursesData.find(c => c.id === courseId);
      const istDate = getISTDate(new Date());
      
      const attendancePayload = {
        student: studentData.id,
        course: course.course.id,
        student_course: courseId,
        status: status,
        trainer: course.trainer?.id || null,
        date: istDate
      };
      
      const response = await createAttendance(attendancePayload);
      
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: getCurrentIST(),
          status: status,
          id: response.data.id
        }
      }));
      
      setSnackbar({ open: true, message: 'Attendance marked successfully', severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to mark attendance', severity: 'error' });
    }
  };

  const handleAttendanceChange = async (newStatus, courseId) => {
    try {
      const currentAttendance = attendanceData[courseId];
      const istDate = getISTDate(new Date());
      
      let response;
      if (currentAttendance && currentAttendance.id) {
        response = await changeAttendanceStatus(currentAttendance.id, newStatus);
      } else {
        response = await createAttendance({
          student_course: courseId,
          status: newStatus,
          date: istDate
        });
      }
  
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: getCurrentIST(),
          status: newStatus,
          id: response.data.id
        }
      }));
  
      setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success' });
    } catch (err) {
      if (err.response?.status === 400) {
        setSnackbar({ open: true, message: err.response.data.error || 'Failed to update attendance', severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'An error occurred while updating attendance', severity: 'error' });
      }
    }
  };

  const handleJoinRoom = (courseId) => {
    const course = coursesData.find(c => c.id === courseId);
    if (course?.bbb_join_url) {
      window.open(course.bbb_join_url, '_blank');
    } else {
      setSnackbar({ open: true, message: 'Join URL not available', severity: 'error' });
    }
  };

  const handleSubmitCourseHold = async () => {
    try {
      const formattedHoldData = {
        student_course: coursesData[selectedCourseIndex].id,
        start_date: dayjs(courseHoldData.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(courseHoldData.end_date).format('YYYY-MM-DD'),
        reason: courseHoldData.reason
      };

      await createCourseHold(formattedHoldData);
      setSnackbar({ open: true, message: 'Course hold request submitted successfully', severity: 'success' });
      setCourseHoldModalOpen(false);
      setCourseHoldData({ start_date: null, end_date: null, reason: '' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to submit course hold request', severity: 'error' });
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await createStudentFeedback({
        student: studentData.id,
        course: coursesData[selectedCourseIndex].course.id,
        feedback_type: feedbackType,
        topic: feedbackTopic,
        content: feedbackContent
      });
      setFeedbackModalOpen(false);
      setFeedbackType('GENERAL');
      setFeedbackTopic('');
      setFeedbackContent('');
      setSnackbar({ open: true, message: 'Feedback submitted successfully', severity: 'success' });
      fetchData();
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to submit feedback', severity: 'error' });
    }
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    setFeedbackDetailsModalOpen(true);
  };

  const handleNotificationRead = async (id) => {
    const notification = notifications.unread.find(n => n.id === id);
    if (notification) {
      try {
        await markNotificationAsRead(id);
        setNotifications(prevNotifications => ({
          unread: prevNotifications.unread.filter(n => n.id !== id),
          read: [...prevNotifications.read, notification]
        }));
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    }
  };

  const handleCourseHoldInputChange = (field, value) => {
    setCourseHoldData(prev => ({ ...prev, [field]: value }));
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleCloseMaterialDialog = () => {
    setSelectedMaterial(null);
  };
  const fetchCompetitionsData = async () => {
    try {
      setLoading(true);
      const competitionsResponse = await listCompetitions({ is_active: true });
      const competitionsData = competitionsResponse.data;
      setCompetitions(competitionsData);
  
      // Fetch leaderboards
      const leaderboardData = await Promise.all(
        competitionsData.map(async (comp) => {
          try {
            const response = await getCompetitionLeaderboard(comp.id);
            return {
              competition_id: comp.id,
              entries: response.data
            };
          } catch (error) {
            console.error(`Error fetching leaderboard for competition ${comp.id}:`, error);
            return {
              competition_id: comp.id,
              entries: []
            };
          }
        })
      );
      setLeaderboards(leaderboardData);
  
      // Fetch attempts
      const attemptsData = {};
      await Promise.all(
        competitionsData.map(async (comp) => {
          try {
            const attemptsResponse = await getCompetitionAttempts(comp.id);
            attemptsData[comp.id] = attemptsResponse.data;
          } catch (error) {
            console.error(`Error fetching attempts for competition ${comp.id}:`, error);
            attemptsData[comp.id] = [];
          }
        })
      );
      setCompetitionAttempts(attemptsData);
    } catch (error) {
      setError('Failed to load competitions');
    } finally {
      setLoading(false);
    }
  };

  const handleCompetitionLeaderboardPage = async (competitionId, newPage) => {
    try {
      const response = await getCompetitionLeaderboard(competitionId, { page: newPage });
      setLeaderboards(prev => {
        const filtered = prev.filter(lb => lb.competition_id !== competitionId);
        return [...filtered, {
          competition_id: competitionId,
          entries: response.data,
          currentPage: newPage
        }];
      });
    } catch (error) {
      console.error('Error fetching leaderboard page:', error);
    }
  };

  const renderCircularProgress = (percentage) => (
    <Box sx={{ 
      position: 'relative', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1
    }}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={80}
          thickness={4}
          sx={{
            color: percentage === 100 ? 'success.main' : 'primary.main',
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'white',
              fontWeight: 'bold' 
            }}
          >
            {`${Math.round(percentage)}%`}
          </Typography>
        </Box>
      </Box>
      <Typography 
        variant="caption" 
        sx={{ 
          color: percentage === 100 ? 'success.main' : 'primary.main',
          textAlign: 'center'
        }}
      >
        {percentage === 100 ? 'Completed' : 'In Progress'}
      </Typography>
    </Box>
  );

  const renderProgressSection = () => (
    <ContentCard>
      <Typography variant="h6" sx={{ 
        color: '#4A90E2', 
        mb: 3,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        pb: 2
      }}>
        Course Progress
      </Typography>
  
      {/* Progress Cards Grid */}
      <Box sx={{ mb: 3 }}>
        {studentFlows.map((flow) => (
          <Box 
            key={flow.id}
            sx={{ 
              mb: 2,
              p: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography sx={{ 
              color: 'white', 
              fontSize: '1rem',
              fontWeight: 500,
              textAlign: 'center'
            }}>
              {flow.base_course_flow?.title || 'Untitled Flow'}
            </Typography>
  
            {renderCircularProgress(flow.progress?.overall_progress?.completion_percentage || 0)}
          </Box>
        ))}
      </Box>
      <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center',
      mt: 3,
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      pt: 3
    }}>
      <GradientButton 
        onClick={() => setShowProgressModal(true)}
        startIcon={<TouchAppIcon />}
        fullWidth
      >
        View Learning Content
      </GradientButton>
    </Box>

      
  
      {/* Full Page Progress Modal */}
   
      <Dialog
      fullScreen
      open={showProgressModal}
      onClose={() => setShowProgressModal(false)}
      PaperProps={{
        sx: {
          backgroundColor: '#0A0F24',
          backgroundImage: 'none'
        }
      }}
    >
      <DialogTitle sx={{ 
        bgcolor: '#0B1537', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 1
      }}>
        <Typography variant="h6" sx={{ color: 'white' }}>Course Content</Typography>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={() => setShowProgressModal(false)}
          sx={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 2, 
          bgcolor: 'rgba(74, 144, 226, 0.1)', 
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <TouchAppIcon sx={{ color: '#4A90E2' }} />
          <Typography variant="body2" sx={{ color: 'white' }}>
            Tap the sections below to expand and access your learning materials
          </Typography>
        </Box>

        {studentFlows.map((flow) => (
        <Accordion
          key={flow.id}
          expanded={expandedFlow === flow.id}
          onChange={() => setExpandedFlow(expandedFlow === flow.id ? null : flow.id)}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            color: 'white',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              }
            }
          }}
        >
          <AccordionSummary>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {renderCircularProgress(flow.progress?.overall_progress?.completion_percentage || 0)}
                <Typography>{flow.base_course_flow?.title || 'Untitled Flow'}</Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: '#4A90E2',
              }}>
                <Typography variant="caption" sx={{ mr: 1 }}>
                  {expandedFlow === flow.id ? 'Collapse' : 'Expand'}
                </Typography>
                <RotatingIcon expanded={expandedFlow === flow.id} />
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {flow.progress?.items_progress?.map((item) => (
              <Accordion
                key={item.item_id}
                expanded={expandedItems[item.item_id] || false}
                onChange={() => {
                  setExpandedItems(prev => ({
                    ...prev,
                    [item.item_id]: !prev[item.item_id]
                  }));
                }}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.02)',
                  '&:before': { display: 'none' },
                  '& .MuiAccordionSummary-root': {
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    }
                  }
                }}
              >
                <AccordionSummary>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <Typography>{item.item_title}</Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 2
                    }}>
                      {item.is_completed && (
                        <Chip 
                          icon={<CheckCircle sx={{ fontSize: '1rem !important' }} />}
                          label="Completed"
                          size="small"
                          color="success"
                        />
                      )}
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        color: '#4A90E2',
                      }}>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {expandedItems[item.item_id] ? 'Hide Content' : 'View Content'}
                        </Typography>
                        <RotatingIcon expanded={expandedItems[item.item_id]} />
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                      {item.content_progress?.map((content) => (
                        <ListItem
                          key={content.content_id}
                          onClick={() => {
                            setSelectedContent({
                              ...content,
                              flowId: flow.id,
                              itemId: item.flow_item_id,
                              currentCompletionStatus: content.is_completed
                            });
                          }}
                          sx={{
                            borderRadius: 1,
                            mb: 1,
                            backgroundColor: 'rgba(255, 255, 255, 0.03)',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.08)',
                              transform: 'translateX(8px)'
                            },
                            position: 'relative',
                            pl: 2
                          }}
                        >
                          <ListItemText
                            primary={
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                color: content.is_completed ? '#4caf50' : 'white'
                              }}>
                                {content.content_type === 'VIDEO' && <VideoIcon />}
                                {content.content_type === 'TEXT' && <ArticleIcon />}
                                {content.content_type === 'QUIZ' && <QuizIcon />}
                                <Typography>{content.content_title}</Typography>
                              </Box>
                            }
                            secondary={
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  color: 'rgba(255, 255, 255, 0.6)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.5,
                                  mt: 0.5
                                }}
                              >
                                <TouchAppIcon sx={{ fontSize: '1rem' }} />
                                Tap to view content
                              </Typography>
                            }
                          />
                          <Chip
                            label={content.is_completed ? 'Completed' : 'Pending'}
                            color={content.is_completed ? 'success' : 'default'}
                            size="small"
                            sx={{ mr: 1 }}
                          />
                          <KeyboardArrowRightIcon sx={{ color: '#4A90E2' }} />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  
      {/* Content Viewer */}
      {selectedContent && (
        <ContentViewer
          open={Boolean(selectedContent)}
          onClose={() => setSelectedContent(null)}
          content={selectedContent}
          onComplete={async () => {
            try {
              await markContentComplete(selectedContent.flowId, {
                content_id: selectedContent.content_id,
                flow_item_id: selectedContent.itemId
              });
              
              // Refresh the flows data
              const response = await listStudentCourseFlows();
              const flowsWithProgress = await Promise.all(
                response.data.map(async (flow) => {
                  const progressResponse = await getStudentProgressReport(flow.id);
                  return {
                    ...flow,
                    progress: progressResponse.data
                  };
                })
              );
              setStudentFlows(flowsWithProgress);
              setSelectedContent(null);
            } catch (error) {
              console.error('Error marking content complete:', error);
              setSnackbar({
                open: true,
                message: 'Failed to mark content as complete',
                severity: 'error'
              });
            }
          }}
          currentCompletionStatus={selectedContent.currentCompletionStatus}
        />
      )}
    </ContentCard>
  );

  const renderRecordings = () => (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 3, color: '#4A90E2' }}>
        Class Recordings
      </Typography>
  
      <ContentCard>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Start Date"
              value={recordingsFilters.startDate}
              onChange={(date) => handleRecordingFilterChange('startDate', date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': { borderColor: 'rgba(255,255,255,0.3)' }
                    },
                    '& .MuiInputLabel-root': { color: 'rgba(255,255,255,0.7)' }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="End Date"
              value={recordingsFilters.endDate}
              onChange={(date) => handleRecordingFilterChange('endDate', date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': { borderColor: 'rgba(255,255,255,0.3)' }
                    },
                    '& .MuiInputLabel-root': { color: 'rgba(255,255,255,0.7)' }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item>
              <GradientButton
                onClick={() => fetchRecordings(1, false)}
                startIcon={<FilterListIcon />}
              >
                Apply Filters
              </GradientButton>
            </Grid>
            <Grid item>
              <GradientButton
                onClick={clearRecordingFilters}
                startIcon={<ClearIcon />}
              >
                Clear
              </GradientButton>
            </Grid>
          </Grid>
        </Grid>
  
        <div id="recordingsScrollable" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 400px)' }}>
          <InfiniteScroll
            dataLength={recordings.length}
            next={loadMoreRecordings}
            hasMore={hasMoreRecordings}
            loader={<CircularProgress sx={{ m: 2 }} />}
            scrollableTarget="recordingsScrollable"
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'white' }}>Date</TableCell>
                    <TableCell sx={{ color: 'white' }}>Duration</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recordings.map((recording) => (
                    <TableRow key={recording.id}>
                      <TableCell sx={{ color: 'white' }}>
                        {format(parseISO(recording.creation_date), 'dd MMM yyyy, HH:mm')}
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        {Math.round((parseInt(recording.meta_data.endTime) - 
                          parseInt(recording.meta_data.startTime)) / 60000)} min
                      </TableCell>
                      <TableCell align="center">
                        <IconButton 
                          onClick={() => handlePlayRecording(recording.playback_url)}
                          sx={{ color: 'white' }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </div>
        
        {recordingsError && (
          <Alert 
            severity="error" 
            sx={{ mt: 2 }}
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => fetchRecordings(1, false)}
              >
                <RefreshIcon />
              </IconButton>
            }
          >
            {recordingsError}
          </Alert>
        )}
      </ContentCard>
    </Box>
  );
  // Render methods
  // Enhanced renderBanner function with animated News Feed button
// This is the updated code for the renderBanner method in MobileStudentDashboard.js

const renderBanner = () => {
  // Define animations using keyframes
  const pulseAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  `;

  const glowAnimation = keyframes`
    0% { box-shadow: 0 0 0px rgba(255, 0, 0, 0.7); }
    50% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.7); }
    100% { box-shadow: 0 0 0px rgba(255, 0, 0, 0.7); }
  `;
  
  const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px); }
    50% { transform: translateX(0); }
    75% { transform: translateX(2px); }
    100% { transform: translateX(0); }
  `;

  // Random bright color animation
  const colorChangeAnimation = keyframes`
    0% { background: linear-gradient(45deg, #FF0000, #D32F2F); }
    25% { background: linear-gradient(45deg, #FF9800, #F57C00); }
    50% { background: linear-gradient(45deg, #FFEB3B, #FBC02D); }
    75% { background: linear-gradient(45deg, #FF4081, #C2185B); }
    100% { background: linear-gradient(45deg, #FF0000, #D32F2F); }
  `;

  return (
    <EnhancedProfileBanner 
      studentData={studentData}
      notifications={notifications}
      handleMenuOpen={handleMenuOpen}
      setNotificationPopperOpen={setNotificationPopperOpen}
      setNotificationIconRef={setNotificationIconRef}
      newsFeedButton={
        <NewsFeedButton
          onClick={handleToggleNewsFeed}
          startIcon={<Newspaper size={16} />}
          size="small"
          sx={{ 
            py: 0.75, 
            px: 2,
            fontSize: '0.8rem',
            height: '32px',
            minWidth: '110px',
            animation: `${pulseAnimation} 2s infinite, ${glowAnimation} 2s infinite, ${colorChangeAnimation} 8s infinite`,
            transition: 'all 0.3s ease',
            position: 'relative',
            boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
            '&:hover': {
              transform: 'scale(1.1)',
              animation: `${shakeAnimation} 0.5s infinite`
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '3px',
              right: '3px',
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: '#ff4444',
              boxShadow: '0 0 4px #ff4444',
            }
          }}
        >
          News Feed
        </NewsFeedButton>
      }
    />
  );
};

  const formatTimeRemaining = (timeRemaining) => {
    if (timeRemaining <= 0) return 'Expired';
    const days = Math.floor(timeRemaining / (24 * 3600));
    const hours = Math.floor((timeRemaining % (24 * 3600)) / 3600);
    return `${days}d ${hours}h remaining`;
  };
  
  const hasAttempted = (competitionId) => {
    return competitionAttempts[competitionId]?.some(attempt => attempt.completion_time);
  };
  
  const canAttempt = (competition) => {
    if (!competition.is_active) return false;
    if (competition.time_remaining <= 0) return false;
    return !hasAttempted(competition.id);
  };
  
  const handleLeaderboardToggle = async (competitionId) => {
    const isCurrentlyCollapsed = collapsedLeaderboards.has(competitionId);
    
    if (isCurrentlyCollapsed) {
      try {
        const response = await getCompetitionLeaderboard(competitionId);
        setLeaderboards(prev => {
          const filtered = prev.filter(lb => lb.competition_id !== competitionId);
          return [...filtered, {
            competition_id: competitionId,
            entries: response.data
          }];
        });
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    }
    
    setCollapsedLeaderboards(prev => {
      const newSet = new Set(prev);
      if (isCurrentlyCollapsed) {
        newSet.delete(competitionId);
      } else {
        newSet.add(competitionId);
      }
      return newSet;
    });
  };

  const renderCompetitions = () => (
    <Container disableGutters maxWidth="100%">
      <BannerContainer>
        <img
          src="https://nationalinstituteoflanguage.in/wp-content/uploads/2025/02/Copy-of-Untitled-Design-1.png"
          alt="Competition Banner"
        />
      </BannerContainer>
  
      {competitions.map((competition) => {
        const attempted = hasAttempted(competition.id);
        const isAvailable = canAttempt(competition);
        const isLeaderboardCollapsed = collapsedLeaderboards.has(competition.id);
        const competitionLeaderboard = leaderboards.find(
          lb => lb.competition_id === competition.id
        );
  
        return (
          <CompetitionCard key={competition.id} sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
              {competition.title}
            </Typography>
            
            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
              {competition.description}
            </Typography>
  
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <TimerOutlined sx={{ color: '#4A90E2' }} />
              <Typography variant="body2" color="white">
                Time Limit: {competition.time_limit} minutes
              </Typography>
            </Box>
  
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <HistoryOutlined sx={{ color: '#4A90E2' }} />
              <Typography variant="body2" color="white">
                {formatTimeRemaining(competition.time_remaining)}
              </Typography>
            </Box>
  
            <Box sx={{ mb: 2 }}>
              <Chip 
                label={attempted ? 'Attempted' : 'Not Attempted'} 
                color={attempted ? 'success' : 'default'}
                size="small"
                sx={{ mr: 1 }}
              />
              <Chip 
                label={`${competition.total_questions} Questions`}
                size="small"
              />
            </Box>
  
            {competitionAttempts[competition.id]?.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="white">
                  Your Score: {competitionAttempts[competition.id][0].score}
                </Typography>
                <Typography variant="body2" color="white">
                  Time Taken: {competitionAttempts[competition.id][0].duration}
                </Typography>
              </Box>
            )}
  
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <GradientButton
                onClick={() => navigate(`/competitions/${competition.id}/quiz`)}
                disabled={!isAvailable}
                fullWidth
              >
                {attempted ? 'Already Attempted' : 'Start Quiz'}
              </GradientButton>
              
              <GradientButton
                onClick={() => handleLeaderboardToggle(competition.id)}
                startIcon={<EmojiEventsOutlined />}
                fullWidth
              >
                {!isLeaderboardCollapsed ? 'Hide Leaderboard' : 'Show Leaderboard'}
              </GradientButton>
            </Box>
  
            {!isLeaderboardCollapsed && competitionLeaderboard && (
              <Box sx={{ mt: 2 }}>
                <CompetitionLeaderboard
  leaderboardData={competitionLeaderboard.entries}
  competition={competition}
  currentUser={studentData}
  onPageChange={(newPage) => handleCompetitionLeaderboardPage(competition.id, newPage)}
  currentPage={competitionLeaderboard.currentPage || 1}
  totalPages={Math.ceil(competitionLeaderboard.entries.count / 20)}
  loading={loading}
/>
              </Box>
            )}
          </CompetitionCard>
        );
      })}
  
      {competitions.length === 0 && (
        <Alert severity="info">
          No active competitions available at the moment.
        </Alert>
      )}
    </Container>
  );

  const renderAttendanceSection = () => (
    <ContentCard>
      <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
        Today's Attendance
      </Typography>
      {coursesData.map((course) => (
        <Box key={course.id} mb={3}>
          <Typography variant="subtitle1" gutterBottom>
            {course.course.name}
            <Chip 
              label={course.course.is_group_class ? 'Group' : 'Individual'} 
              size="small"
              sx={{ 
                ml: 1, 
                bgcolor: 'rgba(74,144,226,0.1)',
                color: '#4A90E2',
                border: '1px solid #4A90E2'
              }}
            />
          </Typography>
          
          {shouldShowAttendanceButtons(course) && (
            attendanceData[course.id]?.marked ? (
              <Box>
                <StatusChip
                  label={attendanceData[course.id].status}
                  status={attendanceData[course.id].status}
                  sx={{ mb: 1 }}
                />
                <Typography variant="caption" display="block" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Marked at: {attendanceData[course.id].time?.format('HH:mm')}
                </Typography>
                
                <Box display="flex" gap={1} mt={2} flexWrap="wrap">
                  <GradientButton
                    size="small"
                    onClick={() => handleAttendanceChange('PRESENT', course.id)}
                    disabled={attendanceData[course.id].status === 'PRESENT'}
                  >
                    Present
                  </GradientButton>
                  <GradientButton
                    size="small"
                    onClick={() => handleAttendanceChange('ABSENT', course.id)}
                    disabled={attendanceData[course.id].status === 'ABSENT'}
                  >
                    Absent
                  </GradientButton>
                  <GradientButton
                    size="small"
                    onClick={() => handleAttendanceChange('TRAINER_ABSENT', course.id)}
                    disabled={attendanceData[course.id].status === 'TRAINER_ABSENT'}
                  >
                    Trainer Absent
                  </GradientButton>
                </Box>
              </Box>
            ) : (
              <Box display="flex" gap={1} flexWrap="wrap">
                <GradientButton
                  size="small"
                  onClick={() => handleAttendanceMarking('PRESENT', course.id)}
                >
                  Mark Present
                </GradientButton>
                <GradientButton
                  size="small"
                  onClick={() => handleAttendanceMarking('TRAINER_ABSENT', course.id)}
                >
                  Trainer Absent
                </GradientButton>
              </Box>
            )
          )}
        </Box>
      ))}
      
      <Box mt={2}>
        <GradientButton
          startIcon={<CalendarIcon />}
          onClick={() => setCalendarModalOpen(true)}
          fullWidth
        >
          View Attendance History
        </GradientButton>
      </Box>
    </ContentCard>
    );

    const renderClassLinks = () => (
      <Box>
        <Typography variant="h6" sx={{ px: 2, mb: 2, color: '#4A90E2' }}>
          Class Links
        </Typography>
        <ScrollableCards>
          {coursesData.map(course => (
            <ClassCard key={course.id}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#4A90E2' }}>
                {course.course.name}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Chip 
                  icon={<TimeIcon />}
                  label={course.course.class_time || course.class_time}
                  size="small"
                  sx={{ 
                    bgcolor: 'rgba(74,144,226,0.1)',
                    color: '#4A90E2',
                    border: '1px solid #4A90E2'
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <GradientButton
                  onClick={() => handleJoinRoom(course.id)}
                  disabled={!canJoinCourse(course)}
                  fullWidth
                >
                  Join Class Room
                </GradientButton>
                {course.trainer?.google_meet_link && (
                  <GradientButton
                    href={course.trainer.google_meet_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!canJoinCourse(course)}
                    fullWidth
                  >
                    Google Meet
                  </GradientButton>
                )}
                {course.trainer?.zoom_meeting_link && (
                  <GradientButton
                    href={course.trainer.zoom_meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!canJoinCourse(course)}
                    fullWidth
                  >
                    Zoom
                  </GradientButton>
                )}
              </Box>
            </ClassCard>
          ))}
        </ScrollableCards>
      </Box>
    );
  
    const renderCourseInfo = () => (
        <>
      <ContentCard>
        <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
          Course Information
        </Typography>
        <Tabs
          value={selectedCourseIndex}
          onChange={(e, value) => setSelectedCourseIndex(value)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            mb: 2,
            '& .MuiTab-root': {
              color: 'rgba(255,255,255,0.7)',
              '&.Mui-selected': {
                color: '#4A90E2',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#4A90E2',
            },
          }}
        >
          {coursesData.map((course, index) => (
            <Tab
              key={course.id}
              label={course.course.name}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>
  
        {coursesData[selectedCourseIndex] && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Start Date
                </Typography>
                <Typography variant="body1" sx={{ color: '#4A90E2' }}>
                  {formatDate(coursesData[selectedCourseIndex].start_date)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  End Date
                </Typography>
                <Typography variant="body1" sx={{ color: '#4A90E2' }}>
                  {formatDate(coursesData[selectedCourseIndex].end_date)}
                </Typography>
              </Grid>
            </Grid>
  
            
  
            <Box mt={2} display="flex" gap={2}>
              <GradientButton
                startIcon={<TimelineIcon />}
                onClick={() => setCourseHoldModalOpen(true)}
                fullWidth
              >
                Request Hold
              </GradientButton>
            </Box>
          </Box>
        )}
      </ContentCard>
      {renderProgressSection()}
      </>
    );
  
    const renderStudyMaterials = () => (
      <ContentCard><Box mt={2} display="flex" gap={2}>
     
      <GradientButton
        startIcon={<AssignmentIcon />}
        onClick={() => setAssignmentModalOpen(true)}
        fullWidth
      >
        Assignments
      </GradientButton>
      <GradientButton
    startIcon={<MenuBookIcon />}
    onClick={() => setReadListenModalOpen(true)}
    fullWidth
  >
    Read & Listen
  </GradientButton>
    </Box>
        <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
          Study Materials
        </Typography>
        <ScrollableList>
          {studyMaterials.map((material) => (
            <ListItem 
              key={material.id} 
              button 
              onClick={() => handleMaterialClick(material)}
            >
              <ListItemIcon>
                <DescriptionIcon sx={{ color: '#4A90E2' }} />
              </ListItemIcon>
              <ListItemText 
                primary={material.topic}
                secondary={
                  <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    Available until: {formatDate(material.expiry_date)}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </ScrollableList>
      </ContentCard>
    );
  
    const renderMoreInfo = () => (
        <>
          <ContentCard>
            <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2', mb: 3 }}>
              Trainer Information
            </Typography>
            {coursesData[selectedCourseIndex]?.trainer ? (
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Avatar
                  src={getImageUrl(coursesData[selectedCourseIndex].trainer.profile_image)}
                  alt={coursesData[selectedCourseIndex].trainer.full_name}
                  sx={{
                    width: 120,
                    height: 120,
                    margin: '0 auto 16px',
                    border: '4px solid #4A90E2',
                    boxShadow: '0 0 20px rgba(74,144,226,0.3)'
                  }}
                />
                <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                  {coursesData[selectedCourseIndex].trainer.full_name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 1 }}>
                  {coursesData[selectedCourseIndex].trainer.about_me}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  City: {coursesData[selectedCourseIndex].trainer.city}
                </Typography>
                <TrainerNotification trainer={coursesData[selectedCourseIndex].trainer} />
              </Box>
            ) : coursesData[selectedCourseIndex]?.course.is_group_class ? (
              <Box>
                <Typography variant="subtitle1" gutterBottom>Group Course Trainers:</Typography>
                {coursesData[selectedCourseIndex].course.trainer_assignments.map((assignment, index) => (
                  <Box key={index} sx={{ mb: 2, p: 2, bgcolor: 'rgba(255,255,255,0.05)', borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ color: 'white' }}>{assignment.trainer_name}</Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                      Time: {assignment.start_time} - {assignment.end_time}
                    </Typography>
                  </Box>
                ))}
                <Chip 
                  label="Group Course" 
                  color="secondary" 
                  size="small" 
                  sx={{ mt: 2 }} 
                />
              </Box>
            ) : (
              <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                No trainer assigned yet.
              </Typography>
            )}
          </ContentCard>
      
          <ContentCard sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2', mb: 3 }}>
              Coordinator Information
            </Typography>
            {coordinatorData ? (
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  src={getImageUrl(coordinatorData.profile_image)}
                  alt={coordinatorData.name}
                  sx={{
                    width: 120,
                    height: 120,
                    margin: '0 auto 16px',
                    border: '4px solid #4A90E2',
                    boxShadow: '0 0 20px rgba(74,144,226,0.3)'
                  }}
                />
                <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                  {coordinatorData.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Phone: {coordinatorData.phone}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                No coordinator assigned yet.
              </Typography>
            )}
          </ContentCard>
      
          <ContentCard sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#4A90E2' }}>
              Feedback & Complaints
            </Typography>
            <GradientButton
              onClick={() => setFeedbackModalOpen(true)}
              fullWidth
              sx={{ mb: 2 }}
            >
              Send New Feedback
            </GradientButton>
      
            <Typography variant="subtitle1" gutterBottom>Pending Feedbacks</Typography>
            <ScrollableList>
              {feedbacks.filter(f => f.status !== 'RESOLVED').map((feedback) => (
                <ListItem 
                  key={feedback.id}
                  button
                  onClick={() => handleFeedbackClick(feedback)}
                >
                  <ListItemText 
                    primary={feedback.topic}
                    secondary={feedback.content.substring(0, 50) + '...'}
                  />
                  <Chip
                    label={feedback.status}
                    color={feedback.status === 'PENDING' ? 'default' : 'primary'}
                    size="small"
                  />
                </ListItem>
              ))}
            </ScrollableList>
      
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Recently Resolved
            </Typography>
            <ScrollableList>
              {feedbackHistory.map((feedback) => (
                <ListItem 
                  key={feedback.id}
                  button
                  onClick={() => handleFeedbackClick(feedback)}
                >
                  <ListItemText 
                    primary={feedback.topic}
                    secondary={feedback.content.substring(0, 50) + '...'}
                  />
                  <Chip label="RESOLVED" color="success" size="small" />
                </ListItem>
              ))}
            </ScrollableList>
          </ContentCard>
        </>
      );
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress sx={{ color: '#4A90E2' }} />
        </Box>
      );
    }
  
    if (error) {
      return (
        <Box padding={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      );
    }
  
    return (
      <AppContainer>
         {studentData && <ChatbotNotification user={studentData} />}
         {studentData && <MessagePopupHandler user={studentData} />}
        
        {renderBanner()}
  
        <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            bgcolor: '#0B1537',
            color: 'white',
            border: '1px solid rgba(74,144,226,0.1)'
          }
        }}
      >
        <MenuItem onClick={() => {
          setEditMode(true);
          handleMenuClose();
        }}>
          <ListItemIcon>
            <EditIcon sx={{ color: '#4A90E2' }} />
          </ListItemIcon>
          <ListItemText>Edit Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          setFeedbackModalOpen(true);
          handleMenuClose();
        }}>
          <ListItemIcon>
            <FeedbackIcon sx={{ color: '#4A90E2' }} />
          </ListItemIcon>
          <ListItemText>Send Feedback</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          handleLogout();
          handleMenuClose();
        }}>
          <ListItemIcon>
            <LogoutIcon sx={{ color: '#4A90E2' }} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
  
        {notices.length > 0 && (
          <Box px={2} mb={3}>
            <NoticeCard notices={notices} userRole="STUDENT" />
          </Box>
        )}
  
        <TabPanel hidden={activeTab !== 0}>
          {renderAttendanceSection()}
          {renderClassLinks()}
          {renderCourseInfo()}
          {renderMoreInfo()}
        </TabPanel>
  
        <TabPanel hidden={activeTab !== 1} style={{ paddingBottom: '450px' }}>
          {renderStudyMaterials()}
          <ClassContentSection studentId={studentData?.id} />
        </TabPanel>

        <TabPanel hidden={activeTab !== 2}>
  {renderCompetitions()}
</TabPanel>
<TabPanel hidden={activeTab !== 3} style={{ paddingBottom: '400px' }}>
  {renderRecordings()}
</TabPanel>

  
        
        <SocialTabPanel hidden={activeTab !== 4}>
  <Box sx={{ pb: 7 }}> {/* Add padding bottom to avoid content being hidden by nav */}
    <MobileSocialPage />
  </Box>
</SocialTabPanel>
  
        
<BottomNav
  value={activeTab}
  onChange={(event, newValue) => {
    handleTabChange(event, newValue);
    if (newValue === 2) {
      setShowCompetitionTooltip(false);
      setHasNewCompetition(false);
    }
  }}
  showLabels
  sx={{ 
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#0B1537',
    borderTop: '1px solid rgba(255,255,255,0.1)',
  }}
>
  <BottomNavigationAction
    label="Home"
    icon={<DashboardIcon />}
    value={0}
    sx={{ 
      color: activeTab === 0 ? '#4A90E2' : 'rgba(255,255,255,0.5)',
      '& .MuiBottomNavigationAction-label': {
        fontSize: '0.75rem',
        '&.Mui-selected': { fontSize: '0.75rem' }
      }
    }}
  />
  <BottomNavigationAction
    label="Content"
    icon={<DescriptionIcon />}
    value={1}
    sx={{ 
      color: activeTab === 1 ? '#4A90E2' : 'rgba(255,255,255,0.5)',
      '& .MuiBottomNavigationAction-label': {
        fontSize: '0.75rem',
        '&.Mui-selected': { fontSize: '0.75rem' }
      }
    }}
  />
  <AnimatedBottomNavAction
    label="Competitions"
    icon={<CompetitionsIcon />}
    value={2}
    isanimating={hasNewCompetition ? 'true' : 'false'}
    sx={{ 
      color: activeTab === 2 ? '#4A90E2' : 'rgba(255,255,255,0.5)',
      position: 'relative',
      '& .MuiBottomNavigationAction-label': {
        fontSize: '0.75rem',
        '&.Mui-selected': { fontSize: '0.75rem' }
      }
    }}
  />
  <BottomNavigationAction
    label="Recordings"
    icon={<VideoIcon />}
    value={3}
    sx={{ 
      color: activeTab === 3 ? '#4A90E2' : 'rgba(255,255,255,0.5)',
      '& .MuiBottomNavigationAction-label': {
        fontSize: '0.75rem',
        '&.Mui-selected': { fontSize: '0.75rem' }
      }
    }}
  />
  <SocialBottomNavAction
    label="Social"
    icon={<PeopleIcon />}
    value={4}
    isselected={(activeTab === 4).toString()}
  />
</BottomNav>
        {/* Dialogs */}
        <Dialog
          open={calendarModalOpen}
          onClose={() => setCalendarModalOpen(false)}
          fullScreen
          PaperProps={{
            sx: {
              bgcolor: '#0B1537'
            }
          }}
        >
          <DialogTitle sx={{ bgcolor: '#0B1537', color: 'white' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setCalendarModalOpen(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">Attendance Calendar</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: '#0A0F24', p: 0 }}>
            <AttendanceCalendar
              studentId={studentData?.id}
              userRole="STUDENT"
            />
          </DialogContent>
        </Dialog>
  
        {/* Profile Edit Dialog */}
        <Dialog
          open={editMode}
          onClose={() => setEditMode(false)}
          fullScreen
          PaperProps={{
            sx: {
              bgcolor: '#0B1537'
            }
          }}
        >
          <DialogTitle sx={{ bgcolor: '#0B1537', color: 'white' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setEditMode(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">Edit Profile</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: '#0A0F24', p: 2 }}>
            <Box mt={2}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
  <Box display="flex" justifyContent="center" mb={3}>
    <StyledAvatar
      src={imagePreview || getImageUrl(studentData?.profile_image)}
      alt={`${studentData?.first_name} ${studentData?.last_name}`}
      sx={{
        width: 120,
        height: 120,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        }
      }}
    />
  </Box>
  <input
    accept="image/*"
    id="profile-image-input"
    type="file"
    onChange={handleProfileImageChange}
    style={{ display: 'none' }}
  />
  <label htmlFor="profile-image-input">
    <GradientButton 
      component="span" 
      fullWidth
      startIcon={<EditIcon />}
    >
      {imagePreview ? 'Change Image' : 'Upload New Image'}
    </GradientButton>
  </label>
  {imagePreview && (
    <GradientButton
      fullWidth
      onClick={() => {
        setImagePreview(null);
        setNewProfileImage(null);
      }}
      sx={{ mt: 1 }}
      startIcon={<CloseIcon />}
      color="error"
    >
      Remove New Image
    </GradientButton>
  )}
</Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={updatedProfile.first_name || studentData?.first_name || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={updatedProfile.last_name || studentData?.last_name || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    value={updatedProfile.phone || studentData?.phone || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    value={updatedProfile.city || studentData?.city || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="About Me"
                    multiline
                    rows={4}
                    value={updatedProfile.about_me || studentData?.about_me || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                    sx={{
                      '& label': { color: '#4A90E2' },
                      '& textarea': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Date of Birth"
                    value={dayjs(updatedProfile.date_of_birth || studentData?.date_of_birth)}
                    onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date?.format('YYYY-MM-DD') })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{
                          '& label': { color: '#4A90E2' },
                          '& input': { color: 'white' },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={3}>
                <GradientButton
                  onClick={handleProfileUpdate}
                  fullWidth
                >
                  Save Changes
                </GradientButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        {/* Read Listen Dialog */}
<ReadListenDialog 
  open={readListenModalOpen}
  onClose={() => setReadListenModalOpen(false)}
/>
  
        {/* Course Hold Dialog */}
        <Dialog
          open={courseHoldModalOpen}
          onClose={() => setCourseHoldModalOpen(false)}
          PaperProps={{
            sx: {
              bgcolor: '#0B1537',
              color: 'white'
            }
          }}
        >
          <DialogTitle>Request Course Hold</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <DatePicker
                label="Start Date"
                value={courseHoldData.start_date}
                onChange={(date) => handleCourseHoldInputChange('start_date', date)}
                minDate={dayjs().add(1, 'day')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{
                      mb: 2,
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={courseHoldData.end_date}
                onChange={(date) => handleCourseHoldInputChange('end_date', date)}
                minDate={dayjs(courseHoldData.start_date).add(1, 'day')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{
                      mb: 2,
                      '& label': { color: '#4A90E2' },
                      '& input': { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                      }
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Reason for Hold"
                value={courseHoldData.reason}
                onChange={(e) => handleCourseHoldInputChange('reason', e.target.value)}
                sx={{
                  '& label': { color: '#4A90E2' },
                  '& textarea': { color: 'white' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                  }
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setCourseHoldModalOpen(false)}>
              Cancel
            </GradientButton>
            <GradientButton onClick={handleSubmitCourseHold}>
              Submit Request
            </GradientButton>
          </DialogActions>
        </Dialog>
  
        {/* Feedback Dialog */}
        <Dialog
          open={feedbackModalOpen}
          onClose={() => setFeedbackModalOpen(false)}
          fullScreen
          PaperProps={{
            sx: {
              bgcolor: '#0B1537'
            }
          }}
        >
          <DialogTitle sx={{ bgcolor: '#0B1537', color: 'white' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setFeedbackModalOpen(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">Send Feedback</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: '#0A0F24', p: 2 }}>
            <FormControl fullWidth margin="normal">
              <InputLabel sx={{ color: '#4A90E2' }}>
                Feedback Type
              </InputLabel>
              <Select
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(74,144,226,0.3)'
                  }
                }}
              >
                <MenuItem value="GENERAL">General</MenuItem>
                <MenuItem value="COURSE">Course Related</MenuItem>
                <MenuItem value="TRAINER">Trainer Related</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              fullWidth
              margin="normal"
              label="Feedback Topic"
              value={feedbackTopic}
              onChange={(e) => setFeedbackTopic(e.target.value)}
              sx={{
                '& label': { color: '#4A90E2' },
                '& input': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                }
              }}
            />
            
            <TextField
              fullWidth
              margin="normal"
              label="Your Feedback"
              multiline
              rows={4}
              value={feedbackContent}
              onChange={(e) => setFeedbackContent(e.target.value)}
              sx={{
                '& label': { color: '#4A90E2' },
                '& textarea': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(74,144,226,0.3)' }
                }
              }}
            />
            
            <Box mt={2}>
              <GradientButton 
                onClick={handleFeedbackSubmit}
                fullWidth
              >
                Submit Feedback
              </GradientButton>
            </Box>
          </DialogContent>
        </Dialog>
  
        {/* Feedback Details Dialog */}
        <Dialog
          open={feedbackDetailsModalOpen}
          onClose={() => setFeedbackDetailsModalOpen(false)}
          PaperProps={{
            sx: {
              bgcolor: '#0B1537',
              color: 'white'
            }
          }}
        >
          <DialogTitle>Feedback Details</DialogTitle>
          <DialogContent>
            {selectedFeedback && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  {selectedFeedback.topic}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'rgba(255,255,255,0.7)' }}>
                  Type: {selectedFeedback.feedback_type}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {selectedFeedback.content}
                </Typography>
                <Chip
                  label={selectedFeedback.status}
                  color={selectedFeedback.status === 'RESOLVED' ? 'success' : 'default'}
                  sx={{ mb: 2 }}
                />
                {selectedFeedback.admin_remarks && (
                  <Box mt={2}>
                    <Typography variant="subtitle2" gutterBottom>
                      Admin Response:
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                      {selectedFeedback.admin_remarks}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setFeedbackDetailsModalOpen(false)}>
              Close
            </GradientButton>
          </DialogActions>
        </Dialog>
  
        {/* Material Details Dialog */}
        <Dialog
          open={Boolean(selectedMaterial)}
          onClose={handleCloseMaterialDialog}
          fullScreen
          PaperProps={{
            sx: {
              bgcolor: '#0B1537'
            }
          }}
        >
          <DialogTitle sx={{ bgcolor: '#0B1537', color: 'white' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseMaterialDialog}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">{selectedMaterial?.topic}</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: '#0A0F24', p: 2 }}>
            {selectedMaterial && (
              <>
                <Typography variant="body1" gutterBottom>
                  Course: {selectedMaterial.course?.name}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'rgba(255,255,255,0.7)' }}>
                  Available until: {formatDate(selectedMaterial.expiry_date)}
                </Typography>
                <List>
                  {selectedMaterial.files.map((file) => (
                    <ListItem key={file.id}>
                      <ListItemIcon>
                        <DescriptionIcon sx={{ color: '#4A90E2' }} />
                      </ListItemIcon>
                      <ListItemText primary={file.file_name} />
                      <GradientButton
                        href={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </GradientButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </DialogContent>
        </Dialog>
  
        {/* Absence Warning Dialog */}
        <Dialog
          open={showAbsenceWarning}
          onClose={() => setShowAbsenceWarning(false)}
          PaperProps={{
            sx: {
              bgcolor: '#0B1537',
              color: 'white'
            }
          }}
        >
          <DialogTitle sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            color: '#FFD700'
          }}>
            <WarningIcon />
            Attendance Warning
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              {absenceWarningMessage}
            </Typography>
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={() => setShowAbsenceWarning(false)}>
              Understood
            </GradientButton>
          </DialogActions>
        </Dialog>
  
        {/* Notifications Dialog */}
        <Dialog
          open={notificationPopperOpen}
          onClose={() => setNotificationPopperOpen(false)}
          fullScreen
          PaperProps={{
            sx: {
              bgcolor: '#0B1537'
            }
          }}
        >
          <DialogTitle sx={{ bgcolor: '#0B1537', color: 'white' }}>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setNotificationPopperOpen(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">Notifications</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: '#0A0F24', p: 0 }}>
            <Tabs 
              value={activeNotificationTab} 
              onChange={(e, value) => setActiveNotificationTab(value)}
              centered
              sx={{
                borderBottom: 1,
                borderColor: 'rgba(255, 255, 255, 0.1)',
                '& .MuiTab-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&.Mui-selected': {
                    color: 'white',
                  },
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'white',
                },
              }}
            >
              <Tab 
                label={`Unread (${notifications.unread.length})`}
                sx={{ textTransform: 'none' }}
              />
              <Tab 
              label={`Read (${notifications.read.length})`}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>

          <NotificationList>
            <Box sx={{ px: 2 }}>
              {activeNotificationTab === 0 ? (
                notifications.unread.length === 0 ? (
                  <Box sx={{ py: 4, textAlign: 'center' }}>
                    <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                      No unread notifications
                    </Typography>
                  </Box>
                ) : (
                  notifications.unread.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      onMarkRead={handleNotificationRead}
                    />
                  ))
                )
              ) : (
                notifications.read.length === 0 ? (
                  <Box sx={{ py: 4, textAlign: 'center' }}>
                    <Typography sx={{ color: 'rgba(255,255,255,0.6)' }}>
                      No read notifications
                    </Typography>
                  </Box>
                ) : (
                  notifications.read.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                    />
                  ))
                )
              )}
            </Box>
          </NotificationList>
        </DialogContent>
      </Dialog>
      <Dialog
  fullScreen
  open={newsFeedOpen}
  onClose={() => setNewsFeedOpen(false)}
  TransitionComponent={Zoom}
  PaperProps={{
    sx: {
      backgroundColor: '#0A0F24',
      backgroundImage: 'none'
    }
  }}
>
  <MobileNewsFeed onClose={() => setNewsFeedOpen(false)} />
</Dialog>

      

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: '12px',
            bgcolor: snackbar.severity === 'success' ? '#4CAF50' : '#f44336',
            color: '#fff',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <MobileAssignmentManagement 
  open={assignmentModalOpen}
  onClose={() => setAssignmentModalOpen(false)}
  studentId={studentData?.id}
/>
    </AppContainer>
  );
};

MobileStudentDashboard.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default React.memo(MobileStudentDashboard);