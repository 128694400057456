import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Alert,
  Snackbar,
  Chip,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Step,
  Stepper,
  StepLabel,
  Pagination,
  FormGroup,
  Checkbox,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Visibility as ViewIcon,
  Business as CompanyIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  AssessmentOutlined as ReportIcon,
  ReceiptOutlined as ReceiptIcon,
  Close as CloseIcon,
  InsertDriveFileOutlined as FileIcon,
  VisibilityOutlined as PreviewIcon
} from '@mui/icons-material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import TaxReportDialog from './TaxReportDialog';
import TaxSummaryComponent from './TaxSummaryComponent';
import GSTInputBillDialog from '../components/GSTInputBillDialog';
import GSTInputReportDialog from '../components/GSTInputReportDialog';
import GSTInputSummaryComponent from '../components/GSTInputSummaryComponent';

import {
  getOrganizationDetails,
  updateOrganizationDetails,
  listCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  listInvoices,
  getInvoice,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  downloadInvoicePdf,
  getInvoiceStats,
  getNextSequence,
  setSequence,
  listCourses,
  // GST Input Bills API functions
  listGSTInputBills,
  getGSTInputBill,
  createGSTInputBill,
  updateGSTInputBill,
  deleteGSTInputBill
} from '../api';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`invoice-tabpanel-${index}`}
    aria-labelledby={`invoice-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'medium',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(30, 41, 59, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(30, 41, 59, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(2),
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(30, 41, 59, 0.6)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const InvoiceManagementPage = () => {
  // Tab state
  const [tabValue, setTabValue] = useState(0);

  // Organization details state
  const [orgDetails, setOrgDetails] = useState({
    name: '',
    gst_number: '',
    cin_number: '',
    reg_number: '',
    address: '',
    pin_code: '',
    email: '',
    phone: '',
    logo: null
  });
  const [logoPreview, setLogoPreview] = useState(null);

  // Customers state
  const [customers, setCustomers] = useState([]);
  const [customerDialog, setCustomerDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerFormData, setCustomerFormData] = useState({
    name: '',
    address: '',
    email: '',
    phone: ''
  });

  // Tax Report state
  const [taxReportDialog, setTaxReportDialog] = useState(false);
  const [taxReportStats, setTaxReportStats] = useState(null);


  // Invoices state
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [invoiceDialog, setInvoiceDialog] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceFormData, setInvoiceFormData] = useState({
    invoice_type: 'COMPANY',
    sequence_number: 1,
    invoice_date: dayjs(),
    customer_id: '',  
    course_id: '',
    description: '',
    currency: 'INR',
    amount_including_tax: '',
    igst_percentage: 18.00,
    due_amount: '',
    due_date: null  
  });

  // GST Input Bills state
  const [gstInputBills, setGstInputBills] = useState([]);
  const [totalGstInputBills, setTotalGstInputBills] = useState(0);
  const [gstInputBillDialog, setGstInputBillDialog] = useState(false);
  const [selectedGstInputBill, setSelectedGstInputBill] = useState(null);
  const [gstInputReportDialog, setGstInputReportDialog] = useState(false);
  const [gstInputPage, setGstInputPage] = useState(1);
  const [gstInputPageSize, setGstInputPageSize] = useState(10);
  const [gstInputFilters, setGstInputFilters] = useState({
    start_date: null,
    end_date: null,
    document_type: []
  });
  const [gstInputFilterOpen, setGstInputFilterOpen] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  
  // Filter state
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    start_date: null,
    end_date: null,
    invoice_type: []
  });

  // Sequence management state
  const [sequenceDialog, setSequenceDialog] = useState(false);
  const [sequenceFormData, setSequenceFormData] = useState({
    invoice_type: 'COMPANY',
    year: new Date().getFullYear(),
    month: new Date().toLocaleString('default', { month: 'long' }),
    sequence: 1
  });

  // Combined invoice-customer creation state
  const [combinedDialog, setCombinedDialog] = useState(false);
  const [customerType, setCustomerType] = useState('new');
  const [activeStep, setActiveStep] = useState(0);
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  
  // Document preview state
  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  
  // Other state
  const [invoiceStats, setInvoiceStats] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  // Tax Report Dialog handlers
  const openTaxReportDialog = () => {
    setTaxReportDialog(true);
  };
  
  const closeTaxReportDialog = () => {
    setTaxReportDialog(false);
  };
  
  const handleTaxReportSuccess = (stats) => {
    setTaxReportStats(stats);
    showSnackbar('Tax report generated successfully', 'success');
  };
  
  // GST Input Report Dialog handlers
  const openGstInputReportDialog = () => {
    setGstInputReportDialog(true);
  };
  
  const closeGstInputReportDialog = () => {
    setGstInputReportDialog(false);
  };
  
  const handleGstInputReportSuccess = (stats) => {
    showSnackbar('GST input report generated successfully', 'success');
  };

  // Load initial data
  useEffect(() => {
    fetchOrganizationDetails();
    fetchCustomers();
    fetchInvoices();
    fetchInvoiceStats();
    fetchCourses();
    fetchGSTInputBills();
  }, []);

  // Set filtered customers based on search query
  useEffect(() => {
    if (customerSearchQuery.trim() === '') {
      setFilteredCustomers(customers);
    } else {
      const query = customerSearchQuery.toLowerCase();
      setFilteredCustomers(
        customers.filter(customer => 
          customer.name.toLowerCase().includes(query) || 
          (customer.email && customer.email.toLowerCase().includes(query)) ||
          (customer.phone && customer.phone.includes(query))
        )
      );
    }
  }, [customerSearchQuery, customers]);

  // Fetch invoices when pagination or filters change
  useEffect(() => {
    fetchInvoices();
  }, [page, pageSize, filters]);
  
  // Fetch GST input bills when pagination or filters change
  useEffect(() => {
    fetchGSTInputBills();
  }, [gstInputPage, gstInputPageSize, gstInputFilters]);

  const fetchOrganizationDetails = async () => {
    try {
      const response = await getOrganizationDetails();
      setOrgDetails(response.data);
      if (response.data.logo) {
        setLogoPreview(`${process.env.REACT_APP_API_URL || 'https://dj.nilconnect.in'}${response.data.logo}`);
      }
    } catch (error) {
      console.error('Error fetching organization details:', error);
      showSnackbar('Failed to load organization details', 'error');
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await listCustomers();
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      showSnackbar('Failed to load customers', 'error');
    }
  };

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      
      // Create a params object for the API call
      const params = {
        page: page,
        page_size: pageSize
      };
      
      // Add date range filters if set
      if (filters.start_date) {
        params.invoice_date_after = filters.start_date.format('YYYY-MM-DD');
      }
      
      if (filters.end_date) {
        params.invoice_date_before = filters.end_date.format('YYYY-MM-DD');
      }
      
      // Add invoice type filter if set
      if (filters.invoice_type && filters.invoice_type.length > 0) {
        params.invoice_type = filters.invoice_type;
      }
      
      // Call the API with params object directly
      const response = await listInvoices(params);
      
      // Handle response
      if (response && response.data) {
        if (response.data.results && Array.isArray(response.data.results)) {
          // Paginated response
          setInvoices(response.data.results);
          setTotalInvoices(response.data.count || 0);
        } else if (Array.isArray(response.data)) {
          // Direct array response
          setInvoices(response.data);
          setTotalInvoices(response.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setInvoices([]);
          setTotalInvoices(0);
        }
      } else {
        console.error("Invalid response:", response);
        setInvoices([]);
        setTotalInvoices(0);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      showSnackbar('Failed to load invoices: ' + (error.message || 'Unknown error'), 'error');
      setInvoices([]);
      setTotalInvoices(0);
      setLoading(false);
    }
  };
  
  const fetchGSTInputBills = async () => {
    try {
      setLoading(true);
      
      // Create a params object for the API call
      const params = {
        page: gstInputPage,
        page_size: gstInputPageSize
      };
      
      // Add date range filters if set
      if (gstInputFilters.start_date) {
        params.date_after = gstInputFilters.start_date.format('YYYY-MM-DD');
      }
      
      if (gstInputFilters.end_date) {
        params.date_before = gstInputFilters.end_date.format('YYYY-MM-DD');
      }
      
      // Add document type filter if set
      if (gstInputFilters.document_type && gstInputFilters.document_type.length > 0) {
        params.document_type = gstInputFilters.document_type;
      }
      
      // Call the API with params object directly
      const response = await listGSTInputBills(params);
      
      // Handle response
      if (response && response.data) {
        if (response.data.results && Array.isArray(response.data.results)) {
          // Paginated response
          setGstInputBills(response.data.results);
          setTotalGstInputBills(response.data.count || 0);
        } else if (Array.isArray(response.data)) {
          // Direct array response
          setGstInputBills(response.data);
          setTotalGstInputBills(response.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
          setGstInputBills([]);
          setTotalGstInputBills(0);
        }
      } else {
        console.error("Invalid response:", response);
        setGstInputBills([]);
        setTotalGstInputBills(0);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching GST input bills:', error);
      showSnackbar('Failed to load GST input bills: ' + (error.message || 'Unknown error'), 'error');
      setGstInputBills([]);
      setTotalGstInputBills(0);
      setLoading(false);
    }
  };

  const fetchInvoiceStats = async () => {
    try {
      const response = await getInvoiceStats();
      setInvoiceStats(response.data);
    } catch (error) {
      console.error('Error fetching invoice stats:', error);
    }
  };

  const fetchCourses = async () => {
    try {
        const response = await listCourses('NoBBB=true');
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when changing page size
  };
  
  const handleGstInputPageChange = (event, newPage) => {
    setGstInputPage(newPage);
  };
  
  const handleGstInputPageSizeChange = (event) => {
    setGstInputPageSize(parseInt(event.target.value, 10));
    setGstInputPage(1); // Reset to first page when changing page size
  };
  
  const toggleFilterDialog = () => {
    setFilterOpen(!filterOpen);
  };
  
  const toggleGstInputFilterDialog = () => {
    setGstInputFilterOpen(!gstInputFilterOpen);
  };
  
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleGstInputFilterChange = (name, value) => {
    setGstInputFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleInvoiceTypeFilterChange = (event) => {
    const type = event.target.name;
    setFilters(prev => {
      const currentTypes = [...prev.invoice_type];
      
      if (event.target.checked) {
        if (!currentTypes.includes(type)) {
          return {
            ...prev,
            invoice_type: [...currentTypes, type]
          };
        }
      } else {
        return {
          ...prev,
          invoice_type: currentTypes.filter(t => t !== type)
        };
      }
      
      return prev;
    });
  };
  
  const handleDocumentTypeFilterChange = (event) => {
    const type = event.target.name;
    setGstInputFilters(prev => {
      const currentTypes = [...prev.document_type];
      
      if (event.target.checked) {
        if (!currentTypes.includes(type)) {
          return {
            ...prev,
            document_type: [...currentTypes, type]
          };
        }
      } else {
        return {
          ...prev,
          document_type: currentTypes.filter(t => t !== type)
        };
      }
      
      return prev;
    });
  };

  const handleClearFilters = () => {
    setFilters({
      start_date: null,
      end_date: null,
      invoice_type: []
    });
    setPage(1);
  };
  
  const handleClearGstInputFilters = () => {
    setGstInputFilters({
      start_date: null,
      end_date: null,
      document_type: []
    });
    setGstInputPage(1);
  };

  const applyFilters = () => {
    setPage(1); // Reset to first page when applying new filters
    fetchInvoices();
    setFilterOpen(false);
  };
  
  const applyGstInputFilters = () => {
    setGstInputPage(1); // Reset to first page when applying new filters
    fetchGSTInputBills();
    setGstInputFilterOpen(false);
  };

  // Organization details handlers
  const handleOrgDetailsChange = (e) => {
    const { name, value } = e.target;
    setOrgDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOrgDetails(prev => ({ ...prev, logo: file }));
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveOrgDetails = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      Object.keys(orgDetails).forEach(key => {
        if (key === 'logo' && orgDetails[key] instanceof File) {
          formData.append('logo', orgDetails[key]);
        } else if (key !== 'logo' || orgDetails[key] === null) {
          formData.append(key, orgDetails[key]);
        }
      });

      await updateOrganizationDetails(formData);
      showSnackbar('Organization details saved successfully', 'success');
      fetchOrganizationDetails();
      setLoading(false);
    } catch (error) {
      console.error('Error saving organization details:', error);
      showSnackbar('Failed to save organization details', 'error');
      setLoading(false);
    }
  };

  // Customer handlers
  const openCustomerDialog = (customer = null) => {
    if (customer) {
      setSelectedCustomer(customer);
      setCustomerFormData({
        name: customer.name,
        address: customer.address,
        email: customer.email || '',
        phone: customer.phone || ''
      });
    } else {
      setSelectedCustomer(null);
      setCustomerFormData({
        name: '',
        address: '',
        email: '',
        phone: ''
      });
    }
    setCustomerDialog(true);
  };

  const closeCustomerDialog = () => {
    setCustomerDialog(false);
    setSelectedCustomer(null);
  };

  const handleCustomerFormChange = (e) => {
    const { name, value } = e.target;
    setCustomerFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveCustomer = async (continueToInvoice = false) => {
    try {
      setLoading(true);
      let savedCustomerId;

      if (selectedCustomer) {
        await updateCustomer(selectedCustomer.id, customerFormData);
        savedCustomerId = selectedCustomer.id;
        showSnackbar('Customer updated successfully', 'success');
      } else {
        const response = await createCustomer(customerFormData);
        savedCustomerId = response.data.id;
        showSnackbar('Customer created successfully', 'success');
      }
      
      await fetchCustomers();
      
      if (continueToInvoice) {
        setInvoiceFormData(prev => ({
          ...prev,
          customer_id: savedCustomerId
        }));
        setActiveStep(1);
      } else {
        closeCustomerDialog();
      }
      
      setLoading(false);
      return savedCustomerId;
    } catch (error) {
      console.error('Error saving customer:', error);
      showSnackbar('Failed to save customer', 'error');
      setLoading(false);
      return null;
    }
  };

  const handleDeleteCustomer = async (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        setLoading(true);
        await deleteCustomer(id);
        showSnackbar('Customer deleted successfully', 'success');
        fetchCustomers();
        setLoading(false);
      } catch (error) {
        console.error('Error deleting customer:', error);
        showSnackbar('Failed to delete customer', 'error');
        setLoading(false);
      }
    }
  };

  // Invoice handlers
  const openInvoiceDialog = async (invoice = null) => {
    if (invoice) {
      try {
        setLoading(true);
        const response = await getInvoice(invoice.id);
        const invoiceData = response.data;
  
        setSelectedInvoice(invoiceData);
        setInvoiceFormData({
          invoice_type: invoiceData.invoice_type,
          sequence_number: invoiceData.sequence_number,
          invoice_date: dayjs(invoiceData.invoice_date),
          customer_id: invoiceData.customer,
          course_id: invoiceData.course,
          description: invoiceData.description || '',
          currency: invoiceData.currency,
          amount_including_tax: invoiceData.amount_including_tax,
          igst_percentage: invoiceData.igst_percentage,
          due_amount: invoiceData.due_amount || '',
          due_date: invoiceData.due_date ? dayjs(invoiceData.due_date) : null
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        showSnackbar('Failed to load invoice details', 'error');
        setLoading(false);
        return;
      }
      setInvoiceDialog(true);
    } else {
      // Open the combined dialog flow
      openCombinedDialog();
    }
  };

  const closeInvoiceDialog = () => {
    setInvoiceDialog(false);
    setSelectedInvoice(null);
  };

  const handleInvoiceFormChange = (e) => {
    const { name, value } = e.target;
    setInvoiceFormData(prev => ({ ...prev, [name]: value }));

    // Update sequence when invoice type changes
    if (name === 'invoice_type') {
      getNextSequence(value).then(response => {
        setInvoiceFormData(prev => ({
          ...prev,
          sequence_number: response.data.next_sequence
        }));
      }).catch(error => {
        console.error('Error getting next sequence:', error);
      });
    }
  };

  const handleDateChange = (date) => {
    setInvoiceFormData(prev => ({ ...prev, invoice_date: date }));
  };

  const handleSaveInvoice = async () => {
    try {
      setLoading(true);
  
      // Prepare data for the API call using the structure api.js expects
      const apiPayload = {
        invoice_type: invoiceFormData.invoice_type,
        sequence_number: invoiceFormData.sequence_number,
        invoice_date: invoiceFormData.invoice_date.format('YYYY-MM-DD'),
        customer_id: invoiceFormData.customer_id,
        course_id: invoiceFormData.course_id,
        description: invoiceFormData.description,
        currency: invoiceFormData.currency,
        amount_including_tax: invoiceFormData.amount_including_tax,
        igst_percentage: invoiceFormData.igst_percentage,
        due_amount: invoiceFormData.due_amount || null,
        due_date: invoiceFormData.due_date ? invoiceFormData.due_date.format('YYYY-MM-DD') : null
      };
  
      if (selectedInvoice) {
        // Pass the ID and the payload to the update function
        await updateInvoice(selectedInvoice.id, apiPayload);
        showSnackbar('Invoice updated successfully', 'success');
      } else {
        // Pass the payload to the create function
        await createInvoice(apiPayload);
        showSnackbar('Invoice created successfully', 'success');
      }
  
      fetchInvoices();
      fetchInvoiceStats();
      closeInvoiceDialog();
      closeCombinedDialog();
      setLoading(false);
    } catch (error) {
      console.error('Error saving invoice:', error);
      let errorMessage = 'Failed to save invoice';
      if (error.response && error.response.data) {
         // Improved error message display
         try {
            const errorDetails = Object.entries(error.response.data)
              .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
              .join('; ');
            errorMessage = `Failed to save invoice: ${errorDetails}`;
         } catch (e) {
            errorMessage = `Failed to save invoice: ${JSON.stringify(error.response.data)}`;
         }
      }
      showSnackbar(errorMessage, 'error');
      setLoading(false);
    }
  };

  const handleDeleteInvoice = async (id) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      try {
        setLoading(true);
        await deleteInvoice(id);
        showSnackbar('Invoice deleted successfully', 'success');
        fetchInvoices();
        fetchInvoiceStats();
        setLoading(false);
      } catch (error) {
        console.error('Error deleting invoice:', error);
        showSnackbar('Failed to delete invoice', 'error');
        setLoading(false);
      }
    }
  };

  const handleDownloadInvoice = (invoice) => {
    if (invoice.pdf_file) {
      // Directly open the PDF file URL in a new tab or window for download
      window.open(invoice.pdf_file, '_blank');
    } else {
      showSnackbar('Invoice PDF not available for download.', 'warning');
    }
  };
  
  // GST Input Bill handlers
  const openGstInputBillDialog = async (bill = null) => {
    if (bill) {
      try {
        setLoading(true);
        const response = await getGSTInputBill(bill.id);
        const billData = response.data;
        
        setSelectedGstInputBill(billData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching GST input bill details:', error);
        showSnackbar('Failed to load GST input bill details', 'error');
        setLoading(false);
        return;
      }
    } else {
      setSelectedGstInputBill(null);
    }
    setGstInputBillDialog(true);
  };
  
  const closeGstInputBillDialog = () => {
    setGstInputBillDialog(false);
    setSelectedGstInputBill(null);
  };
  
  const handleSaveGstInputBill = async (formData, id = null) => {
    try {
      setLoading(true);
      
      if (id) {
        // Update existing GST input bill
        await updateGSTInputBill(id, formData);
        showSnackbar('GST input bill updated successfully', 'success');
      } else {
        // Create new GST input bill
        await createGSTInputBill(formData);
        showSnackbar('GST input bill created successfully', 'success');
      }
      
      fetchGSTInputBills();
      closeGstInputBillDialog();
      setLoading(false);
    } catch (error) {
      console.error('Error saving GST input bill:', error);
      let errorMessage = 'Failed to save GST input bill';
      if (error.response && error.response.data) {
        try {
          const errorDetails = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('; ');
          errorMessage = `Failed to save GST input bill: ${errorDetails}`;
        } catch (e) {
          errorMessage = `Failed to save GST input bill: ${JSON.stringify(error.response.data)}`;
        }
      }
      showSnackbar(errorMessage, 'error');
      setLoading(false);
    }
  };
  
  const handleDeleteGstInputBill = async (id) => {
    if (window.confirm('Are you sure you want to delete this GST input bill?')) {
      try {
        setLoading(true);
        await deleteGSTInputBill(id);
        showSnackbar('GST input bill deleted successfully', 'success');
        fetchGSTInputBills();
        setLoading(false);
      } catch (error) {
        console.error('Error deleting GST input bill:', error);
        showSnackbar('Failed to delete GST input bill', 'error');
        setLoading(false);
      }
    }
  };
  
  const handlePreviewDocument = (document) => {
    // Use the document_url which should be the absolute URL
    if (document.document_url) {
      setPreviewUrl(document.document_url);
      setPreviewDialog(true);
    } else {
      showSnackbar('Document file URL not available for preview.', 'warning');
    }
  };

  
  const closePreviewDialog = () => {
    setPreviewDialog(false);
    setPreviewUrl('');
  };

  // Combined invoice-customer creation state
  const openCombinedDialog = async () => {
    try {
      const response = await getNextSequence('COMPANY');
      
      setInvoiceFormData({
        invoice_type: 'COMPANY',
        sequence_number: response.data.next_sequence,
        invoice_date: dayjs(),
        customer_id: '',
        course_id: '',
        description: '',
        currency: 'INR',
        amount_including_tax: '',
        igst_percentage: 18.00,
        due_amount: '',
        due_date: null
      });
      
      setCustomerFormData({
        name: '',
        address: '',
        email: '',
        phone: ''
      });
      
      setCustomerType('new');
      setActiveStep(0);
      setCustomerSearchQuery('');
      setCombinedDialog(true);
    } catch (error) {
      console.error('Error getting next sequence:', error);
      showSnackbar('Failed to prepare new invoice form', 'error');
    }
  };

  const closeCombinedDialog = () => {
    setCombinedDialog(false);
    setActiveStep(0);
  };

  const handleCustomerTypeChange = (event) => {
    setCustomerType(event.target.value);
  };

  const handleCustomerSelect = (customerId) => {
    setInvoiceFormData(prev => ({
      ...prev,
      customer_id: customerId
    }));
    setActiveStep(1);
  };

  const handleCustomerSearchChange = (event) => {
    setCustomerSearchQuery(event.target.value);
  };

  const handleCombinedNext = async () => {
    if (activeStep === 0) {
      if (customerType === 'existing') {
        if (!invoiceFormData.customer_id) {
          showSnackbar('Please select a customer', 'error');
          return;
        }
        setActiveStep(1);
      } else {
        // Create new customer, then proceed to invoice
        if (!customerFormData.name || !customerFormData.address) {
          showSnackbar('Please fill in required customer fields', 'error');
          return;
        }
        
        const newCustomerId = await handleSaveCustomer(true);
        if (!newCustomerId) {
          return;
        }
      }
    } else if (activeStep === 1) {
      await handleSaveInvoice();
    }
  };

  const handleCombinedBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  // Sequence management handlers
  const openSequenceDialog = () => {
    setSequenceFormData({
      invoice_type: 'COMPANY',
      year: new Date().getFullYear(),
      month: new Date().toLocaleString('default', { month: 'long' }),
      sequence: 1
    });
    setSequenceDialog(true);
  };

  const closeSequenceDialog = () => {
    setSequenceDialog(false);
  };

  const handleSequenceFormChange = (e) => {
    const { name, value } = e.target;
    setSequenceFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveSequence = async () => {
    try {
      setLoading(true);
      await setSequence(sequenceFormData);
      showSnackbar('Sequence updated successfully', 'success');
      closeSequenceDialog();
      setLoading(false);
    } catch (error) {
      console.error('Error setting sequence:', error);
      showSnackbar('Failed to update sequence', 'error');
      setLoading(false);
    }
  };

  // Snackbar handlers
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Calculate total pages for pagination
  const totalPages = Math.ceil(totalInvoices / pageSize);
  const totalGstInputPages = Math.ceil(totalGstInputBills / gstInputPageSize);

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Invoice Management
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Manage organization details, customers, invoices, and GST inputs
        </Typography>

        {invoiceStats && (
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard sx={{ height: '100%', minHeight: '180px' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Total Invoices</Typography>
                  <Typography variant="h4">{invoiceStats.total_invoices || 0}</Typography>
                  <Box sx={{ display: 'flex', mt: 1 }}>
                    <Chip
                      icon={<CompanyIcon />}
                      label={`Company: ${invoiceStats.company_invoices || 0}`}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <Chip
                      icon={<PersonIcon />}
                      label={`Other: ${invoiceStats.other_invoices || 0}`}
                      size="small"
                    />
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard sx={{ height: '100%', minHeight: '180px' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Total Amount (INR)</Typography>
                  <Typography variant="h4">₹{(invoiceStats.total_inr || 0).toLocaleString()}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard sx={{ height: '100%', minHeight: '180px' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Total Amount (USD)</Typography>
                  <Typography variant="h4">${(invoiceStats.total_usd || 0).toLocaleString()}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard sx={{ height: '100%', minHeight: '180px' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>This Month</Typography>
                  <Typography variant="h4">{invoiceStats.current_month?.total_count || 0}</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Company</Typography>
                      <Typography variant="body1">
                        {invoiceStats.current_month?.company_count || 0}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        ₹{(invoiceStats.current_month?.company_amount || 0).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Other</Typography>
                      <Typography variant="body1">
                        {invoiceStats.current_month?.other_count || 0}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        ₹{(invoiceStats.current_month?.other_amount || 0).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        )}

        {/* Show both summary components side by side in a grid */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <TaxSummaryComponent onOpenTaxReportDialog={openTaxReportDialog} />
          </Grid>
          <Grid item xs={12} md={6}>
            <GSTInputSummaryComponent onOpenGSTInputReportDialog={openGstInputReportDialog} />
          </Grid>
        </Grid>

        <Paper sx={{ borderRadius: 2, overflow: 'hidden', bgcolor: 'background.paper' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                py: 2
              }
            }}
          >
            <Tab label="Invoices" />
            <Tab label="GST Input Bills" />
            <Tab label="Customers" />
            <Tab label="Organization Details" />
          </Tabs>

          {/* Invoices Tab */}
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h5">All Invoices</Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FilterIcon />}
                  onClick={toggleFilterDialog}
                  sx={{ mr: 1 }}
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SettingsIcon />}
                  onClick={openSequenceDialog}
                  sx={{ mr: 1 }}
                >
                  Manage Sequence
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ReportIcon />}
                  onClick={openTaxReportDialog}
                  sx={{ mr: 1 }}
                >
                  Tax Report
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => openInvoiceDialog()}
                >
                  New Invoice
                </Button>
              </Box>
            </Box>
            
            {/* Invoices Filter Dialog */}
            <Dialog
              open={filterOpen}
              onClose={toggleFilterDialog}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Filter Invoices</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={filters.start_date}
                        onChange={(date) => handleFilterChange('start_date', date)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={filters.end_date}
                        onChange={(date) => handleFilterChange('end_date', date)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Invoice Type
                    </Typography>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={filters.invoice_type.includes('COMPANY')}
                            onChange={handleInvoiceTypeFilterChange}
                            name="COMPANY"
                          />
                        }
                        label="Company Invoice"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={filters.invoice_type.includes('OTHER')}
                            onChange={handleInvoiceTypeFilterChange}
                            name="OTHER"
                          />
                        }
                        label="Other Invoice"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClearFilters} color="secondary">
                  Clear Filters
                </Button>
                <Button onClick={toggleFilterDialog}>
                  Cancel
                </Button>
                <Button onClick={applyFilters} variant="contained" color="primary">
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
  
            {/* Active Filter Indicators */}
            {(filters.start_date || filters.end_date || filters.invoice_type.length > 0) && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 1, 
                  mb: 2, 
                  p: 1, 
                  bgcolor: 'rgba(0, 0, 0, 0.03)', 
                  borderRadius: 1 
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mr: 1, alignSelf: 'center' }}>
                  Active Filters:
                </Typography>
                
                {filters.start_date && (
                  <Chip 
                    label={`From: ${filters.start_date.format('MMM D, YYYY')}`} 
                    onDelete={() => handleFilterChange('start_date', null)}
                    size="small"
                  />
                )}
                
                {filters.end_date && (
                  <Chip 
                    label={`To: ${filters.end_date.format('MMM D, YYYY')}`} 
                    onDelete={() => handleFilterChange('end_date', null)}
                    size="small"
                  />
                )}
                
                {filters.invoice_type.includes('COMPANY') && (
                  <Chip 
                    label="Company Invoices" 
                    onDelete={() => {
                      setFilters(prev => ({
                        ...prev,
                        invoice_type: prev.invoice_type.filter(t => t !== 'COMPANY')
                      }));
                    }}
                    size="small"
                  />
                )}
                
                {filters.invoice_type.includes('OTHER') && (
                  <Chip 
                    label="Other Invoices" 
                    onDelete={() => {
                      setFilters(prev => ({
                        ...prev,
                        invoice_type: prev.invoice_type.filter(t => t !== 'OTHER')
                      }));
                    }}
                    size="small"
                  />
                )}
                
                <Button 
                  variant="text" 
                  color="primary" 
                  size="small" 
                  onClick={handleClearFilters}
                  sx={{ ml: 'auto' }}
                >
                  Clear All
                </Button>
              </Box>
            )}
  
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
              </Box>
            ) : invoices.length === 0 ? (
              <Alert severity="info">No invoices found. {filters.start_date || filters.end_date || filters.invoice_type.length > 0 ? 'Try changing your filters or ' : ''}Create your first invoice using the button above.</Alert>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ borderRadius: 2, bgcolor: 'background.paper' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Invoice Number</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Customer</StyledTableCell>
                        <StyledTableCell>Amount</StyledTableCell>
                        <StyledTableCell align="right">Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.map((invoice) => (
                        <StyledTableRow key={invoice.id}>
                          <StyledTableCell>{invoice.invoice_number}</StyledTableCell>
                          <StyledTableCell>{new Date(invoice.invoice_date).toLocaleDateString()}</StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={invoice.invoice_type}
                              color={invoice.invoice_type === 'COMPANY' ? 'primary' : 'secondary'}
                              size="small"
                            />
                          </StyledTableCell>
                          <StyledTableCell>{invoice.customer_name}</StyledTableCell>
                          <StyledTableCell>
                            {invoice.currency === 'INR' ? '₹' : '$'}
                            {parseFloat(invoice.amount_including_tax).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => handleDownloadInvoice(invoice)}
                              title="Download Invoice"
                            >
                              <DownloadIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => openInvoiceDialog(invoice)}
                              title="Edit Invoice"
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => handleDeleteInvoice(invoice.id)}
                              title="Delete Invoice"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                {/* Pagination controls */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                  <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="page-size-label">Items per page</InputLabel>
                    <Select
                      labelId="page-size-label"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      label="Items per page"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                      Showing {invoices.length} of {totalInvoices} invoices
                    </Typography>
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size="large"
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                </Box>
              </>
            )}
          </TabPanel>
  
          {/* GST Input Bills Tab */}
          <TabPanel value={tabValue} index={1}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h5">GST Input Bills</Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FilterIcon />}
                  onClick={toggleGstInputFilterDialog}
                  sx={{ mr: 1 }}
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ReportIcon />}
                  onClick={openGstInputReportDialog}
                  sx={{ mr: 1 }}
                >
                  Generate Report
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => openGstInputBillDialog()}
                >
                  Add GST Input Bill
                </Button>
              </Box>
            </Box>
            
            {/* GST Input Bills Filter Dialog */}
            <Dialog
              open={gstInputFilterOpen}
              onClose={toggleGstInputFilterDialog}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Filter GST Input Bills</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={gstInputFilters.start_date}
                        onChange={(date) => handleGstInputFilterChange('start_date', date)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={gstInputFilters.end_date}
                        onChange={(date) => handleGstInputFilterChange('end_date', date)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Document Type
                    </Typography>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={gstInputFilters.document_type.includes('INVOICE')}
                            onChange={handleDocumentTypeFilterChange}
                            name="INVOICE"
                          />
                        }
                        label="Invoice"
                      />
                      <FormControlLabel
                        control={
                            <Checkbox 
                            checked={gstInputFilters.document_type.includes('BILL')}
                            onChange={handleDocumentTypeFilterChange}
                            name="BILL"
                          />
                        }
                        label="Bill"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={gstInputFilters.document_type.includes('RECEIPT')}
                            onChange={handleDocumentTypeFilterChange}
                            name="RECEIPT"
                          />
                        }
                        label="Receipt"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={gstInputFilters.document_type.includes('OTHER')}
                            onChange={handleDocumentTypeFilterChange}
                            name="OTHER"
                          />
                        }
                        label="Other"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClearGstInputFilters} color="secondary">
                  Clear Filters
                </Button>
                <Button onClick={toggleGstInputFilterDialog}>
                  Cancel
                </Button>
                <Button onClick={applyGstInputFilters} variant="contained" color="primary">
                  Apply Filters
                </Button>
              </DialogActions>
            </Dialog>
            
            {/* Active GST Input Bills Filter Indicators */}
            {(gstInputFilters.start_date || gstInputFilters.end_date || gstInputFilters.document_type.length > 0) && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 1, 
                  mb: 2, 
                  p: 1, 
                  bgcolor: 'rgba(0, 0, 0, 0.03)', 
                  borderRadius: 1 
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mr: 1, alignSelf: 'center' }}>
                  Active Filters:
                </Typography>
                
                {gstInputFilters.start_date && (
                  <Chip 
                    label={`From: ${gstInputFilters.start_date.format('MMM D, YYYY')}`} 
                    onDelete={() => handleGstInputFilterChange('start_date', null)}
                    size="small"
                  />
                )}
                
                {gstInputFilters.end_date && (
                  <Chip 
                    label={`To: ${gstInputFilters.end_date.format('MMM D, YYYY')}`} 
                    onDelete={() => handleGstInputFilterChange('end_date', null)}
                    size="small"
                  />
                )}
                
                {gstInputFilters.document_type.map(type => (
                  <Chip 
                    key={type}
                    label={`${type}`} 
                    onDelete={() => {
                      setGstInputFilters(prev => ({
                        ...prev,
                        document_type: prev.document_type.filter(t => t !== type)
                      }));
                    }}
                    size="small"
                  />
                ))}
                
                <Button 
                  variant="text" 
                  color="primary" 
                  size="small" 
                  onClick={handleClearGstInputFilters}
                  sx={{ ml: 'auto' }}
                >
                  Clear All
                </Button>
              </Box>
            )}
            
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
              </Box>
            ) : gstInputBills.length === 0 ? (
              <Alert severity="info">No GST input bills found. {gstInputFilters.start_date || gstInputFilters.end_date || gstInputFilters.document_type.length > 0 ? 'Try changing your filters or ' : ''}Add your first GST input bill using the button above.</Alert>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ borderRadius: 2, bgcolor: 'background.paper' }}>
                  <Table>
                  <TableHead>
                      <TableRow>
                        <StyledTableCell>Vendor</StyledTableCell>
                        <StyledTableCell>Document Type</StyledTableCell>
                        <StyledTableCell>Document Number</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Amount (Excl. GST)</StyledTableCell>
                        <StyledTableCell>GST</StyledTableCell>
                        <StyledTableCell>Amount (Incl. GST)</StyledTableCell>
                        <StyledTableCell>File Type</StyledTableCell> {/* <-- ADD THIS */}
                        <StyledTableCell>Preview</StyledTableCell> {/* Renamed for clarity */}
                        <StyledTableCell align="right">Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {gstInputBills.map((bill) => (
                        <StyledTableRow key={bill.id}>
                          <StyledTableCell>{bill.vendor_name}</StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={bill.document_type || 'N/A'} // Added fallback
                              color="primary"
                              size="small"
                            />
                          </StyledTableCell>
                          <StyledTableCell>{bill.document_number}</StyledTableCell>
                          <StyledTableCell>{new Date(bill.date).toLocaleDateString()}</StyledTableCell>
                          <StyledTableCell>
                            ₹{parseFloat(bill.amount_excluding_tax || 0).toLocaleString(undefined, { // Added fallback
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            ₹{parseFloat(bill.gst_amount || 0).toLocaleString(undefined, { // Added fallback
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            ₹{parseFloat(bill.amount_including_tax || 0).toLocaleString(undefined, { // Added fallback
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>

                          {/* ADDED File Type Cell */}
                          <StyledTableCell>
                            {bill.file_type ? (
                              <Chip
                                label={bill.file_type.toUpperCase()}
                                size="small"
                                variant="outlined"
                                icon={<FileIcon fontSize="small" />} // Generic file icon
                              />
                            ) : (
                              '-'
                            )}
                          </StyledTableCell>

                          {/* MODIFIED Preview Cell */}
                          <StyledTableCell>
                            {bill.document_url ? ( // Use document_url to check if preview/download is possible
                              <Tooltip title="Preview Document">
                                <IconButton
                                  size="small"
                                  color="info"
                                  onClick={() => handlePreviewDocument(bill)} // Pass the whole bill object
                                >
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Typography variant="caption" color="text.secondary">No file</Typography>
                            )}
                          </StyledTableCell>

                          {/* MODIFIED Actions Cell */}
                          <StyledTableCell align="right">
                            {/* ADDED Download Button */}
                            {bill.document_url && ( // Only show if URL exists
                              <Tooltip title="Download Document">
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => window.open(bill.document_url, '_blank')} // Open URL
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {/* Existing Edit/Delete Buttons */}
                            <Tooltip title="Edit">
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => openGstInputBillDialog(bill)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => handleDeleteGstInputBill(bill.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                {/* Pagination controls for GST Input Bills */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                  <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="gst-input-page-size-label">Items per page</InputLabel>
                    <Select
                      labelId="gst-input-page-size-label"
                      value={gstInputPageSize}
                      onChange={handleGstInputPageSizeChange}
                      label="Items per page"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                      Showing {gstInputBills.length} of {totalGstInputBills} GST input bills
                    </Typography>
                    <Pagination
                      count={totalGstInputPages}
                      page={gstInputPage}
                      onChange={handleGstInputPageChange}
                      color="primary"
                      size="large"
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                </Box>
              </>
            )}
          </TabPanel>
  
          {/* Customers Tab */}
          <TabPanel value={tabValue} index={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h5">Customers</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => openCustomerDialog()}
              >
                New Customer
              </Button>
            </Box>
  
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
              </Box>
            ) : customers.length === 0 ? (
              <Alert severity="info">No customers found. Create your first customer using the button above.</Alert>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, bgcolor: 'background.paper' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell align="right">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((customer) => (
                      <StyledTableRow key={customer.id}>
                        <StyledTableCell>{customer.name}</StyledTableCell>
                        <StyledTableCell>{customer.address}</StyledTableCell>
                        <StyledTableCell>{customer.email || '-'}</StyledTableCell>
                        <StyledTableCell>{customer.phone || '-'}</StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => openCustomerDialog(customer)}
                            title="Edit Customer"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteCustomer(customer.id)}
                            title="Delete Customer"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
  
          {/* Organization Details Tab */}
          <TabPanel value={tabValue} index={3}>
            <StyledPaper>
              <Typography variant="h5" gutterBottom>Organization Details</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                These details will appear on all your invoices
              </Typography>
  
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Organization Name"
                        name="name"
                        value={orgDetails.name}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="GST Number"
                        name="gst_number"
                        value={orgDetails.gst_number}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="CIN Number"
                        name="cin_number"
                        value={orgDetails.cin_number}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Registration Number"
                        name="reg_number"
                        value={orgDetails.reg_number}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="PIN Code"
                        name="pin_code"
                        value={orgDetails.pin_code}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="address"
                        value={orgDetails.address}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                        multiline
                        rows={2}
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={orgDetails.email}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        value={orgDetails.phone}
                        onChange={handleOrgDetailsChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
  
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    Organization Logo
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      height: 200,
                      border: '1px dashed rgba(255, 255, 255, 0.3)',
                      borderRadius: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 2,
                      position: 'relative',
                      overflow: 'hidden',
                      backgroundColor: 'rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    {logoPreview ? (
                      <img
                        src={logoPreview}
                        alt="Organization Logo"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    ) : (
                      <Typography color="text.secondary">
                        No logo uploaded
                      </Typography>
                    )}
                  </Box>
  
                  <Button
                    variant="outlined"
                    component="label"
                    fullWidth
                  >
                    Upload Logo
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </Button>
                </Grid>
  
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveOrgDetails}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Save Details'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
          </TabPanel>
        </Paper>
      </Box>
  
      {/* Customer Dialog */}
      <Dialog
        open={customerDialog}
        onClose={closeCustomerDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedCustomer ? 'Edit Customer' : 'New Customer'}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={customerFormData.name}
            onChange={handleCustomerFormChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={customerFormData.address}
            onChange={handleCustomerFormChange}
            margin="normal"
            multiline
            rows={3}
            required
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={customerFormData.email}
            onChange={handleCustomerFormChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={customerFormData.phone}
            onChange={handleCustomerFormChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCustomerDialog}>Cancel</Button>
          <Button
            onClick={() => handleSaveCustomer(false)}
            variant="contained"
            color="primary"
            disabled={!customerFormData.name || !customerFormData.address || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
  
      {/* Invoice Dialog */}
      <Dialog
        open={invoiceDialog}
        onClose={closeInvoiceDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedInvoice ? 'Edit Invoice' : 'New Invoice'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="invoice-type-label">Invoice Type</InputLabel>
                <Select
                  labelId="invoice-type-label"
                  name="invoice_type"
                  value={invoiceFormData.invoice_type}
                  onChange={handleInvoiceFormChange}
                  label="Invoice Type"
                >
                  <MenuItem value="COMPANY">Company</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Sequence Number"
                name="sequence_number"
                value={invoiceFormData.sequence_number}
                onChange={handleInvoiceFormChange}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                
              />
            </Grid>
  
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  value={invoiceFormData.invoice_date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined'
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
  
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="customer-label">Customer</InputLabel>
                <Select
                  labelId="customer-label"
                  name="customer_id"
                  value={invoiceFormData.customer_id}
                  onChange={handleInvoiceFormChange}
                  label="Customer"
                  required
                >
                  {customers.map(customer => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12}>
              <Autocomplete
                options={courses}
                getOptionLabel={(option) => option.name}
                value={courses.find(course => course.id === invoiceFormData.course_id) || null}
                onChange={(event, newValue) => {
                  setInvoiceFormData(prev => ({
                    ...prev,
                    course_id: newValue ? newValue.id : ''
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course"
                    required
                  />
                )}
              />
            </Grid>
  
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={invoiceFormData.description}
                onChange={handleInvoiceFormChange}
                multiline
                rows={2}
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                  labelId="currency-label"
                  name="currency"
                  value={invoiceFormData.currency}
                  onChange={handleInvoiceFormChange}
                  label="Currency"
                >
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Amount (Including GST)"
                name="amount_including_tax"
                value={invoiceFormData.amount_including_tax}
                onChange={handleInvoiceFormChange}
                type="number"
                InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                required
              />
            </Grid>
  
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="igst-label">IGST Rate</InputLabel>
                <Select
                  labelId="igst-label"
                  name="igst_percentage"
                  value={invoiceFormData.igst_percentage}
                  onChange={handleInvoiceFormChange}
                  label="IGST Rate"
                >
                  <MenuItem value={18}>18%</MenuItem>
                  <MenuItem value={0}>0%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField  
                fullWidth
                label="Due Amount"
                name="due_amount"
                value={invoiceFormData.due_amount}
                onChange={handleInvoiceFormChange}  
                type="number"
                InputProps={{ inputProps: { min: 0, step: "0.01" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"  
                  value={invoiceFormData.due_date}
                  onChange={(date) => setInvoiceFormData(prev => ({ ...prev, due_date: date }))}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  slotProps={{
                    textField: {
                      fullWidth: true, 
                      variant: 'outlined'
                    }
                  }}
                />  
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeInvoiceDialog}>Cancel</Button>
          <Button
            onClick={handleSaveInvoice}
            variant="contained"
            color="primary"
            disabled={!invoiceFormData.customer_id || !invoiceFormData.course_id || !invoiceFormData.amount_including_tax || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* GST Input Bill Dialog */}
      <GSTInputBillDialog
        open={gstInputBillDialog}
        onClose={closeGstInputBillDialog}
        onSave={handleSaveGstInputBill}
        selectedBill={selectedGstInputBill}
        loading={loading}
      />
      
      {/* Document Preview Dialog */}
      <Dialog
        open={previewDialog}
        onClose={closePreviewDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          Document Preview
          <IconButton
            aria-label="close"
            onClick={closePreviewDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', height: '70vh', overflow: 'auto' }}>
            {previewUrl && (
              previewUrl.toLowerCase().endsWith('.pdf') ? (
                <iframe
                  src={previewUrl}
                  style={{ width: '100%', height: '100%', border: 'none' }}
                  title="Document Preview"
                />
              ) : previewUrl.toLowerCase().match(/\.(jpe?g|png|gif)$/i) ? (
                <img
                  src={previewUrl}
                  alt="Document Preview"
                  style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: '0 auto' }}
                />
              ) : (
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography>
                    This document type cannot be previewed directly. Please download the file to view it.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href={previewUrl}
                    target="_blank"
                    sx={{ mt: 2 }}
                  >
                    Download Document
                  </Button>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
      </Dialog>
  
      {/* Combined Customer-Invoice Dialog */}
      <Dialog
        open={combinedDialog}
        onClose={closeCombinedDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Create New Invoice
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            <Step>
              <StepLabel>Select or Create Customer</StepLabel>
            </Step>
            <Step>
              <StepLabel>Complete Invoice Details</StepLabel>
            </Step>
          </Stepper>
  
          {activeStep === 0 && (
            <Box>
              <FormControl component="fieldset" sx={{ mb: 3 }}>
                <RadioGroup
                  name="customer-type"
                  value={customerType}
                  onChange={handleCustomerTypeChange}
                  row
                >
                  <FormControlLabel value="existing" control={<Radio />} label="Existing Customer" />
                  <FormControlLabel value="new" control={<Radio />} label="New Customer" />
                </RadioGroup>
              </FormControl>
  
              {customerType === 'existing' ? (
                <Box>
                  <TextField
                    fullWidth
                    label="Search Customers"
                    placeholder="Search by name, email, or phone"
                    value={customerSearchQuery}
                    onChange={handleCustomerSearchChange}
                    InputProps={{
                      startAdornment: <SearchIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
                    }}
                    sx={{ mb: 2 }}
                  />
                  
                  <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto', mb: 3 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Address</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Phone</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredCustomers.length > 0 ? (
                          filteredCustomers.map((customer) => (
                            <StyledTableRow key={customer.id}>
                              <StyledTableCell>{customer.name}</StyledTableCell>
                              <StyledTableCell>{customer.address}</StyledTableCell>
                              <StyledTableCell>{customer.email || '-'}</StyledTableCell>
                              <StyledTableCell>{customer.phone || '-'}</StyledTableCell>
                              <StyledTableCell>
                                <Button
                                  variant={invoiceFormData.customer_id === customer.id ? 'contained' : 'outlined'}
                                  size="small"
                                  onClick={() => handleCustomerSelect(customer.id)}
                                >
                                  {invoiceFormData.customer_id === customer.id ? 'Selected' : 'Select'}
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5} align="center">
                              No customers found
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Enter New Customer Details
                  </Typography>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={customerFormData.name}
                    onChange={handleCustomerFormChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={customerFormData.address}
                    onChange={handleCustomerFormChange}
                    margin="normal"
                    multiline
                    rows={3}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={customerFormData.email}
                    onChange={handleCustomerFormChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={customerFormData.phone}
                    onChange={handleCustomerFormChange}
                    margin="normal"
                  />
                </Box>
              )}
            </Box>
          )}
  
          {activeStep === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="invoice-type-label">Invoice Type</InputLabel>
                  <Select
                    labelId="invoice-type-label"
                    name="invoice_type"
                    value={invoiceFormData.invoice_type}
                    onChange={handleInvoiceFormChange}
                    label="Invoice Type"
                  >
                    <MenuItem value="COMPANY">Company</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Sequence Number"
                  name="sequence_number"
                  value={invoiceFormData.sequence_number}
                  onChange={handleInvoiceFormChange}
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Invoice Date"
                    value={invoiceFormData.invoice_date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined'
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={courses}
                  getOptionLabel={(option) => option.name}
                  value={courses.find(course => course.id === invoiceFormData.course_id) || null}
                  onChange={(event, newValue) => {
                    setInvoiceFormData(prev => ({
                      ...prev,
                      course_id: newValue ? newValue.id : ''
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      required
                    />
                  )}
                />
              </Grid>
  
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={invoiceFormData.description}
                  onChange={handleInvoiceFormChange}
                  multiline
                  rows={2}
                />
              </Grid>
  
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    name="currency"
                    value={invoiceFormData.currency}
                    onChange={handleInvoiceFormChange}
                    label="Currency"
                  >
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Amount (Including GST)"
                  name="amount_including_tax"
                  value={invoiceFormData.amount_including_tax}
                  onChange={handleInvoiceFormChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="igst-label">IGST Rate</InputLabel>
                  <Select
                    labelId="igst-label"
                    name="igst_percentage"
                    value={invoiceFormData.igst_percentage}
                    onChange={handleInvoiceFormChange}
                    label="IGST Rate"
                  >
                    <MenuItem value={18}>18%</MenuItem>
                    <MenuItem value={0}>0%</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Due Amount"
                  name="due_amount"
                  value={invoiceFormData.due_amount}
                  onChange={handleInvoiceFormChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0, step: "0.01" } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Due Date"
                    value={invoiceFormData.due_date}
                    onChange={(date) => setInvoiceFormData(prev => ({ ...prev, due_date: date }))} 
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined'  
                      }
                    }}
                  />
                </LocalizationProvider>  
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCombinedDialog}>Cancel</Button>
          {activeStep > 0 && (
            <Button onClick={handleCombinedBack}>Back</Button>
          )}
          <Button
            onClick={handleCombinedNext}
            variant="contained"
            color="primary"
            disabled={loading || (activeStep === 0 && customerType === 'existing' && !invoiceFormData.customer_id) || 
                    (activeStep === 0 && customerType === 'new' && (!customerFormData.name || !customerFormData.address)) ||
                    (activeStep === 1 && (!invoiceFormData.course_id || !invoiceFormData.amount_including_tax))}
          >
            {loading ? <CircularProgress size={24} /> : (activeStep === 1 ? 'Create Invoice' : 'Next')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sequence Management Dialog */}
      <Dialog
        open={sequenceDialog}
        onClose={closeSequenceDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Manage Invoice Sequence</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="sequence-invoice-type-label">Invoice Type</InputLabel>
                <Select
                  labelId="sequence-invoice-type-label"
                  name="invoice_type"
                  value={sequenceFormData.invoice_type}
                  onChange={handleSequenceFormChange}
                  label="Invoice Type"
                >
                  <MenuItem value="COMPANY">Company</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Year"
                name="year"
                value={sequenceFormData.year}
                onChange={handleSequenceFormChange}
                type="number"
                InputProps={{ inputProps: { min: 2000 } }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="month-label">Month</InputLabel>
                <Select
                  labelId="month-label"
                  name="month"
                  value={sequenceFormData.month}
                  onChange={handleSequenceFormChange}
                  label="Month"
                >
                  {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(month => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Next Sequence Number"
                name="sequence"
                value={sequenceFormData.sequence}
                onChange={handleSequenceFormChange}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                helperText="Next invoice will use this number. Be careful when changing this!"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSequenceDialog}>Cancel</Button>
          <Button
            onClick={handleSaveSequence}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update Sequence'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Tax Report Dialog */}
      <TaxReportDialog
        open={taxReportDialog}
        onClose={closeTaxReportDialog}
        onSuccess={handleTaxReportSuccess}
      />
      
      {/* GST Input Report Dialog */}
      <GSTInputReportDialog
        open={gstInputReportDialog}
        onClose={closeGstInputReportDialog}
        onSuccess={handleGstInputReportSuccess}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InvoiceManagementPage;