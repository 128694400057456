import React, { useState } from 'react';
import { Box, Typography, IconButton, Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Menu as MenuIcon, 
  Notifications as NotificationsIcon, 
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { getImageUrl } from '../api';

const TRANSITION_DURATION = '0.3s';

const DynamicBanner = styled(Box)(({ isCollapsed }) => ({
  background: `linear-gradient(to bottom, #1a237e, #0d47a1)`,
  borderBottomLeftRadius: 24,
  borderBottomRightRadius: 24,
  padding: '12px 20px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
  transition: `all ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
  height: isCollapsed ? '115px' : '320px', // Increased height to accommodate the button
  display: 'flex',
  flexDirection: 'column'
}));

const HeaderBar = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '28px',
  width: '100%'
});

const HeaderTitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ActionButtons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

const ProfileSection = styled(Box)(({ isCollapsed }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginTop: '8px',
  position: 'relative',
  transition: `all ${TRANSITION_DURATION}`
}));

const StyledAvatar = styled(Avatar)({
  width: '42px',
  height: '42px',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
});

const ToggleButton = styled(IconButton)(({ isCollapsed }) => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  color: 'white',
  padding: 4,
  width: '28px',
  height: '28px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem'
  }
}));

const ActionButton = styled(IconButton)({
  color: 'white',
  padding: '4px',
  width: '28px',
  height: '28px',
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem'
  }
});

const InfoGrid = styled(Box)(({ isCollapsed }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '12px',
  opacity: isCollapsed ? 0 : 1,
  maxHeight: isCollapsed ? '0' : '1000px',
  overflow: 'hidden',
  transition: `all ${TRANSITION_DURATION}`,
  marginTop: '24px',
  visibility: isCollapsed ? 'hidden' : 'visible',
  transform: isCollapsed ? 'translateY(-10px)' : 'translateY(0)',
}));

const InfoItem = styled(Box)({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  padding: '8px 12px',
  borderRadius: 12,
  border: '1px solid rgba(255,255,255,0.1)'
});

const NewsFeedButtonContainer = styled(Box)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '-15px',
  zIndex: 1200,
  marginTop: '10px', // Add spacing from profile content
});

const EnhancedProfileBanner = ({
  studentData,
  notifications,
  handleMenuOpen,
  setNotificationPopperOpen,
  setNotificationIconRef,
  newsFeedButton
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <DynamicBanner isCollapsed={isCollapsed}>
        <HeaderBar>
          <HeaderTitle>
            <Typography variant="subtitle1" sx={{ 
              color: 'white',
              fontSize: '1.1rem',
              fontWeight: 500,
              letterSpacing: '0.2px'
            }}>
              Student Dashboard
            </Typography>
          </HeaderTitle>
          <ActionButtons>
            <ActionButton
              onClick={() => setNotificationPopperOpen(true)}
              ref={setNotificationIconRef}
            >
              <Badge 
                badgeContent={notifications.unread.length} 
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#ff4444',
                    color: 'white',
                    minWidth: '18px',
                    height: '18px',
                    fontSize: '0.7rem'
                  }
                }}
              >
                <NotificationsIcon />
              </Badge>
            </ActionButton>
            <ActionButton onClick={handleMenuOpen}>
              <MenuIcon />
            </ActionButton>
          </ActionButtons>
        </HeaderBar>

        <ProfileSection isCollapsed={isCollapsed}>
          <StyledAvatar
            src={getImageUrl(studentData?.profile_image)}
            alt={`${studentData?.first_name} ${studentData?.last_name}`}
          />
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ 
              color: 'white',
              fontWeight: 500,
              fontSize: '1rem',
              lineHeight: 1.2
            }}>
              {`${studentData?.first_name || ''} ${studentData?.last_name || ''}`}
            </Typography>
            {!isCollapsed && (
              <Typography variant="caption" sx={{ 
                color: 'rgba(255,255,255,0.7)',
                display: 'block',
                marginTop: '4px'
              }}>
                {studentData?.email}
              </Typography>
            )}
          </Box>
          <ToggleButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            size="small"
            isCollapsed={isCollapsed}
            aria-label={isCollapsed ? 'Expand profile' : 'Collapse profile'}
          >
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </ToggleButton>
        </ProfileSection>

        <InfoGrid isCollapsed={isCollapsed}>
          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              Phone
            </Typography>
            <Typography variant="body2" sx={{ color: 'white', fontSize: '0.9rem' }}>
              {studentData?.phone || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              City
            </Typography>
            <Typography variant="body2" sx={{ color: 'white', fontSize: '0.9rem' }}>
              {studentData?.city || 'Not provided'}
            </Typography>
          </InfoItem>

          <InfoItem sx={{ gridColumn: '1 / -1' }}>
            <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
              About Me
            </Typography>
            <Typography variant="body2" sx={{ 
              color: 'white',
              fontSize: '0.9rem',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}>
              {studentData?.about_me || 'No information provided'}
            </Typography>
          </InfoItem>
        </InfoGrid>
        
        {/* News Feed Button - Centered and slightly below banner */}
        <NewsFeedButtonContainer>
          <Box sx={{ mt: 6 }}> {/* Add more top spacing from the name */}
            {newsFeedButton}
          </Box>
        </NewsFeedButtonContainer>
      </DynamicBanner>
      <Box sx={{ 
        height: isCollapsed ? '120px' : '340px', // Adjusted to accommodate the taller banner
        mb: 3 // Increased margin bottom for better spacing from content
      }} />
    </>
  );
};

EnhancedProfileBanner.propTypes = {
  studentData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    about_me: PropTypes.string,
    profile_image: PropTypes.string
  }),
  notifications: PropTypes.shape({
    unread: PropTypes.array.isRequired
  }).isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  setNotificationPopperOpen: PropTypes.func.isRequired,
  setNotificationIconRef: PropTypes.func.isRequired,
  newsFeedButton: PropTypes.node // Add this prop type
};

export default React.memo(EnhancedProfileBanner);