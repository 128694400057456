import React, { useState, useRef } from 'react';
import { Mic, MicOff } from 'lucide-react';
import API from '../services/api';
import '../styles/VoiceInput.css';

const VoiceInput = ({ onVoiceInput, isLoading, conversationId, context, disabled = false }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  // Function to get supported MIME type
  const getSupportedMimeType = () => {
    const types = [
      'audio/webm',
      'audio/mp4',
      'audio/aac',
      'audio/ogg',
      'audio/wav'
    ];

    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    return null;
  };

  const startRecording = async () => {
    if (disabled) {
      setError('Daily message limit reached');
      return;
    }
    
    try {
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error('No supported audio MIME type found on this device');
      }

      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: mimeType
      });
      
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunks.current, { type: mimeType });
          const response = await API.voice.transcribeAudio(audioBlob, conversationId, context);
          
          if (response && response.transcription) {
            onVoiceInput(response.transcription);
          } else {
            setError('No transcription received. Please try again.');
          }
        } catch (error) {
          console.error('Error processing voice input:', error);
          setError('Error processing voice input: ' + (error.message || 'Unknown error'));
        }
      };

      mediaRecorder.current.start();
      setIsRecording(true);

    } catch (error) {
      console.error('Error starting recording:', error);
      if (error.name === 'NotAllowedError') {
        setError('Microphone access denied. Please allow microphone access in your browser settings.');
      } else if (error.name === 'NotFoundError') {
        setError('No microphone found. Please connect a microphone and try again.');
      } else {
        setError(error.message || 'Error accessing microphone');
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      if (mediaRecorder.current.stream) {
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      }
    }
  };

  return (
    <div className="voice-input-container">
      <button
        className={`voice-input-button ${isRecording ? 'recording' : ''}`}
        onClick={isRecording ? stopRecording : startRecording}
        disabled={isLoading || disabled}
        type="button"
        aria-label={isRecording ? "Stop recording" : "Start recording"}
        title={isRecording ? "Stop recording" : "Start recording"}
      >
        {isRecording ? <MicOff size={20} /> : <Mic size={20} />}
      </button>
      {error && (
        <div className="voice-input-error">
          {error}
          {error.includes('microphone access') && (
            <div className="error-help-text">
              Please check your browser settings and ensure microphone access is allowed for this site.
            </div>
          )}
        </div>
      )}
      {isRecording && <div className="recording-indicator">Recording...</div>}
    </div>
  );
};

export default VoiceInput;