import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

const GlassLoadingContainer = styled(Paper)(({ theme }) => ({
  background: 'rgba(15, 23, 42, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  padding: theme.spacing(4),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  width: '100%',
  maxWidth: '500px',
}));

const LoadingItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  '&.completed': {
    color: theme.palette.success.main,
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  height: 10,
  borderRadius: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
    borderRadius: 5,
  },
}));

const GradientCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#FF1B6B' : '#FF930F',
}));

const AdminDashboardLoader = ({ onLoadComplete, actualLoading = true }) => {
  const [loadingSteps, setLoadingSteps] = useState([
    { id: 'stats', label: 'Loading dashboard statistics', completed: false },
    { id: 'attendance', label: 'Fetching attendance reviews', completed: false },
    { id: 'feedback', label: 'Retrieving student feedback', completed: false },
    { id: 'holds', label: 'Processing course holds', completed: false },
    { id: 'notices', label: 'Loading notices', completed: false },
    { id: 'leave', label: 'Checking leave requests', completed: false },
    { id: 'trainers', label: 'Retrieving trainer information', completed: false },
  ]);
  
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [allCompleted, setAllCompleted] = useState(false);
  
  // Track when data has actually loaded
  const [dataLoaded, setDataLoaded] = useState(false);

  // Effect to handle real data loading completion
  useEffect(() => {
    if (!actualLoading && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [actualLoading]);

  // Fast forward animation when actual data is loaded
  useEffect(() => {
    let timer;
    
    if (dataLoaded && progress < 100) {
      // Speed up the completion of the loading animation
      timer = setInterval(() => {
        setProgress(oldProgress => {
          const increment = dataLoaded ? 8 : Math.random() * 5;
          const newProgress = Math.min(oldProgress + increment, 100);
          
          // Complete all remaining steps faster
          const completedCount = loadingSteps.filter(step => step.completed).length;
          const totalSteps = loadingSteps.length;
          
          const stepsToComplete = Math.floor((newProgress / 100) * totalSteps) - completedCount;
          
          for (let i = 0; i < stepsToComplete; i++) {
            if (completedCount + i < totalSteps) {
              completeStep(completedCount + i);
            }
          }
          
          if (newProgress >= 100 && !allCompleted) {
            setAllCompleted(true);
          }
          
          return newProgress;
        });
      }, 200);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [dataLoaded, progress, loadingSteps]);

  // Simulate loading progress when actual loading is still happening
  useEffect(() => {
    if (dataLoaded) return; // Skip if data is already loaded
    
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // Slower progress when waiting for real data
        const newProgress = Math.min(oldProgress + Math.random() * 3, 90); // Cap at 90% until real data loads
        
        // Update steps based on progress
        if (oldProgress < 15 && newProgress >= 15) {
          completeStep(0); // Stats
        } else if (oldProgress < 30 && newProgress >= 30) {
          completeStep(1); // Attendance
        } else if (oldProgress < 45 && newProgress >= 45) {
          completeStep(2); // Feedback
        } else if (oldProgress < 60 && newProgress >= 60) {
          completeStep(3); // Holds
        } else if (oldProgress < 75 && newProgress >= 75) {
          completeStep(4); // Notices
        } else if (oldProgress < 85 && newProgress >= 85) {
          completeStep(5); // Leave
        }
        
        return newProgress;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [dataLoaded]);
  
  // Handle final transition once all steps are complete
  useEffect(() => {
    if (allCompleted) {
      // Ensure minimum display time of 1 second for completed state before fading out
      const timer = setTimeout(() => {
        setShowLoader(false);
        // Give time for fade out animation before notifying parent
        setTimeout(() => {
          if (onLoadComplete) onLoadComplete();
        }, 500);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [allCompleted, onLoadComplete]);

  const completeStep = (index) => {
    setLoadingSteps(steps => {
      const newSteps = [...steps];
      newSteps[index] = { ...newSteps[index], completed: true };
      return newSteps;
    });
    setCurrentStep(index + 1);
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh"
      sx={{ background: 'rgb(15, 23, 42)' }}
    >
      <Fade in={showLoader} timeout={500}>
        <GlassLoadingContainer elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
            <GradientCircularProgress size={60} thickness={4} />
            <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
              Loading Admin Dashboard
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, opacity: 0.8 }}>
              Please wait while we gather your data
            </Typography>
          </Box>

          <StyledLinearProgress variant="determinate" value={progress} />
          
          <Typography variant="body2" sx={{ textAlign: 'right', mb: 2 }}>
            {Math.round(progress)}% Complete
          </Typography>

          <List>
            {loadingSteps.map((step, index) => (
              <LoadingItem 
                key={step.id} 
                className={step.completed ? 'completed' : ''}
              >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {step.completed ? (
                    <CheckCircleIcon sx={{ color: 'success.main' }} />
                  ) : (
                    index === currentStep ? (
                      <GradientCircularProgress size={20} thickness={4} />
                    ) : (
                      <PendingIcon sx={{ color: 'text.secondary', opacity: 0.5 }} />
                    )
                  )}
                </ListItemIcon>
                <ListItemText 
                  primary={step.label}
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    style: { 
                      fontWeight: index === currentStep && !step.completed ? 'bold' : 'normal',
                      opacity: step.completed ? 1 : (index > currentStep ? 0.6 : 1)
                    }
                  }}
                />
              </LoadingItem>
            ))}
          </List>
        </GlassLoadingContainer>
      </Fade>
    </Box>
  );
};

export default AdminDashboardLoader;