import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Chip,
  IconButton,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Assignment as AssignmentIcon,
  InsertDriveFile as FileIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { listAssignments, createAssignmentResponse, } from '../api';

// Styled Components
const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  padding: '8px 16px',
  borderRadius: '20px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  }
}));

const GlassBox = styled(Paper)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '10px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const AssignmentManagement = ({ open, onClose, studentId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [response, setResponse] = useState({
    content: '',
    files: []
  });

  // Helper function to get file extension
  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
  };

  useEffect(() => {
    const fetchAssignments = async () => {
      setLoading(true);
      try {
        const response = await listAssignments({ student: studentId });
        setAssignments(response.data);
      } catch (err) {
        console.error('Error fetching assignments:', err);
        setError('Failed to fetch assignments');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchAssignments();
    }
  }, [open, studentId]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setResponse(prev => ({
      ...prev,
      files: [...prev.files, ...files]
    }));
  };

  const handleRemoveFile = (index) => {
    setResponse(prev => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index)
    }));
  };

  const handleSubmitResponse = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('content', response.content);
      formData.append('assignment', selectedAssignment.id);
      
      response.files.forEach((file, index) => {
        formData.append('uploaded_files', file);
        formData.append('file_types', getFileExtension(file.name));
      });

      await createAssignmentResponse(formData);
      
      // Refresh assignments
      const updatedAssignments = await listAssignments({ student: studentId });
      setAssignments(updatedAssignments.data);
      
      // Reset form
      setResponse({ content: '', files: [] });
      setSelectedAssignment(null);
      
    } catch (err) {
      console.error('Error submitting response:', err);
      setError('Failed to submit response');
    } finally {
      setLoading(false);
    }
  };

  const renderPendingAssignments = () => (
    <List>
      {assignments
        .filter(assignment => assignment.status === 'ACTIVE' && !assignment.responses.some(r => r.student.id === studentId))
        .map(assignment => (
          <GlassBox key={assignment.id}>
            <Typography variant="h6">{assignment.title}</Typography>
            <Typography variant="body2" color="textSecondary">
              Due: {dayjs(assignment.expires_at).format('MMM D, YYYY HH:mm')}
            </Typography>
            <Typography>{assignment.description}</Typography>
            {assignment.files?.length > 0 && (
              <Box mt={1}>
                <Typography variant="subtitle2">Attachments:</Typography>
                {assignment.files.map((file, index) => (
                  <Chip
                    key={index}
                    label={`File ${index + 1}`}
                    onClick={() => window.open(file.file, '_blank')}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            )}
            <GradientButton 
              onClick={() => setSelectedAssignment(assignment)}
              sx={{ mt: 1 }}
            >
              Submit Response
            </GradientButton>
          </GlassBox>
        ))}
    </List>
  );

  const renderSubmittedAssignments = () => (
    <List>
      {assignments
        .filter(assignment => assignment.responses.some(r => r.student.id === studentId))
        .map(assignment => (
          <GlassBox key={assignment.id}>
            <Typography variant="h6">{assignment.title}</Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Submitted: {dayjs(assignment.responses[0].submitted_at).format('MMM D, YYYY HH:mm')}
            </Typography>
            <Typography>{assignment.responses[0].content}</Typography>
            
            {assignment.responses[0].files?.length > 0 && (
              <Box mt={1}>
                <Typography variant="subtitle2">Your Attachments:</Typography>
                {assignment.responses[0].files.map((file, index) => (
                  <Chip
                    key={index}
                    label={`Response File ${index + 1}`}
                    onClick={() => window.open(file.file, '_blank')}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            )}
  
            <Box mt={2}>
              {assignment.responses[0].feedback && (
                <Box sx={{ 
                  bgcolor: 'rgba(76, 175, 80, 0.1)', 
                  p: 2, 
                  borderRadius: 1,
                  border: '1px solid rgba(76, 175, 80, 0.2)',
                  mb: 2
                }}>
                  <Typography variant="subtitle2" color="#4CAF50" gutterBottom>
                    Trainer Feedback
                  </Typography>
                  {assignment.responses[0].score && (
                    <Typography variant="body2" color="#4CAF50" gutterBottom>
                      Score: {assignment.responses[0].score}/100
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ color: 'white', mt: 1 }}>
                    {assignment.responses[0].feedback}
                  </Typography>
                  {assignment.responses[0].feedback_by_name && (
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)', mt: 1, display: 'block' }}>
                      By: {assignment.responses[0].feedback_by_name}
                    </Typography>
                  )}
                </Box>
              )}
              <Chip 
                label={assignment.status}
                color={assignment.status === 'ACTIVE' ? 'primary' : 'default'}
              />
              {assignment.responses[0].feedback && (
                <Chip 
                  label="Feedback Received"
                  color="success"
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          </GlassBox>
        ))}
    </List>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          background: 'rgba(40, 40, 40, 1.1)',
          backdropFilter: 'blur(10px)',
          color: 'white'
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <AssignmentIcon sx={{ mr: 1 }} />
          Assignments
        </Box>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{ mb: 2 }}
        >
          <Tab label="Pending" />
          <Tab label="Submitted" />
        </Tabs>

        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : activeTab === 0 ? (
          renderPendingAssignments()
        ) : (
          renderSubmittedAssignments()
        )}

        {/* Response Submission Dialog */}
        <Dialog
          open={Boolean(selectedAssignment)}
          onClose={() => setSelectedAssignment(null)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              background: 'rgba(40, 40, 40, 1.1)',
              color: 'white'
            }
          }}
        >
          <DialogTitle>Submit Response</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              {selectedAssignment?.title}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Your Response"
              value={response.content}
              onChange={(e) => setResponse(prev => ({ ...prev, content: e.target.value }))}
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white' } }}
            />
            <input
              type="file"
              multiple
              id="response-files"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="response-files">
              <GradientButton
                component="span"
                startIcon={<CloudUploadIcon />}
                sx={{ mt: 1 }}
              >
                Upload Files
              </GradientButton>
            </label>
            <List>
              {response.files.map((file, index) => (
                <ListItem key={index}>
                  <FileIcon sx={{ mr: 1 }} />
                  <ListItemText primary={file.name} />
                  <IconButton onClick={() => handleRemoveFile(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <GradientButton
              onClick={() => setSelectedAssignment(null)}
            >
              Cancel
            </GradientButton>
            <GradientButton
              onClick={handleSubmitResponse}
              disabled={loading || !response.content}
            >
              Submit Response
            </GradientButton>
          </Box>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default AssignmentManagement;