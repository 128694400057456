import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  LinearProgress,
  Alert,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';
import { 
  listCompetitions, 
  getCompetitionLeaderboard,
  getCompetitionAttempts
} from '../api';
import { TimerOutlined, EmojiEventsOutlined, HistoryOutlined } from '@mui/icons-material';
import CompetitionLeaderboard from '../components/CompetitionLeaderboard';
import { getCurrentUser } from '../api';

const BannerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(4),
  borderRadius: '20px',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    display: 'block',
    
    maxHeight: '350px', // Desktop max height
    [theme.breakpoints.down('sm')]: {
      maxHeight: '150px', // Mobile max height
    },
  }
}));

// Styled components remain the same...
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  }
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const StudentCompetitionsPage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [attempts, setAttempts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leaderboards, setLeaderboards] = useState([]);
  const [leaderboardPages, setLeaderboardPages] = useState({});
  const [collapsedLeaderboards, setCollapsedLeaderboards] = useState(new Set()); // Changed to track collapsed instead of expanded
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentUser, setCurrentUser] = useState(null);

  // Separate function to fetch leaderboard data
  const fetchLeaderboards = async (competitions) => {
    try {
      // Filter active competitions
      const activeCompetitions = competitions.filter(comp => comp.is_active);
      
      // Fetch leaderboards for active competitions
      const leaderboardData = await Promise.all(
        activeCompetitions.map(async (comp) => {
          try {
            const response = await getCompetitionLeaderboard(comp.id);
            return {
              competition_id: comp.id,
              entries: response.data
            };
          } catch (error) {
            console.error(`Error fetching leaderboard for competition ${comp.id}:`, error);
            return {
              competition_id: comp.id,
              entries: []
            };
          }
        })
      );
      
      setLeaderboards(leaderboardData);
    } catch (error) {
      console.error('Error fetching leaderboards:', error);
    }
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await getCurrentUser();
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };
  
    fetchCurrentUser();
  }, []);

  // Fetch competitions and attempts
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // Fetch competitions
      const competitionsResponse = await listCompetitions({ is_active: true });
      const competitionsData = competitionsResponse.data;
      setCompetitions(competitionsData);

      // Fetch leaderboards
      await fetchLeaderboards(competitionsData);

      // Fetch attempts
      const attemptsData = {};
      await Promise.all(
        competitionsData.map(async (comp) => {
          try {
            const attemptsResponse = await getCompetitionAttempts(comp.id);
            attemptsData[comp.id] = attemptsResponse.data;
          } catch (error) {
            console.error(`Error fetching attempts for competition ${comp.id}:`, error);
            attemptsData[comp.id] = [];
          }
        })
      );
      setAttempts(attemptsData);

    } catch (err) {
      setError('Failed to load competitions. Please try again later.');
      console.error('Error fetching competitions:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Toggle leaderboard visibility
  const handleLeaderboardToggle = async (competitionId, page = 1) => {
    try {
      const response = await getCompetitionLeaderboard(competitionId, { page });
      setLeaderboards(prev => {
        const filtered = prev.filter(lb => lb.competition_id !== competitionId);
        return [...filtered, {
          competition_id: competitionId,
          entries: response.data,
          currentPage: page
        }];
      });
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  
    
  };

  const handleLeaderboardPageChange = async (competitionId, newPage) => {
    try {
      const response = await getCompetitionLeaderboard(competitionId, { 
        page: newPage,
        page_size: 20 // Consistent with the itemsPerPage in the component
      });
      
      // Update leaderboards state with new data while preserving structure
      setLeaderboards(prev => {
        const filtered = prev.filter(lb => lb.competition_id !== competitionId);
        return [...filtered, {
          competition_id: competitionId,
          entries: response.data,
          currentPage: newPage
        }];
      });
    } catch (error) {
      console.error('Error fetching leaderboard page:', error);
    }
  };
  

  // Helper functions remain the same...
  const formatTimeRemaining = (timeRemaining) => {
    if (timeRemaining <= 0) return 'Expired';
    const days = Math.floor(timeRemaining / (24 * 3600));
    const hours = Math.floor((timeRemaining % (24 * 3600)) / 3600);
    return `${days}d ${hours}h remaining`;
  };

  const hasAttempted = (competitionId) => {
    return attempts[competitionId]?.some(attempt => attempt.completion_time);
  };

  const canAttempt = (competition) => {
    if (!competition.is_active) return false;
    if (competition.time_remaining <= 0) return false;
    return !hasAttempted(competition.id);
  };

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 3 }}>
      {/* Banner Image */}
      <BannerContainer>
        <img
          src="https://nationalinstituteoflanguage.in/wp-content/uploads/2025/02/Copy-of-Untitled-Design-1.png"
          alt="Competition Banner"
          style={{
            borderRadius: theme.shape.borderRadius,
          }}
        />
      </BannerContainer>

      {/* Rest of the page content */}
      <Typography variant="h4" gutterBottom sx={{ mb: 4, color: 'white' }}>
        Competitions
      </Typography>
      <Grid container spacing={3}>
        {competitions.map((competition) => {
          const attempted = hasAttempted(competition.id);
          const isAvailable = canAttempt(competition);
          const isLeaderboardCollapsed = collapsedLeaderboards.has(competition.id); // Changed to check for collapsed state
          const competitionLeaderboard = leaderboards.find(
            lb => lb.competition_id === competition.id
          );

          return (
            <Grid item xs={12} key={competition.id}>
              <Box sx={{ mb: 3 }}>
                <GlassCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom color="white">
                      {competition.title}
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      {competition.description}
                    </Typography>

                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <TimerOutlined sx={{ color: 'white' }} />
                      <Typography variant="body2" color="white">
                        Time Limit: {competition.time_limit} minutes
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <HistoryOutlined sx={{ color: 'white' }} />
                      <Typography variant="body2" color="white">
                        {formatTimeRemaining(competition.time_remaining)}
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Chip 
                        label={attempted ? 'Attempted' : 'Not Attempted'} 
                        color={attempted ? 'success' : 'default'}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <Chip 
                        label={`${competition.total_questions} Questions`}
                        size="small"
                      />
                    </Box>

                    {attempts[competition.id]?.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="white">
                          Your Score: {attempts[competition.id][0].score}
                        </Typography>
                        <Typography variant="body2" color="white">
                          Time Taken: {attempts[competition.id][0].duration}
                        </Typography>
                      </Box>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                      <GradientButton
                        onClick={() => navigate(`/competitions/${competition.id}/quiz`)}
                        disabled={!isAvailable}
                      >
                        {attempted ? 'Already Attempted' : 'Start Quiz'}
                      </GradientButton>
                      
                      <GradientButton
                        onClick={() => handleLeaderboardToggle(competition.id)}
                        startIcon={<EmojiEventsOutlined />}
                      >
                        {!isLeaderboardCollapsed ? 'Hide Leaderboard' : 'Show Leaderboard'}
                      </GradientButton>
                    </Box>
                  </CardContent>
                </GlassCard>

                {/* Leaderboard component - shown by default unless collapsed */}
                {!isLeaderboardCollapsed && competitionLeaderboard && (
                  <Box sx={{ mt: 2 }}>
                    <CompetitionLeaderboard
  leaderboardData={competitionLeaderboard.entries}
  competition={competition}
  currentUser={currentUser}
  onPageChange={(newPage) => handleLeaderboardPageChange(competition.id, newPage)}
  currentPage={competitionLeaderboard.currentPage || 1}
  totalPages={Math.ceil(competitionLeaderboard.entries.count / 20)}
  loading={loading}
/>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}

        {competitions.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              No active competitions available at the moment.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StudentCompetitionsPage;