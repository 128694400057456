import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress
} from '@mui/material';
import { getGSTInputMonthlyReport, parseGSTInputReportHeaders, downloadBlob } from '../api';

const GSTInputReportDialog = ({ open, onClose, onSuccess }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Generate year options (past 5 years to current year)
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let y = currentYear; y >= currentYear - 5; y--) {
    yearOptions.push(y);
  }
  
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  
  const handleGenerateReport = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await getGSTInputMonthlyReport(year, month);
      
      // Get stats from headers
      const stats = parseGSTInputReportHeaders(response);
      
      // Create a filename for the downloaded file
      const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });
      const filename = `gst_input_report_${year}_${monthName}.zip`;
      
      // Download the file
      downloadBlob(response.data, filename);
      
      // Call the success callback with stats
      if (onSuccess) {
        onSuccess(stats);
      }
      
      onClose();
    } catch (error) {
      console.error('Error generating GST input report:', error);
      setError(error.response?.data?.error || 'Failed to generate GST input report. There may be no data for the selected period.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate GST Input Report</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                labelId="year-label"
                value={year}
                onChange={handleYearChange}
                label="Year"
              >
                {yearOptions.map(y => (
                  <MenuItem key={y} value={y}>{y}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="month-label">Month</InputLabel>
              <Select
                labelId="month-label"
                value={month}
                onChange={handleMonthChange}
                label="Month"
              >
                {[
                  { value: 1, label: 'January' },
                  { value: 2, label: 'February' },
                  { value: 3, label: 'March' },
                  { value: 4, label: 'April' },
                  { value: 5, label: 'May' },
                  { value: 6, label: 'June' },
                  { value: 7, label: 'July' },
                  { value: 8, label: 'August' },
                  { value: 9, label: 'September' },
                  { value: 10, label: 'October' },
                  { value: 11, label: 'November' },
                  { value: 12, label: 'December' }
                ].map(m => (
                  <MenuItem key={m.value} value={m.value}>{m.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleGenerateReport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Generate Report'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GSTInputReportDialog;