import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Paper,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AssessmentOutlined as ReportIcon } from '@mui/icons-material';
import { getCompanyTaxSummary } from '../api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'medium',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(30, 41, 59, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(2),
}));

const TaxSummaryComponent = () => {
  const [loading, setLoading] = useState(false);
  const [taxSummary, setTaxSummary] = useState(null);
  const [error, setError] = useState(null);

  // Get current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentMonthName = new Date().toLocaleString('default', { month: 'long' });

  const fetchTaxSummary = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCompanyTaxSummary(currentYear, currentMonth);
      setTaxSummary(response.data);
    } catch (error) {
      console.error('Error fetching tax summary:', error);
      setError(error.response?.data?.error || 'Failed to fetch tax summary');
      setTaxSummary(null);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchTaxSummary();
  }, []);

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <ReportIcon sx={{ mr: 1 }} />
          <Typography variant="h6">
            Company Tax Summary - {currentMonthName} {currentYear}
          </Typography>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={24} />
          </Box>
        ) : error ? (
          <Typography color="error" variant="body2" sx={{ py: 1 }}>
            No tax data available for current month
          </Typography>
        ) : taxSummary ? (
          <>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ p: 1, bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Total Invoices
                  </Typography>
                  <Typography variant="h6">
                    {taxSummary.summary.total_invoices}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <Box sx={{ p: 1, bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Amount (Excl. Tax)
                  </Typography>
                  <Typography variant="h6">
                    ₹{taxSummary.summary.total_amount_without_tax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <Box sx={{ p: 1, bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Tax Amount
                  </Typography>
                  <Typography variant="h6">
                    ₹{taxSummary.summary.total_tax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {taxSummary.tax_rates && Object.keys(taxSummary.tax_rates).length > 0 && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Tax Rate Breakdown
                </Typography>
                <TableContainer component={Paper} sx={{ borderRadius: 1, bgcolor: 'background.paper' }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Rate</StyledTableCell>
                        <StyledTableCell>Count</StyledTableCell>
                        <StyledTableCell>Amount</StyledTableCell>
                        <StyledTableCell>Tax</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(taxSummary.tax_rates).map(([rate, data]) => (
                        <StyledTableRow key={rate}>
                          <StyledTableCell>{rate}%</StyledTableCell>
                          <StyledTableCell>{data.count}</StyledTableCell>
                          <StyledTableCell>
                            ₹{data.amount.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            ₹{data.tax.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </>
        ) : (
          <Typography variant="body2" color="text.secondary" sx={{ py: 1 }}>
            No tax data available for current month
          </Typography>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default TaxSummaryComponent;