import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  Alert,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tooltip,
  Divider,
  Popover
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateSalary, handleApiError, getDetailedSalaryCalculation } from '../api';

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    maxHeight: '90vh',
  },
}));

const GlassTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.common.white,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const GlassAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: theme.palette.common.white,
  backdropFilter: 'blur(10px)',
  borderRadius: '8px !important',
  marginTop: theme.spacing(2),
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2),
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing(1),
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const SummaryBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StatBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const GlassPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    backgroundColor: 'rgba(18, 18, 18, 0.95)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(2),
    maxWidth: '400px',
  },
}));

const PotentialEarningsBreakdown = styled(Box)(({ theme }) => ({
  '& .breakdown-item': {
    padding: theme.spacing(1),
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: theme.spacing(1),
    },
  },
  '& .amount': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  '& .difference': {
    color: theme.palette.success.main,
    fontSize: '0.85em',
    marginLeft: theme.spacing(1),
  },
  '& .clickable': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: theme.spacing(1),
    },
  },
}));

const TotalCalculationBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: 'rgba(76, 175, 80, 0.1)',
  borderRadius: theme.spacing(1),
  border: '1px solid rgba(76, 175, 80, 0.2)',
}));

const PotentialAmountCell = ({ student, onSelectionChange, selectedCalculations }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  if (!student.hypothetical_amount || student.absent_count === 0) {
    return <TableCell align="right">-</TableCell>;
  }

  const potentialDifference = student.hypothetical_amount - student.amount;
  const perAbsenceValue = potentialDifference / student.absent_count;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBreakdownItemClick = (absenceCount) => {
    const key = `${student.student_name}-${absenceCount}`;
    const amount = perAbsenceValue * absenceCount;
    onSelectionChange(key, amount);
  };

  const renderPotentialBreakdown = () => {
    const breakdown = [];
    for (let i = 1; i <= student.absent_count; i++) {
      const potentialAmount = student.amount + (perAbsenceValue * i);
      const key = `${student.student_name}-${i}`;
      const isSelected = selectedCalculations.hasOwnProperty(key);
      
      breakdown.push(
        <Box 
          key={i} 
          className={`breakdown-item clickable ${isSelected ? 'selected' : ''}`}
          onClick={() => handleBreakdownItemClick(i)}
        >
          <Typography variant="body2">
            If {i} {i === 1 ? 'absence' : 'absences'} recovered:
            <span className="amount">₹{potentialAmount.toFixed(2)}</span>
            <span className="difference">(+₹{(perAbsenceValue * i).toFixed(2)})</span>
          </Typography>
        </Box>
      );
    }
    return breakdown;
  };

  const calculateTotalSelected = () => {
    return Object.entries(selectedCalculations)
      .filter(([key]) => key.startsWith(student.student_name))
      .reduce((total, [_, amount]) => total + amount, 0);
  };

  return (
    <TableCell align="right">
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.light,
            textDecoration: 'underline',
          },
          transition: 'color 0.2s ease',
        }}
      >
        ₹{student.hypothetical_amount}
      </Box>
      
      <GlassPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: '300px' }}>
          <Typography variant="h6" gutterBottom sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', pb: 1 }}>
            Potential Earnings Breakdown
          </Typography>
          
          <Box sx={{ mb: 2, p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Current Amount: ₹{student.amount}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Maximum Potential: ₹{student.hypothetical_amount}
            </Typography>
            <Typography variant="body2">
              Per Absence Value: ₹{perAbsenceValue.toFixed(2)}
            </Typography>
          </Box>
          
          <PotentialEarningsBreakdown>
            {renderPotentialBreakdown()}
          </PotentialEarningsBreakdown>

          <TotalCalculationBox>
            <Typography variant="subtitle2" color="success.main">
              Selected Total: ₹{calculateTotalSelected().toFixed(2)}
            </Typography>
          </TotalCalculationBox>
        </Box>
      </GlassPopover>
    </TableCell>
  );
};

function UpdateSalaryModal({ open, onClose, trainerId, trainerName }) {
  const [salaryData, setSalaryData] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    group_class_compensation: 0,
    performance_incentive: 0,
    performance_depreciation: 0,
    arrears: 0,
    tds: 0,
    pf: 0,
    advance: 0,
    advance_recovery: 0,
    loss_recovery: 0
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [detailedCalculation, setDetailedCalculation] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedCalculations, setSelectedCalculations] = useState({});

  const handleSelectionChange = (key, amount) => {
    setSelectedCalculations(prev => {
      const newCalc = { ...prev };
      if (newCalc[key]) {
        delete newCalc[key];
      } else {
        newCalc[key] = amount;
      }
      return newCalc;
    });
  };

  const getTotalSelectedAmount = () => {
    return Object.values(selectedCalculations).reduce((sum, amount) => sum + amount, 0);
  };

  const fetchDetailedCalculation = async () => {
    if (!trainerId) return;
    
    try {
      setLoadingDetails(true);
      const response = await getDetailedSalaryCalculation(trainerId, {
        year: salaryData.year,
        month: salaryData.month
      });
      setDetailedCalculation(response.data);
    } catch (error) {
      console.error('Error fetching detailed calculation:', error);
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchDetailedCalculation();
    }
  }, [open, salaryData.year, salaryData.month]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalaryData(prev => ({
      ...prev,
      [name]: name === 'year' || name === 'month' ? parseInt(value) : parseFloat(value) || 0
    }));
  };

  const handleSubmit = async () => {
    try {
      await updateSalary(trainerId, salaryData);
      setSuccess(true);
      setError(null);
      fetchDetailedCalculation();
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'An error occurred while updating the salary.');
    }
  };

  return (
    <>
      <GradientBackground />
      <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ color: 'white' }}>Update Salary for {trainerName}</DialogTitle>
        <DialogContent>
        {error && (
            <Alert severity="error" sx={{ mb: 2, backgroundColor: 'rgba(211, 47, 47, 0.1)', color: 'white' }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2, backgroundColor: 'rgba(76, 175, 80, 0.1)', color: 'white' }}>
              Salary updated successfully!
            </Alert>
          )}

          {detailedCalculation && (
            <SummaryBox mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <StatBox>
                    <Typography variant="caption">Total Hours</Typography>
                    <Typography variant="h6">{detailedCalculation.total_hours}</Typography>
                  </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StatBox>
                    <Typography variant="caption">Base Amount</Typography>
                    <Typography variant="h6">₹{detailedCalculation.total_amount}</Typography>
                  </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StatBox>
                    <Typography variant="caption">Hourly Rate</Typography>
                    <Typography variant="h6">₹{detailedCalculation.regular_hourly_rate}</Typography>
                  </StatBox>
                </Grid>
              </Grid>
            </SummaryBox>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Year"
                name="year"
                type="number"
                value={salaryData.year}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Month"
                name="month"
                type="number"
                value={salaryData.month}
                onChange={handleInputChange}
                margin="normal"
                InputProps={{ inputProps: { min: 1, max: 12 } }}
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Group Class Compensation"
                name="group_class_compensation"
                type="number"
                value={salaryData.group_class_compensation}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Performance Incentive"
                name="performance_incentive"
                type="number"
                value={salaryData.performance_incentive}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Performance Depreciation"
                name="performance_depreciation"
                type="number"
                value={salaryData.performance_depreciation}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Arrears"
                name="arrears"
                type="number"
                value={salaryData.arrears}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="TDS"
                name="tds"
                type="number"
                value={salaryData.tds}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="PF"
                name="pf"
                type="number"
                value={salaryData.pf}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Advance"
                name="advance"
                type="number"
                value={salaryData.advance}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Advance Recovery"
                name="advance_recovery"
                type="number"
                value={salaryData.advance_recovery}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <GlassTextField
                fullWidth
                label="Loss Recovery"
                name="loss_recovery"
                type="number"
                value={salaryData.loss_recovery}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
          </Grid>

          <GlassAccordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
              <Typography>Detailed Breakdown</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loadingDetails ? (
                <Box display="flex" justifyContent="center" p={2}>
                  <CircularProgress sx={{ color: 'white' }} />
                </Box>
              ) : detailedCalculation ? (
                <>
                  <StyledTableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Student</TableCell>
                          <TableCell align="center">Present</TableCell>
                          <TableCell align="center">Comp</TableCell>
                          <TableCell align="center">Absent</TableCell>
                          <TableCell align="right">Hours</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          {detailedCalculation.student_breakdown.some(s => s.hypothetical_amount) && (
                            <TableCell align="right">Potential</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailedCalculation.student_breakdown.map((student, index) => (
                          <TableRow key={index}>
                            <TableCell>{student.student_name}</TableCell>
                            <TableCell align="center">{student.present_count}</TableCell>
                            <TableCell align="center">{student.comp_count}</TableCell>
                            <TableCell align="center">
                              <Tooltip title={student.absent_count > 2 ? 
                                `${student.extra_hours_if_present} additional hours possible` : ''
                              }>
                                <span style={{ 
                                  color: student.absent_count > 2 ? '#ff4444' : 'inherit'
                                }}>
                                  {student.absent_count}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right">{student.total_hours}</TableCell>
                            <TableCell align="right">₹{student.amount}</TableCell>
                            {detailedCalculation.student_breakdown.some(s => s.hypothetical_amount) && (
                              <PotentialAmountCell 
                                student={student}
                                onSelectionChange={handleSelectionChange}
                                selectedCalculations={selectedCalculations}
                              />
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>

                  {Object.keys(selectedCalculations).length > 0 && (
                    <TotalCalculationBox>
                      <Typography variant="h6" color="success.main" gutterBottom>
                        Total Selected Potential: ₹{getTotalSelectedAmount().toFixed(2)}
                      </Typography>
                      <Typography variant="body2" color="success.light">
                        Selected calculations:
                        {Object.entries(selectedCalculations).map(([key, amount]) => (
                          <Box key={key} sx={{ ml: 2, mt: 1 }}>
                            • {key}: ₹{amount.toFixed(2)}
                          </Box>
                        ))}
                      </Typography>
                    </TotalCalculationBox>
                  )}

                  {detailedCalculation.hypothetical_total !== detailedCalculation.total_amount && (
                    <Alert 
                      severity="info" 
                      sx={{ 
                        mt: 2,
                        backgroundColor: 'rgba(33, 150, 243, 0.1)', 
                        color: 'white',
                        '& .MuiAlert-icon': {
                          color: 'white'
                        }
                      }}
                    >
                      <Typography variant="body2">
                        Maximum potential earnings: ₹{detailedCalculation.hypothetical_total}
                        <br />
                        Total possible increase: ₹{(detailedCalculation.hypothetical_total - detailedCalculation.total_amount).toFixed(2)}
                      </Typography>
                    </Alert>
                  )}
                </>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No detailed calculation available for this period
                </Typography>
              )}
            </AccordionDetails>
          </GlassAccordion>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <GradientButton onClick={onClose}>
            Cancel
          </GradientButton>
          <GradientButton onClick={handleSubmit}>
            Update Salary
          </GradientButton>
        </DialogActions>
      </GlassDialog>
    </>
  );
}

export default UpdateSalaryModal;