import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const GSTInputBillDialog = ({ open, onClose, onSave, selectedBill, loading }) => {
  const [formData, setFormData] = useState({
    vendor_name: '',
    document_type: 'INVOICE',
    document_number: '',
    date: dayjs(),
    description: '',
    amount_excluding_tax: '',
    gst_percentage: 18.00,
    gst_amount: '',
    amount_including_tax: '',
    document_file: null
  });
  
  // File preview state
  const [filePreview, setFilePreview] = useState(null);
  
  // Reset form when dialog opens/closes or selectedBill changes
  useEffect(() => {
    if (selectedBill) {
      setFormData({
        vendor_name: selectedBill.vendor_name || '',
        document_type: selectedBill.document_type || 'INVOICE',
        document_number: selectedBill.document_number || '',
        date: selectedBill.date ? dayjs(selectedBill.date) : dayjs(),
        description: selectedBill.description || '',
        amount_excluding_tax: selectedBill.amount_excluding_tax || '',
        gst_percentage: selectedBill.gst_percentage || 18.00,
        gst_amount: selectedBill.gst_amount || '',
        amount_including_tax: selectedBill.amount_including_tax || '',
        document_file: null // We don't set the file here as we can't retrieve it
      });
      
      // Set file preview if document_file exists
      if (selectedBill.document_file) {
        setFilePreview(selectedBill.document_file);
      } else {
        setFilePreview(null);
      }
    } else {
      setFormData({
        vendor_name: '',
        document_type: 'INVOICE',
        document_number: '',
        date: dayjs(),
        description: '',
        amount_excluding_tax: '',
        gst_percentage: 18.00,
        gst_amount: '',
        amount_including_tax: '',
        document_file: null
      });
      setFilePreview(null);
    }
  }, [selectedBill, open]);
  
  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updated = { ...prev, [name]: value };
      
      // Auto-calculate values based on what's entered
      if (name === 'amount_excluding_tax' && value && prev.gst_percentage) {
        const amountExcl = parseFloat(value);
        const gstPercent = parseFloat(prev.gst_percentage);
        const gstAmount = (amountExcl * gstPercent / 100).toFixed(2);
        const amountIncl = (amountExcl + parseFloat(gstAmount)).toFixed(2);
        
        return {
          ...updated,
          gst_amount: gstAmount,
          amount_including_tax: amountIncl
        };
      }
      
      if (name === 'gst_percentage' && value && prev.amount_excluding_tax) {
        const amountExcl = parseFloat(prev.amount_excluding_tax);
        const gstPercent = parseFloat(value);
        const gstAmount = (amountExcl * gstPercent / 100).toFixed(2);
        const amountIncl = (amountExcl + parseFloat(gstAmount)).toFixed(2);
        
        return {
          ...updated,
          gst_amount: gstAmount,
          amount_including_tax: amountIncl
        };
      }
      
      if (name === 'gst_amount' && value && prev.amount_excluding_tax) {
        const amountExcl = parseFloat(prev.amount_excluding_tax);
        const gstAmount = parseFloat(value);
        const amountIncl = (amountExcl + gstAmount).toFixed(2);
        const gstPercent = ((gstAmount / amountExcl) * 100).toFixed(2);
        
        return {
          ...updated,
          gst_percentage: gstPercent,
          amount_including_tax: amountIncl
        };
      }
      
      if (name === 'amount_including_tax' && value && prev.gst_percentage) {
        const amountIncl = parseFloat(value);
        const gstPercent = parseFloat(prev.gst_percentage);
        const divisor = 1 + (gstPercent / 100);
        const amountExcl = (amountIncl / divisor).toFixed(2);
        const gstAmount = (amountIncl - amountExcl).toFixed(2);
        
        return {
          ...updated,
          amount_excluding_tax: amountExcl,
          gst_amount: gstAmount
        };
      }
      
      return updated;
    });
  };
  
  // Handle date change
  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, date }));
  };
  
  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, document_file: file }));
      
      // Create a preview URL for the file
      const reader = new FileReader();
      reader.onload = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  // Handle form submission
  const handleSubmit = () => {
    // Create FormData for API submission
    const apiFormData = new FormData();
    
    // Append all form fields
    Object.keys(formData).forEach(key => {
      if (key === 'date') {
        apiFormData.append(key, formData[key].format('YYYY-MM-DD'));
      } else if (key === 'document_file' && formData[key] instanceof File) {
        apiFormData.append(key, formData[key]);
      } else if (key !== 'document_file') {
        apiFormData.append(key, formData[key]);
      }
    });
    
    onSave(apiFormData, selectedBill?.id);
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {selectedBill ? 'Edit GST Input Bill' : 'Add GST Input Bill'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Vendor Name"
              name="vendor_name"
              value={formData.vendor_name}
              onChange={handleChange}
              required
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="document-type-label">Document Type</InputLabel>
              <Select
                labelId="document-type-label"
                name="document_type"
                value={formData.document_type}
                onChange={handleChange}
                label="Document Type"
              >
                <MenuItem value="INVOICE">Invoice</MenuItem>
                <MenuItem value="BILL">Bill</MenuItem>
                <MenuItem value="RECEIPT">Receipt</MenuItem>
                <MenuItem value="OTHER">Other Document</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Document Number"
              name="document_number"
              value={formData.document_number}
              onChange={handleChange}
              required
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Document Date"
                value={formData.date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth required />}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    variant: 'outlined'
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Amount (Excluding GST)"
              name="amount_excluding_tax"
              value={formData.amount_excluding_tax}
              onChange={handleChange}
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" } }}
              helperText="Enter this or Amount Including GST"
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="gst-percentage-label">GST Percentage</InputLabel>
              <Select
                labelId="gst-percentage-label"
                name="gst_percentage"
                value={formData.gst_percentage}
                onChange={handleChange}
                label="GST Percentage"
              >
                <MenuItem value={0}>0%</MenuItem>
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={28}>28%</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="GST Amount"
              name="gst_amount"
              value={formData.gst_amount}
              onChange={handleChange}
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" } }}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Amount (Including GST)"
              name="amount_including_tax"
              value={formData.amount_including_tax}
              onChange={handleChange}
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" } }}
              helperText="Enter this or Amount Excluding GST"
            />
          </Grid>
          
          <Grid item xs={12} sm={8}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ height: '56px' }}
            >
              {selectedBill ? 'Change Document File' : 'Upload Document File'}
              <input
                type="file"
                hidden
                accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                onChange={handleFileChange}
              />
            </Button>
            {filePreview && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {selectedBill && !formData.document_file 
                  ? `Current file: ${selectedBill.document_file.split('/').pop()}`
                  : `Selected file: ${formData.document_file?.name}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            !formData.vendor_name || 
            !formData.document_number || 
            !formData.date ||
            (!formData.amount_excluding_tax && !formData.amount_including_tax) ||
            loading
          }
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GSTInputBillDialog;