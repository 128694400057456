
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
  Grid,
  Avatar,
  Button,
  IconButton,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  TextField,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Menu,
  keyframes // Keep keyframes
} from '@mui/material';
import {
  ThumbUp as ThumbUpIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon,
  Comment as CommentIcon,
  MoreVert as MoreVertIcon,
  Share as ShareIcon,
  Schedule as ScheduleIcon
} from '@mui/icons-material';
import ArticleContent from '../components/ArticleContent';
import { useNavigate } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import DOMPurify from 'dompurify';
import {
  listNewsPosts,
  listNewsTopics,
  likeNewsPost,
  saveNewsPost,
  getNewsPostComments,
  commentOnNewsPost, getSavedNewsPosts
} from '../api';
const getVideoSourceType = (url) => {
  if (!url) return null;
  
  // Check for YouTube
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    return 'youtube';
  }
  
  // Check for Vimeo
  if (url.includes('vimeo.com')) {
    return 'vimeo';
  }
  
  // Check for common video file extensions
  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi'];
  if (videoExtensions.some(ext => url.toLowerCase().endsWith(ext))) {
    return 'direct';
  }
  
  // Default to trying direct embed but with warning in console
  console.warn('Unknown video URL format:', url);
  return 'unknown';
};

// Function to convert YouTube/Vimeo URLs to embed URLs
const getEmbedUrl = (url, type) => {
  if (!url) return '';
  
  if (type === 'youtube') {
    // Convert YouTube watch URLs to embed URLs
    const videoId = url.includes('youtu.be/')
      ? url.split('youtu.be/')[1].split('?')[0]
      : url.includes('v=')
        ? url.split('v=')[1].split('&')[0]
        : '';
    
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    }
  }
  
  if (type === 'vimeo') {
    // Convert Vimeo URLs to embed URLs
    const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;
    const match = url.match(vimeoRegex);
    
    if (match && match[1]) {
      return `https://player.vimeo.com/video/${match[1]}`;
    }
  }
  
  // For direct video files, return the URL as is
  return url;
};

const VideoPlayer = ({ videoUrl, thumbnailUrl, title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  
  const videoType = getVideoSourceType(videoUrl);
  const embedUrl = getEmbedUrl(videoUrl, videoType);
  
  const handleVideoLoad = () => {
    setIsLoading(false);
  };
  
  const handleVideoError = () => {
    setIsLoading(false);
    setError(true);
  };
  
  // For embedded platforms (YouTube, Vimeo)
  if (videoType === 'youtube' || videoType === 'vimeo') {
    return (
      <Box 
        sx={{
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'white' }} />
          </Box>
        )}
        
        <Box
          component="iframe"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title || "Video player"}
          onLoad={handleVideoLoad}
          onError={handleVideoError}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            borderRadius: 1,
          }}
        />
      </Box>
    );
  }
  
  // For direct video files
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      )}
      
      <Box
        component="video"
        poster={thumbnailUrl}
        controls
        preload="metadata"
        onLoadedData={handleVideoLoad}
        onError={handleVideoError}
        sx={{
          width: '100%',
          display: 'block',
          borderRadius: 1,
          backgroundColor: '#000',
          aspectRatio: '16/9',
        }}
      >
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </Box>
      
      {error && (
        <Box
          sx={{
            p: 2,
            textAlign: 'center',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <Typography variant="body2">
            Unable to load video. <a href={videoUrl} target="_blank" rel="noopener noreferrer" style={{color: '#90caf9'}}>Open in new tab</a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
// --- CommentsSection Component ---
const CommentsSection = ({ postId, onNewComment }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState('');

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const response = await getNewsPostComments(postId);
        // Handle potential variations in API response structure
        const results = response.data.results || response.data;
        setComments(Array.isArray(results) ? results : []);
      } catch (error) {
        console.error('Error fetching comments:', error);
        setComments([]); // Ensure comments is an array on error
      }
      setLoading(false);
    };

    fetchComments();
  }, [postId]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;

    try {
      const response = await commentOnNewsPost(postId, { content: commentText });
      // Ensure comments is always an array before spreading
      setComments(prevComments => [response.data, ...(Array.isArray(prevComments) ? prevComments : [])]);
      setCommentText('');
      if (onNewComment) onNewComment();
    } catch (error) {
      console.error('Error posting comment:', error);
      // Optionally show an error message to the user
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ color: 'rgba(255, 255, 255, 0.9)'}}> {/* Ensure text is visible */}
        Comments
      </Typography>

      <Box component="form" onSubmit={handleSubmitComment} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          placeholder="Add a comment..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          variant="outlined"
          size="small"
          InputProps={{
             sx: { // Style input for better visibility on dark bg
               backgroundColor: 'rgba(255, 255, 255, 0.05)',
               color: 'white',
               '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: 'rgba(255, 255, 255, 0.2)',
               },
               '&:hover .MuiOutlinedInput-notchedOutline': {
                 borderColor: 'rgba(255, 255, 255, 0.4)',
               },
             },
            endAdornment: (
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={!commentText.trim()}
                sx={{ ml: 1 }}
              >
                Post
              </Button>
            ),
          }}
          InputLabelProps={{ // Style label if needed
             style: { color: 'rgba(255, 255, 255, 0.7)' }
          }}
        />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={3}>
          <CircularProgress size={28} sx={{ color: 'white' }}/>
        </Box>
      ) : !Array.isArray(comments) || comments.length === 0 ? ( // Ensure comments is array
        <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 3, color: 'rgba(255, 255, 255, 0.7)' }}>
          No comments yet. Be the first to comment!
        </Typography>
      ) : (
        <Box>
          {comments.map((comment) => (
            <Box key={comment.id} sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.9)' }}> {/* Ensure text visible */}
              <Box display="flex">
                <Avatar
                  src={comment.user?.profile_image} // Add optional chaining
                  alt={comment.user?.full_name} // Add optional chaining
                  sx={{ width: 32, height: 32, mr: 1.5 }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle2" component="span">
                      {comment.user?.full_name || 'User'} {/* Add fallback */}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistance(new Date(comment.created_at), new Date(), { addSuffix: true })}
                    </Typography>
                  </Box>
                  <Typography variant="body2">{comment.content}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const ImageViewer = ({ imageSrc, altText, caption }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  
  const handleImageLoad = () => {
    setLoading(false);
  };
  
  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };
  
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  
  return (
    <Box 
      sx={{ 
        position: 'relative',
        cursor: expanded ? 'zoom-out' : 'zoom-in',
        width: '100%',
        transition: 'all 0.3s ease',
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }}
      onClick={toggleExpand}
    >
      {loading && (
        <Box 
          sx={{
            height: '300px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }}
        >
          <CircularProgress size={40} sx={{ color: 'white' }} />
        </Box>
      )}
      
      {error ? (
        <Box 
          sx={{
            height: '200px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: 'rgba(255, 255, 255, 0.7)',
            p: 2
          }}
        >
          <Typography variant="body1" gutterBottom>
            Unable to load image
          </Typography>
          <Typography variant="caption">
            {altText || 'Image'}
          </Typography>
        </Box>
      ) : (
        <CardMedia
          component="img"
          image={imageSrc}
          alt={altText || 'Image'}
          onLoad={handleImageLoad}
          onError={handleImageError}
          sx={{ 
            width: '100%',
            display: loading ? 'none' : 'block',
            objectFit: expanded ? 'contain' : 'contain',
            maxHeight: expanded ? '80vh' : '500px',
            transition: 'all 0.3s ease',
            backgroundColor: expanded ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
            zIndex: expanded ? 5 : 'auto',
            borderRadius: 1
          }}
        />
      )}
      
      {!loading && !error && expanded && (
        <Box 
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            p: 0.5,
            borderRadius: 1,
            fontSize: '0.75rem',
          }}
        >
          Click to zoom out
        </Box>
      )}
      
      {caption && !expanded && (
        <Box 
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            fontSize: '0.875rem',
            maxHeight: '60px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: loading ? 'none' : 'block',
          }}
        >
          {caption}
        </Box>
      )}
    </Box>
  );
};


// --- PostCard Component ---
const PostCard = ({ post, onLike, onSave }) => {
  const [showComments, setShowComments] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const toggleComments = () => setShowComments(!showComments);

  // Function to get post content based on post_type
  // Updated getPostContent function to integrate with the new ArticleContent component
// Add this import at the top of your file:
// import ArticleContent from './ArticleContent'; // If in separate file
// Or use the component directly if defined in the same file

// Function to get post content based on post_type
const getPostContent = () => {
  switch(post.post_type) {
    case 'ARTICLE':
      return (
        <>
          {post.article && (
            <ArticleContent 
              article={post.article} 
              title={post.title}
            />
          )}
        </>
      );

    case 'VIDEO':
      return (
        <>
          {post.video && (
            <>
              <CardContent>
                {/* Display video duration */}
                {post.video.duration && (
                  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <ScheduleIcon fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      {post.video.duration}
                    </Typography>
                  </Box>
                )}
                
                {/* Use our VideoPlayer component */}
                {post.video.video_url && (
                  <Box sx={{ mb: 2 }}>
                    <VideoPlayer 
                      videoUrl={post.video.video_url}
                      thumbnailUrl={post.video.thumbnail}
                      title={post.title}
                    />
                  </Box>
                )}
                
                {/* Only show thumbnail if no video URL is available */}
                {!post.video.video_url && post.video.thumbnail && (
                  <Box sx={{ mb: 2 }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={post.video.thumbnail}
                      alt={post.title}
                      sx={{
                        objectFit: 'cover',
                        borderRadius: 1
                      }}
                    />
                  </Box>
                )}
              </CardContent>
            </>
          )}
        </>
      );

    case 'IMAGE':
      return (
        <>
          {post.image?.image && (
            <Box sx={{ width: '100%', mb: post.image?.caption ? 0 : 2 }}>
              <ImageViewer 
                imageSrc={post.image.image}
                altText={post.title}
                caption={post.image?.caption}
              />
            </Box>
          )}
            
          {post.image?.caption && (
            <CardContent>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)', 
                  fontStyle: 'italic',
                  mt: -1, // Negative margin to reduce space between image and caption
                  mb: 1
                }}
              >
                {post.image.caption}
              </Typography>
            </CardContent>
          )}
        </>
      );

    case 'LINK':
      return (
        <>
          {post.link && (
            <CardContent>
              {post.link.url && (
                <Typography 
                  variant="body2" 
                  color="primary"
                  component="a"
                  href={post.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mb: 1, wordBreak: 'break-all', display: 'block' }}
                >
                  {post.link.url}
                </Typography>
              )}
              
              {post.link.description && (
                <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)', mt: 1 }}>
                  {post.link.description}
                </Typography>
              )}
            </CardContent>
          )}
          
          {post.link?.preview_image && (
            <CardMedia
              component="img"
              height="200"
              image={post.link.preview_image}
              alt={post.title}
              sx={{ objectFit: 'cover' }}
            />
          )}
        </>
      );

    default:
      return (
        <CardContent>
          <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
            No content available for this post type.
          </Typography>
        </CardContent>
      );
  }
};

 return (
   <Card
    elevation={3}
    sx={{
      mb: 3,
      borderRadius: 2,
      overflow: 'visible', // Keep visible for potential menu overlaps
      background: 'rgba(30, 41, 59, 0.8)', // Slightly more opaque card background
      backdropFilter: 'blur(8px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      color: 'white' // Ensure default text color is white
    }}
   >
     <CardContent sx={{ pb: 1 }}>
       <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
         <Box sx={{ display: 'flex', alignItems: 'center' }}>
           <Avatar
            src={post.created_by?.profile_image} // Optional chaining
            alt={post.created_by?.full_name} // Optional chaining
            sx={{ mr: 1.5 }}
          />
           <Box>
            <Typography variant="subtitle1" component="div">
              {post.created_by?.full_name || 'Unknown User'} {/* Fallback */}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {formatDistance(new Date(post.created_at), new Date(), { addSuffix: true })}
            </Typography>
           </Box>
         </Box>
         <Box>
          {post.topic && ( // Check if topic exists
            <Chip
              label={post.topic.name}
              size="small"
              sx={{ mr: 1, backgroundColor: 'rgba(59, 130, 246, 0.2)', color: '#90caf9' }}
            />
          )}
          <IconButton
            size="small"
            onClick={handleMenuOpen}
            sx={{ color: 'white' }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{ // Style menu for dark mode
                sx: {
                    backgroundColor: '#2d3748', // Dark background for menu
                    color: 'white'
                }
            }}
          >
            <MenuItem onClick={handleMenuClose}>Copy link</MenuItem>
            <MenuItem onClick={handleMenuClose}>Report post</MenuItem>
          </Menu>
         </Box>
       </Box>
       <Typography variant="h6" component="h2" gutterBottom sx={{ color: 'white' }}>
        {post.title}
      </Typography>
     </CardContent>

     {getPostContent()}

     <CardActions sx={{ px: 2, py: 1, justifyContent: 'space-between' }}>
       <Box>
        <IconButton
          onClick={() => onLike(post.id)}
          sx={{ color: post.is_liked ? '#f48fb1' : 'white' }} // Pink when liked
        >
          {post.is_liked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
        </IconButton>
        <Typography variant="body2" component="span" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          {post.likes_count > 0 && post.likes_count}
        </Typography>
        <IconButton onClick={toggleComments} sx={{ ml: 1, color: 'white' }}>
          <CommentIcon />
        </IconButton>
        <Typography variant="body2" component="span" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          {post.comments_count > 0 && post.comments_count}
        </Typography>
       </Box>
       <Box>
        <IconButton
          onClick={() => onSave(post.id)}
          sx={{ color: post.is_saved ? '#f48fb1' : 'white' }} // Pink when saved
        >
          {post.is_saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </IconButton>
        <IconButton sx={{ color: 'white' }}>
          <ShareIcon />
        </IconButton>
       </Box>
     </CardActions>

     {showComments && (
      <Box sx={{ px: 2, pb: 2 }}>
        <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.2)' }}/>
        <CommentsSection
          postId={post.id}
          onNewComment={() => {
            // Refresh likes/comments count subtly after new comment
             if (onLike) onLike(post.id, true); // Pass true to indicate refresh only
          }}
        />
      </Box>
     )}
   </Card>
 );
};

// --- Keyframes for Sphere Movement ---
const moveSphere1 = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 0.1; }
  50% { transform: translate(100px, 200px) scale(1.2); opacity: 0.3; }
  100% { transform: translate(0, 0) scale(1); opacity: 0.1; }
`;

const moveSphere2 = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 0.2; }
  50% { transform: translate(-150px, -100px) scale(0.8); opacity: 0.05; }
  100% { transform: translate(0, 0) scale(1); opacity: 0.2; }
`;

const moveSphere3 = keyframes`
  0% { transform: translate(0, 0) scale(1.1); opacity: 0.25; }
  50% { transform: translate(50px, -180px) scale(1); opacity: 0.1; }
  100% { transform: translate(0, 0) scale(1.1); opacity: 0.25; }
`;

const moveSphere4 = keyframes`
  0% { transform: translate(0, 0) scale(0.9); opacity: 0.15; }
  50% { transform: translate(-80px, 120px) scale(1.1); opacity: 0.3; }
  100% { transform: translate(0, 0) scale(0.9); opacity: 0.15; }
`;


// --- Animated Background Component ---
const AnimatedBackground = () => {
  const sphereBaseStyles = {
    position: 'absolute',
    borderRadius: '50%',
    filter: 'blur(40px)', // Add blur for a softer effect
    pointerEvents: 'none', // Prevent interaction
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1, // Ensure it's behind everything
        overflow: 'hidden', // Prevent spheres going 'outside' the viewport bounds visually
        bgcolor: '#0f172a', // Dark base background color
      }}
    >
      {/* Sphere 1 */}
      <Box
        sx={{
          ...sphereBaseStyles,
          width: { xs: '150px', sm: '250px', md: '350px' }, // Responsive size
          height: { xs: '150px', sm: '250px', md: '350px' },
          background: 'rgba(59, 130, 246, 0.2)', // Blueish
          top: '10%',
          left: '15%',
          animation: `${moveSphere1} 25s infinite alternate ease-in-out`,
        }}
      />
      {/* Sphere 2 */}
      <Box
        sx={{
          ...sphereBaseStyles,
          width: { xs: '100px', sm: '200px', md: '300px' },
          height: { xs: '100px', sm: '200px', md: '300px' },
          background: 'rgba(236, 72, 153, 0.15)', // Pinkish
          bottom: '15%',
          right: '20%',
          animation: `${moveSphere2} 30s infinite alternate linear`,
          animationDelay: '-5s', // Start at different point
        }}
      />
      {/* Sphere 3 */}
      <Box
        sx={{
          ...sphereBaseStyles,
          width: { xs: '120px', sm: '180px', md: '220px' },
          height: { xs: '120px', sm: '180px', md: '220px' },
          background: 'rgba(16, 185, 129, 0.1)', // Greenish
          top: '40%',
          right: '10%',
          animation: `${moveSphere3} 28s infinite alternate ease-in-out`,
          animationDelay: '-10s',
        }}
      />
       {/* Sphere 4 */}
       <Box
        sx={{
          ...sphereBaseStyles,
          width: { xs: '80px', sm: '150px', md: '200px' },
          height: { xs: '80px', sm: '150px', md: '200px' },
          background: 'rgba(249, 115, 22, 0.12)', // Orangish
          bottom: '30%',
          left: '5%',
          animation: `${moveSphere4} 35s infinite alternate ease-in-out`,
           animationDelay: '-2s',
        }}
      />
       {/* Add more spheres as desired */}
    </Box>
  );
};


// --- Main News Feed Component ---
const NewsFeedPage = () => {
  const [posts, setPosts] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all'); // 'all' for Newsfeed, 'saved' for Saved
  const [selectedTopic, setSelectedTopic] = useState('all');
  const [refreshKey, setRefreshKey] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  // Fetch topics on component mount
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await listNewsTopics();
        setTopics(response.data.results || response.data || []); // Ensure array
      } catch (error) {
        console.error('Error fetching topics:', error);
        setTopics([]); // Set empty array on error
      }
    };

    fetchTopics();
  }, []);

  // Fetch posts based on selectedTab and selectedTopic
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        let response;
        if (selectedTab === 'saved') {
          response = await getSavedNewsPosts();
        } else {
          // Fetch all post types when selectedTab is 'all'
          const params = {};
          if (selectedTopic !== 'all') {
            params.topic = selectedTopic;
          }
          response = await listNewsPosts(params);
        }
         // Handle potential variations in API response structure
        const results = response.data.results || response.data;
        setPosts(Array.isArray(results) ? results : []);

      } catch (error) {
        console.error('Error fetching posts:', error);
        setPosts([]); // Set empty array on error
        setSnackbar({ open: true, message: 'Failed to load posts.', severity: 'error' });
      }
      setLoading(false);
    };

    fetchPosts();
  }, [selectedTab, selectedTopic, refreshKey]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedTopic('all'); // Reset topic filter when changing main tab
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    // Ensure we are on the 'Newsfeed' tab when a topic is selected
    if (selectedTab === 'saved') {
       setSelectedTab('all');
    }
  };

  const handleLikePost = async (postId, refreshOnly = false) => {
    if (!refreshOnly) {
      try {
        await likeNewsPost(postId);
         // Optimistic UI update for like status
        setPosts(currentPosts => currentPosts.map(post =>
            post.id === postId
                ? { ...post, is_liked: !post.is_liked, likes_count: post.is_liked ? post.likes_count - 1 : post.likes_count + 1 }
                : post
        ));
      } catch (error) {
        console.error('Error liking post:', error);
        setSnackbar({ open: true, message: 'Error liking post.', severity: 'error' });
        return; // Don't refresh if the action failed
      }
    }
     // Optionally trigger a full refresh if needed, e.g., after a comment
     if(refreshOnly) {
        setRefreshKey(prev => prev + 1);
     }
  };

  const handleSavePost = async (postId) => {
    const originalPosts = [...posts]; // Store original state for potential revert
    const postToUpdate = posts.find(p => p.id === postId);
    if (!postToUpdate) return;

    const isCurrentlySaved = postToUpdate.is_saved;

     // Optimistic UI update
      setPosts(currentPosts => currentPosts.map(post =>
        post.id === postId ? { ...post, is_saved: !isCurrentlySaved } : post
      ));

      // If on the saved tab, optimistically remove the item
      if (selectedTab === 'saved' && isCurrentlySaved) {
         setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
      }

    try {
      await saveNewsPost(postId);

      setSnackbar({
        open: true,
        message: isCurrentlySaved ? 'Post removed from saved' : 'Post saved',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error saving post:', error);
       // Revert optimistic update on error
       setPosts(originalPosts);
      setSnackbar({
        open: true,
        message: 'Error updating saved status. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleChipTopicClick = (topicId) => {
      setSelectedTopic(topicId);
      // Switch to Newsfeed tab if currently on Saved
      if (selectedTab === 'saved') {
         setSelectedTab('all');
      }
   }

  return (
    // Outer Box: Relative positioning context for fixed background, basic padding
    <Box sx={{
        minHeight: '100vh',
        position: 'relative', // Needed for z-index layering
        bgcolor: 'transparent', // Make sure this doesn't obscure the AnimatedBackground
        pt: 3, // Add padding top
     }}>
        {/* --- Render Animated Background --- */}
        <AnimatedBackground />

       {/* Inner container for content width and higher z-index */}
       <Box sx={{
           maxWidth: 1200,
           mx: 'auto',
           px: { xs: 1, sm: 2, md: 3 },
           position: 'relative', // To ensure content is above z-index: -1 background
           zIndex: 1, // Ensure content is above the background
           color: 'white' // Set default text color here if needed
       }}>
        {/* Header */}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography variant="h4" gutterBottom sx={{ color: 'white', fontWeight: 600 }}>
             {selectedTab === 'saved' ? 'Saved Items' : 'News Feed'}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* Main Content Area */}
          <Grid item xs={12} md={8}>
            {/* Tabs */}
            <Box sx={{ mb: 3, borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.2)' }}> {/* Adjusted border color */}
               <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="inherit" // Use inherit to work with theme/override
                  indicatorColor="primary"
                  sx={{
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 500,
                      minWidth: 0,
                      px: 2,
                      color: 'rgba(255, 255, 255, 0.7)', // Tab text color
                      '&.Mui-selected': {
                        color: 'white', // Selected tab text color
                      },
                    },
                    '& .MuiTabs-indicator': { // Style indicator
                        backgroundColor: '#f48fb1' // Use pink color for indicator
                    }
                  }}
                >
                 <Tab label="Newsfeed" value="all" />
                 <Tab label="Saved" value="saved" />
               </Tabs>
             </Box>

             {/* Posts List or Loading/Empty State */}
             {loading ? (
                <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress sx={{ color: 'white' }} />
                </Box>
             ) : posts.length === 0 ? (
               <Paper
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    borderRadius: 2,
                    backgroundColor: 'rgba(30, 41, 59, 0.8)', // Slightly more opaque
                    backdropFilter: 'blur(8px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    color: 'white'
                   }}
                >
                 <Typography variant="h6" gutterBottom>
                    {selectedTab === 'saved' ? 'No Saved Posts' : 'No Posts Found'}
                 </Typography>
                 <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)'}}>
                    {selectedTab === 'saved'
                        ? "You haven't saved any posts yet."
                        : "There are no posts to display matching your current filters."}
                 </Typography>
               </Paper>
             ) : (
                posts.map(post => (
                    <PostCard
                        key={post.id}
                        post={post}
                        onLike={handleLikePost}
                        onSave={handleSavePost}
                    />
                ))
             )}
          </Grid>

          {/* Sidebar Area */}
          <Grid item xs={12} md={4}>
            <Box position="sticky" top={20}>
               {/* Topics Filter (Conditional) */}
               {selectedTab === 'all' && (
                  <Paper
                    sx={{
                        p: 3,
                        mb: 3,
                        borderRadius: 2,
                        backgroundColor: 'rgba(30, 41, 59, 0.8)',
                        backdropFilter: 'blur(8px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        color: 'white'
                     }}
                    >
                     <Typography variant="h6" gutterBottom>Topics</Typography>
                     <FormControl fullWidth variant="outlined" size="small" sx={{
                        mb: 2,
                        '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                        '& .MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                            '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                            '&.Mui-focused fieldset': { borderColor: 'primary.main' },
                            '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' } // Dropdown icon color
                        }
                       }}>
                       <InputLabel id="topic-select-label">Filter by Topic</InputLabel>
                       <Select
                          labelId="topic-select-label"
                          value={selectedTopic}
                          onChange={handleTopicChange}
                          label="Filter by Topic"
                          MenuProps={{ // Style dropdown menu
                              PaperProps: {
                                  sx: {
                                      backgroundColor: '#2d3748',
                                      color: 'white',
                                  },
                              },
                          }}
                        >
                         <MenuItem value="all">All Topics</MenuItem>
                         {topics.map(topic => (
                            <MenuItem key={topic.id} value={topic.id}>{topic.name}</MenuItem>
                         ))}
                       </Select>
                     </FormControl>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {/* Display a limited number of topic chips */}
                        {topics.slice(0, 8).map(topic => (
                            <Chip
                                key={topic.id}
                                label={topic.name}
                                clickable
                                onClick={() => handleChipTopicClick(topic.id)} // Use specific handler
                                sx={{
                                    backgroundColor: selectedTopic === topic.id ? 'primary.main' : 'rgba(59, 130, 246, 0.2)',
                                    color: selectedTopic === topic.id ? 'white' : '#90caf9',
                                    '&:hover': {
                                        backgroundColor: selectedTopic === topic.id ? 'primary.dark' : 'rgba(59, 130, 246, 0.4)',
                                    }
                                }}
                            />
                        ))}
                        {/* Add a chip to clear topic filter */}
                        {selectedTopic !== 'all' && (
                            <Chip
                               label="Clear Filter"
                               clickable
                               onClick={() => handleChipTopicClick('all')} // Set topic to 'all'
                               size="small"
                               sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                  color: '#cbd5e1',
                                  '&:hover': {
                                     backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                  }
                               }}
                            />
                         )}
                     </Box>
                  </Paper>
               )} {/* End conditional rendering of Topics */}

               {/* Recent Activity / Popular Posts */}
               <Paper
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: 'rgba(30, 41, 59, 0.8)',
                    backdropFilter: 'blur(8px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    color: 'white'
                  }}
                >
                 <Typography variant="h6" gutterBottom>
                   {selectedTab === 'saved' ? 'Recently Saved' : 'Recent Activity'}
                 </Typography>

                 {/* Show subset of current posts (either all or saved) */}
                 {posts.slice(0, 3).map(post => (
                    <Box key={post.id} sx={{ mb: 2, pb: 2, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                        <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'rgba(255, 255, 255, 0.9)' }}>
                            {post.title}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="caption" color="text.secondary">
                                {format(new Date(post.created_at), 'MMM d, yyyy')}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {post.likes_count} likes • {post.comments_count} comments
                            </Typography>
                        </Box>
                    </Box>
                 ))}
                 {/* Button to switch back to newsfeed if on saved tab */}
                 {selectedTab === 'saved' && posts.length > 0 && (
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, color: 'white', borderColor: 'rgba(255, 255, 255, 0.5)' }}
                        onClick={() => setSelectedTab('all')}
                    >
                        View Full Newsfeed
                    </Button>
                 )}
               </Paper>
            </Box>
          </Grid>
        </Grid>

        {/* Snackbar */}
        <Snackbar
            open={snackbar.open}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
           {/* Ensure Alert text is readable */}
           <Alert
                onClose={handleCloseSnackbar}
                severity={snackbar.severity}
                variant="filled" // Use filled variant for better contrast
                sx={{ width: '100%' }}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
      </Box>{/* End Inner content container */}
    </Box> // End Outer container
  );
};

export default NewsFeedPage;

