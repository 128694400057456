// src/pages/VocabHistoryPage.js
import React, { useState, useEffect } from 'react';
import { getVocabProgressHistory } from '../api'; // Ensure this API call returns *all* necessary word details
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Modal,
  TextField,
  InputAdornment,
  IconButton,CircularProgress
} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material'
import { Calendar, Search as SearchIcon } from 'lucide-react';
import '../Chat/vocab-styles.css'; // Reuse existing styles as much as possible
import dayjs from 'dayjs';


const WordDetailsModal = ({ word, onClose, blockOrder }) => {
    if (!word) return null;

    return (
      <Modal open={!!word} onClose={onClose} fullWidth maxWidth="md">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#1a1d21',
            border: '1px solid #373b40',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            width: '90%',
            maxWidth: '900px',
            maxHeight: '80vh',
            overflowY: 'auto',
            color: 'white',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
              {word.word}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="subtitle2" sx={{ color: '#8a8d91', mb: 3 }}>
            Block #{blockOrder}
          </Typography>
          

           {/* Word Details */}
           {(word.image_url || word.video_url) && (
              <div className="media-container">
                {word.image_url && (
                  <div className="media-preview">
                    <img 
                      src={word.image_url} 
                      alt={`Preview for ${word.word}`}
                      className="media-thumbnail"
                    />
                  </div>
                )}
              </div>
            )}

              <div className="definitions-container">
                <div className="content-section">
                  <h4>Hindi:</h4>
                  <p>{word.hindi_definition}</p>
                </div>
                
                <div className="content-section">
                  <h4>English:</h4>
                  <p>{word.english_definition}</p>
                </div>
                
                <div className="content-section">
                  <h4>Examples:</h4>
                  <ul className="examples-list">
                    {word.example1 && <li>{word.example1}</li>}
                    {word.example2 && <li>{word.example2}</li>}
                    {word.example3 && <li>{word.example3}</li>}
                  </ul>
                </div>
              </div>
        </Box>
      </Modal>
    );
  };

const VocabHistoryPage = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredHistory, setFilteredHistory] = useState([]);

  const handleWordClick = (word) => {
    setSelectedWord(word);
  };

  const handleCloseModal = () => {
    setSelectedWord(null);
  };

   // Function to filter history based on search query
   useEffect(() => {
    if (searchQuery) {
      const filtered = history.map(entry => ({
        ...entry,
        block: {
          ...entry.block,
          words: entry.block.words.filter(word =>
            word.word.toLowerCase().includes(searchQuery.toLowerCase()) ||
            word.hindi_definition.toLowerCase().includes(searchQuery.toLowerCase()) ||
            word.english_definition.toLowerCase().includes(searchQuery.toLowerCase())
          )
        }
      })).filter(entry => entry.block.words.length > 0); // Remove blocks with no matching words
      setFilteredHistory(filtered);
    } else {
      setFilteredHistory(history);
    }
  }, [searchQuery, history]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await getVocabProgressHistory();
        setHistory(response.data);
        setFilteredHistory(response.data)
        setLoading(false);
      } catch (err) {
        setError('Failed to load vocabulary history.');
        setLoading(false);
      }
    };

    fetchHistory();
  }, []);

    // Function to format the completion date
    const formatDate = (dateString) => {
        return dayjs(dateString).format('DD MMM YYYY, HH:mm');
      };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress sx={{ color: '#0084ff' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3} color="error.main">
        {error}
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, bgcolor: '#1a1d21', color: 'white', minHeight: '100vh' }}>
       <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
        Vocabulary History
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search vocabulary..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 3, input: { color: 'white' } }}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
      />
      
      <Grid container spacing={3}>
        {filteredHistory.map((entry) => (
          <Grid item xs={12} sm={6} md={4} key={entry.id}>
            <Card sx={{ bgcolor: '#2a2e33', color: 'white', border: '1px solid #373b40' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ color: '#0084ff', fontWeight: 'bold' }}>
                        Block #{entry.block.order}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#8a8d91' }}>
                        Completed: {formatDate(entry.completed_at)}
                    </Typography>
                </Box>
                

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {entry.block.words.map((word) => (
                    <Button
                        key={word.id}
                        onClick={() => handleWordClick(word)}
                        sx={{
                            bgcolor: 'rgba(0, 132, 255, 0.1)',
                            color: '#0084ff',
                            '&:hover': { bgcolor: 'rgba(0, 132, 255, 0.2)' },
                            textTransform: 'none',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                            borderRadius: '16px',
                        }}
                        >
                        {word.word}
                    </Button>
                    ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <WordDetailsModal
        word={selectedWord}
        onClose={handleCloseModal}
        blockOrder={selectedWord ? history.find(entry => entry.block.words.some(w => w.id === selectedWord.id))?.block.order : null}

      />
    </Box>
  );
};

export default VocabHistoryPage;